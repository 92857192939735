// src/components/AuthGuard.jsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import WelcomeModal from '../WelcomeModal';

const AuthGuard = ({ children }) => {
  const { currentUser, userOnboardingStatus, setActiveStep, exploring, setExploring } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    

    // If no user, always show login modal
    if (!currentUser) {
      setShowLoginModal(true);
      return;
    }

    // If user needs onboarding, keep modal open
    // need to track in db is onboarding is complete
    if (currentUser && userOnboardingStatus?.isComplete != true) {
      setShowLoginModal(true);
      // setActiveStep(2)
      return;
    }

    // User is authenticated and doesn't need onboarding
    setShowLoginModal(false);

  }, [currentUser, userOnboardingStatus]);

  const exploreHandler = () => {
    setExploring(true);
    setShowLoginModal(false);
  } 

  useEffect(() => {
    if (!exploring) {
      setShowLoginModal(true);
    }
  }, [exploring]);

  return (
    <>
      {children}
      <WelcomeModal
        exploreHandler={exploreHandler}
        open={showLoginModal}
        activeStep={userOnboardingStatus?.currentStep}
        setActiveStep={setActiveStep}
        onClose={() => {
          // Only allow closing if user is authenticated and onboarding is complete
          if (currentUser && !userOnboardingStatus.needsOnboarding) {
            setShowLoginModal(false);
          }
        }}
      />
    </>
  );
};

export default AuthGuard;