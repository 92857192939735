import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Divider,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailOutlineIcon from '@mui/icons-material/AlternateEmail';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// Custom styled components
const AuthButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: '4px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  }
}));

const SocialButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: '12px',
  borderRadius: '4px',
  textTransform: 'none',
  fontWeight: 500,
  border: `1px solid ${theme.palette.divider}`,
  color: 'white',
  backgroundColor: 'rgba(255, 255, 255, 0.23)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '16px',
  backgroundColor: 'rgba(255, 255, 255, 0.09)',
  borderRadius: '4px',
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.39)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.6)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.6)',
    },
    '& input': {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },

  '& .MuiInputLabel-root': {
    color: 'white',
    '&.Mui-focused': {
      color: 'white',
    },
  },

  '& .MuiInputAdornment-root': {
    color: 'white',
  },

  '& .MuiFormHelperText-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

export default function WelcomePage({
  isSignUp,
  formData,
  setFormData,
  errors,
  handleToggleSignUp,
  handleGoogleSignIn,
  handleSubmit,
  exploreHandler
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight="700" sx={{ color: '#f1f1f1', mb: 1 }}>
        {isSignUp ? 'Create Account' : 'graphify'}
      </Typography>
      <Typography variant="body2" color="#f1f1f1" sx={{ mb: 3 }}>
        {isSignUp ? 'Time to stop doomscrolling and explore it.' : 'Stop doomscrolling and explore it.'}
      </Typography>


      {isSignUp && (
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <StyledTextField
            name="firstName"
            label="First Name"
            fullWidth
            value={formData.firstName}
            onChange={handleInputChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon sx={{ color: 'white' }} fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            name="lastName"
            label="Last Name"
            fullWidth
            value={formData.lastName}
            onChange={handleInputChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      <StyledTextField
        name="email"
        label="Email"
        fullWidth
        value={formData.email}
        onChange={handleInputChange}
        error={!!errors.email}
        helperText={errors.email}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlineIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />

      <StyledTextField
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        value={formData.password}
        onChange={handleInputChange}
        error={!!errors.password}
        helperText={errors.password}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {isSignUp && (
        <StyledTextField
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          fullWidth
          value={formData.confirmPassword}
          onChange={handleInputChange}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        <Divider sx={{ flex: 1, borderColor: "rgba(255, 255, 255, 0.39)" }} />
        <Typography variant="body2" color="white" sx={{ mx: 2 }}>
          OR
        </Typography>
        <Divider sx={{ flex: 1, borderColor: "rgba(255, 255, 255, 0.39)" }} />
      </Box>

      <SocialButton
        startIcon={<GoogleIcon />}
        onClick={handleGoogleSignIn}
        fullWidth
        sx={{ mb: 3 }}
      >
        Continue with Google
      </SocialButton>


      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <AuthButton
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.09)',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)',
            border: '1px solid rgba(255, 255, 255, 0.39)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }
          }}
          onClick={exploreHandler}
        >
          Explore
        </AuthButton>

        <AuthButton
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)',
            '&:hover': {
              backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)',
            }
          }}
          onClick={handleSubmit}
        >
          {isSignUp ? 'Continue' : 'Sign In'}
        </AuthButton>

      </Box>

      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Typography variant="body2" color="white">
          {isSignUp ? 'Already have an account?' : "Don't have an account?"}
          <Link
            component="button"
            variant="body2"
            onClick={handleToggleSignUp}
            sx={{ ml: 1, fontWeight: 600 }}
          >
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

