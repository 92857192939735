import React from 'react';
import {
  Box,
  Typography,
  Button,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAuth } from '../contexts/AuthContext';

const AuthButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  }
}));

const SuccessPage = ({
  isSignUp,
  dataPermission,
  setDataPermission,
  onClose,
  handleNext,
  activeStep,
  setActiveStep
}) => {
  const { logout } = useAuth();
  const theme = useTheme();

  return (
    <Box sx={{ textAlign: 'center', py: 3 }}>
      <CheckCircleOutlineIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
      <Typography variant="h5" fontWeight="700" mb={2}>
        {isSignUp ? 'Account Created' : 'Sign In Successful'}
      </Typography>

      <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" mb={2}>
        {isSignUp
          ? 'Your account has been created successfully. Before you get started, we need to know a few things about you.'
          : 'Welcome! You have been signed in successfully.'}
      </Typography>

      <Box sx={{
        display: 'flex',
        alignItems: 'flex-start',
        textAlign: 'left',
        bgcolor: 'rgba(255, 255, 255, 0.09)',
        p: 2,
        borderRadius: 2,
        mb: 3,
        border: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.39)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            id="data-permission"
            checked={dataPermission}
            onChange={(e) => setDataPermission(e.target.checked)}
            style={{
              cursor: 'pointer',
              width: '18px',
              height: '18px',
              marginRight: '12px',
              accentColor: '#7C00CC'
            }}
          />
        </Box>
        <Box>
          <Typography
            component="label"
            htmlFor="data-permission"
            variant="body2"
            fontWeight="600"
            sx={{ cursor: 'pointer', display: 'block', mb: 0.5 }}
          >
            Research Data Permission
          </Typography>
          <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
            We have permission to use your data for research purposes. Your personal data is never shared and is kept private in accordance with our Privacy Policy.
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <AuthButton
          variant="outlined"
          color="primary"
          onClick={logout}
          sx={{ flex: 1, color: 'white', borderColor: 'rgba(255, 255, 255, 0.39)', '&:hover': { borderColor: 'rgba(255, 255, 255, 0.6)', backgroundColor: 'rgba(255, 255, 255, 0.10)' } }}
        >
          Sign Out
        </AuthButton>
        <AuthButton
          variant="outlined"
          color="primary"
          onClick={() => setActiveStep(activeStep + 2)}
          sx={{ flex: 1, color: 'white', borderColor: 'rgba(255, 255, 255, 0.39)', '&:hover': { borderColor: 'rgba(255, 255, 255, 0.6)', backgroundColor: 'rgba(255, 255, 255, 0.10)' } }}
        >
          Skip Survey
        </AuthButton>
        <AuthButton
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{
            flex: 1, backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)',
            '&:hover': {
              backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)',
            }
          }}
        >
          Take Short Survey
        </AuthButton>
      </Box>
    </Box>
  );
};

export default SuccessPage;