import React, { useRef, useState, useEffect, useMemo } from "react";
import { ForceGraph3D } from "react-force-graph";
import * as THREE from "three";
import { useAuth } from './contexts/AuthContext';
import { getGraphData, processFiles } from './utils/api';
import data from './utils/mock';

const hexToRGBA = (hex, alpha = 0.1) => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


const DemoGraphVisualizer = ({ exploring, setSelectedNode, selectedNode, setShowVideo, highlightLinks, setHighlightLinks, highlightNodes, setHighlightNodes }) => {
    const fgRef = useRef();
    const [graphData, setGraphData] = useState({ nodes: [], links: [] });
    const [nodeDegrees, setNodeDegrees] = useState({});
    const [autoRotate, setAutoRotate] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const [hoveredNode, setHoveredNode] = useState(null);
    const linkHoverTimeoutRef = useRef(null);
    const nodeHoverTimeoutRef = useRef(null);

    useEffect(() => {
        if (!exploring) {
            setAutoRotate(true)
        } else {
            setAutoRotate(false)
        }
        const degrees = {};
        data.links.forEach(({ source, target }) => {
            const sourceId = typeof source === 'object' ? source.id : source;
            const targetId = typeof target === 'object' ? target.id : target;

            degrees[sourceId] = (degrees[sourceId] || 0) + 1;
            degrees[targetId] = (degrees[targetId] || 0) + 1;
        });
        console.log("degrees", degrees)
        setNodeDegrees(degrees);
        setGraphData(data);
    }, [exploring]);



    const getNodeColor = (node) => {
        if (highlightNodes.has(node.id || node.name)) {
            return "#ffffff"; // Highlight color
        }

        // Color based on degree (number of connections)
        const degree = nodeDegrees[node.id || node.name] || 0;

        // Color scale based on connection count
        if (degree >= 4) return "#ff4f4f";      // Red for highly connected
        if (degree === 3) return "#ffaf4f";     // Orange
        if (degree === 2) return "#4fff9f";     // Green
        if (degree === 1) return "#4fc3ff";     // Blue
        return "#af4fff";                       // Purple for isolated nodes
    };
    const getLinkColor = (link) => {
        return highlightLinks.has(link) ? "#ffffff" : "#666666";
    };

    const getNodeSize = (node) => {
        const degree = nodeDegrees[node.id] || 1;
        const baseSize = Math.min(5 + degree * 0.5, 15);

        return highlightNodes.has(node.id) ? baseSize * 1.5 : baseSize;
    };

    const handleNodeClick = (node) => {
        console.log("Node clicked:", node);

        if (node.id == selectedNode?.id) {
            setSelectedNode(null)
            setShowVideo(false)
            return
        } else {
        }

        setSelectedNode(node);
        setShowVideo(true);

        // Highlight connected nodes and links
        const connectedLinks = graphData.links.filter(
            link => link.source.id === node.id || link.target.id === node.id
        );

        const connectedNodes = new Set();
        connectedLinks.forEach(link => {
            connectedNodes.add(link.source.id);
            connectedNodes.add(link.target.id);
        });

        setHighlightLinks(new Set(connectedLinks));
        setHighlightNodes(connectedNodes);

        // Move camera to focus on the node
        const distance = 50;
        const offsetX = 50; // Adjust this value to shift more to the left


        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

        if (fgRef.current) {
            fgRef.current.cameraPosition(
                { x: (node.x * distRatio) + offsetX, y: node.y * distRatio, z: node.z * distRatio },
                node,
                5000
            );
        }
    };


    // Create text sprite for node labels
    const createTextSprite = (text, color) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = 500;
        canvas.height = 64;
        context.fillStyle = "rgba(0, 0, 0, 0)";
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.font = "Bold 24px Inter, system-ui, sans-serif";
        context.fillStyle = color;
        context.textAlign = "center";
        context.fillText(text, canvas.width / 2, canvas.height / 2);

        return canvas;
    };



    useEffect(() => {
        let animationFrameId;
        let angle = 0;

        const animateRotation = () => {
            if (autoRotate && fgRef.current) {
                const radius = 3000; // Distance from center
                angle += 0.0005; // Rotation speed

                const x = radius * Math.sin(angle);
                const z = radius * Math.cos(angle);

                fgRef.current.cameraPosition(
                    { x, y: 100, z },
                    { x: 0, y: 0, z: 0 }, // lookAt
                    0 // instant move
                );
            }

            animationFrameId = requestAnimationFrame(animateRotation);
        };

        if (autoRotate) animateRotation();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [autoRotate]);



    const handleNodeHover = (node) => {
        document.body.style.cursor = node ? 'pointer' : 'default';

        // if (node) { 
        //  clearTimeout(nodeHoverTimeoutRef.current);
        //   nodeHoverTimeoutRef.current = setTimeout(() => {
        //     setHoveredNode(node);
        //     document.body.style.cursor = 'pointer';
        //   }, 1000);
        // } else {
        //   setHoveredNode(null);
        //   document.body.style.cursor = 'default';
        //   nodeHoverTimeoutRef.current = null;

        // }
    };


    const handleLinkHover = (link) => {
        document.body.style.cursor = link ? 'pointer' : 'default';

        // User started hovering a link
        if (link) {
            // Clear any previous timeout (just in case)
            clearTimeout(linkHoverTimeoutRef.current);
            // Start a new timeout to set the hoveredLink after 1 second
            linkHoverTimeoutRef.current = setTimeout(() => {
                console.log("Setting timeout...");
                setHoveredLink(link);
            }, 1000);
        } else {
            // User stopped hovering
            clearTimeout(linkHoverTimeoutRef.current);
            linkHoverTimeoutRef.current = null;
            setHoveredLink(null);
        }
    };


    useEffect(() => {
        return () => {
            clearTimeout(linkHoverTimeoutRef.current);
            clearTimeout(nodeHoverTimeoutRef.current);
            document.body.style.cursor = 'default';
        }

    }, []);



    return (
        <div className="w-full h-screen relative bg-black overflow-hidden">
            <div className="w-full h-full">
                <ForceGraph3D
                    // nodeAutoColorBy="id"
                    ref={fgRef}
                    graphData={graphData}
                    linkSource={"source_id"}
                    linkTarget={"target_id"}
                    backgroundColor="#000000"
                    linkColor={getLinkColor}
                    linkWidth={link => highlightLinks.has(link) ? 2 : 1}
                    linkOpacity={0.6}
                    linkDirectionalParticles={2}
                    linkDirectionalParticleWidth={link => highlightLinks.has(link) ? 3 : 1}
                    linkDirectionalParticleSpeed={() => 0.005}
                    onLinkHover={handleLinkHover}
                    nodeRelSize={6}
                    onNodeClick={handleNodeClick}
                    onNodeHover={handleNodeHover}
                    // onEngineStop={() => {
                    //     console.log("Engine stopped");
                    //     // Set a timeout to consider the simulation idle after a short delay
                    //     setTimeout(() => {
                    //         // setIsSimulationActive(false);
                    //     }, 1000);
                    // }}
                    cooldownTicks={100}
                    nodeThreeObject={(node) => {
                        const material = new THREE.MeshBasicMaterial({
                            color: getNodeColor(node),
                            transparent: true,
                            opacity: 0.9,
                        });

                        const sphere = new THREE.Mesh(
                            new THREE.SphereGeometry(getNodeSize(node)),
                            material
                        );

                        // Add text label
                        const spriteMaterial = new THREE.SpriteMaterial({
                            map: new THREE.CanvasTexture(
                                createTextSprite( node.name || node.id, getNodeColor(node))
                            ),
                            depthTest: false,
                        });

                        const sprite = new THREE.Sprite(spriteMaterial);
                        sprite.scale.set(20, 10, 1);
                        sprite.position.set(0, 10, 0);
                        sphere.add(sprite);

                        // Add subtle glow effect
                        if (highlightNodes.has(node.id)) {
                            const glowMaterial = new THREE.MeshBasicMaterial({
                                color: "#ffffff",
                                transparent: true,
                                opacity: 0.15,
                            });
                            const glow = new THREE.Mesh(
                                new THREE.SphereGeometry(getNodeSize(node) * 1.5),
                                glowMaterial
                            );
                            sphere.add(glow);
                        }

                        return sphere;
                    }}
                />
            </div>
            {hoveredLink && !hoveredNode && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: 10,
                        padding: '12px',
                        fontSize: '0.9rem',
                        zIndex: 999,
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(12px)',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                        color: 'white',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: 6,
                    }}
                >
                    <div><strong>Source:</strong> {hoveredLink.source?.id || hoveredLink.source_id}</div>
                    <div><strong>Target:</strong> {hoveredLink.target?.id || hoveredLink.target_id}</div>
                    {(hoveredLink.metadata?.description_x || hoveredLink.data?.description_x) && (
                        <div><strong>Context:</strong> {hoveredLink.metadata?.description_x || hoveredLink.data?.description_x}</div>
                    )}
                    {(hoveredLink.metadata?.title || hoveredLink.data?.title) && (
                        <div><strong>Video:</strong> {hoveredLink.metadata?.title || hoveredLink.data?.title}</div>
                    )}
                </div>
            )}

            {hoveredNode && !hoveredLink && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 10, // stack it above the link tooltip
                        left: 10,
                        padding: '12px',
                        fontSize: '0.9rem',
                        zIndex: 999,
                        backgroundColor: hexToRGBA(getNodeColor(hoveredNode), 0.15),
                        backdropFilter: 'blur(12px)',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                        color: 'white',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: 6,
                    }}
                >
                    <div><strong>Topic:</strong> {hoveredNode.name || hoveredNode.id}</div>
                    {(hoveredNode.data?.title || hoveredNode.metadata?.title) && (
                        <div><strong>Video:</strong> {hoveredNode.data?.title || hoveredNode.metadata?.title}</div>
                    )}
                    {(hoveredNode.data?.description || hoveredNode.metadata?.description) && (
                        <div><strong>Description:</strong> {hoveredNode.data?.description || hoveredNode.metadata?.description}</div>
                    )}
                    {hoveredNode.data?.time_saved && (
                        <div><strong>You saved this video on {hoveredNode.data.time_saved}</strong></div>
                    )}
                </div>
            )}

        </div>
    );
};

export default DemoGraphVisualizer;