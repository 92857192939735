import React from 'react';
import {
  Box,
  Typography,
  Button,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { userCheck } from '../utils/api';
const AuthButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  }
}));

const StyledTextField = styled('select')(({ theme }) => ({
  marginBottom: '16px',
  padding: '12px 14px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.divider}`,
  fontSize: '1rem',
  width: '100%',
  outline: 'none',
  fontFamily: theme.typography.fontFamily,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}20`,
  }
}));

const StyledInput = styled('input')(({ theme }) => ({
  marginBottom: '16px',
  padding: '12px 14px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.divider}`,
  fontSize: '1rem',
  width: '100%',
  outline: 'none',
  fontFamily: theme.typography.fontFamily,
  '&::placeholder': {
    color: theme.palette.text.disabled,
  },
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}20`,
  }
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  marginBottom: '8px',
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: '0.875rem',
}));

const SurveyPage = ({ surveyData, setSurveyData, handleSkipSurvey, handleSurveySubmit, activeStep, setActiveStep }) => {
  const handleSurveyChange = (e) => {
    const { name, value } = e.target;
    setSurveyData({
      ...surveyData,
      [name]: value
    });
  };

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h5" fontWeight="700" mb={2}>
        Research Survey (Optional)
      </Typography>

      <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" mb={3}>
        Help us understand our users better. All questions are optional and your responses will remain anonymous.
      </Typography>

      <Box sx={{ maxHeight: '400px', overflowY: 'auto', pr: 2, mb: 3 }}>
        <Typography variant="subtitle2" fontWeight="600" mb={1}>Basic Information</Typography>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Age Bracket</InputLabel>
          <StyledTextField
            name="ageBracket"
            value={surveyData.ageBracket}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="13-17">13-17</option>
            <option value="18-24">18-24</option>
            <option value="25-34">25-34</option>
            <option value="35+">35+</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Gender Identity</InputLabel>
          <StyledTextField
            name="genderIdentity"
            value={surveyData.genderIdentity}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Non-binary">Non-binary</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Location (City, State, Country)</InputLabel>
          <StyledInput
            name="location"
            value={surveyData.location}
            onChange={handleSurveyChange}
            placeholder="Optional"
          />
        </Box>

        <Box mb={3}>
          <InputLabel sx={{ color: 'white' }}>Language Preference</InputLabel>
          <StyledInput
            name="languagePreference"
            value={surveyData.languagePreference}
            onChange={handleSurveyChange}
            placeholder="Languages you consume content in"
          />
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle2" fontWeight="600" mb={1}>Content & Shopping Preferences</Typography>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Do you engage with luxury brands, discount shopping, or financial planning content?</InputLabel>
          <StyledTextField
            name="incomeInterests"
            value={surveyData.incomeInterests}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Luxury brands">Luxury brands</option>
            <option value="Discount shopping">Discount shopping</option>
            <option value="Financial planning">Financial planning</option>
            <option value="Mix of all">Mix of all</option>
            <option value="None of these">None of these</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>How often do you engage with ads?</InputLabel>
          <StyledTextField
            name="adInteraction"
            value={surveyData.adInteraction}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Very frequently">Very frequently</option>
            <option value="Occasionally">Occasionally</option>
            <option value="Rarely">Rarely</option>
            <option value="Never">Never</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>How frequently do you click on sponsored content?</InputLabel>
          <StyledTextField
            name="clickThroughRate"
            value={surveyData.clickThroughRate}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Very frequently">Very frequently</option>
            <option value="Occasionally">Occasionally</option>
            <option value="Rarely">Rarely</option>
            <option value="Never">Never</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Do you engage with TikTok Shop, product drops, or brand collaborations?</InputLabel>
          <StyledTextField
            name="purchaseBehavior"
            value={surveyData.purchaseBehavior}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Yes, frequently">Yes, frequently</option>
            <option value="Yes, occasionally">Yes, occasionally</option>
            <option value="Rarely">Rarely</option>
            <option value="No, never">No, never</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Do you engage with influencer promo codes or referral links?</InputLabel>
          <StyledTextField
            name="affiliateEngagement"
            value={surveyData.affiliateEngagement}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Yes, frequently">Yes, frequently</option>
            <option value="Yes, occasionally">Yes, occasionally</option>
            <option value="Rarely">Rarely</option>
            <option value="No, never">No, never</option>
          </StyledTextField>
        </Box>

        <Box mb={3}>
          <InputLabel sx={{ color: 'white' }}>What is something super niche you are into?</InputLabel>
          <StyledInput
            name="nicheInterest"
            value={surveyData.nicheInterest}
            onChange={handleSurveyChange}
            placeholder="Optional"
          />
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle2" fontWeight="600" mb={1}>Shopping Behavior</Typography>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>How often do you buy from impulse purchases after seeing a TikTok video?</InputLabel>
          <StyledTextField
            name="impulseFrequency"
            value={surveyData.impulseFrequency}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Very frequently">Very frequently</option>
            <option value="Occasionally">Occasionally</option>
            <option value="Rarely">Rarely</option>
            <option value="Never">Never</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Do you look for discount codes or deals before purchasing?</InputLabel>
          <StyledTextField
            name="discountUse"
            value={surveyData.discountUse}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Always">Always</option>
            <option value="Usually">Usually</option>
            <option value="Sometimes">Sometimes</option>
            <option value="Rarely">Rarely</option>
            <option value="Never">Never</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>What's the highest-priced item you've bought because of TikTok?</InputLabel>
          <StyledInput
            name="highestPurchase"
            value={surveyData.highestPurchase}
            onChange={handleSurveyChange}
            placeholder="Optional (e.g., $50, $200, etc.)"
          />
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Are you more likely to buy from small businesses or big brands on TikTok?</InputLabel>
          <StyledTextField
            name="preferredBusiness"
            value={surveyData.preferredBusiness}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Small businesses">Small businesses</option>
            <option value="Big brands">Big brands</option>
            <option value="Both equally">Both equally</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Do you prefer high-end, mid-tier, or budget-friendly product recommendations?</InputLabel>
          <StyledTextField
            name="pricePreference"
            value={surveyData.pricePreference}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="High-end">High-end</option>
            <option value="Mid-tier">Mid-tier</option>
            <option value="Budget-friendly">Budget-friendly</option>
            <option value="Depends on the product">Depends on the product</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>How frequently do you purchase something after seeing it on TikTok?</InputLabel>
          <StyledTextField
            name="purchaseFrequency"
            value={surveyData.purchaseFrequency}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="A few times a year">A few times a year</option>
            <option value="Rarely">Rarely</option>
            <option value="Never">Never</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Do you prefer shopping directly on TikTok or visiting an external website?</InputLabel>
          <StyledTextField
            name="shoppingPreference"
            value={surveyData.shoppingPreference}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="TikTok Shop">TikTok Shop</option>
            <option value="External website">External website</option>
            <option value="No preference">No preference</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>What type of product ads are most likely to get your attention?</InputLabel>
          <StyledTextField
            name="productInterest"
            value={surveyData.productInterest}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Tech">Tech</option>
            <option value="Beauty">Beauty</option>
            <option value="Clothing">Clothing</option>
            <option value="Fitness">Fitness</option>
            <option value="Digital services">Digital services</option>
            <option value="Home goods">Home goods</option>
            <option value="Food & Drinks">Food & Drinks</option>
            <option value="Other">Other</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Do you trust TikTok influencers' recommendations?</InputLabel>
          <StyledTextField
            name="trustInfluencers"
            value={surveyData.trustInfluencers}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Yes, completely">Yes, completely</option>
            <option value="Somewhat">Somewhat</option>
            <option value="Depends on the influencer">Depends on the influencer</option>
            <option value="Not really">Not really</option>
            <option value="Not at all">Not at all</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>Have you ever used TikTok for product research before making a purchase?</InputLabel>
          <StyledTextField
            name="productResearch"
            value={surveyData.productResearch}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Yes, frequently">Yes, frequently</option>
            <option value="Yes, occasionally">Yes, occasionally</option>
            <option value="Rarely">Rarely</option>
            <option value="No, never">No, never</option>
          </StyledTextField>
        </Box>

        <Box mb={2}>
          <InputLabel sx={{ color: 'white' }}>How important are reviews from TikTok creators in your buying decision?</InputLabel>
          <StyledTextField
            name="reviewImportance"
            value={surveyData.reviewImportance}
            onChange={handleSurveyChange}
          >
            <option value="">Select (optional)</option>
            <option value="Very important">Very important</option>
            <option value="Somewhat important">Somewhat important</option>
            <option value="Not very important">Not very important</option>
            <option value="Not important at all">Not important at all</option>
          </StyledTextField>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <AuthButton
          variant="outlined"
          onClick={() => setActiveStep(activeStep - 1)}
          sx={{ flex: 1, color: 'white', borderColor: 'rgba(255, 255, 255, 0.39)', '&:hover': { borderColor: 'rgba(255, 255, 255, 0.6)', backgroundColor: 'rgba(255, 255, 255, 0.10)' } }}
        >
          Go Back
        </AuthButton>
        {/* <Button 
          variant="outlined"
          onClick={handleSkipSurvey}
          sx={{ flex: 1, borderRadius: '8px', textTransform: 'none' }}
        >
          Skip
        </Button> */}
        <AuthButton
          variant="contained"
          color="primary"
   
          onClick={() => {
            handleSurveySubmit();
            userCheck();
          }}
          sx={{
            flex: 1,
            backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)',
            '&:hover': {
              backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)',
            }
          }}
        >
          Submit & Continue
        </AuthButton>
      </Box>
    </Box>
  );
};

export default SurveyPage;