import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  useMediaQuery
} from '@mui/material';

// Import page components (import paths will depend on your project structure)
import WelcomePage from './components/WelcomePage';
import VerifyPage from './components/VerifyPage';
import SuccessPage from './components/SuccessPage';
import SurveyPage from './components/SurveyPage';
import DataUploadPage from './components/DataUploadPage';
import { getGraphData, processFiles, uploadToGoogleCloud, userCheck } from './utils/api';
import { useAuth } from './contexts/AuthContext';
import { createSurvey } from './utils/api';
const MAX_ROTATE = 1; // degrees

const WelcomeModal = ({ open, onClose, activeStep, setActiveStep, exploreHandler }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSignUp, setIsSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataPermission, setDataPermission] = useState(true);
  const [user_id, setUserId] = useState(null);
  const { signup, login, loginWithGoogle, currentUser, completeOnboarding, userOnboardingStatus } = useAuth();
  const tiltRef = useRef(null);
  const [transform, setTransform] = useState('rotateX(0deg) rotateY(0deg)');
  const [uploading, setUploading] = useState(false);


  const handleMouseMove = (e) => {
    const rect = tiltRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const rotateX = ((y - centerY) / centerY) * -MAX_ROTATE;
    const rotateY = ((x - centerX) / centerX) * MAX_ROTATE;

    setTransform(`rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
  };

  const resetTransform = () => {
    setTransform('rotateX(0deg) rotateY(0deg)');
  };

  // Form data state
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  // Survey data state
  const [surveyData, setSurveyData] = useState({
    ageBracket: '',
    genderIdentity: '',
    location: '',
    languagePreference: '',
    incomeInterests: '',
    adInteraction: '',
    clickThroughRate: '',
    purchaseBehavior: '',
    affiliateEngagement: '',
    nicheInterest: '',
    impulseFrequency: '',
    discountUse: '',
    highestPurchase: '',
    preferredBusiness: '',
    pricePreference: '',
    purchaseFrequency: '',
    shoppingPreference: '',
    productInterest: '',
    trustInfluencers: '',
    productResearch: '',
    reviewImportance: ''
  });

  // Upload data state
  const [uploadData, setUploadData] = useState({
    tiktok: [],
    google: [],
    instagram: [],
    twitter: []
  });

  // Validation errors
  const [errors, setErrors] = useState({});

  // Reset state when modal opens
  useEffect(() => {
    if (open) {
      setIsSignUp(false);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
      });
      setErrors({});
    }
  }, [open]);


  // Form validation
  const validateForm = () => {
    const newErrors = {};

    if (isSignUp) {
      if (!formData.firstName) newErrors.firstName = 'First name is required';
      if (!formData.lastName) newErrors.lastName = 'Last name is required';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    if (isSignUp && formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Navigation handlers
  const handleNext = () => {
    if (activeStep === 0) {
      if (validateForm()) {
        setActiveStep(1);
      }
    } else if (activeStep === 1) {
      handleSubmit();
    } else if (activeStep === 2) {
      setActiveStep(3);
    } else if (activeStep === 3) {
      setActiveStep(4);
    } else if (activeStep === 4) {
      handleFinalComplete();
    }
  };

  const handleBack = () => {
    setLoading(false)
    setActiveStep(activeStep - 1);
    // console.log("activeStep", activeStep)
  };

  // Auth handlers
  const handleSubmit = async () => {
    console.log("handleSubmit")
    setLoading(true);
    // console.log('Form submitted:', {
    //   ...formData,
    //   name: formData.firstName + " " + formData.lastName,
    //   dataPermission
    // });

    const payload = {
      ...formData,
      name: formData.firstName + " " + formData.lastName,
      dataPermission
    }

    if (isSignUp) {
      await signup(formData.email, formData.password).then(response => {
        console.log("signup response", response)
      }).catch(error => {
        console.error('Error signing up:', error);
        alert('Failed to sign up. Please try again.');
        setActiveStep(0);
        setLoading(false);
      });
    } else {
      await login(formData.email, formData.password).then(response => {
      }).catch(error => {
        console.error('Error logging in:', error);
        alert('Failed to log in. Please try again.');
        setActiveStep(0);
        setLoading(false);
      });
    }
  };

  const handleToggleSignUp = () => {
    setIsSignUp(!isSignUp);
    setErrors({});
  };

  const handleGoogleSignIn = async () => {
    try {
      setErrors({});
      setLoading(true);
      await loginWithGoogle()
    } catch (error) {
      setErrors({ error: `Failed to sign in with Google: ${error.message}` });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Survey handlers
  const handleSurveySubmit = async () => {
    console.log('Survey submitted:', surveyData);
    const payload = {
      ...surveyData,
      user_id: currentUser.id
    }
    await createSurvey(payload)
    setActiveStep(activeStep + 1); // Move to data upload step
  };

  const handleSkipSurvey = () => {
    console.log('Survey skipped');
    setActiveStep(4); // Skip to data upload step
  };

  // Upload handlers
  const handleUploadComplete = () => {
    setLoading(true)
    setUploading(true)
    console.log('Upload completed:', uploadData);
    handleFinalComplete();
  };

  const handleSkipUpload = () => {
    console.log('Upload skipped');
    handleFinalComplete();
  };

  // Complete flow
  const handleFinalComplete = async () => {
    console.log('Registration flow completed');
    try {

      const uploadResults = {};

      for (const platform in uploadData) {

        if (!uploadData[platform] || uploadData[platform].length === 0) {
          continue;
        }

        const platformResults = await uploadPlatformFiles(platform, uploadData[platform], user_id);
        uploadResults[platform] = platformResults;
      }

      console.log('Upload results:', uploadResults);

      getGraphData().then(graphData => {
        console.log(graphData);
        if (graphData.nodes.length === 0 && graphData.links.length === 0) {
          // createNode({...mockGraphData.nodes[0], graph_id: graphData.id, name: mockGraphData.nodes[0].id})
          console.log("created node")
          processFiles('tiktok').then(response => {
            console.log("Starting to process files...")
            console.log(response);
            return response;
          }).catch(error => {
            console.error('Error processing files:', error);
          });
        }
      }).catch(error => {
        console.error('Error getting graph data:', error);
      });

      completeOnboarding()
      setUploading(false)
    } catch (error) {
      console.error('Error during upload:', error);
      alert('An error occurred while uploading your data. Please try again.');
    } finally {
      setLoading(false);
    }

    // Close the modal and return to app
    //onClose();

  };


  const uploadPlatformFiles = async (platform, files, user_id) => {
    const results = [];

    for (const file of files) {
      // Skip files that aren't activity files if you only want those      
      try {
        // Upload to GCS - assuming you have access to the file content
        const cloudUrl = await uploadToGoogleCloud(file, platform, user_id);

        results.push({
          fileName: file.fileName,
          path: file.path,
          cloudUrl,
          status: 'uploaded_to_cloud'
        });
      } catch (error) {
        console.error(`Failed to upload ${file.fileName}:`, error);

        results.push({
          fileName: file.fileName,
          path: file.path,
          error: error.message,
          status: 'failed'
        });
      }
    }


    return results;
  };


  // Define steps based on sign up or login flow
  const getSteps = () => {
    return isSignUp
      ? ['Account', 'Security', 'Complete', 'Research', 'Data']
      : ['Login', 'Verify', 'Complete', 'Research', 'Data'];
  };

  const steps = getSteps();


  // Render the current step
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <WelcomePage
            isSignUp={isSignUp}
            exploreHandler={exploreHandler}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            handleNext={handleNext}
            handleToggleSignUp={handleToggleSignUp}
            handleGoogleSignIn={handleGoogleSignIn}
            handleSubmit={handleSubmit}
          />
        );

      case 1:
        return (
          <VerifyPage
            isSignUp={isSignUp}
            formData={formData}
            loading={loading}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
          />
        );

      case 2:
        return (
          <SuccessPage
            isSignUp={isSignUp}
            dataPermission={dataPermission}
            setDataPermission={setDataPermission}
            onClose={onClose}
            handleNext={handleNext}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );

      case 3:
        return (
          <SurveyPage
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            handleSkipSurvey={handleSkipSurvey}
            handleSurveySubmit={handleSurveySubmit}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );

      case 4:
        return (
          <DataUploadPage
            loading={loading}
            uploadingLoad={uploading}
            uploadData={uploadData}
            setUploadData={setUploadData}
            handleSkipUpload={handleSkipUpload}
            handleUploadComplete={handleUploadComplete}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={null}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          maxWidth: activeStep === 4 ? null : '500px',
          borderRadius: { xs: 0, sm: 3 },
          m: { xs: 0, sm: 2 },
          height: { xs: '100%', sm: 'auto' },
          overflowY: 'auto',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          backdropFilter: 'blur(12px)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
          color: 'white',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }
      }}
    >
      <DialogContent
        sx={{
          p: { xs: 2, sm: 4 },
          backgroundColor: 'transparent',
          perspective: '1000px',
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={resetTransform}
        ref={tiltRef}
      >
        <Box
          sx={{
            transform,
            transition: 'transform 0.1s ease-out',
            transformStyle: 'preserve-3d',
          }}
        >
          {activeStep > 0 && (
            <Box sx={{ width: '100%', mb: 4 }}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  '& .MuiStepLabel-label': {
                    color: 'white', // Inactive label
                    '&.Mui-active': {
                      color: '#B300B3', // Active step label
                    },
                    '&.Mui-completed': {
                      color: '#6E00B3', // Completed step label
                    },
                  },
                  '& .MuiStepIcon-root': {
                    color: 'rgba(255, 255, 255, 0.4)', // Default (inactive)
                    '&.Mui-active': {
                      color: '#B300B3', // Active icon
                    },
                    '&.Mui-completed': {
                      color: '#6E00B3', // Completed icon
                    },
                  },
                  '& .MuiStepIcon-text': {
                    fill: 'white', // Step number inside circle
                  }
                }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

          )}

          {renderStepContent(activeStep)}
        </Box>
      </DialogContent>
    </Dialog>
  );

};

export default WelcomeModal;