import React from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

const AuthButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  }
}));

const VerifyPage = ({ isSignUp, formData, loading, handleBack, handleSubmit }) => {
  return (
    <Box>
      <Typography variant="h5" fontWeight="700" mb={3}>
        {isSignUp ? 'Almost There' : 'Verify Identity'}
      </Typography>

      <Typography variant="body2" color="white" mb={3}>
        {isSignUp
          ? 'Please confirm your details before creating your account.'
          : 'We need to verify your identity to continue.'}
      </Typography>

      <Box sx={{
        bgcolor: 'rgba(255, 255, 255, 0.09)',
        p: 3,
        borderRadius: 2,
        mb: 3
      }}>
        <Typography variant="body2" fontWeight="600" mb={1}>
          Account Information
        </Typography>

        {isSignUp && (
          <Typography variant="body2" mb={1}>
            Name: {formData.firstName} {formData.lastName}
          </Typography>
        )}

        <Typography variant="body2" mb={1}>
          Email: {formData.email}
        </Typography>

        <Typography variant="body2">
          Password: {formData.password.replace(/./g, '•')}
        </Typography>
      </Box>

      <Typography variant="body2" color="white" mb={2}>
        By continuing, you agree to our Terms of Service and Privacy Policy.
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{ color: 'white', flex: 1, borderRadius: '8px', textTransform: 'none', borderColor: "rgba(255, 255, 255, 0.39)" }}
        >
          Back
        </Button>
        <AuthButton
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          sx={{
            flex: 1,
            backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)',
            '&:hover': {
              backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)',
            }
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            isSignUp ? 'Create Account' : 'Sign In'
          )}
        </AuthButton>
      </Box>
    </Box>
  );
};

export default VerifyPage;