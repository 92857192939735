const mockData = {
  id: "bec0c46b-b8cc-46d1-8b99-610c0832039d",
  created_at: "2025-04-08T10:01:20.065335",
  nodes: [
    {
      id: "247ff7d9-2b9a-43ea-947e-a1cff045cbdb",
      name: "RUG",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
      name: "THREE-DAY FAST",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "7fdf8e5b-32b3-4772-b52c-fcac2cfda691",
      name: "CHICAGO ESTATE ADVISORS",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      name: "ESTATE SALE",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "0a603499-b3f1-4dae-9385-0f25efcbdf0e",
      name: "CHANEL",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "1d3235e7-2a24-44dc-b372-42dd96f0f5c5",
      name: "ASTRA STREET",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "211408fa-6268-431b-849f-0bc2579303c7",
      name: "JEWELRY",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "949a3205-f16b-4f91-ae5d-5cdcb71246cc",
      name: "CLOTHES",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "78b26078-9166-4606-8a31-69063e11da26",
      name: "HEADBOARD",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "eb90a3e8-4578-4a4d-a044-1cbec5f525cb",
      name: "ANTIQUES",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "bcc755f1-b356-4907-aefc-2555dcdd60ec",
      name: "FIVE BELOW",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
      name: "LIVING SPACE",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "c973125a-22b9-471e-a202-3f88c6d35e6c",
      name: "KITCHEN",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      name: "STUDIO",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "b1e5498a-2b96-43b4-9e16-c5f012cc8e72",
      name: "PATIO",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "b4b2f459-5300-45ad-9398-c9b61b4be628",
      name: "BATHROOM",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
      name: "LSAC",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
      name: "LAW SCHOOLS",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      name: "GPA",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      name: "APPLICANT",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
      name: "OVERALL CUMULATIVE GPA",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
      name: "DEGREE GPA",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
      name: "FACEBOOK MARKETPLACE",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      name: "APARTMENT TRANSFORMATION",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      name: "IKEA",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      name: "STUDIO APARTMENT",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "1ea4b3a8-4d69-454a-a4bf-ea8a9e39ec1f",
      name: "DRESSER",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "a1f9ad59-42ed-48b6-baed-52f689359d7e",
      name: "QUEEN-SIZED BED FRAME",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
      name: "COFFEE TABLE",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
      name: "FLOOR LAMP",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "10ff1090-9b1e-4259-ae3e-a68ec9d8d889",
      name: "DINING CHAIRS",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "bff1c9c7-2ba4-4cb3-a753-733cef2b65cc",
      name: "RECRUITER SCREENING",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "bf163a77-6eaf-4055-bc65-94c666b51973",
      name: "VERBAL OFFER",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "8a0dbafe-c622-42cc-9409-1f9b6e7b377c",
      name: "SIX MONTH PROMOTION REVIEW",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
      name: "COMPANIES",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "5a933e1d-d70e-4a33-aeee-33749920651a",
      name: "HIRING BUDGETS",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
      name: "EMPLOYEES",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      name: "VP",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "7990f597-ecda-4897-94de-d8a3a7db025c",
      name: "STARTUP",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "82f87e77-7ff8-4ea1-a05c-d2f6e8731351",
      name: "LARGER COMPANIES",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "95503808-3df9-424f-b779-be3152c78a28",
      name: "PROGRAMS/INTERNSHIPS",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      name: "SALARY NEGOTIATION",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "9bacc367-af81-4348-80a7-5d0c3cfec81a",
      name: "RESEARCH",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
      name: "NEW YORK CITY",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "6c418376-f724-487e-aebf-ff618a6b4813",
      name: "MIRRORS",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "4de72e22-4f4f-4c5e-ae62-1716552455cd",
      name: "INTERIOR DESIGNERS",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "8d715494-92df-46a8-b1ac-eeb6123438ef",
      name: "GOLD COAST",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "d2dba83e-7451-4f9e-a741-7c7a0533abb5",
      name: "JANUARY 31ST TO FEBRUARY 2ND",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "34446084-c7d3-4ab8-87b5-cb23570f396e",
      name: "KOFIE",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "0036cab4-e395-4397-a82f-87872ecabecc",
      name: "NEW APARTMENT DECORATING SERIES",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "08840aac-cc9d-4f6b-ad07-2ba64e45f5da",
      name: "PEEL AND STICK WALLPAPER",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "93a9c8bf-2a4d-427f-ab49-8c6219fbe763",
      name: "STEAM MOP",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "2458d043-52e7-4607-970e-552d4d680d08",
      name: "SMART HOME AUTOMATION",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "3b14e240-3010-434f-ad03-0621badabf67",
      name: "TABLET INTERFACE",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
      name: "LIVING ROOM",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "3602b39f-c3c2-417a-900b-0a882180d352",
      name: "LIGHT SOURCES",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "31136ae2-5b67-43b5-ac65-4729afeb18a9",
      name: "MUSIC",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      name: "AMAZON",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
    },
    {
      id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
      name: "CURTAIN DIVIDER",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
    },
    {
      id: "99adf17f-0a5c-4107-8582-81877059cac1",
      name: "ASSEMBLY PROCESS",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
    },
    {
      id: "983a48e3-8d42-4744-9891-79974d58a47f",
      name: "LSAT READING COMPREHENSION",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "92ae7250-8853-4077-a6fe-45abb99a1c93",
      name: "SIMPLIFYLSAT.COM",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "edfeddcd-13cf-499d-80ed-3d251b049651",
      name: "LEGAL THEORISTS",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "a57879b8-8546-409c-9185-9fb53f6f8962",
      name: "CORPORATIONS",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "1ac38851-a468-4064-8860-e6c80848b922",
      name: "COMPACT 580-SQUARE-FOOT APARTMENT",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "c30dc008-2120-403a-8746-7c04394e1d3a",
      name: "6-FOOT ROUND MIRROR",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "41d75f90-1452-423d-b02d-be618dc0e695",
      name: "PERSON LIVING IN APARTMENT",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
      name: "DESIGN STRATEGY",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      name: "OBAMA",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "3dfb8a5f-2b3f-4b3b-bf3d-bd76ea9feb9a",
      name: "GEORGE BUSH",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "57220f5f-6650-4761-9380-1564961e7a32",
      name: "BILL CLINTON",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
      name: "TRUMP",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
      name: "ILLEGAL IMMIGRANTS",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "493dc633-c881-4f2d-b93a-74dd7a526c15",
      name: "CRIMINAL OFFENDERS",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "491d310b-caef-4928-9a04-9eeee7e22235",
      name: "YOUNGER ILLEGAL IMMIGRANTS",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      name: "LAW SCHOOL",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
      name: "WHITE LIBERALISM",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
      name: "ESSAY",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "e442f5ba-fa57-4708-8878-3e9167250e74",
      name: "JOURNEY TO LAW SCHOOL",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      name: "STANFORD LAW",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "482c575c-d2f4-4ef9-b8d0-9213a45c93b8",
      name: "DEAN OF LAW SCHOOL ADMISSIONS",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "e4835908-3458-4f39-a06f-2f238084e018",
      name: "STANFORD LAW APPLICATION",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "5896a336-26f6-4867-8767-1dfcc553a00f",
      name: "FRIEND",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
      name: "PERSON IN RED DRESS",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "350ec84f-a734-4478-b3cd-5101ccd02880",
      name: "GRADUATION",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "6266b334-719c-4b35-87aa-9893b287782c",
      name: "HIGH-RISE APARTMENT",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      name: "INDUSTRIAL LOFT",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "0ef3b297-2bb8-4497-a295-d04d6386e5e6",
      name: "CITYSCAPE VIEWS",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "e57740c7-d189-4eab-bdfa-ef22f764bb73",
      name: "KITCHEN ISLAND",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "aba2f5e5-0f72-41af-b618-7f2c7ccd292a",
      name: "URBAN SKYLINES",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "33301f56-8ede-495a-bd0f-900492128245",
      name: "WORKING NOMADS",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      name: "REMOTE WORK",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
      name: "DICE",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "7923e185-ff75-4cfa-9528-582a0cbb7ef6",
      name: "PRODUCT EXPERIENCE",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "73fdea2f-ff0f-47a4-b245-c6ce6a756e02",
      name: "TOY ROCKET",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
      name: "WE ARE DISTRIBUTED",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "37c46975-3a37-4055-896f-63cdd07cb45b",
      name: "FLEX JOBS",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
      name: "REMOTE SOURCE",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
      name: "REMOTE.CO",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "718ab578-5292-4d09-a2e8-a5cac4fa5141",
      name: "PRODUCT LIFE CYCLE",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "2c62f196-6757-43c0-8b49-17d6c08a8be1",
      name: "CUSTOMERS",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "b3eecaaa-62bc-4389-8ad4-c0ca3a49452e",
      name: "CLIENTS",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "89c21614-fb16-4273-a3e2-74a519e52f62",
      name: "4DAYWEEK.IO",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "f4829c80-5a7f-47a3-83c5-fb4d0b2d7ac4",
      name: "PURPOSE JOBS",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
      name: "POWER TO FLY",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
      name: "LINKEDIN",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "b5aeefb8-a661-4211-8e7d-abdd06b336d6",
      name: "ENGINEERS",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "aa94bec5-0b90-43a6-bfe0-320757038d7e",
      name: "MARKETING TEAM",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "58256bfb-fa32-460a-bbbe-dbe0cefb1d13",
      name: "FINANCE TEAM",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
      name: "INDEED",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      name: "FRAMED ARTWORK",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "27216569-5b76-460a-9de0-589c665c5e0c",
      name: "SCREEN",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "6b278f60-4b27-4436-88e7-3eb681bb80f3",
      name: "REMOTE",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
      name: "ROOM",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
      name: "LAW SCHOOL APPLICATION STRATEGIES",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
      name: "ADMISSIONS OFFICERS",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
      name: "INTERVIEWS",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "df6efd4c-892a-4025-ace0-291e150849ac",
      name: "PRODUCT MANAGER",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "71d1aa93-c6d4-4a73-b361-69a958dae90f",
      name: "COLLEGE",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "5b6f5b01-c267-413e-9a8a-8d213cd9f877",
      name: "SOFTWARE PRODUCT",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "14eabc50-306b-4b9c-8fb9-8427ac6c8160",
      name: "PHYSICAL PRODUCT",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "8a883164-b2f1-4248-a153-794456519049",
      name: "STUDIO APARTMENT MAKEOVER",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "7fd92e05-fef3-4884-9186-4611d70f262b",
      name: "MID-CENTURY DRESSER",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "6f90bc3c-f5e0-4e60-ab12-601659045b08",
      name: "VINTAGE 1990S SOFA",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "e7783877-7a97-4397-8538-388e81631c6a",
      name: "PLYWOOD HEADBOARD",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "ccbbe157-b4d2-4a4f-9338-24f4538f931b",
      name: "BED FRAME",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "9973cce6-df8b-496f-9f32-ab6cb6462fe7",
      name: "ACCENT CHAIRS",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      name: "NYC",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "4ae2e0eb-f69d-4590-8812-2ef9fb1f218e",
      name: "WEST ELM",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "f4cca64e-12ac-4028-97b9-18c4ce6b8ebc",
      name: "HOMEGOODS",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "0203a028-101c-45c0-a4a6-64ed7eb594e6",
      name: "ELFA",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "0be68aec-af81-48b7-8f29-4989d53cf590",
      name: "PREPTEST 61",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      name: "CONDITIONAL LOGIC",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
      name: "STUDENT",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
      name: "TEST SCORE IMPROVEMENT",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      name: "CHICAGO",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
      name: "APARTMENT HUNT",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      name: "WEST LOOP",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "5849e087-bd35-43a8-bad4-8f2eec0e5d78",
      name: "CONVERTIBLE SPACE",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
      name: "APARTMENT HUNTING",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
      name: "CONVERTIBLE UNIT",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "271058e0-f3cb-41f0-a760-0c940b50563d",
      name: "HOME FINDS FOR 2024",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "678fe9f5-60b3-418e-93fd-67c3857ceeb4",
      name: "ANTI-SLIP COUCH COVER",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "05cee4ee-de11-46f3-84a5-592e1a0c3ac5",
      name: "LIFT-TOP COFFEE TABLE",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "7bd93453-e506-4bf9-9b08-96d223292400",
      name: "SOLID WOOD ENTRYWAY TABLE",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
      name: "PAX WARDROBE SYSTEM",
      group: 1,
      data: {
        title: "B S Y ✩ on TikTok",
        description:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
    },
    {
      id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
      name: "ASSEMBLY DEMONSTRATION",
      group: 1,
      data: {
        title: "B S Y ✩ on TikTok",
        description:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
    },
    {
      id: "0e8979ad-8c13-4dab-a3ed-06070e50c435",
      name: "IMANI’S PHASE",
      group: 1,
      data: {
        title: "Imani | DIY, Design & Life on TikTok",
        description:
          "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
        url: "https://www.tiktokv.com/share/video/7301508923985497387/",
        video_id: "0",
        time_saved: "2025-02-02 00:31:03",
      },
    },
    {
      id: "7d35b208-2393-4f38-91ae-1b3af1405037",
      name: "RENTAL-FRIENDLY WALLPAPER",
      group: 1,
      data: {
        title: "Imani | DIY, Design & Life on TikTok",
        description:
          "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
        url: "https://www.tiktokv.com/share/video/7301508923985497387/",
        video_id: "0",
        time_saved: "2025-02-02 00:31:03",
      },
    },
    {
      id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
      name: "TIKTOK",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
      name: "COUCH",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "ca1c2ef7-6d7c-4fc7-8de0-377bea639783",
      name: "DOG",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
      name: "PROVERBS 26:11",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
      name: "PASTOR",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
      name: "SOUL TIE",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "8f7aee4b-eb26-458b-a3d4-ca136cb81dc8",
      name: "HONBAY",
      group: 1,
      data: {
        title: "Hello Kristen on TikTok",
        description:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
    },
    {
      id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
      name: "LAW SCHOOL ADMISSION TEST",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
      name: "27-18-9 RULE",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "7377c763-102f-4452-bbb8-f22487ebde3f",
      name: "READING COMPREHENSION SECTION",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      name: "ACCUTANE",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
      name: "CERAVE HYDRATING FACIAL CLEANSER",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
      name: "CERAVE DAILY MOISTURIZING LOTION",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
      name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "fce6a301-1609-4299-a839-8ec20522bae0",
      name: "AQUAPHOR",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
      name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
      name: "CERAVE",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "979d0892-ba23-4572-a746-320e71e24750",
      name: "ELTAMD",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "25626dfa-ad22-4270-af3a-2d3b6d54c141",
      name: "MISSHA",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      name: "STANFORD UNIVERSITY",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "886d1720-4657-46d8-921c-05724c65cd76",
      name: "COLLEGE APPLICATION PROCESS",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      name: "ESSAY WRITING",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
      name: "ACTIVITY PRIORITIZATION",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "cf0c8784-59b6-4b49-8957-eac895827848",
      name: "EXTRACURRICULAR ACTIVITIES",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "13659935-4065-4988-8e18-8dab82c574f9",
      name: "BIGGEST CHALLENGE SOCIETY FACES PROMPT",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "93440a23-ab4a-414f-bb20-94c17e8329cf",
      name: "HISTORICAL MOMENT QUESTION",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "f1abda8b-8a72-463b-ab02-84bdb4d8be3b",
      name: "ROOMMATE ESSAY",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "2decae6e-eb04-4fa1-9bfc-c061045a275a",
      name: "FINAL ESSAY",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "23204505-dfc4-4f08-8e1e-ba69bb6359c7",
      name: "NAACP",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "abda8c0d-6084-4820-925e-6b1301562a16",
      name: "SOUTH",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "ac8fbb02-7a30-4280-8c28-2a3f77db9d65",
      name: "FEBRUARY 5TH",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "8db10b44-d57c-48f9-9227-45981320a879",
      name: "APARTMENT MOVE",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
      name: "CLOUD WALLPAPER",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      name: "APARTMENT",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
      name: "SPEAKER",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      name: "LSAT",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
    },
    {
      id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      name: "CONTENT CREATOR",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
    },
    {
      id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
      name: "PREDICTION TECHNIQUE",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
    },
    {
      id: "d92010b8-178a-4e79-b008-5dbf093808f7",
      name: "CHICAGO LOFT",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
      name: "DOWNTOWN CHICAGO",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
      name: "PRINTERS ROW",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "c0867fae-af12-4d84-9b68-958dfdd0669f",
      name: "EMPTY APARTMENT",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
    },
    {
      id: "26096370-0225-4089-9198-582e07c0702f",
      name: "TRANSFORMATION",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
    },
    {
      id: "49259c9b-4e62-4a37-adb1-adfcfc789613",
      name: "DECOR",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
    },
    {
      id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      name: "ROUND WOOD COFFEE TABLE",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "5a610178-5f79-4cb5-8041-867ab2a41874",
      name: 'YUSONG 73" TALL BOOKSHELF',
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "017de81e-714d-401f-b1da-fa549eb5fe98",
      name: "SONY PS-LX310BT WIRELESS TURNTABLE",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "c761f5a8-9d2a-45f7-b519-bca78cbb47af",
      name: "SONOS ERA 300",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "c2be0e25-8b77-4a5d-b997-3e9771d2384e",
      name: "MUELLER RETRO TOASTER",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "cd015a21-18d3-4ebf-be5e-8539457834de",
      name: "CITYSCAPE",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "e557f283-b55a-4900-9b35-c32668ca039a",
      name: "BOOKS",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "0dcef8d7-abb7-48d2-af97-a04e4f696b11",
      name: "GAMING CONTROLLER",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "606ec4ed-b7da-4cd8-b054-e4f2ea79aade",
      name: "MUG",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "83ea678e-7e1e-4a31-bfb1-f8898bae71b6",
      name: "MODEL CAR",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "1c4f288b-3c69-4164-b618-b5da44755e25",
      name: "PLANT",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "6b9a53d4-08eb-4958-8043-782a97ea8685",
      name: "DIAGNOSTIC TEST",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
      name: "PRACTICE TESTS",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "5db23971-9150-4e2c-9238-0ef2f472ae25",
      name: "LAW HUB",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
      name: "LSAT DEMON",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "6b040336-3002-442f-a870-baaa2b401d00",
      name: "LSAT DEMON PODCAST",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "b370beda-376c-417e-85c9-14b410d1782e",
      name: "1 BED/1 BATH APARTMENT",
      group: 1,
      data: {
        title: "Smart City Locating Chicago on TikTok",
        description:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
    },
    {
      id: "a1ad1629-2d4e-49c9-94d6-bc0f8adea7b1",
      name: "IMMIGRATION AND CUSTOMS ENFORCEMENT",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "e0d50f6d-cd7e-42a3-bcc3-cc33c281149c",
      name: "ICE OFFICER",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "05d4f10a-8dad-4833-a42f-1ccd3caadeb8",
      name: "RESIDENT",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "cbfe8634-3213-4dd0-9edc-30992801517a",
      name: "FIFTH AMENDMENT",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "7f7a0c09-7af7-4044-a7d9-a5862eb03a8c",
      name: "MODULAR SECTIONAL SLEEPER SOFA",
      group: 1,
      data: {
        title: "Hello Kristen on TikTok",
        description:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
    },
    {
      id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
      name: "SMARTPHONE CAMERA INTERFACE",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "d02e66f8-5679-4be9-afbf-159468f31e8f",
      name: "BASKETBALL-TURNED-PLANTER",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "be8dfb3b-437a-4e86-8ddb-9597fa5f0366",
      name: "COMMUNITY",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "1f91da00-43ea-4194-ad08-122990c55de4",
      name: "VISUAL AESTHETIC",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
      name: "USER EXPERIENCE",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      name: "RECLECTIC",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
      name: "GURNEE MILLS MALL",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "8c57d3a9-dc3e-4621-9fc8-497d04fb120d",
      name: "URBAN OUTFITTERS",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "04885dc2-130d-4604-b59e-386791a95b8e",
      name: "ANTHROPOLOGY",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "801a7302-e140-48c3-866c-c23493f719ff",
      name: "FREE PEOPLE",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "c4af8628-1f05-47c6-97a5-f1f5f06be506",
      name: "NUULY",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "efd5419d-2d3b-48cf-bbed-cbcbfbd66df3",
      name: "NORTH FACE",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "d9073a6c-2788-49c9-baf6-1014d7fa4132",
      name: "COVID-19 SAFETY MEASURES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "5346b129-4305-41bb-a3b4-e60d6e51073c",
      name: "GOOD AMERICAN",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "ce5bd19b-cb2b-4476-9831-1534d15f217d",
      name: "GOLA",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "7a11781f-dffa-471b-92c6-89d705061766",
      name: "VANS",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
      name: "ILLINOIS",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "d8998fa3-450e-4bef-882f-a61465398a04",
      name: "GURNEE",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      name: "MCBEAN IMMIGRATION LAW",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "c0e350e3-59a6-4aae-b968-467057a3945f",
      name: "IMMIGRANT LEGAL RESOURCE CENTER",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      name: "JUNTOSSEGUROS.COM",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      name: "RESOURCEFULNESS",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "2539d697-bd0a-435d-a468-7d64fdf83772",
      name: "ICE RAID LOCATIONS",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "977de398-cef3-4b97-999a-1374a43b432d",
      name: "VAL COUCH",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
      name: "SOCIAL MEDIA",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "d10397ae-7d40-43e6-a6e2-937551359e3a",
      name: "CURRENT LAW STUDENTS",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "a93e0095-438f-4454-bcba-cc6358809b6a",
      name: "GRADING CURVE",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "786a93ce-2abc-4dec-94b6-f572392675e9",
      name: "EXPERIENTIAL LEARNING OPPORTUNITIES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "220222e1-f217-4217-8ba2-faee2c864608",
      name: "PUBLIC DEFENDER",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "9e7d8f3c-0e5b-454a-a729-c8d16a4fe2e6",
      name: "CRIMINAL LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "f48012d4-dcfc-4f05-ba28-d003dd429c25",
      name: "BUSINESS LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "a899167f-13d7-4776-95ec-dc4305aab0ac",
      name: "TAX LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "103c65e8-5adc-410f-8189-89de75e4dc04",
      name: "ENTERTAINMENT LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "cb911e07-64c6-4999-801c-8201e85de699",
      name: "LIVING COSTS",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "02e0138c-4313-4ecb-b872-87ced892539b",
      name: "FREE RESOURCES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "780e01a7-144c-4b50-9169-21c0d905a602",
      name: "NETWORKING OPPORTUNITIES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "caf7a7b1-45b2-4778-80c1-f03eaf81ce0b",
      name: "MENTAL HEALTH ASSISTANCE",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "ec7b3d33-35fe-4803-8e29-a2ad4a96cf1c",
      name: "ACCOMMODATIONS",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
      name: "WALMART",
      group: 1,
      data: {
        title: "jules on TikTok",
        description:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
    },
    {
      id: "e3de905f-ef6d-4098-bf47-4c3dce1a2771",
      name: "INTERIOR DESIGN",
      group: 1,
      data: {
        title: "jules on TikTok",
        description:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
    },
    {
      id: "16cd135d-6e67-4136-ad67-ad10ea8b9b6b",
      name: "COASTAL DECOR",
      group: 1,
      data: {
        title: "jules on TikTok",
        description:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
    },
    {
      id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
      name: "OFF-WHITE COUCH",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "85078403-3e8e-4bcc-9fca-169b2cea33f8",
      name: "SMALL DOG",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "34d1996e-bb34-4718-a709-a5e1b0190a7c",
      name: "RINA",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "f6cf40a0-4374-4061-a4f7-88f281a6a04f",
      name: "HOWL'S MOVING CASTLE",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "219f5408-d1ce-44d6-81d5-1477a216219f",
      name: "CALCIFER",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "05a46f1f-98a8-4006-b927-0c1fd58458ec",
      name: "70S AESTHETIC",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
      name: "TELEVISION CONCEALMENT",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "4627d490-5180-45d0-b4b2-a7a88f75f717",
      name: "REMOTE CONTROL",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "ae3d1d8c-a08c-4296-81ba-4965be515ee2",
      name: "AMAZON VALYOU",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "a467062a-7f7c-4d77-a876-28ae38bb34f0",
      name: "COUCH PURCHASE",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "e73f818c-2063-4571-a76c-4002e7ca7881",
      name: "N.W.A.",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
      name: "THE NOTORIOUS B.I.G.",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
      name: "2PAC",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      name: "HOME INTERIOR",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "6386f9c5-25dd-420a-a7dd-cc7a15c7ce26",
      name: "VINYL PLAYER SETUP",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      name: "LATINOS IN LAW",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "db9f1e53-d624-4643-8304-5163dc453afc",
      name: "LEGAL EDUCATION",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "e6cecea6-ed85-46a9-b206-2d94f868ef78",
      name: "HAYAO MIYAZAKI",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "bc77912e-3bca-44b1-aeae-1cb41ba8fae0",
      name: "LAW BOOKS",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "202ed9f8-ce25-4863-bc98-4bf26e15e7b7",
      name: "DESK",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "2fef7194-3635-4785-b55d-5b65d4ef1c1a",
      name: "LAPTOP",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "eacd213b-0e28-49aa-a3ff-b6171e4be01e",
      name: "HEADPHONES",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "4782a313-1999-43b3-a33e-9bb0b940f1e5",
      name: "TJ MAXX",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "68fe43fd-29b6-4560-9d7c-5778abf843b3",
      name: "HOME GOODS",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "86854636-36a3-4713-a8bd-fcc970f65198",
      name: "ONECLICKWORKER.COM",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
      name: "JOB INTERVIEW",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "718d313e-4e89-42bf-ae98-a936669eb238",
      name: "EVENT MANAGER",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "c52eb868-b399-408b-b1db-be0c56473d56",
      name: "CORPORATE CONFERENCES",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "66c1682e-6aac-4e5e-9f2f-0e0914522912",
      name: "VINTAGE CAR POSTER",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "22d9e728-de3f-426e-a059-3ac1a0f5515c",
      name: "WEDDINGS",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "f693cdbb-8cd0-430a-b1eb-dfa607cecf74",
      name: "COMMUNITY FESTIVALS",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      name: "7SAGE",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
      name: "MANHATTAN PREP",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "d076b170-b018-4169-bd71-f4d76d0e836c",
      name: "POWERSCORE",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "36bba867-0a9d-4970-ab8b-07f795fdfc35",
      name: "BLIND REVIEW",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "fa14bbb0-2e7c-4afd-8630-ec67a9affba9",
      name: "7SAGE YOUTUBE CHANNEL",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
      name: "LETTERS OF RECOMMENDATION",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "dbd71c04-37d7-494a-ac72-3524c0f582c5",
      name: "SPLITTER CANDIDATE",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "6a508c1f-0408-4024-bf11-965a8c045800",
      name: "SAMSUNG",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
      name: "THE FRAME",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "ebc40e35-36c2-40da-a009-c99cfb31cfba",
      name: "FRAME",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "fbdfade2-a57d-4481-a5cf-410cb3a5995a",
      name: "GALLERY WALL",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "7f0d8a00-39f4-421e-bff4-df57f230054c",
      name: "PLANTS",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "1f55aecf-83b8-4835-9afe-cec8999e4a24",
      name: "SKATEBOARD ART",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "c9e08d94-26a3-42d2-9129-4c76ed8099e2",
      name: "MODWAY",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "281f9b2d-3728-43be-ade8-6104877e2a11",
      name: "WALNUT MODWAY TV SIDEBOARD",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "c729bafe-4805-4403-84eb-9a68f394daea",
      name: "MATTE BLACK FLOATING SHELVES",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "1f4c4326-d517-4440-9b1b-d70e2d6e2c92",
      name: "RETRO FLIP CLOCK",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
      name: "CLAW SIDE TABLE",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "eaa86736-e1d3-4f7c-89df-f3bb1212fe24",
      name: "URBAN SKYLINE",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "687e66c5-3e76-4abb-adc4-afdaa21f1853",
      name: "WALNUT TV SIDEBOARD",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "3537c340-2931-409f-aa3a-f20206e1536d",
      name: "BLACK MATTE FLOATING SHELVES",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "6a6cefcf-373f-4da5-aadd-77315b563cae",
      name: "WHITE ADJUSTABLE FLIP DESK CLOCK",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "18149e0d-4f47-421c-8536-971989ee8f02",
      name: "NATURAL EDGE SIDE TABLE",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "5c68ffc9-c0d8-445d-a98a-b01a0f232685",
      name: "BALCONY",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "5f0b6fb9-2710-41c2-a063-e6ff8a86b2e6",
      name: "STUDY SPACE",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "5a3bb2f4-2299-4125-b626-33797f660bfa",
      name: "BEDROOM",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "736383b0-a597-43d1-b94a-b99a30aad2bc",
      name: "SKATEBOARD WALL ART",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "55ad87d3-a81b-4243-9f74-c4237a5dcbbf",
      name: "DESIGNER FURNITURE",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "e417a76f-fa74-4725-81b2-369ed3108677",
      name: "PERSONAL CONNECTION",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "d308cd80-c521-4550-ae3a-4be51ed68a06",
      name: "DERMATOLOGIST",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "21b7bdad-34ec-46e5-a0c1-103f6e908b85",
      name: "ACNE",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "fe16457b-f86e-4df5-8a0d-1e32ff014a7b",
      name: "DRYNESS",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "54749543-c531-448d-b9c0-4b4579cfd230",
      name: "SKIN THINNING",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "f8a354f5-7441-4466-9cd4-712364dbcc29",
      name: "MENTAL OR COGNITIVE SIDE EFFECTS",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "818d5c70-c4fe-4bcf-be50-14bad404c61d",
      name: "CHICAGO ESTATE ADVISORS",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "b2fa3f10-c597-49b2-a974-8a7d56d5aa58",
      name: "ESTATE SALE",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "113c6036-c314-4c5a-b8b0-6f413abb1f79",
      name: "CHANEL",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "88e3fb52-dda6-445c-bc82-43b9434cdee3",
      name: "ASTRA STREET",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "11169aee-c058-4348-ab5b-6d2246c51d38",
      name: "JEWELRY",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "cd02e806-5590-4975-b2ef-6b5b42c5135b",
      name: "CLOTHES",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "1fec4241-15cc-4b30-9b5d-6bc7866191e3",
      name: "PATIO",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "195ff1d1-b7d7-426c-a44f-f023b4f9c8b9",
      name: "ANTIQUES",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "37cb5eed-326e-4e57-9f14-9145d2493762",
      name: "RECRUITER SCREENING PROCESS",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "519e3a10-bed9-43cd-ac39-41e6a1509ca5",
      name: "SALARY NEGOTIATION",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "3b7aade7-0754-49d2-8c96-57da9536eec5",
      name: "EMAIL FOLLOW UP",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "f83f41eb-9105-46f7-af10-ed4e98862433",
      name: "EMPLOYEES",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "ce42aa47-96ff-4fc0-a73d-606e550c4800",
      name: "COMPENSATION",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "76f6f542-e95a-42d2-bd21-8b42661b89c0",
      name: "CHICAGO",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "011676aa-091e-411d-8398-734ffda8782f",
      name: "CHICAGO LOFT",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "38a289fc-85b8-47e8-800d-63c993e96861",
      name: "PRINTERS ROW",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "0d304e0c-d33e-46ce-acca-11804670c201",
      name: "PRESENTER",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "81b32122-d390-48cf-aa2b-6d70f9982163",
      name: "FIVE BELOW",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "551bb044-d5c6-4207-99a0-58d2f8b61236",
      name: "LIVING SPACE",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "484b3a6f-b67d-4aaa-b4ce-239c2cf3b5d5",
      name: "KITCHEN",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "1e60c03d-7996-44b7-8c09-da00ae5df511",
      name: "STUDIO",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "eb800ee8-0e6e-4c78-8102-e507b51967a6",
      name: "BATHROOM",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "b3ef2c7e-a4f1-493e-8196-a84276f73fa7",
      name: "LSAC",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "c55e7c16-143e-4796-ace9-2a473150e19d",
      name: "LAW SCHOOLS",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "632b55c5-11d2-444d-9269-064b7aeed9a9",
      name: "GPA",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "e7d1a6d2-6155-473b-a959-f12c691f8ff3",
      name: "APPLICANT",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "08cb630c-81af-4968-954f-d62647bbe09b",
      name: "OVERALL CUMULATIVE GPA",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "ee8a0b79-8576-45ec-9c79-85e7a2cb0129",
      name: "DEGREE GPA",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "accba4ae-9802-47d5-9aea-d451a97a0c7e",
      name: "FACEBOOK MARKETPLACE",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "297c5074-a25f-4c00-a520-1a69ea7fad77",
      name: "APARTMENT TRANSFORMATION",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "e3c3cd38-b0b7-4b4a-8388-e4e6d7fffe51",
      name: "IKEA",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "c323416e-ff40-4d7e-973e-a07fe0b98cd6",
      name: "STUDIO APARTMENT",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "b33d6ec7-f948-4434-a725-b1cbc2354262",
      name: "DRESSER",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "5a342d80-ffdb-49cc-88ce-87dd06c7a4af",
      name: "RUG",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "a24eae1c-f298-4552-b07f-6c4688017f17",
      name: "HEADBOARD",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "0ea76f5e-3968-47af-a7bc-e9060f811d5e",
      name: "QUEEN-SIZED BED FRAME",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "c8241f32-63ff-40bb-b340-21490edbf2cc",
      name: "COFFEE TABLE",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "1700fe94-0b8c-4e69-bba5-2f8d4628fae2",
      name: "FLOOR LAMP",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "b4589dee-8686-43f4-98dc-d46e3b3d6643",
      name: "DINING CHAIRS",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "9258dd90-0e89-4c8d-b1ac-8cda47b7b120",
      name: "PT61 SECTION 4 QUESTION 11",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "fc7310d4-c445-46d3-be51-25c5a0335af7",
      name: "LSAT",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "5b1300ad-6b5a-4bd5-922a-3ca53066f910",
      name: "CONDITIONAL LOGIC",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "67747bef-0dbd-4db7-8499-03cdcfccda8d",
      name: "SCORE IMPROVEMENT",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "5c072759-c0ca-4c7d-9356-d858cb27fdb2",
      name: "RECRUITER SCREENING",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "9cf79b35-cc28-4297-a000-7c01b4b6ddaa",
      name: "VERBAL OFFER",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "3ec7ec3c-0f43-41a9-87fb-ea087e7dbccb",
      name: "SIX MONTH PROMOTION REVIEW",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "95e419dc-9ff6-403a-b30f-629ac21faee1",
      name: "COMPANIES",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "8bca11d2-16c7-40ca-b81d-77bede82f1b0",
      name: "HIRING BUDGETS",
      group: 1,
      data: {
        title: "Dan From HR on TikTok",
        description:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
    },
    {
      id: "05d60126-7615-4fed-98b8-f0bfaf094ad8",
      name: "OBAMA",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "4322eaac-0ff3-4aef-b336-2394cc71f54f",
      name: "TRUMP",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "d1fb8152-a6df-4363-bd7c-a7d2aa4d13e6",
      name: "IMMIGRATION POLICIES",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "dac5f905-c0de-4d7f-bf9f-8cf221fcbce2",
      name: "SMART HOME AUTOMATION",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "12134920-97aa-4217-af3a-f6366dd225a0",
      name: "IMMIGRATION",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
      name: "LAW SCHOOL APPLICATION PROCESS",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "5bc03290-abdc-479e-85b7-1421c63bdefe",
      name: "GRADED ADDENDUM",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "16a0e10e-fb05-431e-ae47-beb7623c6ac3",
      name: "LETTERS OF RECOMMENDATION",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "09b7c61d-2e0d-49d3-9f76-c772a60700a6",
      name: "LAW SCHOOL",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "68c1f5de-d525-4866-96d6-97af86730cf1",
      name: "PROFESSORS",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "f6c43243-50ac-48c7-937e-07b7bd5c0441",
      name: "EMPLOYER",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "6c2d2ae0-d5c0-463f-b0e3-81aa135f0204",
      name: "VP",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "04be3f0a-f97e-4c8c-9be5-fd2a2e00692d",
      name: "STARTUP",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "ee8810a6-8698-4346-8cfa-1f24ee289e76",
      name: "LARGER COMPANIES",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "9a07ef2f-7511-4864-b508-3d963f4a585c",
      name: "PROGRAMS/INTERNSHIPS",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "3e58f13e-1527-4236-91b5-60aec12b6849",
      name: "RESEARCH",
      group: 1,
      data: {
        title: "Natasha on TikTok",
        description:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
    },
    {
      id: "54b4b552-a4f1-4216-8443-71acd035910b",
      name: "NEW YORK CITY",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "1b95c89b-7874-461f-94e3-4159b80c152a",
      name: "MIRRORS",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "c91bfe7c-b19a-4b24-a518-de0606bf5ed4",
      name: "INTERIOR DESIGNERS",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "2dd75106-2914-4802-b033-d0ee0dfe98b4",
      name: "GOLD COAST",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "f636e789-8565-4c95-98e8-6120018c8cee",
      name: "JANUARY 31ST TO FEBRUARY 2ND",
      group: 1,
      data: {
        title: "Chicago Estate Advisors on TikTok",
        description:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
    },
    {
      id: "00968741-9cd0-4d1d-90d0-caf0a7fe229a",
      name: "MILLENNIAL GREY FLOORED AREA",
      group: 1,
      data: {
        title: "Brianna Via on TikTok",
        description:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
    },
    {
      id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
      name: "LIVING ROOM TRANSFORMATION",
      group: 1,
      data: {
        title: "Brianna Via on TikTok",
        description:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
    },
    {
      id: "c6e0aea1-0432-4f65-92d5-e62e3f7b202a",
      name: "SOFA",
      group: 1,
      data: {
        title: "Brianna Via on TikTok",
        description:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
    },
    {
      id: "f5304d2a-6f21-4291-996d-55a825699313",
      name: "CHAIR",
      group: 1,
      data: {
        title: "Brianna Via on TikTok",
        description:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
    },
    {
      id: "3cb827c6-4545-4657-9c7d-303f6e23d6c0",
      name: "ACCENT TABLE",
      group: 1,
      data: {
        title: "Brianna Via on TikTok",
        description:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
    },
    {
      id: "3cefcb02-7e96-4999-8bd7-b1ef8d8538ee",
      name: "FABLE SECTIONAL",
      group: 1,
      data: {
        title: "Brianna Via on TikTok",
        description:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
    },
    {
      id: "c110f968-7cdc-4a9d-ad53-157fba72088b",
      name: "KOFIE",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "c84f9a9d-e04b-4844-8786-4c5aba0d436f",
      name: "NEW APARTMENT DECORATING SERIES",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "18b3235c-ab1b-47e7-a331-f58d6a3e96f6",
      name: "PEEL AND STICK WALLPAPER",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "2166d1d9-9f3c-42e0-b4f0-d031c1770787",
      name: "STEAM MOP",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "686784e9-f513-4018-8824-a160f0fc4221",
      name: "ACCUTANE",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "e69b677e-1f93-4fc7-80de-44688848cad8",
      name: "DERMATOLOGIST",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "cb102ad6-1b96-4945-8de8-09d1049645cd",
      name: "CONTENT CREATOR",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "9f972bc8-a06a-4ad0-b24d-5d6b0712d278",
      name: "BLOOD TESTS",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "77862817-e467-45d6-b199-6f813bada24c",
      name: "TABLET INTERFACE",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "e67f5b02-3ed7-47c8-a33b-89ad5d91165f",
      name: "LIVING ROOM",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "5720395d-91d3-4d10-a152-bdfb7534e317",
      name: "LIGHT SOURCES",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "94126045-ffc7-487a-94d4-64748b3be12f",
      name: "MUSIC",
      group: 1,
      data: {
        title: "osyqo on TikTok",
        description:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
    },
    {
      id: "4db0754b-a928-4330-89e8-f1381eb15d61",
      name: "AMAZON",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
    },
    {
      id: "20be079c-3fcb-477e-9bfa-4cc26094b73d",
      name: "CURTAIN DIVIDER",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
    },
    {
      id: "ca911708-827e-4e11-801b-49070372ae04",
      name: "ASSEMBLY PROCESS",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
    },
    {
      id: "7f71d838-d2b0-4fbc-855e-9482d579b62e",
      name: "SALARY NEGOTIATION SCRIPT",
      group: 1,
      data: {
        title: "isimemena on TikTok",
        description:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
    },
    {
      id: "03aa5b58-061b-4a63-a547-abc81f6c12d3",
      name: "JOB OFFER",
      group: 1,
      data: {
        title: "isimemena on TikTok",
        description:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
    },
    {
      id: "05cc950d-5867-459e-91d2-390eba6728e7",
      name: "INDIVIDUALS",
      group: 1,
      data: {
        title: "isimemena on TikTok",
        description:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
    },
    {
      id: "cd83faa5-87ad-4ebe-9d81-1adfa1b14f01",
      name: "TEAM",
      group: 1,
      data: {
        title: "isimemena on TikTok",
        description:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
    },
    {
      id: "a2bc5eaf-2fdf-4d51-9e0f-5b64a95ec1ce",
      name: "LSAT READING COMPREHENSION",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "27900f0a-340b-4715-8fd2-96ead2c5b2b6",
      name: "SIMPLIFYLSAT.COM",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "ee816fd4-3ba5-49ae-824a-8d866dbff937",
      name: "LEGAL THEORISTS",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "777fc9ad-7043-4478-b764-f79a5995d82b",
      name: "CORPORATIONS",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "651d7af4-0726-4b41-8e1a-ddf38c3020ba",
      name: "COMPACT 580-SQUARE-FOOT APARTMENT",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "4e0a654a-6e86-4826-9104-0c0e149d74a8",
      name: "6-FOOT ROUND MIRROR",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "6efb468d-2d60-4bd3-a49d-d202dced0a4a",
      name: "PERSON LIVING IN APARTMENT",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "81dc18fc-a8e7-488b-88ce-f9bd15f634a7",
      name: "DESIGN STRATEGY",
      group: 1,
      data: {
        title: "Ivangellys on TikTok",
        description:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
    },
    {
      id: "a0edb1e7-4c59-4870-a56f-a08847c9262b",
      name: "STANFORD LAW",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "99ff1050-5468-4e5d-9b6d-86ee81275c70",
      name: "ADMISSIONS INFORMATION SESSIONS",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "127e28ea-af4e-49d0-b0fc-efe7d62fb1c1",
      name: "DIVERSITY STATEMENT",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "1b5d2231-d442-4d27-8cb1-44d956ad7b10",
      name: "WHY STANFORD LAW STATEMENT",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "d94068c4-12a5-4faf-95bd-f8323c197872",
      name: "RECRUITER",
      group: 1,
      data: {
        title: "Don’t Go Broke Trying💰🎙 on TikTok",
        description:
          "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
        url: "https://www.tiktokv.com/share/video/7334735055845182726/",
        video_id: "0",
        time_saved: "2025-01-29 04:11:33",
      },
    },
    {
      id: "486e1c47-44cb-4f2f-9c9e-760e16dabefd",
      name: "SPEAKER",
      group: 1,
      data: {
        title: "Don’t Go Broke Trying💰🎙 on TikTok",
        description:
          "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
        url: "https://www.tiktokv.com/share/video/7334735055845182726/",
        video_id: "0",
        time_saved: "2025-01-29 04:11:33",
      },
    },
    {
      id: "2c22e5fc-8911-467c-a279-a394ac3c6c0e",
      name: "GEORGE BUSH",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "e06306fd-118c-4080-83f5-507d06ab2b35",
      name: "BILL CLINTON",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "0f15d359-1079-4edf-95a5-a318de66b794",
      name: "ILLEGAL IMMIGRANTS",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "10891d64-a06b-4a3f-9e7f-87e926b00b06",
      name: "CRIMINAL OFFENDERS",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "4543732f-50e6-4f15-a5b4-17520b3eeb37",
      name: "YOUNGER ILLEGAL IMMIGRANTS",
      group: 1,
      data: {
        title: "Bofa Deez on TikTok",
        description:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
    },
    {
      id: "b978a04e-bf26-4915-b5a8-5331ba278b75",
      name: "WHITE LIBERALISM",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "21b5b31a-1ec1-499a-8c4e-ab2b92cc9c02",
      name: "ESSAY",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "a2664c45-f1ab-435f-b14c-6eefb60d3939",
      name: "JOURNEY TO LAW SCHOOL",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "278f7d97-c2e1-453a-8879-71301a46f534",
      name: "DEAN OF LAW SCHOOL ADMISSIONS",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "fa29a4db-c937-4da1-b56e-7d199a1ec15a",
      name: "STANFORD LAW APPLICATION",
      group: 1,
      data: {
        title: "LegallyAmrutha on TikTok",
        description:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
    },
    {
      id: "fdfa2d11-09f2-4601-87fd-21b4992f4b87",
      name: "FRIEND",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "bc99126d-e985-40b8-96ad-e5e736af94ba",
      name: "PERSON IN RED DRESS",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "9d3dc321-7116-4770-942f-ce654b2dd0dd",
      name: "GRADUATION",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "8537575a-32b3-49e1-ac43-1b5a2be7eedb",
      name: "HIGH-RISE APARTMENT",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "90979082-fdb1-48ad-a2c1-39fb0b8af13d",
      name: "INDUSTRIAL LOFT",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "5e888930-389f-4907-86f9-e69ee079c91b",
      name: "CITYSCAPE VIEWS",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "8f7f9a8a-247c-4387-a249-5a49e7b8d702",
      name: "KITCHEN ISLAND",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "23b1ff40-2619-48a9-9673-5c6088a5e7f7",
      name: "URBAN SKYLINES",
      group: 1,
      data: {
        title: "Noelle Simpson on TikTok",
        description:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
    },
    {
      id: "bd986adf-a7d7-452c-a094-6428af41e521",
      name: "WORKING NOMADS",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "686d58bb-148b-4c3e-86e8-e44b9fcdde5d",
      name: "LAW SCHOOL APPLICATION STRATEGIES",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "1794e2c2-9efa-4422-94ce-515ed76f4141",
      name: "REMOTE WORK",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "1a36583d-4808-4fa0-9be8-2046f2197a6a",
      name: "DICE",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "55c3ca0c-1d5b-402a-84c0-37e8d63f3f01",
      name: "WE ARE DISTRIBUTED",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "a883c7d6-3789-4005-9bb9-d072c9c0b3c5",
      name: "FLEX JOBS",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "a82e3aad-5c40-4dc1-8223-1a9a15193322",
      name: "ADMISSIONS OFFICERS",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "9447df10-e34c-401a-9792-670bdcb72591",
      name: "INTERVIEWS",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "28dc3e4a-7d2a-4020-9856-3edd5b65fb3a",
      name: "PRODUCT MANAGER",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "b75856d0-7cb4-4ad2-9c2d-3abccc69286e",
      name: "REMOTE SOURCE",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "d84033ba-a9b5-4160-bd3c-903684dbdb49",
      name: "REMOTE.CO",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "cd991ac9-4320-4eb7-a72e-50fc8af39fc9",
      name: "4DAYWEEK.IO",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "3e57f3a2-302e-4795-9849-06d6c2badb01",
      name: "PURPOSE JOBS",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "90cb4197-b9b5-49fd-904c-b86859e471e1",
      name: "COLLEGE",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "57a040ab-e039-41e7-9024-252830bad4ee",
      name: "SOFTWARE PRODUCT",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "4177a780-65d1-426e-be82-f2951ec5ca2c",
      name: "PHYSICAL PRODUCT",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "5a3789c1-bb31-4050-85cb-98b5243c4729",
      name: "POWER TO FLY",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "a5fdff54-8a75-41b2-8992-0df9e4c4cf06",
      name: "LINKEDIN",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "8356e717-8758-49e1-9f55-d897658cffb8",
      name: "INDEED",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "14ac857b-c057-4305-8483-804cf344bfb7",
      name: "FRAMED ARTWORK",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "98a985d9-51f0-4290-8dfc-81ee666c21ed",
      name: "SCREEN",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "ca33a435-1d04-44c5-a54d-89d2b7b1758f",
      name: "REMOTE",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "a5b434d7-57cf-48b8-a034-2164e15bce07",
      name: "ROOM",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "77306ad9-9799-4616-adf3-247940d2a9b2",
      name: "PRODUCT EXPERIENCE",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "a6645144-d21d-44f3-8a12-b1dd84eceb90",
      name: "PRODUCT LIFE CYCLE",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "4fb94691-2ab2-494b-a191-8a250580d1b8",
      name: "CUSTOMERS",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "a99d136e-13b8-450f-b035-76e7f9fb380c",
      name: "CLIENTS",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "9f1d50c1-861a-4722-be8d-1968e7132cee",
      name: "ENGINEERS",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "a45333ef-f167-4011-a867-3e28b478156f",
      name: "MARKETING TEAM",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "14f16c63-5d7f-44a7-bef8-8f6c9d17fbd5",
      name: "FINANCE TEAM",
      group: 1,
      data: {
        title: "Mar 💌 on TikTok",
        description:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
    },
    {
      id: "cef1a836-cde8-4886-9e5e-26aff32247c5",
      name: "STUDIO APARTMENT MAKEOVER",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "76026870-8c91-444f-badc-4a55cd3d8b9e",
      name: "MID-CENTURY DRESSER",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "5eec9cbd-2a4b-4c72-bde9-49ecdfb9f1b3",
      name: "VINTAGE 1990S SOFA",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "d1801d4f-0a7e-4b92-ba82-2129e3d31789",
      name: "PLYWOOD HEADBOARD",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "a2dde7e6-d145-4ec4-abc8-461e76197ef8",
      name: "BED FRAME",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "2b09d92c-fb7a-4133-923c-ac93e3c7a61b",
      name: "ACCENT CHAIRS",
      group: 1,
      data: {
        title: "Lone Fox on TikTok",
        description:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
    },
    {
      id: "530faf16-9726-49c1-ad07-33ebecd94a58",
      name: "NYC",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "c6d49874-063d-4b7b-9aaf-b9534cb4ed82",
      name: "WEST ELM",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "32ea002e-0fd0-450d-9a6f-c221b444df51",
      name: "HOMEGOODS",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "02dc09fc-960c-449a-9589-15a15d54ac60",
      name: "ELFA",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "0d71d569-7880-49fe-a7fe-9bac9ffb2610",
      name: "CLOSET ASSEMBLY",
      group: 1,
      data: {
        title: "josi on TikTok",
        description:
          "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
        url: "https://www.tiktokv.com/share/video/7461773312218107166/",
        video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
        time_saved: "2025-01-21 04:57:39",
      },
    },
    {
      id: "580d612f-f12c-4dd2-b50b-db3caba3552b",
      name: "INDIVIDUAL",
      group: 1,
      data: {
        title: "josi on TikTok",
        description:
          "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
        url: "https://www.tiktokv.com/share/video/7461773312218107166/",
        video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
        time_saved: "2025-01-21 04:57:39",
      },
    },
    {
      id: "5b34ec12-3fa5-47c4-811b-33ecdd8e7a0b",
      name: "PREPTEST 61",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "f0a613e6-3abc-4cc7-bad3-8551c165fadf",
      name: "STUDENT",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "1cf39e0d-df6f-40e9-a260-34e720f32e24",
      name: "TEST SCORE IMPROVEMENT",
      group: 1,
      data: {
        title: "Lizna Lakhani on TikTok",
        description:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
    },
    {
      id: "5f713365-3834-46ac-8c3f-d57d575483b6",
      name: "APARTMENT HUNT",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "a62999f4-94c1-4a84-86da-17d1bf98649a",
      name: "WEST LOOP",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "2d6a139b-0239-4410-836c-a3c8027bd243",
      name: "CONVERTIBLE SPACE",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "903c444c-15e5-4c83-b2e4-9ac30f1a2c96",
      name: "APARTMENT HUNTING",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "c44aa932-a268-4855-b6e7-686f330d6fba",
      name: "CONVERTIBLE UNIT",
      group: 1,
      data: {
        title: "Jessika | Chicago Living on TikTok",
        description:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
    },
    {
      id: "e7df515f-54b7-42ff-86f5-439203cef1af",
      name: "CRIMINAL SANCTIONS",
      group: 1,
      data: {
        title: "simplifylsat on TikTok",
        description:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
    },
    {
      id: "ee17c8fe-de46-4a1f-92f0-bfb26d9726b2",
      name: "HOME FINDS FOR 2024",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "6892e600-3b7b-45eb-901d-cb60d12f2c24",
      name: "ANTI-SLIP COUCH COVER",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "41e8b175-f035-407c-aaa4-c0b76dc1e8c3",
      name: "LIFT-TOP COFFEE TABLE",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "e31f0e57-6989-49a7-b53b-d846e761055e",
      name: "SOLID WOOD ENTRYWAY TABLE",
      group: 1,
      data: {
        title: "ash on TikTok",
        description:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
    },
    {
      id: "9940f611-549d-45a5-b0d1-1c063c56182a",
      name: "PAX WARDROBE SYSTEM",
      group: 1,
      data: {
        title: "B S Y ✩ on TikTok",
        description:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
    },
    {
      id: "897486fe-c8d9-418c-b7c7-3f530b0a6b92",
      name: "ASSEMBLY DEMONSTRATION",
      group: 1,
      data: {
        title: "B S Y ✩ on TikTok",
        description:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
    },
    {
      id: "477d3c3f-ba0f-466d-9bb1-8824501186e9",
      name: "IMANI’S PHASE",
      group: 1,
      data: {
        title: "Imani | DIY, Design & Life on TikTok",
        description:
          "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
        url: "https://www.tiktokv.com/share/video/7301508923985497387/",
        video_id: "0",
        time_saved: "2025-02-02 00:31:03",
      },
    },
    {
      id: "3a185b10-5744-43a0-ba9f-fe290aff4ee0",
      name: "RENTAL-FRIENDLY WALLPAPER",
      group: 1,
      data: {
        title: "Imani | DIY, Design & Life on TikTok",
        description:
          "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
        url: "https://www.tiktokv.com/share/video/7301508923985497387/",
        video_id: "0",
        time_saved: "2025-02-02 00:31:03",
      },
    },
    {
      id: "b3e035e1-caad-4a9a-a8b3-c43ae93a9796",
      name: "TIKTOK",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "6b8af74e-d83a-4332-a017-cf0e4ec694f5",
      name: "COUCH",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "abb46bb2-d0cc-458a-8c53-8b8f81310bb5",
      name: "DOG",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "108284c7-c07b-4918-9f6b-f2e01adb6c04",
      name: "LSAT 27-18-9 RULE",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "4028376c-5840-4be4-a2a2-b49f9c868cb1",
      name: "READING COMPREHENSION TIMED SECTIONS",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "9ae1b0b5-e6d0-4744-a9f5-fc2a5bd4ce3e",
      name: "MCBEAN IMMIGRATION LAW",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "651f18e4-3106-4a30-821e-44a36aaacc93",
      name: "ILRC",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "5e496d32-899c-456a-9fda-9ae3a2a0ca74",
      name: "JUNTOSSEGUROS.COM",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "0324cd44-8202-46e7-b804-7ff420ef368e",
      name: "ICE",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "33265e99-830b-42f2-8656-868376b9373a",
      name: "PROVERBS 26:11",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "e3b55faa-a120-4948-9d2f-7221a8a4fc78",
      name: "PASTOR",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "54f235e2-7e66-482e-b0b3-8e88402025a3",
      name: "SOUL TIE",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "42707f38-1b4e-4440-8f62-47058be174a6",
      name: "THREE-DAY FAST",
      group: 1,
      data: {
        title: "Vanessa Elaine on TikTok",
        description: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
    },
    {
      id: "62eac6b3-f325-47fa-b1e0-bec2d2cffb7d",
      name: "RECRUITMENT EVENTS",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "58f901cd-c90e-47d2-a615-5ae88b6196fd",
      name: "APPLICATION STATUSES",
      group: 1,
      data: {
        title: "Charis | Law School Life on TikTok",
        description:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
    },
    {
      id: "e097c7fd-6648-4acc-852c-82a9901a1d75",
      name: "HONBAY",
      group: 1,
      data: {
        title: "Hello Kristen on TikTok",
        description:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
    },
    {
      id: "9b5eb103-b4db-4ece-b4fa-60340c6d0845",
      name: "LAW SCHOOL ADMISSION TEST",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "b92ea771-781d-4ec0-91f0-cd23138c193c",
      name: "27-18-9 RULE",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "953b9d27-d67b-4a6f-94b0-4fb66628e581",
      name: "READING COMPREHENSION SECTION",
      group: 1,
      data: {
        title: "theburtonmethod on TikTok",
        description:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
    },
    {
      id: "4726150a-98fd-4bfd-a7c4-d934bc4a3ca4",
      name: "CERAVE HYDRATING FACIAL CLEANSER",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "fe4476de-2525-4160-9722-4abe6e16080b",
      name: "CERAVE DAILY MOISTURIZING LOTION",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "06e9ae2d-e6f8-4b14-87cf-548dae62ce69",
      name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "5dd5860d-ba89-4075-a8c2-cd791bc8c5c8",
      name: "AQUAPHOR",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "119f3c04-98cf-4677-b8c4-aac473456a7e",
      name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "1c84484d-4883-41ed-a029-f84b7380ffc0",
      name: "CERAVE",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "ecd99a56-7556-4135-9532-55b79c123111",
      name: "ELTAMD",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "074dfb46-9f57-4c64-a2e5-c71520e6d33b",
      name: "MISSHA",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "19d0545b-3f89-4197-a88f-a235726f9d30",
      name: "STANFORD UNIVERSITY",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "f74c2e70-0ee4-46b3-9a42-a9ddc1cfae7d",
      name: "COLLEGE APPLICATION PROCESS",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "e9374531-d995-45ef-a284-d9aa8832b064",
      name: "ESSAY WRITING",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "27e3f086-047c-401a-8845-092e39a948f1",
      name: "ACTIVITY PRIORITIZATION",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "a7bdf876-391a-47c1-a5c2-5a6b34f4b711",
      name: "EXTRACURRICULAR ACTIVITIES",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "daafb33d-fe4b-400c-9ede-5eee7d0851d8",
      name: "DECOR",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
    },
    {
      id: "5eae514e-0510-4428-b9c5-24c5acef2c10",
      name: "BIGGEST CHALLENGE SOCIETY FACES PROMPT",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "7fb29170-5ad9-43ab-9a30-392129a01b97",
      name: "HISTORICAL MOMENT QUESTION",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "b52d47dd-99b2-4713-9d6b-cf953caddbae",
      name: "ROOMMATE ESSAY",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "e05ab442-307f-4a27-b60d-be6bd4b16379",
      name: "FINAL ESSAY",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "8f2aacb9-4ac7-4075-b97f-ee92d73d52ae",
      name: "ONECLICKWORKER.COM",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "a5a7fea1-2a20-48cf-a4f8-95d053e063ab",
      name: "EVENT MANAGER",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "06ad9e46-5784-4265-bfbd-0f662dd7774e",
      name: "CORPORATE CONFERENCES",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "19ea683a-9a88-443e-a5b0-8da144e1bb0e",
      name: "WEDDINGS",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "597b79a6-def7-4c95-b370-305d5748cba5",
      name: "COMMUNITY FESTIVALS",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "0790eb3f-b2be-44ae-8f98-afbf446c1e17",
      name: "JOB INTERVIEW",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "8890a70d-3414-44ac-a1a1-ea2d6488588d",
      name: "AI",
      group: 1,
      data: {
        title: "One Click Worker on TikTok",
        description:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
    },
    {
      id: "0713fd02-1066-40c6-b6d4-c9b3e45ad033",
      name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "dfdc276a-c1b2-4c94-b742-7a7036e60341",
      name: "NAACP",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "e1aee7f9-e8b4-4e24-a060-01cbc9f13463",
      name: "SOUTH",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "363d547f-4eb1-4213-9652-3a922a29e5d2",
      name: "FEBRUARY 5TH",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "42374648-37d4-46a7-a258-e932a806aec3",
      name: "APARTMENT MOVE",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "adc38932-00cc-493c-b1df-64c5b737a009",
      name: "CLOUD WALLPAPER",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "ee1a9281-281e-409f-945a-171a2b2b75ed",
      name: "APARTMENT",
      group: 1,
      data: {
        title: "jasmeannnn on TikTok",
        description:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
    },
    {
      id: "a7dae2aa-12fd-42fd-8ecf-ffac5391f05d",
      name: "PREDICTION TECHNIQUE",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
    },
    {
      id: "7f62784c-7dc7-4a6e-b552-6d1c04e7291a",
      name: "DOWNTOWN CHICAGO",
      group: 1,
      data: {
        title: "daisymazainchicago on TikTok",
        description:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
    },
    {
      id: "404c88a2-53f9-449d-af1f-7d0c73cb1ea7",
      name: "EMPTY APARTMENT",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
    },
    {
      id: "d97d4768-3c43-4a59-872f-b5b8d758cd64",
      name: "TRANSFORMATION",
      group: 1,
      data: {
        title: "Sarah Elizabeth on TikTok",
        description:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
    },
    {
      id: "e9ce7003-de15-45ad-8e94-2a7b5e6f8c99",
      name: "IVY LEAGUE SCHOOLS",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "ff3329fc-8218-4cec-8e7b-8a1d8f5afc88",
      name: "PERSONAL STATEMENT",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
    },
    {
      id: "96287841-32d1-407d-84a2-0be2469705cd",
      name: "ROUND WOOD COFFEE TABLE",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "0736feb2-be2c-47cd-abaf-e27ec3a40570",
      name: 'YUSONG 73" TALL BOOKSHELF',
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "bfdf759f-3c17-4bbd-b07b-348d904f89c4",
      name: "SONY PS-LX310BT WIRELESS TURNTABLE",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "5c1188a9-70dd-48ed-9e83-af8423cc6424",
      name: "SONOS ERA 300",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "e080f358-6f5c-4635-b718-34c9fc286ce3",
      name: "MUELLER RETRO TOASTER",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "f3b5fcc6-bd9d-44cd-a0ff-ddfa1adbc8b5",
      name: "CITYSCAPE",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "881b933a-b92e-4b9e-b84b-720c7d721ee4",
      name: "BOOKS",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "988fe6e1-e28d-4cab-9b38-879a51cd5dbf",
      name: "GAMING CONTROLLER",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "1d0ea81a-bf16-4edc-abb7-1a7c9ed97674",
      name: "MUG",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "44550833-824b-4e37-bf2f-5b9f1c4875ae",
      name: "TOY ROCKET",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "0e74cfd9-e784-4b82-b4d7-58b91b25706a",
      name: "MODEL CAR",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "d170dff7-3bea-40b3-b666-cfcedd874c41",
      name: "PLANT",
      group: 1,
      data: {
        title: "John Baptist on TikTok",
        description:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
    },
    {
      id: "91a611b1-cbb2-4cb0-858a-91e7578132ef",
      name: "DIAGNOSTIC TEST",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "97aa3adf-4150-4b92-bb0f-d3f8d9e41119",
      name: "PRACTICE TESTS",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "a0e7a3da-d21c-42fb-b12c-5c14923f6463",
      name: "LAW HUB",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "07248113-7cc9-42c8-b2fe-899fa0489376",
      name: "LSAT DEMON",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "10b28757-e8ab-4e3c-8be8-a6767a330abc",
      name: "LSAT DEMON PODCAST",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
    },
    {
      id: "5ff2cc68-8f08-4e76-9e6a-c86caaa6b334",
      name: "1 BED/1 BATH APARTMENT",
      group: 1,
      data: {
        title: "Smart City Locating Chicago on TikTok",
        description:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
    },
    {
      id: "cfe02158-3bda-40fc-831e-f8ff450c2404",
      name: "WEST LOOP STUDIO",
      group: 1,
      data: {
        title: "Smart City Locating Chicago on TikTok",
        description:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
    },
    {
      id: "e6203578-bb2e-48e1-a9f0-0412a6b2ba74",
      name: "ONE-BEDROOM UNIT",
      group: 1,
      data: {
        title: "Smart City Locating Chicago on TikTok",
        description:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
    },
    {
      id: "322fe56a-2c42-481f-97c9-acb15abc79f1",
      name: "TRAIN STATIONS",
      group: 1,
      data: {
        title: "Smart City Locating Chicago on TikTok",
        description:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
    },
    {
      id: "2a83e7c9-0261-4e45-bf13-e2e4072e257d",
      name: "FRIEND'S GRADUATION",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "82f873cd-2d1a-4361-bb9f-c8a52ee9c1b7",
      name: "OUTFIT",
      group: 1,
      data: {
        title: "lizbethponceh on TikTok",
        description: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
    },
    {
      id: "3bf3f25f-509e-41ea-a7e5-4b0e65a5ab5b",
      name: "IMMIGRATION AND CUSTOMS ENFORCEMENT",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "bb42cd35-309c-4fcf-96ca-f84c45b824bf",
      name: "ICE OFFICER",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "1d90c23f-44d3-473b-b565-a496b331811b",
      name: "RESIDENT",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "e313c103-9471-4379-8685-34d3c620ef44",
      name: "FIFTH AMENDMENT",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "c01fcda3-f8cd-4ea2-9bca-48811c386ab1",
      name: "MODULAR SECTIONAL SLEEPER SOFA",
      group: 1,
      data: {
        title: "Hello Kristen on TikTok",
        description:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
    },
    {
      id: "185a6658-697e-44ab-ae57-fc82107d9dc6",
      name: "LATINO REPRESENTATION IN LAW",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "68f446f0-c8d3-4428-8258-b81f7e37b83a",
      name: "LAW LIBRARY",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "1ad75421-4bb9-4b19-971d-3bddf7c08bcb",
      name: "LEGAL STUDIES",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "709567d5-0151-4fa2-b491-4015dbb13f79",
      name: "BLOG POST",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      name: "REMOTE JOB SEARCHES",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "b63d7b1f-3af8-4548-9ce0-1c18dcb57a82",
      name: "4 DAY WEEK",
      group: 1,
      data: {
        title: "adri remote jobs on TikTok",
        description:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
    },
    {
      id: "ebe7f840-d066-4345-bdc4-95740bc8da0f",
      name: "SMARTPHONE CAMERA INTERFACE",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "beff35ee-9866-4d59-a267-7784402475d2",
      name: "COMMUNITY",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "32d4f7fa-9ea3-47ac-a77e-8b8eb8c34935",
      name: "VISUAL AESTHETIC",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "bc769556-7b97-4522-bc11-75a6c9a2713f",
      name: "USER EXPERIENCE",
      group: 1,
      data: {
        title: "nitya on TikTok",
        description:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
    },
    {
      id: "f064d4fb-1227-416a-aaf4-3ea7b22a92b2",
      name: "RECLECTIC",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "95008f79-359c-4a13-aef4-a8494004edd3",
      name: "GURNEE MILLS MALL",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "42f0e24b-5cb0-4dbc-a616-fa4e2325e060",
      name: "URBAN OUTFITTERS",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "66490a24-729b-4939-b5d7-ef3808204060",
      name: "ANTHROPOLOGY",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "28d4a12c-290f-402b-8b13-6e47cfd2b838",
      name: "FREE PEOPLE",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "dcade1bb-6348-4e14-90f7-631eab1b4981",
      name: "NUULY",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "a625d2f2-323e-47f6-ba58-e0fa5cda5a80",
      name: "NORTH FACE",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "fd93ac57-dfd5-40d8-8a3c-0098b6ae745f",
      name: "GOOD AMERICAN",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "9751441f-025b-4fb6-a313-5b00a59d9bc3",
      name: "U.S. CONSTITUTION",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "30264418-a1c2-497e-ac88-d473cedfe0b4",
      name: "GOLA",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "57a53400-5943-4fa7-a59f-878ec602efa9",
      name: "VANS",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "bf19b819-6dd0-4d3e-9f40-dc9a0d272cf3",
      name: "ILLINOIS",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "9280f044-9719-4d2d-af82-e221fc031343",
      name: "GURNEE",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "9e6b14f4-118d-4549-8218-3bf0eba87c9f",
      name: "GOLAS",
      group: 1,
      data: {
        title: "natintheburbs on TikTok",
        description:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
    },
    {
      id: "c200c460-0241-4f82-8045-033bfdb5ca62",
      name: "ONE L",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "c54d5876-bf2f-49e6-bfce-debfdae23a54",
      name: "COVID SAFETY PRECAUTIONS",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "a2827a2b-a8b5-4694-87a3-8fe9ac273877",
      name: "PUBLIC DEFENDER TRACK",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "80c11db7-2d9d-41f4-bb88-b77f01410a28",
      name: "BUSINESS LAW TRACK",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "a33e1cbb-96d3-410c-8d49-28bd61a8c3f3",
      name: "TAX TRACK",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "47d424aa-88cf-4021-9d06-909b5ca39677",
      name: "ENTERTAINMENT LAW TRACK",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "74cfce9e-cb5d-4b94-8e52-fe96ab3cb489",
      name: "IMMIGRANT LEGAL RESOURCE CENTER",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "3ecc2075-436b-41ed-9d3d-efbdd462aa0e",
      name: "RESOURCEFULNESS",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "71cb7ef9-1779-4d16-9c30-2307ccfc6cda",
      name: "ICE RAID LOCATIONS",
      group: 1,
      data: {
        title: "Yolanda Diaz on TikTok",
        description: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
    },
    {
      id: "8b5c5e3f-6100-4787-956f-780f222ad01a",
      name: "RESIDENCE",
      group: 1,
      data: {
        title: "Gloria Cardenas on TikTok",
        description:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
    },
    {
      id: "7ce89b37-85fd-4377-ad79-9bc38e9d83b2",
      name: "VAL COUCH",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "76bb8469-9427-49d5-8113-2976257175dc",
      name: "SOCIAL MEDIA",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "3a573489-8290-4f54-ab87-bc074c5214ea",
      name: "CURRENT LAW STUDENTS",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "0c3df685-82f8-48ed-85e2-b68f125cbb2d",
      name: "GRADING CURVE",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "d12c5287-849a-4add-ba14-c1f8b7445971",
      name: "EXPERIENTIAL LEARNING OPPORTUNITIES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "8d9b3c58-d53a-46c0-9cf3-67c9a71d3998",
      name: "COVID-19 SAFETY MEASURES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "e646b638-f51e-492a-8e81-e2aaaf439f03",
      name: "PUBLIC DEFENDER",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "6ae89ba8-002c-48c1-90c7-985af431c9bc",
      name: "CRIMINAL LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "63df5acb-5847-4612-9093-c97e55a24391",
      name: "BUSINESS LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "338e7c6f-8305-42b0-a3ea-d1293964139e",
      name: "TAX LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "65a6a51d-3ca3-4284-b0d6-5f00dafe2724",
      name: "ENTERTAINMENT LAW",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "ecc15da6-5143-4abd-a3e2-bd142244fc95",
      name: "LIVING COSTS",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "bc20a5b7-d997-47f3-bd1e-08cc92d61c78",
      name: "FREE RESOURCES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "1c4fcb5f-0148-4364-8a43-121a5d559073",
      name: "NETWORKING OPPORTUNITIES",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "a5be26c5-905b-461d-9c3a-f0d9adda530f",
      name: "MENTAL HEALTH ASSISTANCE",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "9a4a5c17-663c-4b00-9227-4312f23fb75f",
      name: "ACCOMMODATIONS",
      group: 1,
      data: {
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
    },
    {
      id: "8bc0f003-85c9-467e-947e-b800070ac3e4",
      name: "WALMART",
      group: 1,
      data: {
        title: "jules on TikTok",
        description:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
    },
    {
      id: "708fdfea-7840-4d4c-9b93-f3099b41d2a5",
      name: "INTERIOR DESIGN",
      group: 1,
      data: {
        title: "jules on TikTok",
        description:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
    },
    {
      id: "7d312a88-f15a-402e-91db-1f5c60e01e1b",
      name: "COASTAL DECOR",
      group: 1,
      data: {
        title: "jules on TikTok",
        description:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
    },
    {
      id: "da6dc505-8684-417b-9f89-acb48177a1e8",
      name: "HIRING MANAGERS",
      group: 1,
      data: {
        title: "Greg L. - Career Coach 🤓 on TikTok",
        description:
          "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
        url: "https://www.tiktokv.com/share/video/7320405835459480837/",
        video_id: "0",
        time_saved: "2025-01-29 04:07:43",
      },
    },
    {
      id: "49d10a73-0f3f-4d59-9e52-fd4f971e150d",
      name: "BUDGET",
      group: 1,
      data: {
        title: "Greg L. - Career Coach 🤓 on TikTok",
        description:
          "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
        url: "https://www.tiktokv.com/share/video/7320405835459480837/",
        video_id: "0",
        time_saved: "2025-01-29 04:07:43",
      },
    },
    {
      id: "6766ec61-da79-49c3-a5be-bc6e9dc20295",
      name: "ULTA MD",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "7c5c5d87-5b2b-44cf-a007-a2ee83c0f5c2",
      name: "MISHA",
      group: 1,
      data: {
        title: "AlexAcosta-RubioBright on TikTok",
        description:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
    },
    {
      id: "8e9b07d8-b041-4ce6-aa3d-6f330a675275",
      name: "OFF-WHITE COUCH",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "52bf9be2-a6ba-4094-a969-bbdcb07cd351",
      name: "SMALL DOG",
      group: 1,
      data: {
        title: "CLARISSA | NYC on TikTok",
        description:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
    },
    {
      id: "67d59bd8-3f16-4ebe-a9fb-f80199c945f2",
      name: "RINA",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "1cc4c0e1-f5a1-47e3-b204-d206db86520d",
      name: "HOWL'S MOVING CASTLE",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "65fc3920-bb33-4644-b8e2-c664cd3427d0",
      name: "CALCIFER",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "a453267a-8f46-4df3-8bcd-bf108e1204b1",
      name: "70S AESTHETIC",
      group: 1,
      data: {
        title: "cata haus on TikTok",
        description:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
    },
    {
      id: "7eb1f52e-afa9-47de-8dac-a0eca9c79210",
      name: "COOKED MEAL",
      group: 1,
      data: {
        title: "Varnished on TikTok",
        description:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
    },
    {
      id: "0f3489fb-5354-48c0-8344-a8c901f44820",
      name: "GENDER ROLES",
      group: 1,
      data: {
        title: "Varnished on TikTok",
        description:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
    },
    {
      id: "6a6ac87a-cf50-4fcd-8aca-f9bcc7a78eb7",
      name: "CULINARY SKILLS",
      group: 1,
      data: {
        title: "Varnished on TikTok",
        description:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
    },
    {
      id: "02958d03-fc20-4f61-8bdf-8d2a15b28b85",
      name: "PERSONAL IDENTITY",
      group: 1,
      data: {
        title: "Varnished on TikTok",
        description:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
    },
    {
      id: "06cc6e83-b86d-46cd-95dd-de160407c360",
      name: "SOCIETAL EXPECTATIONS",
      group: 1,
      data: {
        title: "Varnished on TikTok",
        description:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
    },
    {
      id: "62176e62-34c3-4ae7-92af-4e02718605db",
      name: "TELEVISION CONCEALMENT",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "861bc9be-d234-461f-8c5d-038a7a66f9fd",
      name: "REMOTE CONTROL",
      group: 1,
      data: {
        title: "Mallory on TikTok",
        description:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
    },
    {
      id: "0536b9ca-96d1-4e13-aff3-fd6a55231e3a",
      name: "AMAZON VALYOU",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "85b8dd93-ff39-4330-acf6-7c770911b641",
      name: "COUCH PURCHASE",
      group: 1,
      data: {
        title: "Rolph Alcide on TikTok",
        description:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
    },
    {
      id: "a48e5325-1734-4ff8-9784-3d6552cff343",
      name: "N.W.A.",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "ec71d378-2b63-4c89-b0ef-5ab864c754c8",
      name: "THE NOTORIOUS B.I.G.",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "a4bb30a1-0e1a-4532-ad65-58fe3ce67247",
      name: "2PAC",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "419f680e-7e0e-4624-addf-c8701d43cbce",
      name: "HOME INTERIOR",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "fa718eca-1ec9-4981-87c2-070a5c6083b4",
      name: "VINYL PLAYER SETUP",
      group: 1,
      data: {
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
    },
    {
      id: "35f75903-025d-473a-a6b5-e87fa63a9ed8",
      name: "LAW SCHOOL ADMISSIONS",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "44302998-0a5d-4638-820d-ef3c5c8db75f",
      name: "ADMISSIONS OFFICES",
      group: 1,
      data: {
        title: "Michigan Law on TikTok",
        description:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
    },
    {
      id: "6be95519-2b3d-4e42-bfe0-a2170ea073a5",
      name: '"BIGGEST CHALLENGE SOCIETY FACES" ESSAY',
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "d4435904-02f6-4735-8742-2ad44ef292fc",
      name: '"HISTORICAL MOMENT" ESSAY',
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "bd2c3241-e0c0-45d6-8081-95177785c0fd",
      name: '"LAST TWO SUMMERS" PROMPT',
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "8bdbc9dc-0390-4e37-840c-8012b3b7be3c",
      name: '"FIVE IMPORTANT THINGS" ESSAY',
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "e927ce12-1d0f-4c9a-918d-fa43ff85a3af",
      name: '"ROOMMATE" ESSAY',
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "1d33e06a-b745-4352-87d8-2dca42f48c9a",
      name: '"AN IDEA THAT EXCITES YOU" ESSAY',
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "c2bc915d-4b1e-4520-8b24-48d0e84228d8",
      name: '"DISTINCTIVE CONTRIBUTION" ESSAY',
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "b25974e8-2520-4533-81a9-f255e0c13866",
      name: "APPLICATION TIPS",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "ac316247-f633-4afb-bb0d-7c7176f16409",
      name: "EXTRACURRICULARS",
      group: 1,
      data: {
        title: "Brandon (@tineocollegeprep) on TikTok",
        description:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
    },
    {
      id: "a69cdcb3-7c7b-45c6-ac84-6a2be6d5d706",
      name: "7SAGE",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "52e437bc-3af5-47ce-a948-07b61f7da5f9",
      name: "MANHATTAN PREP",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "be82e9f4-08be-465f-89a6-9ebb191ada44",
      name: "POWERSCORE",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "11cf4e7c-7b83-4fe2-9493-0e2df71a50bf",
      name: "LATINOS IN LAW",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "d6b5ea41-91e2-46ff-82d2-dc7b97a29eb4",
      name: "LEGAL EDUCATION",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "4c76d07b-5c58-424c-8500-c0755ac41d6b",
      name: "LAW BOOKS",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "854d50b7-1714-4dc8-9a8f-8d86874571a5",
      name: "DESK",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "a18a96ca-32dd-4452-88a4-f14719bd87e1",
      name: "LAPTOP",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "1d49a519-4b8b-4a63-934a-d96cd83f6635",
      name: "HEADPHONES",
      group: 1,
      data: {
        title: "edgarr.jv on TikTok",
        description:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
    },
    {
      id: "c5ebac57-4f9a-4014-8ccd-d558dcaada10",
      name: "TJ MAXX",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "e44b96c5-1247-41f0-bd5d-8f4d88e631ab",
      name: "HOME GOODS",
      group: 1,
      data: {
        title: "annie on TikTok",
        description:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
    },
    {
      id: "20bc2d52-55c3-42b9-85d0-eda13df6ea15",
      name: "BLIND REVIEW",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "cd9c9388-6890-4dfd-882d-61125261757a",
      name: "7SAGE YOUTUBE CHANNEL",
      group: 1,
      data: {
        title: "haley on TikTok",
        description:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
    },
    {
      id: "bfe9b79e-fe69-4c00-81aa-0b567cf2d26b",
      name: "SPLITTER CANDIDATE",
      group: 1,
      data: {
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
    },
    {
      id: "c253d615-02a0-496e-b5d0-08bac183208b",
      name: "SAMSUNG",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "60ad09c1-8eff-4c8b-a9df-b1e3113c3d82",
      name: "THE FRAME",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "f74bd626-3911-4b70-960c-e98a845f01d9",
      name: "FRAME",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "16f4c3ba-2046-4e42-9e99-e192b6f55977",
      name: "GALLERY WALL",
      group: 1,
      data: {
        title: "paige_mariah on TikTok",
        description:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
    },
    {
      id: "fd5cace4-3051-4304-ae3b-f038bfb8779a",
      name: "HAYAO MIYAZAKI",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "57104f82-ae4e-4a27-a419-f9be3f9f241d",
      name: "VINTAGE CAR POSTER",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "69e5eb79-6e41-4949-b41f-0e6bbc2f57bd",
      name: "BASKETBALL-TURNED-PLANTER",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "63f63389-3a49-4d7f-9401-2c7039b8bb62",
      name: "PLANTS",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "1f30cf9a-3bd0-490f-9a4c-4a97fc05ace8",
      name: "SKATEBOARD ART",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "98c8cdc6-6202-4228-8b4d-69b807363f45",
      name: "MODWAY",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "4c5a188b-c487-46f1-8388-dd20163f2baf",
      name: "WALNUT MODWAY TV SIDEBOARD",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "b3050efc-5b17-4b39-b4d5-701215c7de95",
      name: "MATTE BLACK FLOATING SHELVES",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "f99bd58b-4d3e-468c-8fda-a987ac94ee23",
      name: "RETRO FLIP CLOCK",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "be3ac7bc-e04a-46c1-8615-530bba71f024",
      name: "CLAW SIDE TABLE",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "17c17a5c-811d-4492-8823-c3cf71c378f9",
      name: "URBAN SKYLINE",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "95d26663-6c2b-42a7-ad57-e69b999a046a",
      name: "WALNUT TV SIDEBOARD",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "fa809954-3cdd-4554-876f-70abf55dc6cf",
      name: "BLACK MATTE FLOATING SHELVES",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "b6749a6c-ebcf-4642-b74c-c605281b7796",
      name: "WHITE ADJUSTABLE FLIP DESK CLOCK",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "0ff3e809-b9a9-48e6-ba8d-c931231810ce",
      name: "NATURAL EDGE SIDE TABLE",
      group: 1,
      data: {
        title: "Ian Kim on TikTok",
        description:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
    },
    {
      id: "c25f66ba-0338-4227-bd35-6e8e2dcbd4b0",
      name: "BALCONY",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "0d58fca7-8b18-456a-8de1-17319a489cc3",
      name: "STUDY SPACE",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "de74d14b-aef0-45b3-aabc-ed61f0af7f16",
      name: "BEDROOM",
      group: 1,
      data: {
        title: "Minty Scott on TikTok",
        description:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
    },
    {
      id: "94a1f940-27ac-4df3-a3ac-269a4a89015b",
      name: "SKATEBOARD WALL ART",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "226bcbc8-e292-4bed-ad11-207f2961c7cc",
      name: "DESIGNER FURNITURE",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "7f75be9e-d321-4701-a55a-8b1403d1ad5f",
      name: "PERSONAL CONNECTION",
      group: 1,
      data: {
        title: "gaby ♊︎ on TikTok",
        description: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
    },
    {
      id: "1d9c5451-723e-446f-8f6c-bf5c389e00f9",
      name: "FEBRUARY 5TH DEADLINE",
      group: 1,
      data: {
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
    },
    {
      id: "88931f4a-1767-42b2-85b6-bf89de48ab45",
      name: "170+ SCORER",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
    },
    {
      id: "990fcd5c-ac50-4898-9374-af3ede519997",
      name: "PREDICTION",
      group: 1,
      data: {
        title: "Bianca on TikTok",
        description:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
    },
    {
      id: "ac5850e6-c3b8-4df0-b0f6-1c431933ff35",
      name: "ACNE",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "d7fc5160-b694-4218-9016-ed56fcc88c5d",
      name: "DRYNESS",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "d078dd45-378b-4afb-b10a-7f2de0770622",
      name: "SKIN THINNING",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
    {
      id: "69d53178-1659-44c4-bf0b-fc67c0b415b9",
      name: "MENTAL OR COGNITIVE SIDE EFFECTS",
      group: 1,
      data: {
        title: "Nour Khalil on TikTok",
        description:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
    },
  ],
  links: [
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "5f0b6fb9-2710-41c2-a063-e6ff8a86b2e6",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 8,
        description_x: "The study space is a designated area within the studio",
        human_readable_id: 307,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "e2257229-dcab-4415-a480-576066ab0afb",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "5f0b6fb9-2710-41c2-a063-e6ff8a86b2e6",
        name: "STUDY SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      target_id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 8,
        description_x:
          "The overall cumulative GPA is used to evaluate the applicant's academic performance",
        human_readable_id: 23,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "35941fe5-adb1-4088-9eed-51b047998884",
      source: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
        name: "OVERALL CUMULATIVE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "7fdf8e5b-32b3-4772-b52c-fcac2cfda691",
      target_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 17,
        description_x:
          "Chicago Estate Advisors is responsible for organizing and presenting items for the estate sale. They are managing the entire process of the estate sale, ensuring that all items are properly displayed and available for potential buyers.",
        human_readable_id: 0,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "ca8cacdf-afb6-4962-8b83-345675a705e1",
      source: {
        id: "7fdf8e5b-32b3-4772-b52c-fcac2cfda691",
        name: "CHICAGO ESTATE ADVISORS",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "0a603499-b3f1-4dae-9385-0f25efcbdf0e",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 8,
        description_x:
          "The estate sale prominently features a collection of luxury items, including Chanel purses. This collection highlights the presence of various Chanel items, making it a notable aspect of the estate sale.",
        human_readable_id: 1,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "cb8aedad-0592-42a9-94e9-a9a6fbd40359",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "0a603499-b3f1-4dae-9385-0f25efcbdf0e",
        name: "CHANEL",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "1d3235e7-2a24-44dc-b372-42dd96f0f5c5",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 9,
        description_x: "The estate sale is being held on Astra Street.",
        human_readable_id: 2,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "d35f9ed4-a3c5-45ec-a312-bc99d732a14f",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "1d3235e7-2a24-44dc-b372-42dd96f0f5c5",
        name: "ASTRA STREET",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "211408fa-6268-431b-849f-0bc2579303c7",
      data: {
        text_unit_ids:
          "448d7dc014697fef14d00837a8f6c0e17ec88ba83ad4726d4c23c4f0e5616b7f1af7204d6f50a33b43941ea08986d5a5ec768b1f7fc76c45e0767ce9a3775deb",
        weight: 6,
        description_x:
          "Jewelry is one of the luxury items featured in the estate sale",
        human_readable_id: 3,
        n_tokens: 73,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "9ab0ccd9-a6ce-4806-b3c1-3a055b2398a3",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "211408fa-6268-431b-849f-0bc2579303c7",
        name: "JEWELRY",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "949a3205-f16b-4f91-ae5d-5cdcb71246cc",
      data: {
        text_unit_ids:
          "448d7dc014697fef14d00837a8f6c0e17ec88ba83ad4726d4c23c4f0e5616b7f1af7204d6f50a33b43941ea08986d5a5ec768b1f7fc76c45e0767ce9a3775deb",
        weight: 6,
        description_x:
          "Clothes are part of the luxury items available at the estate sale",
        human_readable_id: 4,
        n_tokens: 73,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "5e201c25-29bc-4f63-9ae7-30e3335af602",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "949a3205-f16b-4f91-ae5d-5cdcb71246cc",
        name: "CLOTHES",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "eb90a3e8-4578-4a4d-a044-1cbec5f525cb",
      data: {
        text_unit_ids:
          "448d7dc014697fef14d00837a8f6c0e17ec88ba83ad4726d4c23c4f0e5616b7f1af7204d6f50a33b43941ea08986d5a5ec768b1f7fc76c45e0767ce9a3775deb",
        weight: 1,
        description_x:
          "Antiques are among the luxury items being sold at the estate sale",
        human_readable_id: 5,
        n_tokens: 73,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "80bff8bd-07ea-4a91-9b23-655fec5b30d9",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "eb90a3e8-4578-4a4d-a044-1cbec5f525cb",
        name: "ANTIQUES",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "8d715494-92df-46a8-b1ac-eeb6123438ef",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 8,
        description_x: "The estate sale is being held in Gold Coast",
        human_readable_id: 39,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "dfd7a7f3-c44a-4734-8648-5f6af6984020",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "8d715494-92df-46a8-b1ac-eeb6123438ef",
        name: "GOLD COAST",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "d2dba83e-7451-4f9e-a741-7c7a0533abb5",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 1,
        description_x:
          "The estate sale is scheduled to occur from January 31st to February 2nd",
        human_readable_id: 40,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "b0d80439-e6f3-48c9-8ec6-b88b1dc98442",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "d2dba83e-7451-4f9e-a741-7c7a0533abb5",
        name: "JANUARY 31ST TO FEBRUARY 2ND",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "bcc755f1-b356-4907-aefc-2555dcdd60ec",
      target_id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
      data: {
        text_unit_ids:
          "43e0a3475c13cf93a2ed2ad4466cdc83dd78aadebb3b8b3cc380d48f309ae82f6aa7fa2cb66bac9042c93669d055ffcac63b8f5cc437ae548ce19733de020fa3",
        weight: 6,
        description_x:
          "The living space includes a bar cart purchased from Five Below",
        human_readable_id: 6,
        n_tokens: 222,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "ed888fec-d134-4f09-9510-d78005940ec5",
      source: {
        id: "bcc755f1-b356-4907-aefc-2555dcdd60ec",
        name: "FIVE BELOW",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
        name: "LIVING SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
      target_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 16,
        description_x:
          "The LIVING SPACE is a central area within the STUDIO, which is described as cute and cozy. It is an integral part of the studio, providing a comfortable and inviting environment.",
        human_readable_id: 8,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "5681e997-29e6-4328-8d33-0de9e41b8398",
      source: {
        id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
        name: "LIVING SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
      target_id: "c0867fae-af12-4d84-9b68-958dfdd0669f",
      data: {
        text_unit_ids:
          "fdf62deffd4ce4a62643183c8df1d22ab45c598ae0b41e296dae0b8c6e3657b838c31f2ba03bb3f8205f386cc0863fa01509cf5105bba6c04f150662f03f1c0a",
        weight: 8,
        description_x:
          "The empty apartment was transformed into a well-organized living space",
        human_readable_id: 182,
        n_tokens: 162,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7432918152297614634.mp4",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
      id: "a08f8335-ed06-479a-820e-bc1792afe684",
      source: {
        id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
        name: "LIVING SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "c0867fae-af12-4d84-9b68-958dfdd0669f",
        name: "EMPTY APARTMENT",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
          url: "https://www.tiktokv.com/share/video/7432918152297614634/",
          video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
          time_saved: "2025-02-02 03:36:08",
        },
      },
    },
    {
      source_id: "c973125a-22b9-471e-a202-3f88c6d35e6c",
      target_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 16,
        description_x:
          "The KITCHEN is an integral part of the STUDIO. It is prominently featured during the studio tour, showcasing its significance within the space.",
        human_readable_id: 7,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "03de5f8d-8e77-4ffe-9254-af30fe61253c",
      source: {
        id: "c973125a-22b9-471e-a202-3f88c6d35e6c",
        name: "KITCHEN",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "b1e5498a-2b96-43b4-9e16-c5f012cc8e72",
      data: {
        text_unit_ids:
          "43e0a3475c13cf93a2ed2ad4466cdc83dd78aadebb3b8b3cc380d48f309ae82f6aa7fa2cb66bac9042c93669d055ffcac63b8f5cc437ae548ce19733de020fa3",
        weight: 7,
        description_x:
          "The patio is an anticipated addition to the studio for study breaks",
        human_readable_id: 9,
        n_tokens: 222,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "644be3ad-42e2-44d3-8f3e-681c6dffa245",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "b1e5498a-2b96-43b4-9e16-c5f012cc8e72",
        name: "PATIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "b4b2f459-5300-45ad-9398-c9b61b4be628",
      data: {
        text_unit_ids:
          "43e0a3475c13cf93a2ed2ad4466cdc83dd78aadebb3b8b3cc380d48f309ae82f6aa7fa2cb66bac9042c93669d055ffcac63b8f5cc437ae548ce19733de020fa3",
        weight: 8,
        description_x:
          "The bathroom is a part of the studio and completes the tour",
        human_readable_id: 10,
        n_tokens: 222,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "18b9414d-36c1-47cc-a593-51d2b1f2f789",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "b4b2f459-5300-45ad-9398-c9b61b4be628",
        name: "BATHROOM",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 1,
        description_x:
          "A divider from Facebook Marketplace is used in the studio",
        human_readable_id: 305,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "12ba0e95-59d3-4230-8740-0de11fe74244",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
        name: "FACEBOOK MARKETPLACE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "5c68ffc9-c0d8-445d-a98a-b01a0f232685",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 8,
        description_x: "The balcony is an outdoor extension of the studio",
        human_readable_id: 306,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "96b22db8-ae55-47e0-b0e8-cfc0ecdbb884",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "5c68ffc9-c0d8-445d-a98a-b01a0f232685",
        name: "BALCONY",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "5a3bb2f4-2299-4125-b626-33797f660bfa",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 8,
        description_x:
          "The bedroom is a part of the studio, separated by a divider",
        human_readable_id: 308,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "e7017d2b-63a0-4f8a-83c5-b839fc132dd8",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "5a3bb2f4-2299-4125-b626-33797f660bfa",
        name: "BEDROOM",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
      target_id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 1,
        description_x:
          "LSAC provides the overall cumulative GPA that law schools use to evaluate applicants",
        human_readable_id: 11,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "c37aee94-518f-4290-bd51-b4b2c2ff433f",
      source: {
        id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
        name: "LSAC",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
        name: "LAW SCHOOLS",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
      target_id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
      data: {
        text_unit_ids:
          "bbcd90222e5ad69e9cfb11aafe7df34f2a84e7d5319e629b2d27b33f7839169992009be2ed32e2590ccfc658d79fc0a0a3d96771da66e0bf545b73197e392282",
        weight: 8,
        description_x:
          "The strategies are designed to enhance applications to law schools by focusing on alignment with personal aspirations",
        human_readable_id: 93,
        n_tokens: 166,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "7ac42f26-d98f-47ea-a7fc-749572abd5f2",
      source: {
        id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
        name: "LAW SCHOOLS",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
        name: "LAW SCHOOL APPLICATION STRATEGIES",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      target_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 7,
        description_x:
          "The applicant's GPA is a critical factor in law school admissions",
        human_readable_id: 12,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "746c5340-c1f4-4a91-a1d3-04396e5b1e18",
      source: {
        id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
        name: "GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      target_id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 9,
        description_x:
          "The overall cumulative GPA is a comprehensive measure of an applicant's academic performance",
        human_readable_id: 13,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "b8250863-8e51-4bff-8137-c460b747d27c",
      source: {
        id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
        name: "GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
        name: "OVERALL CUMULATIVE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      target_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 8,
        description_x:
          "Law schools consider GPA as a significant factor in the admissions process",
        human_readable_id: 284,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "f0ca5339-f023-4174-a6d5-6c9bbaff9293",
      source: {
        id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
        name: "GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      target_id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 6,
        description_x:
          "The applicant's degree GPA may differ from their overall cumulative GPA",
        human_readable_id: 14,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "29b08938-e2b2-445a-8353-abf2bce836ec",
      source: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
        name: "DEGREE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      target_id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 8,
        description_x:
          "The overall cumulative GPA is used to evaluate the applicant's academic performance",
        human_readable_id: 16,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "80401bf8-7eea-4eb5-8337-8d31984fd30a",
      source: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
        name: "OVERALL CUMULATIVE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
      target_id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 1,
        description_x:
          "The degree GPA may differ from the overall cumulative GPA, which is used by law schools",
        human_readable_id: 15,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "e45783a6-2c15-4d95-b3fa-eef349c4aab1",
      source: {
        id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
        name: "OVERALL CUMULATIVE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
        name: "DEGREE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
      target_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 8,
        description_x:
          "Facebook Marketplace was the primary source for acquiring furniture and decor items for the apartment transformation",
        human_readable_id: 17,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "843a52d1-3f15-479a-b867-9c58376661c9",
      source: {
        id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
        name: "FACEBOOK MARKETPLACE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 8,
        description_x:
          "Furniture and decor for the studio apartment makeover were predominantly sourced from Facebook Marketplace",
        human_readable_id: 105,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "025980e1-2e38-48fe-b6c8-edfbef0ca93e",
      source: {
        id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
        name: "FACEBOOK MARKETPLACE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 1,
        description_x:
          "IKEA provided curtains and a vintage sofa that were used in the apartment transformation",
        human_readable_id: 18,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "3155c3e8-ede4-4a85-b6aa-1a56ad739e25",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 9,
        description_x:
          "The studio apartment was the location where the transformation took place",
        human_readable_id: 19,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "593fc256-258b-4459-8d5c-e1be2311e2a4",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "1ea4b3a8-4d69-454a-a4bf-ea8a9e39ec1f",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 6,
        description_x:
          "The dresser was the most expensive item purchased for the apartment transformation",
        human_readable_id: 20,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "31983aff-d36d-4140-8bd7-4afbed56810c",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "1ea4b3a8-4d69-454a-a4bf-ea8a9e39ec1f",
        name: "DRESSER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "247ff7d9-2b9a-43ea-947e-a1cff045cbdb",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 5,
        description_x:
          "The rug was one of the items sourced for the apartment transformation",
        human_readable_id: 21,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "ef8dbc13-54db-447f-ae90-0d456f08eb05",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "247ff7d9-2b9a-43ea-947e-a1cff045cbdb",
        name: "RUG",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "78b26078-9166-4606-8a31-69063e11da26",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 7,
        description_x:
          "The headboard was constructed and reupholstered as part of the apartment transformation",
        human_readable_id: 22,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "56b98562-3426-4b82-9303-ed67e1b4a134",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "78b26078-9166-4606-8a31-69063e11da26",
        name: "HEADBOARD",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "a1f9ad59-42ed-48b6-baed-52f689359d7e",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 6,
        description_x:
          "The queen-sized bed frame was purchased for the apartment transformation",
        human_readable_id: 23,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "f89445d8-c253-4899-9002-775094ab6fa3",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "a1f9ad59-42ed-48b6-baed-52f689359d7e",
        name: "QUEEN-SIZED BED FRAME",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 5,
        description_x:
          "The coffee table was purchased for the apartment transformation",
        human_readable_id: 24,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "b991d00e-932e-461e-b764-d9ed44f30b34",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
        name: "COFFEE TABLE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 5,
        description_x:
          "The floor lamp was purchased for the apartment transformation",
        human_readable_id: 25,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "dd6850f5-4a0f-40e6-a197-87fbb7e67c17",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
        name: "FLOOR LAMP",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "10ff1090-9b1e-4259-ae3e-a68ec9d8d889",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 1,
        description_x:
          "The dining chairs were reupholstered and used in the apartment transformation",
        human_readable_id: 26,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "b282066b-2d19-41d2-948d-2c992cef218c",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "10ff1090-9b1e-4259-ae3e-a68ec9d8d889",
        name: "DINING CHAIRS",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "IKEA curtains were used in the studio apartment makeover",
        human_readable_id: 106,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "536b6e91-54d5-40e1-9944-c9d7f1f634df",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
      data: {
        text_unit_ids:
          "2a6c470016d3024a16884c76dcfddbfda422dc02ef1ed818f1ba39bcab928c2e3eaa00444b87f5af64ed26fc3dd6528e94faf1b68ac0bd717b33dbdc305f0747",
        weight: 1,
        description_x:
          "IKEA manufactures and sells the PAX wardrobe system, which is part of their product line",
        human_readable_id: 132,
        n_tokens: 47,
        title: "B S Y ✩ on TikTok",
        description_y:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7220431692467604779.mp4",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
      id: "06681090-d31e-4b75-a96e-124164ec43bc",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
        name: "PAX WARDROBE SYSTEM",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
      data: {
        text_unit_ids:
          "2a6c470016d3024a16884c76dcfddbfda422dc02ef1ed818f1ba39bcab928c2e3eaa00444b87f5af64ed26fc3dd6528e94faf1b68ac0bd717b33dbdc305f0747",
        weight: 1,
        description_x:
          "The assembly demonstration is organized by IKEA to highlight their PAX wardrobe system",
        human_readable_id: 133,
        n_tokens: 47,
        title: "B S Y ✩ on TikTok",
        description_y:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7220431692467604779.mp4",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
      id: "6aa0bdab-d09e-4364-9685-f46bd35ee4d8",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
        name: "ASSEMBLY DEMONSTRATION",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
      data: {
        text_unit_ids:
          "45360f7e54423c62dfa2a8404189af7bea9ddf29939ddee2cb38d350d85f558968d60f13a773ec824d07f100f082fa240ac31f2347e5b86022376e7153c28bb6",
        weight: 8,
        description_x: "The studio apartment is located in New York City",
        human_readable_id: 47,
        n_tokens: 150,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7439095695274970410.mp4",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
      id: "e3d381c3-1c1b-40c7-8d27-2a9d7ead333d",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
        name: "NEW YORK CITY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      data: {
        text_unit_ids:
          "6f44efc1e5599054486b52d3a68f69f07b0a2b238b02e4ba5ebedb76b897ade033a084833ad84c4f521e3fc177a98276d970612946be1712ae1121898552fb4e",
        weight: 7,
        description_x:
          "The studio apartment is located in the West Loop neighborhood of Chicago",
        human_readable_id: 202,
        n_tokens: 81,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "e0c9615b-e2f6-45f0-9c78-c51baafe6962",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "e6cecea6-ed85-46a9-b206-2d94f868ef78",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 5,
        description_x:
          "The studio apartment features Hayao Miyazaki sketches as part of its personalized decor",
        human_readable_id: 290,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "02446ff4-78b6-4bce-bfa1-fa66f8c38bf0",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "e6cecea6-ed85-46a9-b206-2d94f868ef78",
        name: "HAYAO MIYAZAKI",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "66c1682e-6aac-4e5e-9f2f-0e0914522912",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 4,
        description_x:
          "The studio apartment includes a vintage car poster as part of its decor, reflecting personal taste",
        human_readable_id: 291,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "b8f6ea03-f654-4739-9464-628dfbac6213",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "66c1682e-6aac-4e5e-9f2f-0e0914522912",
        name: "VINTAGE CAR POSTER",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "d02e66f8-5679-4be9-afbf-159468f31e8f",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 1,
        description_x:
          "The studio apartment features a basketball-turned-planter, showcasing creativity in decoration",
        human_readable_id: 292,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "bdc8e2e1-16a5-4db7-b0db-c7475f9d76b0",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "d02e66f8-5679-4be9-afbf-159468f31e8f",
        name: "BASKETBALL-TURNED-PLANTER",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "7f0d8a00-39f4-421e-bff4-df57f230054c",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 11,
        description_x:
          "The studio apartment features plants as an integral part of its decor, contributing to a personalized and enhanced atmosphere. These plants are used as part of the personal decorations, adding a touch of nature and individuality to the living space.",
        human_readable_id: 293,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "0923454b-e0f5-4c68-a305-2f259ba0f247",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "7f0d8a00-39f4-421e-bff4-df57f230054c",
        name: "PLANTS",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "1f55aecf-83b8-4835-9afe-cec8999e4a24",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 1,
        description_x:
          "The studio apartment features skateboard art, reflecting the occupant's personal style",
        human_readable_id: 294,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "b903c0c5-7486-4a2f-a725-6225671cc577",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "1f55aecf-83b8-4835-9afe-cec8999e4a24",
        name: "SKATEBOARD ART",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "736383b0-a597-43d1-b94a-b99a30aad2bc",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 7,
        description_x:
          "The skateboard wall art is part of the personal decorations in the studio apartment",
        human_readable_id: 309,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "2eb27010-629e-4982-910c-6ca638d0b712",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "736383b0-a597-43d1-b94a-b99a30aad2bc",
        name: "SKATEBOARD WALL ART",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "55ad87d3-a81b-4243-9f74-c4237a5dcbbf",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 1,
        description_x:
          "Designer furniture is used to enhance the aesthetic of the studio apartment",
        human_readable_id: 310,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "9058813a-65e9-4cae-a0ed-639aa2d2a3cd",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "55ad87d3-a81b-4243-9f74-c4237a5dcbbf",
        name: "DESIGNER FURNITURE",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "e417a76f-fa74-4725-81b2-369ed3108677",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 1,
        description_x:
          "The studio apartment evokes a personal connection and sense of ownership in its inhabitants",
        human_readable_id: 311,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "414c3fbd-823f-45ac-9d61-12274fea6064",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "e417a76f-fa74-4725-81b2-369ed3108677",
        name: "PERSONAL CONNECTION",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "The coffee table was purchased and used in the studio apartment makeover",
        human_readable_id: 111,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "c39c1a8f-761f-416a-8e1c-ed0b2351338b",
      source: {
        id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
        name: "COFFEE TABLE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "The floor lamp was acquired and used in the studio apartment makeover",
        human_readable_id: 112,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "1d986d14-14cb-41bf-b575-490f464b8b88",
      source: {
        id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
        name: "FLOOR LAMP",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "bff1c9c7-2ba4-4cb3-a753-733cef2b65cc",
      target_id: "bf163a77-6eaf-4055-bc65-94c666b51973",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 5,
        description_x:
          "The recruiter screening is an early stage that leads to the verbal offer in the hiring process",
        human_readable_id: 27,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "797d3c84-347d-463c-ae46-0193f3fae92c",
      source: {
        id: "bff1c9c7-2ba4-4cb3-a753-733cef2b65cc",
        name: "RECRUITER SCREENING",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "bf163a77-6eaf-4055-bc65-94c666b51973",
        name: "VERBAL OFFER",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "bf163a77-6eaf-4055-bc65-94c666b51973",
      target_id: "8a0dbafe-c622-42cc-9409-1f9b6e7b377c",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 1,
        description_x:
          "The verbal offer should include a six month promotion review as part of the contract",
        human_readable_id: 28,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "c2f36567-70c5-4e36-8ad7-eae38f65f5b9",
      source: {
        id: "bf163a77-6eaf-4055-bc65-94c666b51973",
        name: "VERBAL OFFER",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "8a0dbafe-c622-42cc-9409-1f9b6e7b377c",
        name: "SIX MONTH PROMOTION REVIEW",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
      target_id: "5a933e1d-d70e-4a33-aeee-33749920651a",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 6,
        description_x:
          "Hiring budgets are managed by companies to control salary expenses",
        human_readable_id: 29,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "7d2a77ea-2bbd-46a1-af25-d143b19d44d5",
      source: {
        id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
        name: "COMPANIES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "5a933e1d-d70e-4a33-aeee-33749920651a",
        name: "HIRING BUDGETS",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
      target_id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 7,
        description_x:
          "Employees negotiate salaries with companies to reach a mutually agreeable compensation",
        human_readable_id: 30,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "67ef1692-d5e6-490f-8442-39fd70ff15e7",
      source: {
        id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
        name: "COMPANIES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
        name: "EMPLOYEES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "5a933e1d-d70e-4a33-aeee-33749920651a",
      target_id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 1,
        description_x:
          "Employees' salary negotiations are influenced by the constraints of hiring budgets",
        human_readable_id: 31,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "3077c3ed-3a99-4d39-bf1e-92547573fbf2",
      source: {
        id: "5a933e1d-d70e-4a33-aeee-33749920651a",
        name: "HIRING BUDGETS",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
        name: "EMPLOYEES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      target_id: "7990f597-ecda-4897-94de-d8a3a7db025c",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 5,
        description_x:
          "The VP suggests that negotiation might be easier at a startup",
        human_readable_id: 32,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "c45e104a-6c3c-463a-a010-045019f99b3c",
      source: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "7990f597-ecda-4897-94de-d8a3a7db025c",
        name: "STARTUP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      target_id: "82f87e77-7ff8-4ea1-a05c-d2f6e8731351",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 5,
        description_x:
          "The VP notes potential inflexibility in larger companies during salary negotiations",
        human_readable_id: 33,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "e8939ede-d642-4e54-967e-cd7b18aacc15",
      source: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "82f87e77-7ff8-4ea1-a05c-d2f6e8731351",
        name: "LARGER COMPANIES",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      target_id: "95503808-3df9-424f-b779-be3152c78a28",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 1,
        description_x:
          "The VP mentions possible difficulties in negotiating for programs or internships",
        human_readable_id: 34,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "f79e791a-88fd-4d41-95e2-37936ef844dd",
      source: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "95503808-3df9-424f-b779-be3152c78a28",
        name: "PROGRAMS/INTERNSHIPS",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      target_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 1,
        description_x:
          "The VP provides advice on how to approach salary negotiation",
        human_readable_id: 35,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "e558dfe6-e29f-451a-9103-45867b442ec9",
      source: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "9bacc367-af81-4348-80a7-5d0c3cfec81a",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 7,
        description_x:
          "The research provides insights and strategies for effective salary negotiation",
        human_readable_id: 36,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "24657a95-c6e6-4d2b-8670-a28cd943ee72",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "9bacc367-af81-4348-80a7-5d0c3cfec81a",
        name: "RESEARCH",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
      target_id: "6c418376-f724-487e-aebf-ff618a6b4813",
      data: {
        text_unit_ids:
          "33becafada3009530b8d3c1462b722503b41e47731f8b1ae7a5e153464f5f3a8bb66bb56787c2a36ff4eeae7bcca5c16ffcbe8209bb5e3fa99ece602d91a959b",
        weight: 1,
        description_x:
          "Mirrors are used in a New York City apartment to enhance the decor and create an impression of greater space",
        human_readable_id: 37,
        n_tokens: 86,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "0193aab2-2a35-4d3a-89ee-6e90557df930",
      source: {
        id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
        name: "NEW YORK CITY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "6c418376-f724-487e-aebf-ff618a6b4813",
        name: "MIRRORS",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
      target_id: "1ac38851-a468-4064-8860-e6c80848b922",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 8,
        description_x: "The compact apartment is located in New York City",
        human_readable_id: 52,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "26109e28-16cf-44d5-bc93-4c46c8dd391c",
      source: {
        id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
        name: "NEW YORK CITY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "6c418376-f724-487e-aebf-ff618a6b4813",
      target_id: "4de72e22-4f4f-4c5e-ae62-1716552455cd",
      data: {
        text_unit_ids:
          "33becafada3009530b8d3c1462b722503b41e47731f8b1ae7a5e153464f5f3a8bb66bb56787c2a36ff4eeae7bcca5c16ffcbe8209bb5e3fa99ece602d91a959b",
        weight: 7,
        description_x:
          "Interior designers use mirrors as a trick to enhance the perception of space in a room",
        human_readable_id: 38,
        n_tokens: 86,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "5b8686f2-9f09-4b39-88f7-fcc58b3ddd87",
      source: {
        id: "6c418376-f724-487e-aebf-ff618a6b4813",
        name: "MIRRORS",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "4de72e22-4f4f-4c5e-ae62-1716552455cd",
        name: "INTERIOR DESIGNERS",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "34446084-c7d3-4ab8-87b5-cb23570f396e",
      target_id: "0036cab4-e395-4397-a82f-87872ecabecc",
      data: {
        text_unit_ids:
          "8282cc0eef09ee92374d48d6d14021ae6b41568e95c696dccc62838d48a28d645177867ad0eda38ba51b520fcca0144306708c5ba5f902f4e523640964a77f23",
        weight: 1,
        description_x:
          "Kofie the dog is moving as part of the new apartment decorating series",
        human_readable_id: 41,
        n_tokens: 96,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "f99a66c5-8d39-4c6e-b77c-a0652535388e",
      source: {
        id: "34446084-c7d3-4ab8-87b5-cb23570f396e",
        name: "KOFIE",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "0036cab4-e395-4397-a82f-87872ecabecc",
        name: "NEW APARTMENT DECORATING SERIES",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "08840aac-cc9d-4f6b-ad07-2ba64e45f5da",
      target_id: "93a9c8bf-2a4d-427f-ab49-8c6219fbe763",
      data: {
        text_unit_ids:
          "8282cc0eef09ee92374d48d6d14021ae6b41568e95c696dccc62838d48a28d645177867ad0eda38ba51b520fcca0144306708c5ba5f902f4e523640964a77f23",
        weight: 8,
        description_x:
          "The steam mop is used to remove peel and stick wallpaper effectively",
        human_readable_id: 42,
        n_tokens: 96,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "0d869951-3fd4-48b8-8e98-86b33b9845eb",
      source: {
        id: "08840aac-cc9d-4f6b-ad07-2ba64e45f5da",
        name: "PEEL AND STICK WALLPAPER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "93a9c8bf-2a4d-427f-ab49-8c6219fbe763",
        name: "STEAM MOP",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "2458d043-52e7-4607-970e-552d4d680d08",
      target_id: "3b14e240-3010-434f-ad03-0621badabf67",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 1,
        description_x:
          "The tablet interface is used to control the smart home automation system, enabling changes in lighting and ambiance",
        human_readable_id: 43,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "4bff709c-edf2-4c3b-bfe5-6aeffa5e8078",
      source: {
        id: "2458d043-52e7-4607-970e-552d4d680d08",
        name: "SMART HOME AUTOMATION",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "3b14e240-3010-434f-ad03-0621badabf67",
        name: "TABLET INTERFACE",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "2458d043-52e7-4607-970e-552d4d680d08",
      target_id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 7,
        description_x:
          "The living room's ambiance was transformed by the smart home automation system",
        human_readable_id: 44,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "4feca248-160c-49c5-97e3-f7698189680d",
      source: {
        id: "2458d043-52e7-4607-970e-552d4d680d08",
        name: "SMART HOME AUTOMATION",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
        name: "LIVING ROOM",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "2458d043-52e7-4607-970e-552d4d680d08",
      target_id: "3602b39f-c3c2-417a-900b-0a882180d352",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 8,
        description_x:
          "The smart home automation system controlled the light sources in the living room",
        human_readable_id: 45,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "b504fb1d-bc4f-452e-a8cb-2e47f4089ceb",
      source: {
        id: "2458d043-52e7-4607-970e-552d4d680d08",
        name: "SMART HOME AUTOMATION",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "3602b39f-c3c2-417a-900b-0a882180d352",
        name: "LIGHT SOURCES",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
      target_id: "31136ae2-5b67-43b5-ac65-4729afeb18a9",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 1,
        description_x:
          "Music played in the living room, enhancing the atmosphere",
        human_readable_id: 46,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "f452430c-582a-4075-a6eb-c1050a5773de",
      source: {
        id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
        name: "LIVING ROOM",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "31136ae2-5b67-43b5-ac65-4729afeb18a9",
        name: "MUSIC",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
      data: {
        text_unit_ids:
          "45360f7e54423c62dfa2a8404189af7bea9ddf29939ddee2cb38d350d85f558968d60f13a773ec824d07f100f082fa240ac31f2347e5b86022376e7153c28bb6",
        weight: 7,
        description_x: "The curtain divider components are sourced from Amazon",
        human_readable_id: 48,
        n_tokens: 150,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7439095695274970410.mp4",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
      id: "95272794-8728-4bd4-8b5c-aa0d336e065f",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
        name: "CURTAIN DIVIDER",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 16,
        description_x:
          "The NYC studio apartment incorporates various Amazon products, including a shoe cabinet and a wine rack, highlighting the integration of Amazon's offerings into the living space.",
        human_readable_id: 114,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "d0eb2e17-1ae9-420a-993f-7036488da1e3",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "8f7aee4b-eb26-458b-a3d4-ca136cb81dc8",
      data: {
        text_unit_ids:
          "d77968a305e08b0f09f9673ef96519decadfe71397e7a6cbc9cdaf83850d5b2efb1ca72c57e9be9615286de0968f2946e4c4a8ee015431348b41d0841a759af1",
        weight: 1,
        description_x:
          "Amazon sells products from Honbay, including modular sectional sleeper sofas",
        human_readable_id: 206,
        n_tokens: 61,
        title: "Hello Kristen on TikTok",
        description_y:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7377867118848593194.mp4",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
      id: "095e44ad-3c90-4a2e-a5b1-681404a9931c",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "8f7aee4b-eb26-458b-a3d4-ca136cb81dc8",
        name: "HONBAY",
        group: 1,
        data: {
          title: "Hello Kristen on TikTok",
          description:
            "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
          url: "https://www.tiktokv.com/share/video/7377867118848593194/",
          video_id: "0",
          time_saved: "2025-02-02 00:45:00",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "CeraVe Hydrating Facial Cleanser is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 151,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "1261e968-6076-4536-ba86-64420e81dfce",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
        name: "CERAVE HYDRATING FACIAL CLEANSER",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "CeraVe Daily Moisturizing Lotion is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 152,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "a5a31669-18fb-49e6-bf81-2a92f8ca91f9",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
        name: "CERAVE DAILY MOISTURIZING LOTION",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "EltaMD Tinted Moisturizing Sunscreen is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 153,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "120d307f-5ae9-44de-a4db-980b8f06dc38",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
        name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "fce6a301-1609-4299-a839-8ec20522bae0",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "Aquaphor is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 154,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "ef1e5d6a-7c6a-4507-bba0-df8870212dcd",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "fce6a301-1609-4299-a839-8ec20522bae0",
        name: "AQUAPHOR",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 1,
        description_x:
          "MISSHA M Perfect Cover BB Cream No. 27 is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 155,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "4dd3dd69-db11-4efb-8bb4-1a93f3e2b072",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
        name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          "The Round Wood Coffee Table is available for purchase on Amazon",
        human_readable_id: 184,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "6520ac8d-b5fe-4f54-a4b4-ac889c6fe50d",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "5a610178-5f79-4cb5-8041-867ab2a41874",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          'The Yusong 73" Tall Bookshelf is available for purchase on Amazon',
        human_readable_id: 185,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "f3cde95b-3452-4ec1-83f5-9e302730bd2c",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "5a610178-5f79-4cb5-8041-867ab2a41874",
        name: 'YUSONG 73" TALL BOOKSHELF',
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "017de81e-714d-401f-b1da-fa549eb5fe98",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          "The Sony PS-LX310BT Wireless Turntable is available for purchase on Amazon",
        human_readable_id: 186,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "269f3365-ead0-44a2-b58f-523519d35280",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "017de81e-714d-401f-b1da-fa549eb5fe98",
        name: "SONY PS-LX310BT WIRELESS TURNTABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c761f5a8-9d2a-45f7-b519-bca78cbb47af",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          "The Sonos Era 300 speaker is available for purchase on Amazon",
        human_readable_id: 187,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "3be3d764-9544-4e47-8732-7ca8fb555494",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c761f5a8-9d2a-45f7-b519-bca78cbb47af",
        name: "SONOS ERA 300",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c2be0e25-8b77-4a5d-b997-3e9771d2384e",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 1,
        description_x:
          "The Mueller Retro Toaster is available for purchase on Amazon",
        human_readable_id: 188,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "fe59fe54-b193-4d2e-8dab-84f77f74cc74",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c2be0e25-8b77-4a5d-b997-3e9771d2384e",
        name: "MUELLER RETRO TOASTER",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "cd015a21-18d3-4ebf-be5e-8539457834de",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 5,
        description_x:
          "The cityscape view is part of the content highlighting products available on Amazon",
        human_readable_id: 189,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "37f6e549-37ec-48fe-abde-b233818e5c27",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "cd015a21-18d3-4ebf-be5e-8539457834de",
        name: "CITYSCAPE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "977de398-cef3-4b97-999a-1374a43b432d",
      data: {
        text_unit_ids:
          "8c1c23ae7f3854ef5c9cb6a70adcc97ab46d10e416ec6c5a330c70a65313879487e3984d7b0bd04c96f6478af1dcd338ee2bcf5698f66fd030080f919cfa1272",
        weight: 1,
        description_x:
          "Amazon sells the Val couch, which is a product available for purchase",
        human_readable_id: 231,
        n_tokens: 67,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "410a5116-efa0-4690-aa19-5b4605da0e16",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "977de398-cef3-4b97-999a-1374a43b432d",
        name: "VAL COUCH",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
      data: {
        text_unit_ids:
          "8c1c23ae7f3854ef5c9cb6a70adcc97ab46d10e416ec6c5a330c70a65313879487e3984d7b0bd04c96f6478af1dcd338ee2bcf5698f66fd030080f919cfa1272",
        weight: 6,
        description_x:
          "Social media influences consumer purchases on platforms like Amazon",
        human_readable_id: 232,
        n_tokens: 67,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "fef44d24-0c54-4854-a3e9-803efcd49a69",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
        name: "SOCIAL MEDIA",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c9e08d94-26a3-42d2-9129-4c76ed8099e2",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 7,
        description_x:
          "Amazon offers Modway products, including the walnut TV sideboard, as part of its home decor selection",
        human_readable_id: 295,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "4cc79e28-a659-44cd-860c-a1b16a7d3c41",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c9e08d94-26a3-42d2-9129-4c76ed8099e2",
        name: "MODWAY",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "281f9b2d-3728-43be-ade8-6104877e2a11",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 8,
        description_x:
          "The walnut Modway TV sideboard is available for purchase on Amazon",
        human_readable_id: 296,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "874fe865-5e97-4d3b-8eab-ccf681400c75",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "281f9b2d-3728-43be-ade8-6104877e2a11",
        name: "WALNUT MODWAY TV SIDEBOARD",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c729bafe-4805-4403-84eb-9a68f394daea",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 8,
        description_x:
          "The matte black floating shelves are part of Amazon's curated home decor selection",
        human_readable_id: 297,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "cc497b13-357a-4066-886a-e4ca5772ae61",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c729bafe-4805-4403-84eb-9a68f394daea",
        name: "MATTE BLACK FLOATING SHELVES",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "1f4c4326-d517-4440-9b1b-d70e2d6e2c92",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 8,
        description_x:
          "The retro flip clock is available on Amazon, contributing to home decor aesthetics",
        human_readable_id: 298,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "f133ae41-6be3-4a69-8e1e-5bff2aac53f8",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "1f4c4326-d517-4440-9b1b-d70e2d6e2c92",
        name: "RETRO FLIP CLOCK",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 1,
        description_x:
          "The claw side table is part of Amazon's home decor offerings, emphasizing organic forms",
        human_readable_id: 299,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "e69ae48b-a0e3-44f1-9b8a-a0f8f184fee6",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
        name: "CLAW SIDE TABLE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "687e66c5-3e76-4abb-adc4-afdaa21f1853",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the walnut TV sideboard as part of its interior design products",
        human_readable_id: 301,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "d2c4b176-7773-4210-963e-4142610be83a",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "687e66c5-3e76-4abb-adc4-afdaa21f1853",
        name: "WALNUT TV SIDEBOARD",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "3537c340-2931-409f-aa3a-f20206e1536d",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the black matte floating shelves as part of its interior design products",
        human_readable_id: 302,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "29298962-5529-40a2-817d-c25a925b4b8c",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "3537c340-2931-409f-aa3a-f20206e1536d",
        name: "BLACK MATTE FLOATING SHELVES",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "6a6cefcf-373f-4da5-aadd-77315b563cae",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the white adjustable flip desk clock as part of its interior design products",
        human_readable_id: 303,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "7243ad04-f476-436f-a46b-b3ae6c6309c7",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "6a6cefcf-373f-4da5-aadd-77315b563cae",
        name: "WHITE ADJUSTABLE FLIP DESK CLOCK",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "18149e0d-4f47-421c-8536-971989ee8f02",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the natural edge side table as part of its interior design products",
        human_readable_id: 304,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "8c6bbb80-0d9e-4cf7-8c22-36691d253831",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "18149e0d-4f47-421c-8536-971989ee8f02",
        name: "NATURAL EDGE SIDE TABLE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
      target_id: "99adf17f-0a5c-4107-8582-81877059cac1",
      data: {
        text_unit_ids:
          "45360f7e54423c62dfa2a8404189af7bea9ddf29939ddee2cb38d350d85f558968d60f13a773ec824d07f100f082fa240ac31f2347e5b86022376e7153c28bb6",
        weight: 1,
        description_x:
          "The assembly process is part of creating the curtain divider",
        human_readable_id: 49,
        n_tokens: 150,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7439095695274970410.mp4",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
      id: "44b0e7b0-54e0-4827-be76-41e30a89b657",
      source: {
        id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
        name: "CURTAIN DIVIDER",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "99adf17f-0a5c-4107-8582-81877059cac1",
        name: "ASSEMBLY PROCESS",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
    },
    {
      source_id: "983a48e3-8d42-4744-9891-79974d58a47f",
      target_id: "92ae7250-8853-4077-a6fe-45abb99a1c93",
      data: {
        text_unit_ids:
          "2798f20bc4f1d15d9faab41b4bc4f24eee0148bc2f1ba9cfcc37d7480a3a6fa202f4e68f9f61d5a9d259055e19977580b2fb56d34200009889dc4bdb60b5faf3",
        weight: 7,
        description_x:
          "SimplifyLSAT.com provides guides and strategies to improve performance on the LSAT Reading Comprehension section",
        human_readable_id: 50,
        n_tokens: 124,
        title: "simplifylsat on TikTok",
        description_y:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7096272025118887210.mp4",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
      id: "bceeeb10-0dbc-412d-942c-11feac8eda22",
      source: {
        id: "983a48e3-8d42-4744-9891-79974d58a47f",
        name: "LSAT READING COMPREHENSION",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
      target: {
        id: "92ae7250-8853-4077-a6fe-45abb99a1c93",
        name: "SIMPLIFYLSAT.COM",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
    },
    {
      source_id: "edfeddcd-13cf-499d-80ed-3d251b049651",
      target_id: "a57879b8-8546-409c-9185-9fb53f6f8962",
      data: {
        text_unit_ids:
          "2798f20bc4f1d15d9faab41b4bc4f24eee0148bc2f1ba9cfcc37d7480a3a6fa202f4e68f9f61d5a9d259055e19977580b2fb56d34200009889dc4bdb60b5faf3",
        weight: 5,
        description_x:
          "Legal theorists discuss the legal implications of how corporations are charged",
        human_readable_id: 51,
        n_tokens: 124,
        title: "simplifylsat on TikTok",
        description_y:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7096272025118887210.mp4",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
      id: "a2c0f22c-308d-4ed9-bbb5-3adb8cbb4f94",
      source: {
        id: "edfeddcd-13cf-499d-80ed-3d251b049651",
        name: "LEGAL THEORISTS",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
      target: {
        id: "a57879b8-8546-409c-9185-9fb53f6f8962",
        name: "CORPORATIONS",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
    },
    {
      source_id: "1ac38851-a468-4064-8860-e6c80848b922",
      target_id: "c30dc008-2120-403a-8746-7c04394e1d3a",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 1,
        description_x:
          "The round mirror is used as a design strategy in the compact apartment",
        human_readable_id: 53,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "11c57db4-0925-4d8a-a123-0a4e47f3b999",
      source: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "c30dc008-2120-403a-8746-7c04394e1d3a",
        name: "6-FOOT ROUND MIRROR",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "1ac38851-a468-4064-8860-e6c80848b922",
      target_id: "41d75f90-1452-423d-b02d-be618dc0e695",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 9,
        description_x: "The person resides in the compact apartment",
        human_readable_id: 54,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "8b5582e2-e3ea-4fb2-8542-dbdca2b76844",
      source: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "41d75f90-1452-423d-b02d-be618dc0e695",
        name: "PERSON LIVING IN APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "1ac38851-a468-4064-8860-e6c80848b922",
      target_id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 1,
        description_x:
          "The design strategy is being implemented in the compact apartment",
        human_readable_id: 55,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "6d706971-39ab-46f2-9d46-48098ed87a64",
      source: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
        name: "DESIGN STRATEGY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "c30dc008-2120-403a-8746-7c04394e1d3a",
      target_id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 7,
        description_x:
          "The design strategy involves using a 6-foot round mirror to enhance the apartment",
        human_readable_id: 56,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "bdc26581-385b-48f0-a146-a3c17319f769",
      source: {
        id: "c30dc008-2120-403a-8746-7c04394e1d3a",
        name: "6-FOOT ROUND MIRROR",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
        name: "DESIGN STRATEGY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "3dfb8a5f-2b3f-4b3b-bf3d-bd76ea9feb9a",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 5,
        description_x:
          "Obama deported fewer illegal immigrants compared to George Bush",
        human_readable_id: 57,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "cb3d1ef2-7596-4021-ba7d-0fd10017a458",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "3dfb8a5f-2b3f-4b3b-bf3d-bd76ea9feb9a",
        name: "GEORGE BUSH",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "5896a336-26f6-4867-8767-1dfcc553a00f",
      target_id: "82f873cd-2d1a-4361-bb9f-c8a52ee9c1b7",
      data: {
        text_unit_ids:
          "9431a5bc5ac5f28369eb11a5222e6d7df004be287277e5e2624da77c8558c604875215e442c4d312c989ca22bc609058fc8313a848fe555552e7d04a1aa037ca",
        weight: 1,
        description_x:
          "The outfit was chosen to celebrate the friend's graduation",
        human_readable_id: 281,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "f3921f19-6ca2-4664-bf81-3d0874aa987f",
      source: {
        id: "5896a336-26f6-4867-8767-1dfcc553a00f",
        name: "FRIEND",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "82f873cd-2d1a-4361-bb9f-c8a52ee9c1b7",
        name: "OUTFIT",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "57220f5f-6650-4761-9380-1564961e7a32",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 5,
        description_x:
          "Obama deported fewer illegal immigrants compared to Bill Clinton",
        human_readable_id: 58,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "68ebdff8-c39a-4b20-a6d0-fac88d3f5448",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "57220f5f-6650-4761-9380-1564961e7a32",
        name: "BILL CLINTON",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 1,
        description_x:
          "Obama's immigration policies were more lenient compared to Trump's",
        human_readable_id: 59,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "bf29291d-6e08-498e-bcd2-933178eb3e02",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
        name: "TRUMP",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 6,
        description_x:
          "Obama deported fewer illegal immigrants compared to his predecessors",
        human_readable_id: 60,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "1a050d1e-cf18-4a74-bca9-066ab185351b",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
        name: "ILLEGAL IMMIGRANTS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "493dc633-c881-4f2d-b93a-74dd7a526c15",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 7,
        description_x:
          "Obama prioritized the deportation of criminal offenders",
        human_readable_id: 61,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "5ec5bc97-3aff-416e-a35d-bb91d106eefe",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "493dc633-c881-4f2d-b93a-74dd7a526c15",
        name: "CRIMINAL OFFENDERS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "491d310b-caef-4928-9a04-9eeee7e22235",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 8,
        description_x:
          "Obama implemented protections for younger illegal immigrants",
        human_readable_id: 62,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "63647eb4-251d-4b3d-8060-0bf2579df496",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "491d310b-caef-4928-9a04-9eeee7e22235",
        name: "YOUNGER ILLEGAL IMMIGRANTS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
      target_id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 1,
        description_x:
          "Trump had a stricter view on illegal immigrants, viewing them negatively",
        human_readable_id: 63,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "08b9d0dd-77b9-4349-8dfe-8d75e1afd36a",
      source: {
        id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
        name: "TRUMP",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
        name: "ILLEGAL IMMIGRANTS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "a899167f-13d7-4776-95ec-dc4305aab0ac",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x: "The law school offers a focus area in tax law",
        human_readable_id: 241,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "fc7d982e-51bc-4ecd-8cd4-93fc1b66b272",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "a899167f-13d7-4776-95ec-dc4305aab0ac",
        name: "TAX LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 1,
        description_x:
          "The essay connects the journey to law school with themes of white liberalism, providing thought-provoking commentary",
        human_readable_id: 64,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "c2d7c1ec-b2c7-477d-91ea-a9e9e4c62304",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
        name: "WHITE LIBERALISM",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 3,
        description_x:
          "The essay connects the journey to the specific law school application",
        human_readable_id: 65,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "370b4871-0653-4947-9394-2b18f93a44e7",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
        name: "ESSAY",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "d10397ae-7d40-43e6-a6e2-937551359e3a",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 8,
        description_x:
          "Current law students provide insights into the academic and cultural environment of the law school",
        human_readable_id: 234,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "ae90b05f-ba11-4c27-bf85-6cc832794e08",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "d10397ae-7d40-43e6-a6e2-937551359e3a",
        name: "CURRENT LAW STUDENTS",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "a93e0095-438f-4454-bcba-cc6358809b6a",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 7,
        description_x:
          "The law school uses a grading curve to assess student performance",
        human_readable_id: 235,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "a5c36869-28e5-44c2-b989-294df54e4c2c",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "a93e0095-438f-4454-bcba-cc6358809b6a",
        name: "GRADING CURVE",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "786a93ce-2abc-4dec-94b6-f572392675e9",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 8,
        description_x:
          "The law school offers experiential learning opportunities to enhance practical skills",
        human_readable_id: 236,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "523ff4a7-e687-4056-975e-fd33e0fe31fb",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "786a93ce-2abc-4dec-94b6-f572392675e9",
        name: "EXPERIENTIAL LEARNING OPPORTUNITIES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "d9073a6c-2788-49c9-baf6-1014d7fa4132",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 9,
        description_x:
          "The law school implements COVID-19 safety measures to protect students",
        human_readable_id: 237,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "d98ff7dd-8994-4eaf-b226-38b5616023ab",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "d9073a6c-2788-49c9-baf6-1014d7fa4132",
        name: "COVID-19 SAFETY MEASURES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "220222e1-f217-4217-8ba2-faee2c864608",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x:
          "The law school offers a focus area in public defender work",
        human_readable_id: 238,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "0b7c5199-4ec0-45f7-b6d5-55e333160742",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "220222e1-f217-4217-8ba2-faee2c864608",
        name: "PUBLIC DEFENDER",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "9e7d8f3c-0e5b-454a-a729-c8d16a4fe2e6",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x: "The law school offers a focus area in criminal law",
        human_readable_id: 239,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "3ff35e7f-e70f-40f7-8f21-92a193d7b43a",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "9e7d8f3c-0e5b-454a-a729-c8d16a4fe2e6",
        name: "CRIMINAL LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "f48012d4-dcfc-4f05-ba28-d003dd429c25",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x: "The law school offers a focus area in business law",
        human_readable_id: 240,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "327d2244-2efa-453f-bfe2-e6418ade2ddd",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "f48012d4-dcfc-4f05-ba28-d003dd429c25",
        name: "BUSINESS LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "103c65e8-5adc-410f-8189-89de75e4dc04",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x:
          "The law school offers a focus area in entertainment law",
        human_readable_id: 242,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "18dab22b-1152-4b34-80e9-6cf0cfa42d84",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "103c65e8-5adc-410f-8189-89de75e4dc04",
        name: "ENTERTAINMENT LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "cb911e07-64c6-4999-801c-8201e85de699",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 5,
        description_x:
          "The law school provides information on living costs for students",
        human_readable_id: 243,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "77f97b25-b69b-4570-8d31-a2351c01c647",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "cb911e07-64c6-4999-801c-8201e85de699",
        name: "LIVING COSTS",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "02e0138c-4313-4ecb-b872-87ced892539b",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 7,
        description_x:
          "The law school offers free resources to support students",
        human_readable_id: 244,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "ce7c8342-0a22-4120-bc93-9cdff5f058ef",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "02e0138c-4313-4ecb-b872-87ced892539b",
        name: "FREE RESOURCES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "780e01a7-144c-4b50-9169-21c0d905a602",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 1,
        description_x:
          "The law school provides networking opportunities for students",
        human_readable_id: 245,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "742ed696-5d6f-4251-afc8-8dc406a81e48",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "780e01a7-144c-4b50-9169-21c0d905a602",
        name: "NETWORKING OPPORTUNITIES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "caf7a7b1-45b2-4778-80c1-f03eaf81ce0b",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 8,
        description_x:
          "The law school offers mental health assistance to support student well-being",
        human_readable_id: 246,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "ec8aed84-292e-465d-a6c5-870eedb1da61",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "caf7a7b1-45b2-4778-80c1-f03eaf81ce0b",
        name: "MENTAL HEALTH ASSISTANCE",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "ec7b3d33-35fe-4803-8e29-a2ad4a96cf1c",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 1,
        description_x:
          "The law school provides accommodations to support diverse student needs",
        human_readable_id: 247,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "409279f5-8b1f-49dc-8774-f4019b03f69c",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "ec7b3d33-35fe-4803-8e29-a2ad4a96cf1c",
        name: "ACCOMMODATIONS",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 1,
        description_x:
          "Law schools use LSAT scores as a key factor in admissions, especially for applicants with low GPAs",
        human_readable_id: 283,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "46a0c913-efaf-4074-9639-3a6150ed1743",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 7,
        description_x:
          "Law schools value strong letters of recommendation to assess an applicant's potential",
        human_readable_id: 285,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "974ee4d3-c023-403c-b8a3-860403a1b789",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
        name: "LETTERS OF RECOMMENDATION",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "dbd71c04-37d7-494a-ac72-3524c0f582c5",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 1,
        description_x:
          "Splitter candidates present a unique challenge to law schools due to their mixed academic profiles",
        human_readable_id: 286,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "56d6fd3c-449a-4e36-bc22-4c5f414ae65c",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "dbd71c04-37d7-494a-ac72-3524c0f582c5",
        name: "SPLITTER CANDIDATE",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
      target_id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 1,
        description_x:
          "The essay ties its theme to white liberalism, raising questions about societal desires",
        human_readable_id: 66,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "7ecca7ae-8a6a-49a5-94dd-f8fe830423f5",
      source: {
        id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
        name: "WHITE LIBERALISM",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
        name: "ESSAY",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
      target_id: "e442f5ba-fa57-4708-8878-3e9167250e74",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 4,
        description_x:
          "The essay tells the journey to law school with nuance and insight",
        human_readable_id: 67,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "e1c8982b-6415-448a-b1c9-3357fa6eaf40",
      source: {
        id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
        name: "ESSAY",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "e442f5ba-fa57-4708-8878-3e9167250e74",
        name: "JOURNEY TO LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      target_id: "482c575c-d2f4-4ef9-b8d0-9213a45c93b8",
      data: {
        text_unit_ids:
          "3a9bb5627a67865df5f959bd55f15511eb85c87d7ed3c76951c66f69d95d09196d698261337c71d21b7a309f3e1e46cf20311413f1df7ae5f427a9c78fc8d120",
        weight: 9,
        description_x:
          "The Dean of Law School Admissions is a key figure in the admissions process at Stanford Law, providing guidance and information to prospective students",
        human_readable_id: 68,
        n_tokens: 213,
        title: "LegallyAmrutha on TikTok",
        description_y:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7252552522504834350.mp4",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
      id: "1a8a4696-c0ea-48ca-ad03-dc1c5968b5f5",
      source: {
        id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
        name: "STANFORD LAW",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
      target: {
        id: "482c575c-d2f4-4ef9-b8d0-9213a45c93b8",
        name: "DEAN OF LAW SCHOOL ADMISSIONS",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
    },
    {
      source_id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      target_id: "e4835908-3458-4f39-a06f-2f238084e018",
      data: {
        text_unit_ids:
          "3a9bb5627a67865df5f959bd55f15511eb85c87d7ed3c76951c66f69d95d09196d698261337c71d21b7a309f3e1e46cf20311413f1df7ae5f427a9c78fc8d120",
        weight: 1,
        description_x:
          "The Stanford Law application is a critical part of the admissions process for Stanford Law",
        human_readable_id: 69,
        n_tokens: 213,
        title: "LegallyAmrutha on TikTok",
        description_y:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7252552522504834350.mp4",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
      id: "98926c17-5ce7-4079-9e25-f46401a8fc66",
      source: {
        id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
        name: "STANFORD LAW",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
      target: {
        id: "e4835908-3458-4f39-a06f-2f238084e018",
        name: "STANFORD LAW APPLICATION",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
    },
    {
      source_id: "5896a336-26f6-4867-8767-1dfcc553a00f",
      target_id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
      data: {
        text_unit_ids:
          "34a9b65e87a3c902797e352bf9c158348b86a976594e10b768a379fd4a8f6d460cdc01180bcd8407c500adf1cc69806c791bcea3230869029bb5bf392afbcfa1",
        weight: 1,
        description_x:
          "The person in the red dress is attending the celebration of their friend's graduation",
        human_readable_id: 70,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "c2eecd0c-bf80-45d9-a7d0-c400b9709104",
      source: {
        id: "5896a336-26f6-4867-8767-1dfcc553a00f",
        name: "FRIEND",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
        name: "PERSON IN RED DRESS",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "5896a336-26f6-4867-8767-1dfcc553a00f",
      target_id: "350ec84f-a734-4478-b3cd-5101ccd02880",
      data: {
        text_unit_ids:
          "34a9b65e87a3c902797e352bf9c158348b86a976594e10b768a379fd4a8f6d460cdc01180bcd8407c500adf1cc69806c791bcea3230869029bb5bf392afbcfa1",
        weight: 7,
        description_x:
          "The graduation is the event being celebrated by the friend",
        human_readable_id: 71,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "c5fb09a5-82f8-431b-9b2f-3fa2dd655d10",
      source: {
        id: "5896a336-26f6-4867-8767-1dfcc553a00f",
        name: "FRIEND",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "350ec84f-a734-4478-b3cd-5101ccd02880",
        name: "GRADUATION",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
      target_id: "350ec84f-a734-4478-b3cd-5101ccd02880",
      data: {
        text_unit_ids:
          "34a9b65e87a3c902797e352bf9c158348b86a976594e10b768a379fd4a8f6d460cdc01180bcd8407c500adf1cc69806c791bcea3230869029bb5bf392afbcfa1",
        weight: 1,
        description_x:
          "The person in the red dress is attending the graduation event",
        human_readable_id: 72,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "0de3bb83-9d9d-4530-b342-acf738498fa4",
      source: {
        id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
        name: "PERSON IN RED DRESS",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "350ec84f-a734-4478-b3cd-5101ccd02880",
        name: "GRADUATION",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "6266b334-719c-4b35-87aa-9893b287782c",
      target_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 1,
        description_x:
          "The transition from a high-rise apartment to an industrial loft highlights distinct aesthetic and lifestyle shifts",
        human_readable_id: 73,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "8e51d9b3-a10b-4dd8-8d8f-d4cdc5827e29",
      source: {
        id: "6266b334-719c-4b35-87aa-9893b287782c",
        name: "HIGH-RISE APARTMENT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      target_id: "0ef3b297-2bb8-4497-a295-d04d6386e5e6",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 4,
        description_x:
          "The industrial loft offers expansive cityscape views through its large windows",
        human_readable_id: 74,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "e513cc30-2260-47cd-90f5-149e28307a55",
      source: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "0ef3b297-2bb8-4497-a295-d04d6386e5e6",
        name: "CITYSCAPE VIEWS",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      target_id: "e57740c7-d189-4eab-bdfa-ef22f764bb73",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 3,
        description_x:
          "The industrial loft features a kitchen island as a notable design element",
        human_readable_id: 75,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "885f7b84-fd2d-451a-8cce-e6f15b6f8dc1",
      source: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "e57740c7-d189-4eab-bdfa-ef22f764bb73",
        name: "KITCHEN ISLAND",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      target_id: "aba2f5e5-0f72-41af-b618-7f2c7ccd292a",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 1,
        description_x:
          "The industrial loft's design is complemented by views of urban skylines",
        human_readable_id: 76,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "c7d11747-844f-4817-a697-87b35e5ba80f",
      source: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "aba2f5e5-0f72-41af-b618-7f2c7ccd292a",
        name: "URBAN SKYLINES",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "33301f56-8ede-495a-bd0f-900492128245",
      target_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x:
          "Working Nomads is a job board that specializes in remote work opportunities",
        human_readable_id: 77,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "54be76b4-8926-428b-a59d-3370f3abcf3a",
      source: {
        id: "33301f56-8ede-495a-bd0f-900492128245",
        name: "WORKING NOMADS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 7,
        description_x: "Dice offers remote positions in the tech industry",
        human_readable_id: 78,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "c73828e9-fb8c-4368-aa3f-276019525ff4",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
        name: "DICE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x: "We Are Distributed provides remote work opportunities",
        human_readable_id: 79,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "b8ad4fca-ebd6-4db4-96f0-d456052db91c",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
        name: "WE ARE DISTRIBUTED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "37c46975-3a37-4055-896f-63cdd07cb45b",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x: "Flex Jobs lists flexible and remote job opportunities",
        human_readable_id: 80,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "155a1f56-c041-4d2c-8252-99e81fac3a67",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "37c46975-3a37-4055-896f-63cdd07cb45b",
        name: "FLEX JOBS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
      target_id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
      data: {
        text_unit_ids:
          "bbcd90222e5ad69e9cfb11aafe7df34f2a84e7d5319e629b2d27b33f7839169992009be2ed32e2590ccfc658d79fc0a0a3d96771da66e0bf545b73197e392282",
        weight: 8,
        description_x:
          "The strategies emphasize the importance of engaging with admissions officers to demonstrate interest and foster dialogue",
        human_readable_id: 92,
        n_tokens: 166,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "317de433-6d1b-48a7-a96e-8b2ed0ea913b",
      source: {
        id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
        name: "LAW SCHOOL APPLICATION STRATEGIES",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
      target: {
        id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
        name: "ADMISSIONS OFFICERS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x:
          "Remote Source is dedicated to remote work opportunities",
        human_readable_id: 81,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "75c55a7c-6b1d-4fa0-bd34-0b9cd08bcb75",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
        name: "REMOTE SOURCE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x: "Remote.co focuses on remote job listings",
        human_readable_id: 82,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "53a3bb5f-c3c0-4093-b9ec-0159d68cb696",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
        name: "REMOTE.CO",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "89c21614-fb16-4273-a3e2-74a519e52f62",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 7,
        description_x:
          "4DayWeek.io promotes remote jobs with a four-day workweek",
        human_readable_id: 83,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "15a3c21d-d985-453f-b023-e65f857cdf13",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "89c21614-fb16-4273-a3e2-74a519e52f62",
        name: "4DAYWEEK.IO",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "f4829c80-5a7f-47a3-83c5-fb4d0b2d7ac4",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 7,
        description_x:
          "Purpose Jobs connects job seekers with remote opportunities",
        human_readable_id: 84,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "99ab2379-ead2-4945-8409-48efd70ab236",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "f4829c80-5a7f-47a3-83c5-fb4d0b2d7ac4",
        name: "PURPOSE JOBS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 1,
        description_x:
          "Power to Fly connects women with remote job opportunities",
        human_readable_id: 85,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "aa997eaf-5dc6-4d95-b29f-1452e49474d3",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
        name: "POWER TO FLY",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 6,
        description_x:
          "LinkedIn offers job listings that include remote work opportunities",
        human_readable_id: 86,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "2a07fd8e-e2b8-40bc-a538-cf0d71c712ae",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
        name: "LINKEDIN",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 1,
        description_x:
          "Indeed provides job listings that include remote positions",
        human_readable_id: 87,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "168da4dc-9acc-4dc9-a95e-9139a6c74cb8",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
        name: "INDEED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "27216569-5b76-460a-9de0-589c665c5e0c",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 1,
        description_x:
          "The framed artwork pivots to reveal the hidden screen, integrating technology into the living space",
        human_readable_id: 88,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "684cc3c6-517a-4ccf-9d2d-efb715c2ff76",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "27216569-5b76-460a-9de0-589c665c5e0c",
        name: "SCREEN",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "6b278f60-4b27-4436-88e7-3eb681bb80f3",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 7,
        description_x:
          "The remote is used to activate the pivoting mechanism of the framed artwork",
        human_readable_id: 89,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "23196e3e-7814-45b1-8521-a4c2feca9129",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "6b278f60-4b27-4436-88e7-3eb681bb80f3",
        name: "REMOTE",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 6,
        description_x:
          "The framed artwork is part of the room's design, integrating technology into the living space",
        human_readable_id: 90,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "1f1e9004-8233-439c-97ff-62beb2729ca1",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
        name: "ROOM",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
      data: {
        text_unit_ids:
          "e790fbe5b55965e5ce5d547c54720feac417a8b55e33fa13545df84950952260ae1a4c5fce544928e3e978aa2963e2843e53de087cf8e2ec5488ff93cfbc4a0d",
        weight: 16,
        description_x: "The framed artwork is used to conceal the television",
        human_readable_id: 256,
        n_tokens: 85,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "09f64342-e400-4bed-869d-6a0e07a6cef9",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
        name: "TELEVISION CONCEALMENT",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "27216569-5b76-460a-9de0-589c665c5e0c",
      target_id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 1,
        description_x:
          "The screen is revealed in the room as part of the integrated design",
        human_readable_id: 91,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "5041d8e9-ac98-4ce3-8ffe-5b69279dbcb1",
      source: {
        id: "27216569-5b76-460a-9de0-589c665c5e0c",
        name: "SCREEN",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
        name: "ROOM",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "aa94bec5-0b90-43a6-bfe0-320757038d7e",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 7,
        description_x:
          "Product managers collaborate with the marketing team to promote and sell products",
        human_readable_id: 103,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "6c14f79b-2da4-4e9f-98ad-ef3cadbad0fa",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "aa94bec5-0b90-43a6-bfe0-320757038d7e",
        name: "MARKETING TEAM",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
      target_id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
      data: {
        text_unit_ids:
          "bbcd90222e5ad69e9cfb11aafe7df34f2a84e7d5319e629b2d27b33f7839169992009be2ed32e2590ccfc658d79fc0a0a3d96771da66e0bf545b73197e392282",
        weight: 1,
        description_x:
          "The strategies highlight the importance of referencing prior conversations during interviews to show serious interest",
        human_readable_id: 94,
        n_tokens: 166,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "c68dd214-b57a-404b-8f6b-29e70c8544d5",
      source: {
        id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
        name: "LAW SCHOOL APPLICATION STRATEGIES",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
      target: {
        id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
        name: "INTERVIEWS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "71d1aa93-c6d4-4a73-b361-69a958dae90f",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 5,
        description_x:
          "The text discusses how one can become a product manager straight out of college",
        human_readable_id: 95,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "e7e0ecef-7736-4e93-b66b-87ec40b2d393",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "71d1aa93-c6d4-4a73-b361-69a958dae90f",
        name: "COLLEGE",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "5b6f5b01-c267-413e-9a8a-8d213cd9f877",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 6,
        description_x:
          "Product managers may gain experience by building and managing software products",
        human_readable_id: 96,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "7cfc00d1-eec2-4988-bfbc-261fa68b8bcd",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "5b6f5b01-c267-413e-9a8a-8d213cd9f877",
        name: "SOFTWARE PRODUCT",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "14eabc50-306b-4b9c-8fb9-8427ac6c8160",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 1,
        description_x:
          "Product managers may gain experience by building and managing physical products",
        human_readable_id: 97,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "bc2f5a35-6988-4356-ada1-4ae6d3749653",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "14eabc50-306b-4b9c-8fb9-8427ac6c8160",
        name: "PHYSICAL PRODUCT",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "7923e185-ff75-4cfa-9528-582a0cbb7ef6",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 7,
        description_x:
          "Product managers gain valuable skills and insights through product experience",
        human_readable_id: 98,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "a731ceac-4d1a-4895-802a-baea74347a13",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "7923e185-ff75-4cfa-9528-582a0cbb7ef6",
        name: "PRODUCT EXPERIENCE",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "718ab578-5292-4d09-a2e8-a5cac4fa5141",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 8,
        description_x: "Product managers oversee the entire product life cycle",
        human_readable_id: 99,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "33d02ad3-dc4c-4c1b-9201-302ccb1224c5",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "718ab578-5292-4d09-a2e8-a5cac4fa5141",
        name: "PRODUCT LIFE CYCLE",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "2c62f196-6757-43c0-8b49-17d6c08a8be1",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 9,
        description_x:
          "Product managers communicate with customers to understand their needs and pain points",
        human_readable_id: 100,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "b19eb669-fc14-48b0-ada1-e154dbb84c79",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "2c62f196-6757-43c0-8b49-17d6c08a8be1",
        name: "CUSTOMERS",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "b3eecaaa-62bc-4389-8ad4-c0ca3a49452e",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 9,
        description_x:
          "Product managers work with clients to solve their problems and address their needs",
        human_readable_id: 101,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "3baf3947-a56d-4c8f-a5b4-a96e2e318334",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "b3eecaaa-62bc-4389-8ad4-c0ca3a49452e",
        name: "CLIENTS",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "b5aeefb8-a661-4211-8e7d-abdd06b336d6",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 8,
        description_x:
          "Product managers communicate with engineers to ensure product development aligns with goals",
        human_readable_id: 102,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "41130b9e-d4cc-4a78-a37e-6a91559016e0",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "b5aeefb8-a661-4211-8e7d-abdd06b336d6",
        name: "ENGINEERS",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "58256bfb-fa32-460a-bbbe-dbe0cefb1d13",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 1,
        description_x:
          "Product managers work with the finance team to manage the financial aspects of a product",
        human_readable_id: 104,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "f6e0ee3f-8265-48c7-9b15-74a55d23fb27",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "58256bfb-fa32-460a-bbbe-dbe0cefb1d13",
        name: "FINANCE TEAM",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "7fd92e05-fef3-4884-9186-4611d70f262b",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 7,
        description_x:
          "The mid-century dresser was the most expensive item purchased for the studio apartment makeover",
        human_readable_id: 107,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "1f839109-57eb-4951-aa13-c18c5dcb93ae",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "7fd92e05-fef3-4884-9186-4611d70f262b",
        name: "MID-CENTURY DRESSER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "6f90bc3c-f5e0-4e60-ab12-601659045b08",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 1,
        description_x:
          "The vintage 1990s sofa was purchased for the studio apartment makeover",
        human_readable_id: 108,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "ea9e75b2-a1ca-488a-b8d4-c9a8646bf045",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "6f90bc3c-f5e0-4e60-ab12-601659045b08",
        name: "VINTAGE 1990S SOFA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "e7783877-7a97-4397-8538-388e81631c6a",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 7,
        description_x:
          "The plywood headboard was crafted and integrated into the studio apartment makeover",
        human_readable_id: 109,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "b17e5833-188a-48ae-957c-d663646b9b44",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "e7783877-7a97-4397-8538-388e81631c6a",
        name: "PLYWOOD HEADBOARD",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "ccbbe157-b4d2-4a4f-9338-24f4538f931b",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "The bed frame was purchased and used in the studio apartment makeover",
        human_readable_id: 110,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "dee9759b-1d65-4960-b4b2-3386a145a347",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "ccbbe157-b4d2-4a4f-9338-24f4538f931b",
        name: "BED FRAME",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "9973cce6-df8b-496f-9f32-ab6cb6462fe7",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 1,
        description_x:
          "The accent chairs were re-upholstered and integrated into the studio apartment makeover",
        human_readable_id: 113,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "894a4752-0b77-438a-b4a9-1fb9cc3455d6",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "9973cce6-df8b-496f-9f32-ab6cb6462fe7",
        name: "ACCENT CHAIRS",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "4ae2e0eb-f69d-4590-8812-2ef9fb1f218e",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 16,
        description_x:
          "The NYC studio apartment is furnished with a couch from West Elm, showcasing the use of West Elm furniture in its interior design.",
        human_readable_id: 115,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "f860b137-dda7-4a3a-8151-405437869194",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "4ae2e0eb-f69d-4590-8812-2ef9fb1f218e",
        name: "WEST ELM",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "f4cca64e-12ac-4028-97b9-18c4ce6b8ebc",
      data: {
        text_unit_ids:
          "ec574b47aa335924b2134278202dc0eb2dece8805eb0835f299bd707e0c65e6bf8223ccbd59f943984dda3bef79ce30b751c3f0fb38be372998ddba469d3a87e",
        weight: 10,
        description_x:
          "HomeGoods decor items are used in the NYC studio apartment",
        human_readable_id: 116,
        n_tokens: 218,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "084590e1-71e4-4d4b-940d-f102ee2d90bc",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "f4cca64e-12ac-4028-97b9-18c4ce6b8ebc",
        name: "HOMEGOODS",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "0203a028-101c-45c0-a4a6-64ed7eb594e6",
      data: {
        text_unit_ids:
          "ec574b47aa335924b2134278202dc0eb2dece8805eb0835f299bd707e0c65e6bf8223ccbd59f943984dda3bef79ce30b751c3f0fb38be372998ddba469d3a87e",
        weight: 2,
        description_x:
          "Elfa storage systems are used in the NYC studio apartment",
        human_readable_id: 117,
        n_tokens: 218,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "f916435c-7b4e-44fe-b607-0ca2caa80cd5",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "0203a028-101c-45c0-a4a6-64ed7eb594e6",
        name: "ELFA",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "4782a313-1999-43b3-a33e-9bb0b940f1e5",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 6,
        description_x: "The NYC studio apartment features rugs from TJ Maxx",
        human_readable_id: 271,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "d8dde2ac-ab12-4a48-b2d6-82714e7ec493",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "4782a313-1999-43b3-a33e-9bb0b940f1e5",
        name: "TJ MAXX",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "68fe43fd-29b6-4560-9d7c-5778abf843b3",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 2,
        description_x:
          "The NYC studio apartment features a coffee table from Home Goods",
        human_readable_id: 272,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "7db1148c-5df9-45c7-bfec-48a3be46252d",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "68fe43fd-29b6-4560-9d7c-5778abf843b3",
        name: "HOME GOODS",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "0be68aec-af81-48b7-8f29-4989d53cf590",
      target_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 1,
        description_x:
          "Preptest 61, section 4, question 11, is used to review and master conditional logic",
        human_readable_id: 118,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "e99a47e2-a62c-47f6-97bc-394907df3d3c",
      source: {
        id: "0be68aec-af81-48b7-8f29-4989d53cf590",
        name: "PREPTEST 61",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      target_id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 8,
        description_x:
          "The student focused on mastering conditional logic to improve test scores",
        human_readable_id: 119,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "4da78316-1a01-4b57-a7eb-172b5fb39c54",
      source: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
        name: "STUDENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      target_id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 1,
        description_x:
          "Mastery of conditional logic led to significant test score improvement",
        human_readable_id: 120,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "3293ee94-3096-4fff-a5f5-f02317e108bb",
      source: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
        name: "TEST SCORE IMPROVEMENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
      target_id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 7,
        description_x:
          "The student achieved significant test score improvement",
        human_readable_id: 121,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "55236cb9-348e-46ae-9b35-05a7f710cad8",
      source: {
        id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
        name: "STUDENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
        name: "TEST SCORE IMPROVEMENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
      data: {
        text_unit_ids:
          "dbf12ab489b76f281cd7426d31cbbd004e234a8320c2ee309d9966ec126738b3bbc9238a1f8d33c374438051aca1af544ce292c5d7523d1d651068b720755dd2",
        weight: 7,
        description_x: "The apartment hunt is taking place in Chicago",
        human_readable_id: 122,
        n_tokens: 59,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "bf138375-110c-41de-9d80-13b31bd2d9b2",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
        name: "APARTMENT HUNT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 7,
        description_x:
          "The event of apartment hunting is taking place in the city of Chicago",
        human_readable_id: 125,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "84457fb8-34a5-4257-806d-ff33ce2bceb2",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
        name: "APARTMENT HUNTING",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 1,
        description_x:
          "The convertible unit is part of the apartment hunting event in Chicago",
        human_readable_id: 126,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "31f006ba-d8e5-4b0d-9118-fdc22053f9c1",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
        name: "CONVERTIBLE UNIT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "d92010b8-178a-4e79-b008-5dbf093808f7",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 8,
        description_x:
          "The loft is located in Chicago, specifically in the Printers Row neighborhood",
        human_readable_id: 178,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "868d6ced-38f4-456a-a247-d663c31fca8d",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "d92010b8-178a-4e79-b008-5dbf093808f7",
        name: "CHICAGO LOFT",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 1,
        description_x:
          "Downtown Chicago is a part of the larger city of Chicago",
        human_readable_id: 179,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "64c9b50f-3847-4707-995d-b8ed305be862",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
        name: "DOWNTOWN CHICAGO",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      data: {
        text_unit_ids:
          "6f44efc1e5599054486b52d3a68f69f07b0a2b238b02e4ba5ebedb76b897ade033a084833ad84c4f521e3fc177a98276d970612946be1712ae1121898552fb4e",
        weight: 8,
        description_x: "West Loop is a neighborhood located in Chicago",
        human_readable_id: 201,
        n_tokens: 81,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "b59161bd-37e6-48ed-b04b-5073d13384fe",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
      target_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      data: {
        text_unit_ids:
          "dbf12ab489b76f281cd7426d31cbbd004e234a8320c2ee309d9966ec126738b3bbc9238a1f8d33c374438051aca1af544ce292c5d7523d1d651068b720755dd2",
        weight: 8,
        description_x:
          "The apartment hunt is specifically focused on the West Loop neighborhood",
        human_readable_id: 123,
        n_tokens: 59,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "ee59ab2e-8be6-4807-b7d6-352d04e77d24",
      source: {
        id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
        name: "APARTMENT HUNT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
      target_id: "5849e087-bd35-43a8-bad4-8f2eec0e5d78",
      data: {
        text_unit_ids:
          "dbf12ab489b76f281cd7426d31cbbd004e234a8320c2ee309d9966ec126738b3bbc9238a1f8d33c374438051aca1af544ce292c5d7523d1d651068b720755dd2",
        weight: 1,
        description_x:
          "The convertible space is the focus of the apartment hunt",
        human_readable_id: 124,
        n_tokens: 59,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "a423f481-74d7-4659-aac8-a72bd0f2ac6a",
      source: {
        id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
        name: "APARTMENT HUNT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "5849e087-bd35-43a8-bad4-8f2eec0e5d78",
        name: "CONVERTIBLE SPACE",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 1,
        description_x:
          "The event of apartment hunting is specifically focused on the West Loop neighborhood in Chicago",
        human_readable_id: 127,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "c0350204-5cab-47da-8af5-72e206b2ff64",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
        name: "APARTMENT HUNTING",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 8,
        description_x:
          "The convertible unit is located in the West Loop neighborhood of Chicago",
        human_readable_id: 128,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "69dd7edf-2ea4-4994-a76e-2922a1f42a7c",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
        name: "CONVERTIBLE UNIT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "b370beda-376c-417e-85c9-14b410d1782e",
      data: {
        text_unit_ids:
          "6f44efc1e5599054486b52d3a68f69f07b0a2b238b02e4ba5ebedb76b897ade033a084833ad84c4f521e3fc177a98276d970612946be1712ae1121898552fb4e",
        weight: 1,
        description_x:
          "The 1 bed/1 bath apartment is located in the West Loop neighborhood of Chicago",
        human_readable_id: 203,
        n_tokens: 81,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "8be7f028-43f4-49d7-9427-e350af1bbf57",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "b370beda-376c-417e-85c9-14b410d1782e",
        name: "1 BED/1 BATH APARTMENT",
        group: 1,
        data: {
          title: "Smart City Locating Chicago on TikTok",
          description:
            "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
          url: "https://www.tiktokv.com/share/video/7456922942690184478/",
          video_id: "0",
          time_saved: "2025-01-14 20:26:54",
        },
      },
    },
    {
      source_id: "271058e0-f3cb-41f0-a760-0c940b50563d",
      target_id: "678fe9f5-60b3-418e-93fd-67c3857ceeb4",
      data: {
        text_unit_ids:
          "b8601f026b83def18535fdd08d25809fefcbb62adcf0ed4147ddb946d133a9a51575c7af61bd656bac776728fe2c9d596e86557830c003e914dce9e840d130ba",
        weight: 10,
        description_x:
          "The anti-slip couch cover is one of the products discussed in the Home Finds for 2024 event",
        human_readable_id: 129,
        n_tokens: 88,
        title: "ash on TikTok",
        description_y:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7328209604150693150.mp4",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
      id: "351b6b71-e92d-4983-93ac-6d28a59a2582",
      source: {
        id: "271058e0-f3cb-41f0-a760-0c940b50563d",
        name: "HOME FINDS FOR 2024",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
      target: {
        id: "678fe9f5-60b3-418e-93fd-67c3857ceeb4",
        name: "ANTI-SLIP COUCH COVER",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
    },
    {
      source_id: "271058e0-f3cb-41f0-a760-0c940b50563d",
      target_id: "05cee4ee-de11-46f3-84a5-592e1a0c3ac5",
      data: {
        text_unit_ids:
          "b8601f026b83def18535fdd08d25809fefcbb62adcf0ed4147ddb946d133a9a51575c7af61bd656bac776728fe2c9d596e86557830c003e914dce9e840d130ba",
        weight: 10,
        description_x:
          "The lift-top coffee table is one of the products discussed in the Home Finds for 2024 event",
        human_readable_id: 130,
        n_tokens: 88,
        title: "ash on TikTok",
        description_y:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7328209604150693150.mp4",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
      id: "e472e3b3-93be-44e3-80af-e8712cde9e6f",
      source: {
        id: "271058e0-f3cb-41f0-a760-0c940b50563d",
        name: "HOME FINDS FOR 2024",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
      target: {
        id: "05cee4ee-de11-46f3-84a5-592e1a0c3ac5",
        name: "LIFT-TOP COFFEE TABLE",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
    },
    {
      source_id: "271058e0-f3cb-41f0-a760-0c940b50563d",
      target_id: "7bd93453-e506-4bf9-9b08-96d223292400",
      data: {
        text_unit_ids:
          "b8601f026b83def18535fdd08d25809fefcbb62adcf0ed4147ddb946d133a9a51575c7af61bd656bac776728fe2c9d596e86557830c003e914dce9e840d130ba",
        weight: 2,
        description_x:
          "The solid wood entryway table is one of the products discussed in the Home Finds for 2024 event",
        human_readable_id: 131,
        n_tokens: 88,
        title: "ash on TikTok",
        description_y:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7328209604150693150.mp4",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
      id: "7bd3a2e2-e5bd-4402-ae08-1b306d06cda6",
      source: {
        id: "271058e0-f3cb-41f0-a760-0c940b50563d",
        name: "HOME FINDS FOR 2024",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
      target: {
        id: "7bd93453-e506-4bf9-9b08-96d223292400",
        name: "SOLID WOOD ENTRYWAY TABLE",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
    },
    {
      source_id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
      target_id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
      data: {
        text_unit_ids:
          "2a6c470016d3024a16884c76dcfddbfda422dc02ef1ed818f1ba39bcab928c2e3eaa00444b87f5af64ed26fc3dd6528e94faf1b68ac0bd717b33dbdc305f0747",
        weight: 7,
        description_x:
          "The assembly demonstration is focused on showcasing the features and assembly process of the PAX wardrobe system",
        human_readable_id: 134,
        n_tokens: 47,
        title: "B S Y ✩ on TikTok",
        description_y:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7220431692467604779.mp4",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
      id: "1bc46997-e924-4af5-8485-cef667977d93",
      source: {
        id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
        name: "PAX WARDROBE SYSTEM",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
      target: {
        id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
        name: "ASSEMBLY DEMONSTRATION",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
    },
    {
      source_id: "0e8979ad-8c13-4dab-a3ed-06070e50c435",
      target_id: "7d35b208-2393-4f38-91ae-1b3af1405037",
      data: {
        text_unit_ids:
          "6eda358571d1ef590e699dbd8919e9241fb51e585dac1e96067c512f24ff63e797daa2e779857b7fe667e4870317b47c0f8c7662b25b8c1e77f73ac0aaf4a84c",
        weight: 7,
        description_x:
          "Imani’s Phase is a specific pattern of rental-friendly wallpaper",
        human_readable_id: 135,
        n_tokens: 94,
        title: "Imani | DIY, Design & Life on TikTok",
        description_y:
          "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
        url: "https://www.tiktokv.com/share/video/7301508923985497387/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7301508923985497387.mp4",
        video_id: "0",
        time_saved: "2025-02-02 00:31:03",
      },
      id: "69e2e4b2-951d-4dce-87e4-ff921eb36411",
      source: {
        id: "0e8979ad-8c13-4dab-a3ed-06070e50c435",
        name: "IMANI’S PHASE",
        group: 1,
        data: {
          title: "Imani | DIY, Design & Life on TikTok",
          description:
            "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
          url: "https://www.tiktokv.com/share/video/7301508923985497387/",
          video_id: "0",
          time_saved: "2025-02-02 00:31:03",
        },
      },
      target: {
        id: "7d35b208-2393-4f38-91ae-1b3af1405037",
        name: "RENTAL-FRIENDLY WALLPAPER",
        group: 1,
        data: {
          title: "Imani | DIY, Design & Life on TikTok",
          description:
            "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
          url: "https://www.tiktokv.com/share/video/7301508923985497387/",
          video_id: "0",
          time_saved: "2025-02-02 00:31:03",
        },
      },
    },
    {
      source_id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
      target_id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
      data: {
        text_unit_ids:
          "893d74a691487a3b83da5c180cc49157e7d18f60f41ad2cc3eb906f3ba693ccc42bd9551cbfa767f140f7a9f1b36549ca365af00eafa8dde98c34687a602a96e",
        weight: 1,
        description_x:
          "TikTok played a significant role in popularizing and validating the couch as a desirable product",
        human_readable_id: 136,
        n_tokens: 114,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "3dc42177-2dae-411d-9e97-68da2bfc3150",
      source: {
        id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
        name: "TIKTOK",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
        name: "COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
      target_id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
      data: {
        text_unit_ids:
          "cdb31729e036bf275a87c2186a0df85e143a38726bef4a8a3e3b29de47e3d8aa743fb1dcc6dca077b1b8e0e897c0b359f22d4b14d00b06ab2bcf52b6587b0316",
        weight: 7,
        description_x:
          "TikTok endorsed the off-white couch, contributing to its popularity",
        human_readable_id: 250,
        n_tokens: 89,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "51da9bc9-453e-4eef-a3a1-99bcd30666dc",
      source: {
        id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
        name: "TIKTOK",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
        name: "OFF-WHITE COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
      target_id: "ca1c2ef7-6d7c-4fc7-8de0-377bea639783",
      data: {
        text_unit_ids:
          "893d74a691487a3b83da5c180cc49157e7d18f60f41ad2cc3eb906f3ba693ccc42bd9551cbfa767f140f7a9f1b36549ca365af00eafa8dde98c34687a602a96e",
        weight: 1,
        description_x:
          "The dog is present during the assembly of the couch, indicating a shared living space",
        human_readable_id: 137,
        n_tokens: 114,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "11aea65c-6a93-4d91-b763-44d3272c402b",
      source: {
        id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
        name: "COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "ca1c2ef7-6d7c-4fc7-8de0-377bea639783",
        name: "DOG",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
      target_id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 1,
        description_x:
          "The pastor's confirmation of a soul tie being broken is related to the message of Proverbs 26:11 about not returning to detrimental relationships",
        human_readable_id: 138,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "9619e301-1bfa-42a2-a1c6-ef34e79173ee",
      source: {
        id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
        name: "PROVERBS 26:11",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
        name: "PASTOR",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
      target_id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 1,
        description_x:
          "The concept of a soul tie is related to the message of Proverbs 26:11 about returning to detrimental relationships",
        human_readable_id: 139,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "1d0f4d06-b0ee-4ac4-b622-34890d304459",
      source: {
        id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
        name: "PROVERBS 26:11",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
        name: "SOUL TIE",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
      target_id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 6,
        description_x:
          "The pastor confirmed the breaking of the soul tie after the three-day fast",
        human_readable_id: 140,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "0bf25485-e194-4c0e-b7ee-5289cc08b225",
      source: {
        id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
        name: "PASTOR",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
        name: "THREE-DAY FAST",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
      target_id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 7,
        description_x:
          "The three-day fast was undertaken to break the soul tie",
        human_readable_id: 141,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "7eba70f1-c4b5-4dad-9ace-13db500f0709",
      source: {
        id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
        name: "SOUL TIE",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
        name: "THREE-DAY FAST",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
      target_id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
      data: {
        text_unit_ids:
          "9d3ab01f5678be5681ea9fb96c10e9e11dab35fafd96012d40c4efd6e92bbd6d5120419be2692c0b0b6244094a70c74017eaca482b1fcc0d30528000af6f0312",
        weight: 8,
        description_x:
          "The 27-18-9 rule is a strategy specifically designed for the reading comprehension section of the LSAT",
        human_readable_id: 143,
        n_tokens: 91,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "1b756aaf-315d-4fcd-acd3-eaa445afabc6",
      source: {
        id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
        name: "LAW SCHOOL ADMISSION TEST",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
      target: {
        id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
        name: "27-18-9 RULE",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
      target_id: "7377c763-102f-4452-bbb8-f22487ebde3f",
      data: {
        text_unit_ids:
          "9d3ab01f5678be5681ea9fb96c10e9e11dab35fafd96012d40c4efd6e92bbd6d5120419be2692c0b0b6244094a70c74017eaca482b1fcc0d30528000af6f0312",
        weight: 9,
        description_x:
          "The reading comprehension section is a component of the LSAT",
        human_readable_id: 144,
        n_tokens: 91,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "4167da91-0c5e-48d3-932f-cb420b68607e",
      source: {
        id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
        name: "LAW SCHOOL ADMISSION TEST",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
      target: {
        id: "7377c763-102f-4452-bbb8-f22487ebde3f",
        name: "READING COMPREHENSION SECTION",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
      target_id: "7377c763-102f-4452-bbb8-f22487ebde3f",
      data: {
        text_unit_ids:
          "9d3ab01f5678be5681ea9fb96c10e9e11dab35fafd96012d40c4efd6e92bbd6d5120419be2692c0b0b6244094a70c74017eaca482b1fcc0d30528000af6f0312",
        weight: 1,
        description_x:
          "The 27-18-9 rule provides a time management strategy for the reading comprehension section",
        human_readable_id: 145,
        n_tokens: 91,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "01fc1c77-7a85-4fbb-af94-aa3cf88236af",
      source: {
        id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
        name: "27-18-9 RULE",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
      target: {
        id: "7377c763-102f-4452-bbb8-f22487ebde3f",
        name: "READING COMPREHENSION SECTION",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 7,
        description_x:
          "CeraVe Hydrating Facial Cleanser is recommended for those on Accutane due to its gentle properties",
        human_readable_id: 146,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "b3cf6ebe-3bc4-4280-bc55-02b84c1cb1ba",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
        name: "CERAVE HYDRATING FACIAL CLEANSER",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 8,
        description_x:
          "CeraVe Daily Moisturizing Lotion is recommended to combat the widespread dryness caused by Accutane",
        human_readable_id: 147,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "a450b9c6-bf26-475c-adee-3af3da4b645a",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
        name: "CERAVE DAILY MOISTURIZING LOTION",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "EltaMD Tinted Moisturizing Sunscreen is recommended for those on Accutane to even out skin tone",
        human_readable_id: 148,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "d24c7259-9d61-4b56-bd2d-720dcb38206c",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
        name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
      target_id: "350ec84f-a734-4478-b3cd-5101ccd02880",
      data: {
        text_unit_ids:
          "34a9b65e87a3c902797e352bf9c158348b86a976594e10b768a379fd4a8f6d460cdc01180bcd8407c500adf1cc69806c791bcea3230869029bb5bf392afbcfa1",
        weight: 1,
        description_x:
          "The person in the red dress is attending the graduation event",
        human_readable_id: 115,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "f242c094-7fce-42c2-8a0e-25f04e735682",
      source: {
        id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
        name: "PERSON IN RED DRESS",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "350ec84f-a734-4478-b3cd-5101ccd02880",
        name: "GRADUATION",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "fce6a301-1609-4299-a839-8ec20522bae0",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 7,
        description_x:
          "Aquaphor is recommended for its moisturizing properties for those experiencing dryness from Accutane",
        human_readable_id: 149,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "a644c3eb-a91a-4977-85aa-d35a8f05d72f",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "fce6a301-1609-4299-a839-8ec20522bae0",
        name: "AQUAPHOR",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 5,
        description_x:
          "MISSHA M Perfect Cover BB Cream No. 27 is recommended as a makeup product for those on Accutane",
        human_readable_id: 150,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "c0391638-5c1a-46c7-91aa-df5b887087dc",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
        name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "d308cd80-c521-4550-ae3a-4be51ed68a06",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 1,
        description_x:
          "Dermatologists recommend Accutane as a treatment for severe acne after assessing a patient's suitability through blood tests",
        human_readable_id: 312,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "ef89b040-e307-432e-929d-ddb12f91a499",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "d308cd80-c521-4550-ae3a-4be51ed68a06",
        name: "DERMATOLOGIST",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "21b7bdad-34ec-46e5-a0c1-103f6e908b85",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 9,
        description_x: "Accutane is used as a treatment to get rid of acne",
        human_readable_id: 313,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "d6b713ef-7fcf-4f2d-8c75-958c8555522c",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "21b7bdad-34ec-46e5-a0c1-103f6e908b85",
        name: "ACNE",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "fe16457b-f86e-4df5-8a0d-1e32ff014a7b",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 8,
        description_x: "Accutane can cause extreme dryness as a side effect",
        human_readable_id: 314,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "7fb5b1eb-1e1d-4e1f-a40b-923ea9b9e812",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "fe16457b-f86e-4df5-8a0d-1e32ff014a7b",
        name: "DRYNESS",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "54749543-c531-448d-b9c0-4b4579cfd230",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 8,
        description_x:
          "Accutane can cause major skin thinning as a side effect",
        human_readable_id: 315,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "369f86ac-fbf2-4170-bbed-e1637ba41e62",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "54749543-c531-448d-b9c0-4b4579cfd230",
        name: "SKIN THINNING",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "f8a354f5-7441-4466-9cd4-712364dbcc29",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 1,
        description_x:
          "Accutane can cause mental or cognitive side effects such as aggression and low mood",
        human_readable_id: 316,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "fb2e97c4-3b1e-4150-9667-b74536fbb04f",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "f8a354f5-7441-4466-9cd4-712364dbcc29",
        name: "MENTAL OR COGNITIVE SIDE EFFECTS",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
      target_id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 9,
        description_x:
          "CeraVe Hydrating Facial Cleanser is a product offered by the CeraVe brand",
        human_readable_id: 156,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "007425a5-c499-4870-9807-33828dcb6aaf",
      source: {
        id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
        name: "CERAVE HYDRATING FACIAL CLEANSER",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
        name: "CERAVE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
      target_id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 9,
        description_x:
          "CeraVe Daily Moisturizing Lotion is a product offered by the CeraVe brand",
        human_readable_id: 157,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "f4f0652d-c371-4f50-8d8e-cfd93249a3d7",
      source: {
        id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
        name: "CERAVE DAILY MOISTURIZING LOTION",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
        name: "CERAVE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
      target_id: "979d0892-ba23-4572-a746-320e71e24750",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 9,
        description_x:
          "EltaMD Tinted Moisturizing Sunscreen is a product offered by the EltaMD brand",
        human_readable_id: 158,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "432b0327-0a6b-47fd-93be-de48b00dfb64",
      source: {
        id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
        name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "979d0892-ba23-4572-a746-320e71e24750",
        name: "ELTAMD",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
      target_id: "25626dfa-ad22-4270-af3a-2d3b6d54c141",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 1,
        description_x:
          "MISSHA M Perfect Cover BB Cream No. 27 is a product offered by the MISSHA brand",
        human_readable_id: 159,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "bd035328-cce4-4844-bded-f758ec6c2a1f",
      source: {
        id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
        name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "25626dfa-ad22-4270-af3a-2d3b6d54c141",
        name: "MISSHA",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "886d1720-4657-46d8-921c-05724c65cd76",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 1,
        description_x:
          "Stanford University has a specific college application process that includes essay writing and activity prioritization",
        human_readable_id: 160,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "36773436-9196-4f84-812e-7b368d2126a2",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "886d1720-4657-46d8-921c-05724c65cd76",
        name: "COLLEGE APPLICATION PROCESS",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 8,
        description_x:
          "Stanford University's application process includes specific essay writing requirements",
        human_readable_id: 161,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "2d2c7598-8fa8-41b1-83e0-0f67211c89e7",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 8,
        description_x:
          "Stanford University's application process involves prioritizing extracurricular activities",
        human_readable_id: 162,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "f1b29ab7-4a36-432e-822b-69e195153d1b",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
        name: "ACTIVITY PRIORITIZATION",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "13659935-4065-4988-8e18-8dab82c574f9",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          'The essay writing process includes addressing the "biggest challenge society faces" prompt',
        human_readable_id: 164,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "43b6d93c-e1ab-498a-9daf-74b68c7130dc",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "13659935-4065-4988-8e18-8dab82c574f9",
        name: "BIGGEST CHALLENGE SOCIETY FACES PROMPT",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "93440a23-ab4a-414f-bb20-94c17e8329cf",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          'The essay writing process includes addressing the "historical moment" question',
        human_readable_id: 165,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "080f4108-1b70-4f23-ac5f-7da68f93a84d",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "93440a23-ab4a-414f-bb20-94c17e8329cf",
        name: "HISTORICAL MOMENT QUESTION",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "f1abda8b-8a72-463b-ab02-84bdb4d8be3b",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          'The essay writing process includes composing a "roommate essay" focusing on personal background',
        human_readable_id: 166,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "7cd12f62-4a93-4a92-9de0-b22519424c1a",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "f1abda8b-8a72-463b-ab02-84bdb4d8be3b",
        name: "ROOMMATE ESSAY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "2decae6e-eb04-4fa1-9bfc-c061045a275a",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          "The essay writing process includes a final essay focusing on personal values and upbringing",
        human_readable_id: 167,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "fc7c0bd7-dbc4-4170-932f-1c98378dbe63",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "2decae6e-eb04-4fa1-9bfc-c061045a275a",
        name: "FINAL ESSAY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
      target_id: "cf0c8784-59b6-4b49-8957-eac895827848",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 1,
        description_x:
          "Activity prioritization involves selecting key extracurricular activities to highlight",
        human_readable_id: 163,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "7afcc0cb-a3fd-41ab-9236-74d8eeba936e",
      source: {
        id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
        name: "ACTIVITY PRIORITIZATION",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "cf0c8784-59b6-4b49-8957-eac895827848",
        name: "EXTRACURRICULAR ACTIVITIES",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      target_id: "23204505-dfc4-4f08-8e1e-ba69bb6359c7",
      data: {
        text_unit_ids:
          "141f5b67aaf29b82266423060b05a91e669bec8f1d495b54ae7868f4b547789fa616cd3da46485ef22c521f38f1213bbf18a7687f1f46fba8c05e6b9629bdf6f",
        weight: 1,
        description_x:
          "The Marshall-Motley Scholars Program is sponsored by the NAACP",
        human_readable_id: 168,
        n_tokens: 109,
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description_y:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7442712485888806190.mp4",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
      id: "72f055d2-8c6d-4303-9700-187578b0116c",
      source: {
        id: "35d036f3-2823-4e6a-9fa1-326257353a03",
        name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
      target: {
        id: "23204505-dfc4-4f08-8e1e-ba69bb6359c7",
        name: "NAACP",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
    },
    {
      source_id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      target_id: "abda8c0d-6084-4820-925e-6b1301562a16",
      data: {
        text_unit_ids:
          "141f5b67aaf29b82266423060b05a91e669bec8f1d495b54ae7868f4b547789fa616cd3da46485ef22c521f38f1213bbf18a7687f1f46fba8c05e6b9629bdf6f",
        weight: 8,
        description_x:
          "The program aims to foster civil rights lawyers dedicated to social justice work in the Southern region",
        human_readable_id: 169,
        n_tokens: 109,
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description_y:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7442712485888806190.mp4",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
      id: "8a5cf51b-a99d-4e73-ae5b-4c8331b5d7c6",
      source: {
        id: "35d036f3-2823-4e6a-9fa1-326257353a03",
        name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
      target: {
        id: "abda8c0d-6084-4820-925e-6b1301562a16",
        name: "SOUTH",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
    },
    {
      source_id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      target_id: "ac8fbb02-7a30-4280-8c28-2a3f77db9d65",
      data: {
        text_unit_ids:
          "141f5b67aaf29b82266423060b05a91e669bec8f1d495b54ae7868f4b547789fa616cd3da46485ef22c521f38f1213bbf18a7687f1f46fba8c05e6b9629bdf6f",
        weight: 1,
        description_x:
          "February 5th is the application deadline for the program",
        human_readable_id: 170,
        n_tokens: 109,
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description_y:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7442712485888806190.mp4",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
      id: "309fbcc7-f789-4537-9a70-4965d3d7bc59",
      source: {
        id: "35d036f3-2823-4e6a-9fa1-326257353a03",
        name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
      target: {
        id: "ac8fbb02-7a30-4280-8c28-2a3f77db9d65",
        name: "FEBRUARY 5TH",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
    },
    {
      source_id: "8db10b44-d57c-48f9-9227-45981320a879",
      target_id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 1,
        description_x:
          "The removal of cloud wallpaper is part of the preparation for the apartment move",
        human_readable_id: 171,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "d21412ec-c862-45d8-b64c-f9d80f675356",
      source: {
        id: "8db10b44-d57c-48f9-9227-45981320a879",
        name: "APARTMENT MOVE",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
        name: "CLOUD WALLPAPER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "8db10b44-d57c-48f9-9227-45981320a879",
      target_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 1,
        description_x: "The apartment move is related to the new location",
        human_readable_id: 172,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "f34dead9-396e-44ca-80b5-34e4d4517423",
      source: {
        id: "8db10b44-d57c-48f9-9227-45981320a879",
        name: "APARTMENT MOVE",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
      target_id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 5,
        description_x:
          "The speaker successfully removed the cloud wallpaper using a steam mop",
        human_readable_id: 173,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "f86dd52a-e371-4080-929f-4623e6e0cd7d",
      source: {
        id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
        name: "CLOUD WALLPAPER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
        name: "SPEAKER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 4,
        description_x:
          "The speaker is moving to a new apartment and will start a decorating series",
        human_readable_id: 174,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "4fe4a25b-f2c1-4a86-b60d-71384966f268",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
        name: "SPEAKER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "7f7a0c09-7af7-4044-a7d9-a5862eb03a8c",
      data: {
        text_unit_ids:
          "d77968a305e08b0f09f9673ef96519decadfe71397e7a6cbc9cdaf83850d5b2efb1ca72c57e9be9615286de0968f2946e4c4a8ee015431348b41d0841a759af1",
        weight: 1,
        description_x:
          "The modular sectional sleeper sofa is designed to fit well in apartments due to its compact size",
        human_readable_id: 207,
        n_tokens: 61,
        title: "Hello Kristen on TikTok",
        description_y:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7377867118848593194.mp4",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
      id: "df6d021e-d3b7-4906-afc0-c2de5eee732a",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "7f7a0c09-7af7-4044-a7d9-a5862eb03a8c",
        name: "MODULAR SECTIONAL SLEEPER SOFA",
        group: 1,
        data: {
          title: "Hello Kristen on TikTok",
          description:
            "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
          url: "https://www.tiktokv.com/share/video/7377867118848593194/",
          video_id: "0",
          time_saved: "2025-02-02 00:45:00",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "34d1996e-bb34-4718-a709-a5e1b0190a7c",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 3,
        description_x: "The name Rina appears on a poster within the apartment",
        human_readable_id: 252,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "352da1af-ee00-4cd2-afac-22de963a14ee",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "34d1996e-bb34-4718-a709-a5e1b0190a7c",
        name: "RINA",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "f6cf40a0-4374-4061-a4f7-88f281a6a04f",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 5,
        description_x:
          "A projection of Calcifer from Howl's Moving Castle is displayed on the wall of the apartment",
        human_readable_id: 253,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "008ab6d9-3192-4827-8760-e1f616cbd844",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "f6cf40a0-4374-4061-a4f7-88f281a6a04f",
        name: "HOWL'S MOVING CASTLE",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "219f5408-d1ce-44d6-81d5-1477a216219f",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 1,
        description_x: "Calcifer is projected onto the wall of the apartment",
        human_readable_id: 254,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "b3c2d1cc-522f-4ad8-9918-2605ec733fc5",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "219f5408-d1ce-44d6-81d5-1477a216219f",
        name: "CALCIFER",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "05a46f1f-98a8-4006-b927-0c1fd58458ec",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 7,
        description_x:
          "The apartment is designed with a 70s aesthetic vibe, incorporating elements typical of that era",
        human_readable_id: 255,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "028a9692-9ee0-4fdb-932c-8c2ac34eb5c0",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "05a46f1f-98a8-4006-b927-0c1fd58458ec",
        name: "70S AESTHETIC",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      data: {
        text_unit_ids:
          "be76a1998ebb376e29c86476a8c22d864a888777e91043e7c2bd9ba5f5f8dbecd677372a5580d4bd8811a412ae4925e149503c45bee536c865cbf1354db09493",
        weight: 1,
        description_x:
          "The content creator has scored 170+ on the LSAT and uses the prediction technique to achieve high scores",
        human_readable_id: 175,
        n_tokens: 167,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "39893ff1-25c4-4d3b-8836-2d27b3b8a591",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
      data: {
        text_unit_ids:
          "be76a1998ebb376e29c86476a8c22d864a888777e91043e7c2bd9ba5f5f8dbecd677372a5580d4bd8811a412ae4925e149503c45bee536c865cbf1354db09493",
        weight: 8,
        description_x:
          "The prediction technique is used to improve performance on the LSAT by anticipating answers",
        human_readable_id: 176,
        n_tokens: 167,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "89cab4d2-3d67-493c-a08f-372290a6ff0c",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
        name: "PREDICTION TECHNIQUE",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "5a610178-5f79-4cb5-8041-867ab2a41874",
      target_id: "73fdea2f-ff0f-47a4-b245-c6ce6a756e02",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          'The toy rocket is a decorative item on the Yusong 73" Tall Bookshelf',
        human_readable_id: 193,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "499a59fc-1af4-4b3c-b663-2c71494d6ed6",
      source: {
        id: "5a610178-5f79-4cb5-8041-867ab2a41874",
        name: 'YUSONG 73" TALL BOOKSHELF',
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "73fdea2f-ff0f-47a4-b245-c6ce6a756e02",
        name: "TOY ROCKET",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "6b9a53d4-08eb-4958-8043-782a97ea8685",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 7,
        description_x:
          "The diagnostic test is used to prepare for the LSAT by mimicking real testing conditions",
        human_readable_id: 196,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "4f1d7f88-df6b-409b-b5df-19721a17963e",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "6b9a53d4-08eb-4958-8043-782a97ea8685",
        name: "DIAGNOSTIC TEST",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 8,
        description_x:
          "Practice tests are used to improve skills and prepare for the LSAT",
        human_readable_id: 197,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "6c8ce09d-ebb9-426d-a10f-fab1585d604b",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
        name: "PRACTICE TESTS",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      target_id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
      data: {
        text_unit_ids:
          "be76a1998ebb376e29c86476a8c22d864a888777e91043e7c2bd9ba5f5f8dbecd677372a5580d4bd8811a412ae4925e149503c45bee536c865cbf1354db09493",
        weight: 1,
        description_x:
          "The content creator advocates for the prediction technique as a key strategy for scoring high on the LSAT",
        human_readable_id: 177,
        n_tokens: 167,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "9cf1349f-644a-4abf-aba2-68af89e4079b",
      source: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
        name: "PREDICTION TECHNIQUE",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "d92010b8-178a-4e79-b008-5dbf093808f7",
      target_id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 1,
        description_x:
          "The loft is situated in the Printers Row area of downtown Chicago",
        human_readable_id: 180,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "84d1ca06-d251-4b87-9322-120b07c2b1f4",
      source: {
        id: "d92010b8-178a-4e79-b008-5dbf093808f7",
        name: "CHICAGO LOFT",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
      target: {
        id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
        name: "PRINTERS ROW",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
      target_id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 7,
        description_x:
          "Printers Row is a neighborhood located within downtown Chicago",
        human_readable_id: 181,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "adc66f43-82b6-4992-a1a9-fc9ffc0e9c1c",
      source: {
        id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
        name: "DOWNTOWN CHICAGO",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
      target: {
        id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
        name: "PRINTERS ROW",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "26096370-0225-4089-9198-582e07c0702f",
      target_id: "49259c9b-4e62-4a37-adb1-adfcfc789613",
      data: {
        text_unit_ids:
          "fdf62deffd4ce4a62643183c8df1d22ab45c598ae0b41e296dae0b8c6e3657b838c31f2ba03bb3f8205f386cc0863fa01509cf5105bba6c04f150662f03f1c0a",
        weight: 7,
        description_x:
          "The transformation involved decorating the apartment with a neutral color palette and furnishings",
        human_readable_id: 183,
        n_tokens: 162,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7432918152297614634.mp4",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
      id: "7e624510-ce38-41c9-9d65-3746ec823664",
      source: {
        id: "26096370-0225-4089-9198-582e07c0702f",
        name: "TRANSFORMATION",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
          url: "https://www.tiktokv.com/share/video/7432918152297614634/",
          video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
          time_saved: "2025-02-02 03:36:08",
        },
      },
      target: {
        id: "49259c9b-4e62-4a37-adb1-adfcfc789613",
        name: "DECOR",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
          url: "https://www.tiktokv.com/share/video/7432918152297614634/",
          video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
          time_saved: "2025-02-02 03:36:08",
        },
      },
    },
    {
      source_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      target_id: "e557f283-b55a-4900-9b35-c32668ca039a",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          "Books are styled on the Round Wood Coffee Table as part of the decor",
        human_readable_id: 190,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "6cf9dce7-baf2-4394-8789-ba7424c24eda",
      source: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "e557f283-b55a-4900-9b35-c32668ca039a",
        name: "BOOKS",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      target_id: "0dcef8d7-abb7-48d2-af97-a04e4f696b11",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          "A gaming controller is part of the setup on the Round Wood Coffee Table",
        human_readable_id: 191,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "a3189275-615e-4e17-adeb-3716d02afd7a",
      source: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "0dcef8d7-abb7-48d2-af97-a04e4f696b11",
        name: "GAMING CONTROLLER",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      target_id: "606ec4ed-b7da-4cd8-b054-e4f2ea79aade",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          "A mug is part of the setup on the Round Wood Coffee Table",
        human_readable_id: 192,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "ba622f12-9e2b-4f22-9a47-b4ab740e239c",
      source: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "606ec4ed-b7da-4cd8-b054-e4f2ea79aade",
        name: "MUG",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "5a610178-5f79-4cb5-8041-867ab2a41874",
      target_id: "83ea678e-7e1e-4a31-bfb1-f8898bae71b6",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          'The model car is a decorative item on the Yusong 73" Tall Bookshelf',
        human_readable_id: 194,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "f4870703-c23e-4354-960f-66c396644073",
      source: {
        id: "5a610178-5f79-4cb5-8041-867ab2a41874",
        name: 'YUSONG 73" TALL BOOKSHELF',
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "83ea678e-7e1e-4a31-bfb1-f8898bae71b6",
        name: "MODEL CAR",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "017de81e-714d-401f-b1da-fa549eb5fe98",
      target_id: "1c4f288b-3c69-4164-b618-b5da44755e25",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 1,
        description_x:
          "A potted plant is placed beside the Sony PS-LX310BT Wireless Turntable",
        human_readable_id: 195,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "37b5a0f7-8c7e-48b9-8c84-fed5ffcb9314",
      source: {
        id: "017de81e-714d-401f-b1da-fa549eb5fe98",
        name: "SONY PS-LX310BT WIRELESS TURNTABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "1c4f288b-3c69-4164-b618-b5da44755e25",
        name: "PLANT",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
      target_id: "5db23971-9150-4e2c-9238-0ef2f472ae25",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 8,
        description_x: "Law Hub provides practice tests for LSAT preparation",
        human_readable_id: 198,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "b2932788-ea54-4a33-80a8-6602ad6942d7",
      source: {
        id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
        name: "PRACTICE TESTS",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
      target: {
        id: "5db23971-9150-4e2c-9238-0ef2f472ae25",
        name: "LAW HUB",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
      target_id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 1,
        description_x:
          "LSAT Demon offers practice tests to help refine skills for the LSAT",
        human_readable_id: 200,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "8728ab04-baa9-469e-b2b3-260787b8cc77",
      source: {
        id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
        name: "PRACTICE TESTS",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
      target: {
        id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
        name: "LSAT DEMON",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
      target_id: "6b040336-3002-442f-a870-baaa2b401d00",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 9,
        description_x:
          "The LSAT Demon podcast is part of the LSAT Demon platform, offering additional resources for preparation",
        human_readable_id: 199,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "0363c1a4-d644-4372-b23d-f76378e076e3",
      source: {
        id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
        name: "LSAT DEMON",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
      target: {
        id: "6b040336-3002-442f-a870-baaa2b401d00",
        name: "LSAT DEMON PODCAST",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "a1ad1629-2d4e-49c9-94d6-bc0f8adea7b1",
      target_id: "e0d50f6d-cd7e-42a3-bcc3-cc33c281149c",
      data: {
        text_unit_ids:
          "1e89b612037ca647a20ecb04d19aa7b327745855c8e84f91c0d1874139d128147163b121b50c4d246dfbe699748d137f9c24c608b6f1bd6ab2cd416746fe9a9a",
        weight: 18,
        description_x:
          "The ICE officer is an employee of Immigration and Customs Enforcement",
        human_readable_id: 204,
        n_tokens: 96,
        title: "Gloria Cardenas on TikTok",
        description_y:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7443202459209583914.mp4",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
      id: "4a4b7e61-8864-464b-93a1-2653f131a218",
      source: {
        id: "a1ad1629-2d4e-49c9-94d6-bc0f8adea7b1",
        name: "IMMIGRATION AND CUSTOMS ENFORCEMENT",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
      target: {
        id: "e0d50f6d-cd7e-42a3-bcc3-cc33c281149c",
        name: "ICE OFFICER",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
    },
    {
      source_id: "05d4f10a-8dad-4833-a42f-1ccd3caadeb8",
      target_id: "cbfe8634-3213-4dd0-9edc-30992801517a",
      data: {
        text_unit_ids:
          "1e89b612037ca647a20ecb04d19aa7b327745855c8e84f91c0d1874139d128147163b121b50c4d246dfbe699748d137f9c24c608b6f1bd6ab2cd416746fe9a9a",
        weight: 2,
        description_x:
          "The resident invokes the Fifth Amendment rights to remain silent and refuse to answer questions",
        human_readable_id: 205,
        n_tokens: 96,
        title: "Gloria Cardenas on TikTok",
        description_y:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7443202459209583914.mp4",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
      id: "02f9d424-f4c0-4f5e-8ec1-61ce23ed3b5e",
      source: {
        id: "05d4f10a-8dad-4833-a42f-1ccd3caadeb8",
        name: "RESIDENT",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
      target: {
        id: "cbfe8634-3213-4dd0-9edc-30992801517a",
        name: "FIFTH AMENDMENT",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
    },
    {
      source_id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
      target_id: "be8dfb3b-437a-4e86-8ddb-9597fa5f0366",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 1,
        description_x:
          "The community is engaged in troubleshooting or seeking assistance for issues related to the smartphone camera interface",
        human_readable_id: 208,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "17687c46-71fa-4aad-acd3-ac05428bc29d",
      source: {
        id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
        name: "SMARTPHONE CAMERA INTERFACE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "be8dfb3b-437a-4e86-8ddb-9597fa5f0366",
        name: "COMMUNITY",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
      target_id: "1f91da00-43ea-4194-ad08-122990c55de4",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 7,
        description_x:
          "The visual aesthetic is a key component of the smartphone camera interface",
        human_readable_id: 209,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "8e3a6049-731c-4b05-b502-7548e61f9ea0",
      source: {
        id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
        name: "SMARTPHONE CAMERA INTERFACE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "1f91da00-43ea-4194-ad08-122990c55de4",
        name: "VISUAL AESTHETIC",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
      target_id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 1,
        description_x:
          "The user experience is shaped by the features and design of the smartphone camera interface",
        human_readable_id: 210,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "1eb2cdec-3f50-426f-943b-9bed51a451dd",
      source: {
        id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
        name: "SMARTPHONE CAMERA INTERFACE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
        name: "USER EXPERIENCE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "1f91da00-43ea-4194-ad08-122990c55de4",
      target_id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 6,
        description_x:
          "The visual aesthetic contributes to the overall user experience of the smartphone camera interface",
        human_readable_id: 211,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "9a2af5de-33e8-47ba-94a0-8680de9191c1",
      source: {
        id: "1f91da00-43ea-4194-ad08-122990c55de4",
        name: "VISUAL AESTHETIC",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
        name: "USER EXPERIENCE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 9,
        description_x: "Reclectic is located in Gurnee Mills Mall",
        human_readable_id: 212,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "f9221236-a280-4c6a-9961-716097b6e16e",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
        name: "GURNEE MILLS MALL",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "8c57d3a9-dc3e-4621-9fc8-497d04fb120d",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from Urban Outfitters",
        human_readable_id: 213,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "2e4106c5-45d3-430a-bb4d-b6e9f4ec0054",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "8c57d3a9-dc3e-4621-9fc8-497d04fb120d",
        name: "URBAN OUTFITTERS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "04885dc2-130d-4604-b59e-386791a95b8e",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from Anthropology",
        human_readable_id: 214,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "914569e7-2a6c-43cb-bdaa-75bbf1a4cfe8",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "04885dc2-130d-4604-b59e-386791a95b8e",
        name: "ANTHROPOLOGY",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "801a7302-e140-48c3-866c-c23493f719ff",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from Free People",
        human_readable_id: 215,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "7c2526bb-087d-416b-bfb0-daca5ebac5f9",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "801a7302-e140-48c3-866c-c23493f719ff",
        name: "FREE PEOPLE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "c4af8628-1f05-47c6-97a5-f1f5f06be506",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from Nuuly",
        human_readable_id: 216,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "ad82f7fe-ac9d-43c0-b40e-01967bd43d5a",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "c4af8628-1f05-47c6-97a5-f1f5f06be506",
        name: "NUULY",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "efd5419d-2d3b-48cf-bbed-cbcbfbd66df3",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from North Face",
        human_readable_id: 217,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "237fa1b2-ce7a-44fe-b4f0-65377d8cfc92",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "efd5419d-2d3b-48cf-bbed-cbcbfbd66df3",
        name: "NORTH FACE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "5346b129-4305-41bb-a3b4-e60d6e51073c",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from Good American",
        human_readable_id: 218,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "c0ad38c0-d0da-445e-9544-fa91b09519bd",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "5346b129-4305-41bb-a3b4-e60d6e51073c",
        name: "GOOD AMERICAN",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "ce5bd19b-cb2b-4476-9831-1534d15f217d",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from Gola",
        human_readable_id: 219,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "241b1968-f524-4601-8a0c-785246286009",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "ce5bd19b-cb2b-4476-9831-1534d15f217d",
        name: "GOLA",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "7a11781f-dffa-471b-92c6-89d705061766",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 1,
        description_x: "Reclectic sells merchandise from Vans",
        human_readable_id: 220,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "7483aae2-c2cf-42f2-95db-70c44bf473d3",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "7a11781f-dffa-471b-92c6-89d705061766",
        name: "VANS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
      target_id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 9,
        description_x: "Gurnee Mills Mall is located in Illinois",
        human_readable_id: 221,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "ea098d71-59c3-43f9-86c2-b272a27891ac",
      source: {
        id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
        name: "GURNEE MILLS MALL",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
        name: "ILLINOIS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
      target_id: "d8998fa3-450e-4bef-882f-a61465398a04",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 9,
        description_x: "Gurnee Mills Mall is located in the village of Gurnee",
        human_readable_id: 222,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "b0cc6536-ad3f-403e-b159-69c34ea1ac0a",
      source: {
        id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
        name: "GURNEE MILLS MALL",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "d8998fa3-450e-4bef-882f-a61465398a04",
        name: "GURNEE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 1,
        description_x:
          "A divider from Facebook Marketplace is used in the studio",
        human_readable_id: 440,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "ccadeece-63f6-4118-abe8-0da6659bd318",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
        name: "FACEBOOK MARKETPLACE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
      target_id: "d8998fa3-450e-4bef-882f-a61465398a04",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 1,
        description_x: "Gurnee is a village located in the state of Illinois",
        human_readable_id: 223,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "5a21b5d0-63a4-4840-8461-9c8f6d61305a",
      source: {
        id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
        name: "ILLINOIS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "d8998fa3-450e-4bef-882f-a61465398a04",
        name: "GURNEE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      target_id: "c0e350e3-59a6-4aae-b968-467057a3945f",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 5,
        description_x:
          "Both organizations provide resources and support related to immigration law",
        human_readable_id: 224,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "e2197562-b10d-42ef-89ad-27f79dfb3cb5",
      source: {
        id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
        name: "MCBEAN IMMIGRATION LAW",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "c0e350e3-59a6-4aae-b968-467057a3945f",
        name: "IMMIGRANT LEGAL RESOURCE CENTER",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      target_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 4,
        description_x:
          "Both are involved in providing resources and support for immigrants",
        human_readable_id: 225,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "43ecc5f1-7e7f-471c-b1fe-5d6748b43ea5",
      source: {
        id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
        name: "MCBEAN IMMIGRATION LAW",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      target_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 3,
        description_x:
          "Resourcefulness involves guiding others to trusted information, such as that provided by McBean Immigration Law",
        human_readable_id: 226,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "61ca2e54-cae8-47a1-93e6-0696d9f6f980",
      source: {
        id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
        name: "MCBEAN IMMIGRATION LAW",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "c0e350e3-59a6-4aae-b968-467057a3945f",
      target_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 1,
        description_x:
          "Both organizations focus on supporting immigrants through legal assistance and information",
        human_readable_id: 227,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "4b41e076-4020-4684-b2ee-a8847e9ed41b",
      source: {
        id: "c0e350e3-59a6-4aae-b968-467057a3945f",
        name: "IMMIGRANT LEGAL RESOURCE CENTER",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "c0e350e3-59a6-4aae-b968-467057a3945f",
      target_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 3,
        description_x:
          "Resourcefulness includes directing individuals to comprehensive legal resources like those offered by the Immigrant Legal Resource Center",
        human_readable_id: 228,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "6429e536-4606-4785-a7ef-d9a8bd2026d1",
      source: {
        id: "c0e350e3-59a6-4aae-b968-467057a3945f",
        name: "IMMIGRANT LEGAL RESOURCE CENTER",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      target_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 3,
        description_x:
          "Resourcefulness is exemplified by platforms like juntosseguros.com that provide critical information on ICE raid locations",
        human_readable_id: 229,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "e4eb16e8-085b-45c0-9867-2b1abe95cd7e",
      source: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      target_id: "2539d697-bd0a-435d-a468-7d64fdf83772",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 1,
        description_x:
          "Resourcefulness involves tracking and disseminating information about ICE raid locations",
        human_readable_id: 230,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "b5ef8a31-13f1-4a0e-b7fa-6e8e4a369682",
      source: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "2539d697-bd0a-435d-a468-7d64fdf83772",
        name: "ICE RAID LOCATIONS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "977de398-cef3-4b97-999a-1374a43b432d",
      target_id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
      data: {
        text_unit_ids:
          "8c1c23ae7f3854ef5c9cb6a70adcc97ab46d10e416ec6c5a330c70a65313879487e3984d7b0bd04c96f6478af1dcd338ee2bcf5698f66fd030080f919cfa1272",
        weight: 1,
        description_x:
          "Social media influenced the purchase decision of the Val couch",
        human_readable_id: 233,
        n_tokens: 67,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "c6ee31be-9674-4179-bf3c-3433597ff179",
      source: {
        id: "977de398-cef3-4b97-999a-1374a43b432d",
        name: "VAL COUCH",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
      target: {
        id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
        name: "SOCIAL MEDIA",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
      target_id: "e3de905f-ef6d-4098-bf47-4c3dce1a2771",
      data: {
        text_unit_ids:
          "b4d9e487f4522995ab66535771fcc8f9f3d9a509728a6d03bbf9b00171415636c0e2158a041ff081395d905d1a5b009064b774a825876954f20786b1c2b5d4ee",
        weight: 7,
        description_x:
          "Walmart offers stylish and affordable home decor options that align with interior design trends",
        human_readable_id: 248,
        n_tokens: 141,
        title: "jules on TikTok",
        description_y:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458376179553176863.mp4",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
      id: "4f71d5e2-929b-4e8a-97ee-b29d6ce1e1e3",
      source: {
        id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
        name: "WALMART",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
      target: {
        id: "e3de905f-ef6d-4098-bf47-4c3dce1a2771",
        name: "INTERIOR DESIGN",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
    },
    {
      source_id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
      target_id: "16cd135d-6e67-4136-ad67-ad10ea8b9b6b",
      data: {
        text_unit_ids:
          "b4d9e487f4522995ab66535771fcc8f9f3d9a509728a6d03bbf9b00171415636c0e2158a041ff081395d905d1a5b009064b774a825876954f20786b1c2b5d4ee",
        weight: 6,
        description_x:
          "Walmart provides products that fit the coastal decor theme, such as seashell-patterned blankets and surfboards",
        human_readable_id: 249,
        n_tokens: 141,
        title: "jules on TikTok",
        description_y:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458376179553176863.mp4",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
      id: "b9b8b8ce-904d-4a44-8c84-8d6b4dd73eca",
      source: {
        id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
        name: "WALMART",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
      target: {
        id: "16cd135d-6e67-4136-ad67-ad10ea8b9b6b",
        name: "COASTAL DECOR",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
    },
    {
      source_id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
      target_id: "85078403-3e8e-4bcc-9fca-169b2cea33f8",
      data: {
        text_unit_ids:
          "cdb31729e036bf275a87c2186a0df85e143a38726bef4a8a3e3b29de47e3d8aa743fb1dcc6dca077b1b8e0e897c0b359f22d4b14d00b06ab2bcf52b6587b0316",
        weight: 1,
        description_x:
          "The small dog interacts with the off-white couch by watching its assembly and relaxing on it",
        human_readable_id: 251,
        n_tokens: 89,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "8f087330-f06a-44f9-89bf-5efd5c26546a",
      source: {
        id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
        name: "OFF-WHITE COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "85078403-3e8e-4bcc-9fca-169b2cea33f8",
        name: "SMALL DOG",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
      target_id: "4627d490-5180-45d0-b4b2-a7a88f75f717",
      data: {
        text_unit_ids:
          "e790fbe5b55965e5ce5d547c54720feac417a8b55e33fa13545df84950952260ae1a4c5fce544928e3e978aa2963e2843e53de087cf8e2ec5488ff93cfbc4a0d",
        weight: 2,
        description_x:
          "The remote control is used to operate the concealed television",
        human_readable_id: 257,
        n_tokens: 85,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "8d945785-31b6-45f2-9b36-a6e88b92caf4",
      source: {
        id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
        name: "TELEVISION CONCEALMENT",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "4627d490-5180-45d0-b4b2-a7a88f75f717",
        name: "REMOTE CONTROL",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "ae3d1d8c-a08c-4296-81ba-4965be515ee2",
      target_id: "a467062a-7f7c-4d77-a876-28ae38bb34f0",
      data: {
        text_unit_ids:
          "389aa02c0cea4641dd3565f3a81bf3a08351129935443a014b64153bbccfe2bbe3e83d9253ebf8d2f0fdb142cccb9b4881ee05eaedec11e2c7f7099c01133126",
        weight: 8,
        description_x: "The couch was purchased from Amazon Valyou",
        human_readable_id: 258,
        n_tokens: 89,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "7306b918-8c5a-49e8-96b6-2d97a425195d",
      source: {
        id: "ae3d1d8c-a08c-4296-81ba-4965be515ee2",
        name: "AMAZON VALYOU",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
      target: {
        id: "a467062a-7f7c-4d77-a876-28ae38bb34f0",
        name: "COUCH PURCHASE",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "e73f818c-2063-4571-a76c-4002e7ca7881",
      target_id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 5,
        description_x:
          "Both N.W.A. and The Notorious B.I.G. are iconic figures in the hip-hop music scene",
        human_readable_id: 259,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "bb4d4b15-206f-4a07-aec0-4416ac5645e3",
      source: {
        id: "e73f818c-2063-4571-a76c-4002e7ca7881",
        name: "N.W.A.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
        name: "THE NOTORIOUS B.I.G.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "e73f818c-2063-4571-a76c-4002e7ca7881",
      target_id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 5,
        description_x:
          "Both N.W.A. and 2Pac are influential in the hip-hop genre and have left a lasting legacy",
        human_readable_id: 260,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "482e6fb0-1bf8-445f-8f64-30922a6a3124",
      source: {
        id: "e73f818c-2063-4571-a76c-4002e7ca7881",
        name: "N.W.A.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
        name: "2PAC",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "e73f818c-2063-4571-a76c-4002e7ca7881",
      target_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 4,
        description_x:
          "N.W.A.'s music album is part of the decor in the home interior scene",
        human_readable_id: 261,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "7da9c4df-1377-41f6-aae8-90b22b5fac2a",
      source: {
        id: "e73f818c-2063-4571-a76c-4002e7ca7881",
        name: "N.W.A.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
      target_id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 1,
        description_x:
          "The Notorious B.I.G. and 2Pac were both prominent figures in hip-hop and had a well-known rivalry",
        human_readable_id: 262,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "7f559234-4ea7-42ba-8df3-7194c9c62669",
      source: {
        id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
        name: "THE NOTORIOUS B.I.G.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
        name: "2PAC",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
      target_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 4,
        description_x:
          "The Notorious B.I.G.'s music album is part of the decor in the home interior scene",
        human_readable_id: 263,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "dfb1b9f1-be83-448b-9f9c-f5a9dd93616b",
      source: {
        id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
        name: "THE NOTORIOUS B.I.G.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
      target_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 1,
        description_x:
          "2Pac's music album is part of the decor in the home interior scene",
        human_readable_id: 264,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "14c2811a-1172-4a22-9164-b398ade204cb",
      source: {
        id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
        name: "2PAC",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      target_id: "6386f9c5-25dd-420a-a7dd-cc7a15c7ce26",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 6,
        description_x:
          "The vinyl player setup is a feature within the home interior scene",
        human_readable_id: 265,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "ee7bc365-df28-4829-977d-cba1245ff52b",
      source: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "6386f9c5-25dd-420a-a7dd-cc7a15c7ce26",
        name: "VINYL PLAYER SETUP",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "db9f1e53-d624-4643-8304-5163dc453afc",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 1,
        description_x:
          "The initiative to increase Latino representation is closely tied to the importance of legal education",
        human_readable_id: 266,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "0be2a657-2a50-410b-bf7f-b5fbde6330ea",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "db9f1e53-d624-4643-8304-5163dc453afc",
        name: "LEGAL EDUCATION",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "bc77912e-3bca-44b1-aeae-1cb41ba8fae0",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 5,
        description_x:
          "Law books are essential resources for the education and representation of Latinos in law",
        human_readable_id: 267,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "0bf369b9-c8a6-4254-8e27-b23594555ca7",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "bc77912e-3bca-44b1-aeae-1cb41ba8fae0",
        name: "LAW BOOKS",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "202ed9f8-ce25-4863-bc98-4bf26e15e7b7",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 4,
        description_x:
          "The desk represents the focused effort required to increase Latino representation in law",
        human_readable_id: 268,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "d3e69f1a-5b57-4e54-9a22-5daff1913248",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "202ed9f8-ce25-4863-bc98-4bf26e15e7b7",
        name: "DESK",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "2fef7194-3635-4785-b55d-5b65d4ef1c1a",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 4,
        description_x:
          "The laptop is a tool that aids in the education and representation of Latinos in law",
        human_readable_id: 269,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "6bec68e2-05b0-4a1d-9af4-f993bd040b51",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "2fef7194-3635-4785-b55d-5b65d4ef1c1a",
        name: "LAPTOP",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "eacd213b-0e28-49aa-a3ff-b6171e4be01e",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 1,
        description_x:
          "Headphones are used to enhance focus, supporting the goal of increasing Latino presence in law",
        human_readable_id: 270,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "8da981e7-6da8-480a-ac54-02dfe6bd67a7",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "eacd213b-0e28-49aa-a3ff-b6171e4be01e",
        name: "HEADPHONES",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "86854636-36a3-4713-a8bd-fcc970f65198",
      target_id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 8,
        description_x:
          "OneClickWorker.com provides tools and resources to help candidates prepare for job interviews",
        human_readable_id: 273,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "5a9c7c64-8a54-41b5-8ea2-72ece7f793b2",
      source: {
        id: "86854636-36a3-4713-a8bd-fcc970f65198",
        name: "ONECLICKWORKER.COM",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
        name: "JOB INTERVIEW",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
      target_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 1,
        description_x:
          "Event managers often undergo job interviews to demonstrate their experience and skills in event planning and management",
        human_readable_id: 274,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "84f92490-bafa-46e6-9a55-002899184fd7",
      source: {
        id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
        name: "JOB INTERVIEW",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      target_id: "c52eb868-b399-408b-b1db-be0c56473d56",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 7,
        description_x:
          "Event managers are responsible for planning and managing corporate conferences",
        human_readable_id: 275,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "f7e921f9-5264-47cc-ad50-8052ea176781",
      source: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "c52eb868-b399-408b-b1db-be0c56473d56",
        name: "CORPORATE CONFERENCES",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      target_id: "22d9e728-de3f-426e-a059-3ac1a0f5515c",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 7,
        description_x:
          "Event managers are responsible for planning and managing weddings",
        human_readable_id: 276,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "fb0b86e3-d7a3-4603-a0df-b852d5d1d375",
      source: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "22d9e728-de3f-426e-a059-3ac1a0f5515c",
        name: "WEDDINGS",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      target_id: "f693cdbb-8cd0-430a-b1eb-dfa607cecf74",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 1,
        description_x:
          "Event managers are responsible for planning and managing community festivals",
        human_readable_id: 277,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "768ad5bb-f1f2-47be-bb6d-ca259aab0c7f",
      source: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "f693cdbb-8cd0-430a-b1eb-dfa607cecf74",
        name: "COMMUNITY FESTIVALS",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 5,
        description_x:
          "Both 7Sage and Manhattan Prep provide resources for LSAT preparation",
        human_readable_id: 278,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "b2a1108e-7759-4186-9ba5-d4adeecb372e",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
        name: "MANHATTAN PREP",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "d076b170-b018-4169-bd71-f4d76d0e836c",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 5,
        description_x:
          "Both 7Sage and Powerscore offer resources for LSAT preparation",
        human_readable_id: 279,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "abfe00dd-2bdb-4c5a-bd26-c99234e83eac",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "d076b170-b018-4169-bd71-f4d76d0e836c",
        name: "POWERSCORE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "36bba867-0a9d-4970-ab8b-07f795fdfc35",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 8,
        description_x:
          '7Sage emphasizes the "Blind Review" method as a key strategy for LSAT preparation',
        human_readable_id: 280,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "f58f7330-80f7-4d8c-9214-26b456362464",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "36bba867-0a9d-4970-ab8b-07f795fdfc35",
        name: "BLIND REVIEW",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "fa14bbb0-2e7c-4afd-8630-ec67a9affba9",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 1,
        description_x:
          "The 7Sage YouTube channel is part of 7Sage's resources for LSAT preparation",
        human_readable_id: 281,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "ec3f5102-c631-40cb-aea9-ee5b2410b9d2",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "fa14bbb0-2e7c-4afd-8630-ec67a9affba9",
        name: "7SAGE YOUTUBE CHANNEL",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
      target_id: "d076b170-b018-4169-bd71-f4d76d0e836c",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 1,
        description_x:
          "Both Manhattan Prep and Powerscore offer resources for LSAT preparation",
        human_readable_id: 282,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "a73d1cd5-15bd-497e-9184-eee1e967de61",
      source: {
        id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
        name: "MANHATTAN PREP",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "d076b170-b018-4169-bd71-f4d76d0e836c",
        name: "POWERSCORE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "6a508c1f-0408-4024-bf11-965a8c045800",
      target_id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
      data: {
        text_unit_ids:
          "e38103529afbe759a8da4b4880e4ad715e2310606b9eab98ab0d74a00ea0f26be83cad06fdc372bed6c54ec5dcbf68b6f54025e649eaee0788b59b9d2ecea3a2",
        weight: 9,
        description_x:
          '"The Frame" is a product designed and manufactured by Samsung',
        human_readable_id: 287,
        n_tokens: 78,
        title: "paige_mariah on TikTok",
        description_y:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451658876052688159.mp4",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
      id: "eaf9a819-7bd1-44e2-bcaa-4c8d7e5ccb8e",
      source: {
        id: "6a508c1f-0408-4024-bf11-965a8c045800",
        name: "SAMSUNG",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
      target: {
        id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
        name: "THE FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
    },
    {
      source_id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
      target_id: "ebc40e35-36c2-40da-a009-c99cfb31cfba",
      data: {
        text_unit_ids:
          "e38103529afbe759a8da4b4880e4ad715e2310606b9eab98ab0d74a00ea0f26be83cad06fdc372bed6c54ec5dcbf68b6f54025e649eaee0788b59b9d2ecea3a2",
        weight: 8,
        description_x:
          '"The Frame" TV can be customized with different frames to match personal aesthetic preferences',
        human_readable_id: 288,
        n_tokens: 78,
        title: "paige_mariah on TikTok",
        description_y:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451658876052688159.mp4",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
      id: "d3ba089f-027b-4dcc-96d5-12a0683e283f",
      source: {
        id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
        name: "THE FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
      target: {
        id: "ebc40e35-36c2-40da-a009-c99cfb31cfba",
        name: "FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
    },
    {
      source_id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
      target_id: "fbdfade2-a57d-4481-a5cf-410cb3a5995a",
      data: {
        text_unit_ids:
          "e38103529afbe759a8da4b4880e4ad715e2310606b9eab98ab0d74a00ea0f26be83cad06fdc372bed6c54ec5dcbf68b6f54025e649eaee0788b59b9d2ecea3a2",
        weight: 7,
        description_x:
          '"The Frame" TV is designed to serve as a centerpiece within a gallery wall',
        human_readable_id: 289,
        n_tokens: 78,
        title: "paige_mariah on TikTok",
        description_y:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451658876052688159.mp4",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
      id: "792ef509-d53c-4efa-b3af-055ac633961b",
      source: {
        id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
        name: "THE FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
      target: {
        id: "fbdfade2-a57d-4481-a5cf-410cb3a5995a",
        name: "GALLERY WALL",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
    },
    {
      source_id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
      target_id: "eaa86736-e1d3-4f7c-89df-f3bb1212fe24",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 1,
        description_x:
          "The claw side table crafted from live-edge wood offers a natural contrast to the urban skyline backdrop",
        human_readable_id: 300,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "72e1f703-f6c4-4dab-9c80-54be952e5577",
      source: {
        id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
        name: "CLAW SIDE TABLE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
      target: {
        id: "eaa86736-e1d3-4f7c-89df-f3bb1212fe24",
        name: "URBAN SKYLINE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "7fdf8e5b-32b3-4772-b52c-fcac2cfda691",
      target_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 17,
        description_x:
          "Chicago Estate Advisors is responsible for organizing and presenting items for the estate sale. They are managing the entire process of the estate sale, ensuring that all items are properly displayed and available for potential buyers.",
        human_readable_id: 0,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "cc576ce6-b4e3-44a6-b044-34e2497946b0",
      source: {
        id: "7fdf8e5b-32b3-4772-b52c-fcac2cfda691",
        name: "CHICAGO ESTATE ADVISORS",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "0a603499-b3f1-4dae-9385-0f25efcbdf0e",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 8,
        description_x:
          "The estate sale prominently features a collection of luxury items, including Chanel purses. This collection highlights the presence of various Chanel items, making it a notable aspect of the estate sale.",
        human_readable_id: 1,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "5de46fde-b3e3-4a7a-bd78-1dba3ab90d62",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "0a603499-b3f1-4dae-9385-0f25efcbdf0e",
        name: "CHANEL",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "1d3235e7-2a24-44dc-b372-42dd96f0f5c5",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 9,
        description_x: "The estate sale is being held on Astra Street.",
        human_readable_id: 2,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "9173ddaf-2574-4ca8-a282-691f8381d8d5",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "1d3235e7-2a24-44dc-b372-42dd96f0f5c5",
        name: "ASTRA STREET",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "211408fa-6268-431b-849f-0bc2579303c7",
      data: {
        text_unit_ids:
          "448d7dc014697fef14d00837a8f6c0e17ec88ba83ad4726d4c23c4f0e5616b7f1af7204d6f50a33b43941ea08986d5a5ec768b1f7fc76c45e0767ce9a3775deb",
        weight: 6,
        description_x:
          "Jewelry is one of the luxury items featured in the estate sale",
        human_readable_id: 3,
        n_tokens: 73,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "46592dd4-8264-491c-91b4-2d4d8c150657",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "211408fa-6268-431b-849f-0bc2579303c7",
        name: "JEWELRY",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "949a3205-f16b-4f91-ae5d-5cdcb71246cc",
      data: {
        text_unit_ids:
          "448d7dc014697fef14d00837a8f6c0e17ec88ba83ad4726d4c23c4f0e5616b7f1af7204d6f50a33b43941ea08986d5a5ec768b1f7fc76c45e0767ce9a3775deb",
        weight: 6,
        description_x:
          "Clothes are part of the luxury items available at the estate sale",
        human_readable_id: 4,
        n_tokens: 73,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "1a230c56-eabc-4e24-bc13-352278a5323e",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "949a3205-f16b-4f91-ae5d-5cdcb71246cc",
        name: "CLOTHES",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "eb90a3e8-4578-4a4d-a044-1cbec5f525cb",
      data: {
        text_unit_ids:
          "448d7dc014697fef14d00837a8f6c0e17ec88ba83ad4726d4c23c4f0e5616b7f1af7204d6f50a33b43941ea08986d5a5ec768b1f7fc76c45e0767ce9a3775deb",
        weight: 1,
        description_x:
          "Antiques are among the luxury items being sold at the estate sale",
        human_readable_id: 5,
        n_tokens: 73,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "2e7f66c5-47d5-4f75-90e5-e2822618e4bc",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "eb90a3e8-4578-4a4d-a044-1cbec5f525cb",
        name: "ANTIQUES",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "8d715494-92df-46a8-b1ac-eeb6123438ef",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 8,
        description_x: "The estate sale is being held in Gold Coast",
        human_readable_id: 63,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "e190e625-cf7e-45fa-a282-80e8b567ee12",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "8d715494-92df-46a8-b1ac-eeb6123438ef",
        name: "GOLD COAST",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
      target_id: "d2dba83e-7451-4f9e-a741-7c7a0533abb5",
      data: {
        text_unit_ids:
          "26b8c318dc376deae637390b2028bbeb72e29d0d37317fe89f21e81bb25d0193ebfd959acdfc0ec835471367159f384f9524900d43d09d6dd6d5ec871b09fc09",
        weight: 1,
        description_x:
          "The estate sale is scheduled to occur from January 31st to February 2nd",
        human_readable_id: 64,
        n_tokens: 69,
        title: "Chicago Estate Advisors on TikTok",
        description_y:
          "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
        url: "https://www.tiktokv.com/share/video/7465354144283381022/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465354144283381022.mp4",
        video_id: "0",
        time_saved: "2025-01-30 00:06:24",
      },
      id: "d0bee969-c19e-4822-89f3-c1e8920e072d",
      source: {
        id: "ee76e0cf-36ae-4180-a35b-251d19782a14",
        name: "ESTATE SALE",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
      target: {
        id: "d2dba83e-7451-4f9e-a741-7c7a0533abb5",
        name: "JANUARY 31ST TO FEBRUARY 2ND",
        group: 1,
        data: {
          title: "Chicago Estate Advisors on TikTok",
          description:
            "We’re back again! This weekend, we are in Gold Coast… stay tuned for the address being announced on Thursday at 9am.  If you want to sign in early, check out our estate sales . Net page, view the terms and conditions to grab your spot early!  We can’t say we didn’t warn you 😉 #estatesale #estatesalefinds #chicagovintage #chicagotiktok #estateliquidation #chicagoland #vintage #homedecor #goldcoastchicago #chanelbag #chanel ",
          url: "https://www.tiktokv.com/share/video/7465354144283381022/",
          video_id: "0",
          time_saved: "2025-01-30 00:06:24",
        },
      },
    },
    {
      source_id: "37cb5eed-326e-4e57-9f14-9145d2493762",
      target_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      data: {
        text_unit_ids:
          "acc764e403a75300c8eacdb1cc0b09268b68d4755d37b8407937fe9c73ba1e849332c8810416705b1e5e8cae122b97271a6010cf4e7067ff372b855164841dd1",
        weight: 7,
        description_x:
          "Salary negotiation is advised to occur during the recruiter screening process to establish compensation expectations",
        human_readable_id: 6,
        n_tokens: 100,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "641143e0-4f87-42d9-9496-d19488dbe132",
      source: {
        id: "37cb5eed-326e-4e57-9f14-9145d2493762",
        name: "RECRUITER SCREENING PROCESS",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "3b7aade7-0754-49d2-8c96-57da9536eec5",
      data: {
        text_unit_ids:
          "acc764e403a75300c8eacdb1cc0b09268b68d4755d37b8407937fe9c73ba1e849332c8810416705b1e5e8cae122b97271a6010cf4e7067ff372b855164841dd1",
        weight: 1,
        description_x:
          "Email follow-up is recommended in salary negotiation to provide a clear record and reduce pressure",
        human_readable_id: 7,
        n_tokens: 100,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "938acd3b-f7b0-47b4-bbd7-3b7688309aa5",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "3b7aade7-0754-49d2-8c96-57da9536eec5",
        name: "EMAIL FOLLOW UP",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
      data: {
        text_unit_ids:
          "acc764e403a75300c8eacdb1cc0b09268b68d4755d37b8407937fe9c73ba1e849332c8810416705b1e5e8cae122b97271a6010cf4e7067ff372b855164841dd1",
        weight: 9,
        description_x:
          "Employees engage in salary negotiation to discuss and agree on their compensation terms",
        human_readable_id: 8,
        n_tokens: 100,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "3be0d03c-d2a5-4941-9291-6309304e40d2",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
        name: "EMPLOYEES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "ce42aa47-96ff-4fc0-a73d-606e550c4800",
      data: {
        text_unit_ids:
          "acc764e403a75300c8eacdb1cc0b09268b68d4755d37b8407937fe9c73ba1e849332c8810416705b1e5e8cae122b97271a6010cf4e7067ff372b855164841dd1",
        weight: 1,
        description_x:
          "Compensation is a key topic discussed during salary negotiation",
        human_readable_id: 9,
        n_tokens: 100,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "2b898768-23a9-4e49-b436-f834a99cf8fe",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "ce42aa47-96ff-4fc0-a73d-606e550c4800",
        name: "COMPENSATION",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 1,
        description_x:
          "The VP provides advice on how to approach salary negotiation",
        human_readable_id: 59,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "9ca95dd5-19c1-4028-9d70-676189d4ef99",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "9bacc367-af81-4348-80a7-5d0c3cfec81a",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 7,
        description_x:
          "The research provides insights and strategies for effective salary negotiation",
        human_readable_id: 60,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "f1b8f3e9-a876-4da9-aa15-a97bd28749e8",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "9bacc367-af81-4348-80a7-5d0c3cfec81a",
        name: "RESEARCH",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "5c68ffc9-c0d8-445d-a98a-b01a0f232685",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 8,
        description_x: "The balcony is an outdoor extension of the studio",
        human_readable_id: 441,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "3b2cedb5-c90e-4a6f-8407-33ab128bed95",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "5c68ffc9-c0d8-445d-a98a-b01a0f232685",
        name: "BALCONY",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "d94068c4-12a5-4faf-95bd-f8323c197872",
      data: {
        text_unit_ids:
          "f155e68c1449bc5cbf638b3ffcc4799e16342a535622646de46e7a1d0064419a20016afd2f811e46f1860db367a3dbb69e11bf04779c40ff7af181038bfed7e7",
        weight: 6,
        description_x:
          "The recruiter is involved in the salary negotiation process, acting as a mediator between the candidate and the employer",
        human_readable_id: 99,
        n_tokens: 371,
        title: "Don’t Go Broke Trying💰🎙 on TikTok",
        description_y:
          "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
        url: "https://www.tiktokv.com/share/video/7334735055845182726/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7334735055845182726.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:11:33",
      },
      id: "bd622149-c333-4949-9e5b-2c64f8254c27",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "d94068c4-12a5-4faf-95bd-f8323c197872",
        name: "RECRUITER",
        group: 1,
        data: {
          title: "Don’t Go Broke Trying💰🎙 on TikTok",
          description:
            "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
          url: "https://www.tiktokv.com/share/video/7334735055845182726/",
          video_id: "0",
          time_saved: "2025-01-29 04:11:33",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
      data: {
        text_unit_ids:
          "f155e68c1449bc5cbf638b3ffcc4799e16342a535622646de46e7a1d0064419a20016afd2f811e46f1860db367a3dbb69e11bf04779c40ff7af181038bfed7e7",
        weight: 1,
        description_x:
          "The speaker provides advice on how to effectively conduct a salary negotiation",
        human_readable_id: 100,
        n_tokens: 371,
        title: "Don’t Go Broke Trying💰🎙 on TikTok",
        description_y:
          "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
        url: "https://www.tiktokv.com/share/video/7334735055845182726/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7334735055845182726.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:11:33",
      },
      id: "56df00be-2f8c-402c-9626-10bc683a84ed",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
        name: "SPEAKER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "da6dc505-8684-417b-9f89-acb48177a1e8",
      data: {
        text_unit_ids:
          "2bb77b6ebf007030293db7ef1ad401c71b61e717aea0d266c091f1f2e0149325ab90f033cd7a2d341e79aa26980aec174593e8aec4da02b9db0c7431e84b432f",
        weight: 1,
        description_x:
          "Hiring managers are involved in the process of salary negotiation with job candidates",
        human_readable_id: 358,
        n_tokens: 167,
        title: "Greg L. - Career Coach 🤓 on TikTok",
        description_y:
          "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
        url: "https://www.tiktokv.com/share/video/7320405835459480837/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320405835459480837.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:07:43",
      },
      id: "502f464a-5f2a-4122-b530-4ea28f23f3a0",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "da6dc505-8684-417b-9f89-acb48177a1e8",
        name: "HIRING MANAGERS",
        group: 1,
        data: {
          title: "Greg L. - Career Coach 🤓 on TikTok",
          description:
            "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
          url: "https://www.tiktokv.com/share/video/7320405835459480837/",
          video_id: "0",
          time_saved: "2025-01-29 04:07:43",
        },
      },
    },
    {
      source_id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
      target_id: "49d10a73-0f3f-4d59-9e52-fd4f971e150d",
      data: {
        text_unit_ids:
          "2bb77b6ebf007030293db7ef1ad401c71b61e717aea0d266c091f1f2e0149325ab90f033cd7a2d341e79aa26980aec174593e8aec4da02b9db0c7431e84b432f",
        weight: 1,
        description_x:
          "The salary negotiation process involves discussing a salary within the constraints of the hiring manager's budget",
        human_readable_id: 360,
        n_tokens: 167,
        title: "Greg L. - Career Coach 🤓 on TikTok",
        description_y:
          "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
        url: "https://www.tiktokv.com/share/video/7320405835459480837/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320405835459480837.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:07:43",
      },
      id: "4f3c3842-e83c-489a-a878-0523937cf72a",
      source: {
        id: "68bc6ce2-e821-42d3-bdff-fb41f5d7a107",
        name: "SALARY NEGOTIATION",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "49d10a73-0f3f-4d59-9e52-fd4f971e150d",
        name: "BUDGET",
        group: 1,
        data: {
          title: "Greg L. - Career Coach 🤓 on TikTok",
          description:
            "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
          url: "https://www.tiktokv.com/share/video/7320405835459480837/",
          video_id: "0",
          time_saved: "2025-01-29 04:07:43",
        },
      },
    },
    {
      source_id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
      target_id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 7,
        description_x:
          "Employees negotiate salaries with companies to reach a mutually agreeable compensation",
        human_readable_id: 41,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "cd9f6e0e-6827-40c7-9c9c-ac12e3bb9868",
      source: {
        id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
        name: "EMPLOYEES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
        name: "COMPANIES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
      target_id: "5a933e1d-d70e-4a33-aeee-33749920651a",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 1,
        description_x:
          "Employees' salary negotiations are influenced by the constraints of hiring budgets",
        human_readable_id: 42,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "cdb342a8-48a0-4d4c-a7ed-14e1eae9fb2d",
      source: {
        id: "8c715a9c-dfaa-432d-aeda-d5556e25701f",
        name: "EMPLOYEES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "5a933e1d-d70e-4a33-aeee-33749920651a",
        name: "HIRING BUDGETS",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "d92010b8-178a-4e79-b008-5dbf093808f7",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 16,
        description_x:
          "CHICAGO LOFT is located in Chicago, specifically in the Printers Row neighborhood.",
        human_readable_id: 10,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "5c163bd6-9057-4177-a68a-4908c782dd5c",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "d92010b8-178a-4e79-b008-5dbf093808f7",
        name: "CHICAGO LOFT",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
      data: {
        text_unit_ids:
          "dbf12ab489b76f281cd7426d31cbbd004e234a8320c2ee309d9966ec126738b3bbc9238a1f8d33c374438051aca1af544ce292c5d7523d1d651068b720755dd2",
        weight: 7,
        description_x: "The apartment hunt is taking place in Chicago",
        human_readable_id: 168,
        n_tokens: 59,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "b04ecbdf-6cee-41bf-93b8-fa1588a06093",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
        name: "APARTMENT HUNT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 7,
        description_x:
          "The event of apartment hunting is taking place in the city of Chicago",
        human_readable_id: 171,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "fa5ad52e-a7a8-4acc-9911-7d44688b77bc",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
        name: "APARTMENT HUNTING",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 1,
        description_x:
          "The convertible unit is part of the apartment hunting event in Chicago",
        human_readable_id: 172,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "e3cdbbf8-76f3-4ffa-94bd-672e9c8788d1",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
        name: "CONVERTIBLE UNIT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 1,
        description_x:
          "Downtown Chicago is a part of the larger city of Chicago",
        human_readable_id: 246,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "47c7506b-3959-42af-b164-27f99e85ad46",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
        name: "DOWNTOWN CHICAGO",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      data: {
        text_unit_ids:
          "669026b821037dd5cc37691b24b10da7013785ad0451a353c52cc1fa4c5ddac7c8e80bf04994d7d5d7ae51130673abb655e1cc98ab53bbd5726d2041c3b9cea8",
        weight: 9,
        description_x: "West Loop is a neighborhood located in Chicago",
        human_readable_id: 271,
        n_tokens: 98,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "0ab6c847-464f-4bcd-820f-42acf61827e0",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "cfe02158-3bda-40fc-831e-f8ff450c2404",
      data: {
        text_unit_ids:
          "669026b821037dd5cc37691b24b10da7013785ad0451a353c52cc1fa4c5ddac7c8e80bf04994d7d5d7ae51130673abb655e1cc98ab53bbd5726d2041c3b9cea8",
        weight: 8,
        description_x: "The West Loop studio is located in Chicago",
        human_readable_id: 274,
        n_tokens: 98,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "2174338c-b58a-475d-a26d-cd00f0dac538",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "cfe02158-3bda-40fc-831e-f8ff450c2404",
        name: "WEST LOOP STUDIO",
        group: 1,
        data: {
          title: "Smart City Locating Chicago on TikTok",
          description:
            "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
          url: "https://www.tiktokv.com/share/video/7456922942690184478/",
          video_id: "0",
          time_saved: "2025-01-14 20:26:54",
        },
      },
    },
    {
      source_id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
      target_id: "e6203578-bb2e-48e1-a9f0-0412a6b2ba74",
      data: {
        text_unit_ids:
          "669026b821037dd5cc37691b24b10da7013785ad0451a353c52cc1fa4c5ddac7c8e80bf04994d7d5d7ae51130673abb655e1cc98ab53bbd5726d2041c3b9cea8",
        weight: 8,
        description_x: "The one-bedroom unit is located in Chicago",
        human_readable_id: 275,
        n_tokens: 98,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "32dbd108-cbb8-4f19-b740-962d1a7688ed",
      source: {
        id: "46554d27-6fe4-4c5e-91ff-27a6b6723b09",
        name: "CHICAGO",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "e6203578-bb2e-48e1-a9f0-0412a6b2ba74",
        name: "ONE-BEDROOM UNIT",
        group: 1,
        data: {
          title: "Smart City Locating Chicago on TikTok",
          description:
            "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
          url: "https://www.tiktokv.com/share/video/7456922942690184478/",
          video_id: "0",
          time_saved: "2025-01-14 20:26:54",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "5f0b6fb9-2710-41c2-a063-e6ff8a86b2e6",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 8,
        description_x: "The study space is a designated area within the studio",
        human_readable_id: 442,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "ade49707-a202-428d-9c70-a8b6cfce5d62",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "5f0b6fb9-2710-41c2-a063-e6ff8a86b2e6",
        name: "STUDY SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "d92010b8-178a-4e79-b008-5dbf093808f7",
      target_id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 2,
        description_x:
          "The Chicago Loft is located in the Printers Row neighborhood, which is situated in the downtown area of Chicago.",
        human_readable_id: 11,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "e2f0cbca-16a1-437e-8da7-0ffd27317341",
      source: {
        id: "d92010b8-178a-4e79-b008-5dbf093808f7",
        name: "CHICAGO LOFT",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
      target: {
        id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
        name: "PRINTERS ROW",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "d92010b8-178a-4e79-b008-5dbf093808f7",
      target_id: "0d304e0c-d33e-46ce-acca-11804670c201",
      data: {
        text_unit_ids:
          "2455bbc5140696771b48f122af738d7c33541138f440f3adcbc61d9e003ee6970eb275ea1f1545f7caff5fd82aa78d0c703de1ebe9a43c81930e484ad2eae99a",
        weight: 1,
        description_x:
          "The presenter is the contact person for inquiries about the Chicago loft",
        human_readable_id: 12,
        n_tokens: 72,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "d16cd745-4b78-4f0a-94ea-0fa579cc0f69",
      source: {
        id: "d92010b8-178a-4e79-b008-5dbf093808f7",
        name: "CHICAGO LOFT",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
      target: {
        id: "0d304e0c-d33e-46ce-acca-11804670c201",
        name: "PRESENTER",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
      target_id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
      data: {
        text_unit_ids:
          "1983d6dff8f206d9d262392d38e52f39ce0c6c57af87bcf08dfe259baa2e487e62fd9289d67d2d48e312b6121b26bdb1b964f4668b5aa47f84242c25d6b67e64",
        weight: 7,
        description_x:
          "Printers Row is a neighborhood located within downtown Chicago",
        human_readable_id: 247,
        n_tokens: 81,
        title: "daisymazainchicago on TikTok",
        description_y:
          "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
        url: "https://www.tiktokv.com/share/video/7340072017179200810/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7340072017179200810.mp4",
        video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
        time_saved: "2025-01-28 16:12:10",
      },
      id: "c84fd0f5-f6e0-41af-95bd-753c634bfae4",
      source: {
        id: "70a5d814-e5ff-4337-9d7f-9ff5460ca535",
        name: "PRINTERS ROW",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
      target: {
        id: "af4d49db-4b7e-4907-bd22-34e7f7da821d",
        name: "DOWNTOWN CHICAGO",
        group: 1,
        data: {
          title: "daisymazainchicago on TikTok",
          description:
            "Lives like a 2 bed & has a rooftop with a skyline view 😍 #downtownchicago #chicagoloft #chicagoloop #printersrowchicago #condoloft #chicagohometour #hometour #livinginchicago #chicagorealtor #condoforsale ",
          url: "https://www.tiktokv.com/share/video/7340072017179200810/",
          video_id: "e6383318-38ef-4563-8db4-1a8985d540b7",
          time_saved: "2025-01-28 16:12:10",
        },
      },
    },
    {
      source_id: "bcc755f1-b356-4907-aefc-2555dcdd60ec",
      target_id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
      data: {
        text_unit_ids:
          "43e0a3475c13cf93a2ed2ad4466cdc83dd78aadebb3b8b3cc380d48f309ae82f6aa7fa2cb66bac9042c93669d055ffcac63b8f5cc437ae548ce19733de020fa3",
        weight: 6,
        description_x:
          "The living space includes a bar cart purchased from Five Below",
        human_readable_id: 13,
        n_tokens: 222,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "b064a4d9-7d53-4d5e-8829-4e13ceda3f46",
      source: {
        id: "bcc755f1-b356-4907-aefc-2555dcdd60ec",
        name: "FIVE BELOW",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
        name: "LIVING SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
      target_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 16,
        description_x:
          "The LIVING SPACE is a central area within the STUDIO, which is described as cute and cozy. It is an integral part of the studio, providing a comfortable and inviting environment.",
        human_readable_id: 15,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "4ecac16e-56fa-424a-b291-c6ed0553a920",
      source: {
        id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
        name: "LIVING SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
      target_id: "c0867fae-af12-4d84-9b68-958dfdd0669f",
      data: {
        text_unit_ids:
          "fdf62deffd4ce4a62643183c8df1d22ab45c598ae0b41e296dae0b8c6e3657b838c31f2ba03bb3f8205f386cc0863fa01509cf5105bba6c04f150662f03f1c0a",
        weight: 8,
        description_x:
          "The empty apartment was transformed into a well-organized living space",
        human_readable_id: 248,
        n_tokens: 162,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7432918152297614634.mp4",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
      id: "db5c85e5-79c3-44d0-8091-475c91ad8e2f",
      source: {
        id: "041b4a70-c909-43d9-b44d-5f98c17cd161",
        name: "LIVING SPACE",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "c0867fae-af12-4d84-9b68-958dfdd0669f",
        name: "EMPTY APARTMENT",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
          url: "https://www.tiktokv.com/share/video/7432918152297614634/",
          video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
          time_saved: "2025-02-02 03:36:08",
        },
      },
    },
    {
      source_id: "c973125a-22b9-471e-a202-3f88c6d35e6c",
      target_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 16,
        description_x:
          "The KITCHEN is an integral part of the STUDIO. It is prominently featured during the studio tour, showcasing its significance within the space.",
        human_readable_id: 14,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "386aa2b7-4608-4dcf-a130-4be67c690f83",
      source: {
        id: "c973125a-22b9-471e-a202-3f88c6d35e6c",
        name: "KITCHEN",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "b1e5498a-2b96-43b4-9e16-c5f012cc8e72",
      data: {
        text_unit_ids:
          "43e0a3475c13cf93a2ed2ad4466cdc83dd78aadebb3b8b3cc380d48f309ae82f6aa7fa2cb66bac9042c93669d055ffcac63b8f5cc437ae548ce19733de020fa3",
        weight: 7,
        description_x:
          "The patio is an anticipated addition to the studio for study breaks",
        human_readable_id: 16,
        n_tokens: 222,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "0577df37-97c6-413b-adcf-4f1cf5bc4c71",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "b1e5498a-2b96-43b4-9e16-c5f012cc8e72",
        name: "PATIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "b4b2f459-5300-45ad-9398-c9b61b4be628",
      data: {
        text_unit_ids:
          "43e0a3475c13cf93a2ed2ad4466cdc83dd78aadebb3b8b3cc380d48f309ae82f6aa7fa2cb66bac9042c93669d055ffcac63b8f5cc437ae548ce19733de020fa3",
        weight: 8,
        description_x:
          "The bathroom is a part of the studio and completes the tour",
        human_readable_id: 17,
        n_tokens: 222,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "8e3552ef-4079-47f7-9338-a35adafba941",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "b4b2f459-5300-45ad-9398-c9b61b4be628",
        name: "BATHROOM",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
      target_id: "5a3bb2f4-2299-4125-b626-33797f660bfa",
      data: {
        text_unit_ids:
          "ebc583a5ec01f0600798af6ce76a8237441dca20601b0107e669753d5d527cba0b07bbf4803c245adf40bf3862836a918d26582b235ab3881637e4ce46407e43",
        weight: 8,
        description_x:
          "The bedroom is a part of the studio, separated by a divider",
        human_readable_id: 443,
        n_tokens: 175,
        title: "Minty Scott on TikTok",
        description_y:
          "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
        url: "https://www.tiktokv.com/share/video/7400155510688861482/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400155510688861482.mp4",
        video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
        time_saved: "2025-02-02 15:13:38",
      },
      id: "ab4fddcf-a2d8-459e-b83c-3e9fa9849a54",
      source: {
        id: "ed8783de-d5d2-4d34-bda9-22ef3b8dfc59",
        name: "STUDIO",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
      target: {
        id: "5a3bb2f4-2299-4125-b626-33797f660bfa",
        name: "BEDROOM",
        group: 1,
        data: {
          title: "Minty Scott on TikTok",
          description:
            "Can you believe that couch was only $400 😭🤌🏾 #medstudent #medschool #studioapartment ",
          url: "https://www.tiktokv.com/share/video/7400155510688861482/",
          video_id: "2a5abacc-c330-4937-a3f2-65e501428ecc",
          time_saved: "2025-02-02 15:13:38",
        },
      },
    },
    {
      source_id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
      target_id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 1,
        description_x:
          "LSAC provides the overall cumulative GPA that law schools use to evaluate applicants",
        human_readable_id: 18,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "f1ab0586-c0ae-4c0c-8121-db05d6f414b7",
      source: {
        id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
        name: "LSAC",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
        name: "LAW SCHOOLS",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
      target_id: "35f75903-025d-473a-a6b5-e87fa63a9ed8",
      data: {
        text_unit_ids:
          "0af272eccec360e473476d195fb88b16c4e716ed2c105e49f4de5317d814af246673db15ea7d60754ed43ea7add74472690bc1d880e68e349768755011beb517",
        weight: 1,
        description_x:
          "LSAC provides the overall cumulative GPA that is used in the law school admissions process",
        human_readable_id: 387,
        n_tokens: 193,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "c72dae2a-e2ac-4a7a-b129-945bfce3e174",
      source: {
        id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
        name: "LSAC",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "35f75903-025d-473a-a6b5-e87fa63a9ed8",
        name: "LAW SCHOOL ADMISSIONS",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
      target_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      data: {
        text_unit_ids:
          "0af272eccec360e473476d195fb88b16c4e716ed2c105e49f4de5317d814af246673db15ea7d60754ed43ea7add74472690bc1d880e68e349768755011beb517",
        weight: 6,
        description_x:
          "The applicant's overall cumulative GPA is calculated by LSAC and used in the admissions process",
        human_readable_id: 388,
        n_tokens: 193,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "70557e8a-8af3-4b1c-83b5-cdaeb77b3b59",
      source: {
        id: "5c2a1182-f4bd-4bad-9437-d7082baf4c86",
        name: "LSAC",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
      target_id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
      data: {
        text_unit_ids:
          "bbcd90222e5ad69e9cfb11aafe7df34f2a84e7d5319e629b2d27b33f7839169992009be2ed32e2590ccfc658d79fc0a0a3d96771da66e0bf545b73197e392282",
        weight: 8,
        description_x:
          "The strategies are designed to enhance applications to law schools by focusing on alignment with personal aspirations",
        human_readable_id: 136,
        n_tokens: 166,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "44d1360b-9a81-4e7b-a6dc-f64ba7d08bea",
      source: {
        id: "36b7aa74-2825-47bf-b9cc-e958d2fce44d",
        name: "LAW SCHOOLS",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
        name: "LAW SCHOOL APPLICATION STRATEGIES",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      target_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 7,
        description_x:
          "The applicant's GPA is a critical factor in law school admissions",
        human_readable_id: 19,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "b6407cf9-e92f-45f4-a8f6-8113fce3918d",
      source: {
        id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
        name: "GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      target_id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 9,
        description_x:
          "The overall cumulative GPA is a comprehensive measure of an applicant's academic performance",
        human_readable_id: 20,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "b6836fd4-c0aa-4f5d-8b6c-f64a3f1cf7c8",
      source: {
        id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
        name: "GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
        name: "OVERALL CUMULATIVE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      target_id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
      data: {
        text_unit_ids:
          "13a329a709d64c19d6f08becd6794be7eb924c0a9a3dd05124710053364925d7c23660449f5ebd0c5d0ff9847c782f4e9e9928cdb2930c46bfc0d4383f4abdd2",
        weight: 8,
        description_x:
          "GPA is a critical component of the law school application process",
        human_readable_id: 49,
        n_tokens: 203,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "c8387240-2c87-4048-af08-f5de56463a68",
      source: {
        id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
        name: "GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
        name: "LAW SCHOOL APPLICATION PROCESS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
      target_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 8,
        description_x:
          "Law schools consider GPA as a significant factor in the admissions process",
        human_readable_id: 419,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "b0946bb7-f0b6-4d45-a5f7-6b7d42af8912",
      source: {
        id: "b187bfc2-0949-4e76-a94b-7e26cd29f81e",
        name: "GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      target_id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 6,
        description_x:
          "The applicant's degree GPA may differ from their overall cumulative GPA",
        human_readable_id: 21,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "3c716b43-a288-40ab-997f-a22dd146aede",
      source: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
        name: "DEGREE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
      target_id: "44302998-0a5d-4638-820d-ef3c5c8db75f",
      data: {
        text_unit_ids:
          "0af272eccec360e473476d195fb88b16c4e716ed2c105e49f4de5317d814af246673db15ea7d60754ed43ea7add74472690bc1d880e68e349768755011beb517",
        weight: 7,
        description_x:
          "Applicants' GPAs and personal statements are evaluated by admissions offices during the law school admissions process",
        human_readable_id: 390,
        n_tokens: 193,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "e8761edc-78a7-4069-8f29-57da8bb73284",
      source: {
        id: "d8f2ba60-1846-4f04-892e-1fa918b586ef",
        name: "APPLICANT",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "44302998-0a5d-4638-820d-ef3c5c8db75f",
        name: "ADMISSIONS OFFICES",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
      target_id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
      data: {
        text_unit_ids:
          "87a62af2102f074a6bc44c11f1bf00f3ef388a3ebbc59d2791eb7dc62cf9e66a135e5086c1d2bf8c70900ff2804c949de66af37d99ee23b7026a131de8c14118",
        weight: 1,
        description_x:
          "The degree GPA may differ from the overall cumulative GPA, which is used by law schools",
        human_readable_id: 22,
        n_tokens: 179,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "ff31413a-cd3c-4e5b-b518-73e4be4a064e",
      source: {
        id: "20bbac2d-af1f-4275-a47b-f488490c09aa",
        name: "OVERALL CUMULATIVE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "410ffba0-a58c-4c53-a6c1-9e60729542a1",
        name: "DEGREE GPA",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
      target_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 8,
        description_x:
          "Facebook Marketplace was the primary source for acquiring furniture and decor items for the apartment transformation",
        human_readable_id: 24,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "a4b90cf3-4873-4fe0-b49e-586f84620468",
      source: {
        id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
        name: "FACEBOOK MARKETPLACE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 8,
        description_x:
          "Furniture and decor for the studio apartment makeover were predominantly sourced from Facebook Marketplace",
        human_readable_id: 148,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "0e524388-98ca-4b91-b931-cf063d897760",
      source: {
        id: "a368d4cf-c2a7-4b45-9c15-7afd39517061",
        name: "FACEBOOK MARKETPLACE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 1,
        description_x:
          "IKEA provided curtains and a vintage sofa that were used in the apartment transformation",
        human_readable_id: 25,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "45c4c05a-754e-46c0-9d6d-3de153bad70c",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 9,
        description_x:
          "The studio apartment was the location where the transformation took place",
        human_readable_id: 26,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "62e1719d-0474-4442-856d-79488ab0c8b1",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "1ea4b3a8-4d69-454a-a4bf-ea8a9e39ec1f",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 6,
        description_x:
          "The dresser was the most expensive item purchased for the apartment transformation",
        human_readable_id: 27,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "efa552ff-522f-4eb2-a531-bf0a52acfe85",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "1ea4b3a8-4d69-454a-a4bf-ea8a9e39ec1f",
        name: "DRESSER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "247ff7d9-2b9a-43ea-947e-a1cff045cbdb",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 5,
        description_x:
          "The rug was one of the items sourced for the apartment transformation",
        human_readable_id: 28,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "f7a4468c-d8d1-40a2-b878-ba60c9865e7d",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "247ff7d9-2b9a-43ea-947e-a1cff045cbdb",
        name: "RUG",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "78b26078-9166-4606-8a31-69063e11da26",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 7,
        description_x:
          "The headboard was constructed and reupholstered as part of the apartment transformation",
        human_readable_id: 29,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "05b6c3f8-bffb-4324-aafe-5d4d606697bf",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "78b26078-9166-4606-8a31-69063e11da26",
        name: "HEADBOARD",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "a1f9ad59-42ed-48b6-baed-52f689359d7e",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 6,
        description_x:
          "The queen-sized bed frame was purchased for the apartment transformation",
        human_readable_id: 30,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "9c38aff7-cb96-44d6-ab4f-d231a883b1f1",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "a1f9ad59-42ed-48b6-baed-52f689359d7e",
        name: "QUEEN-SIZED BED FRAME",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 5,
        description_x:
          "The coffee table was purchased for the apartment transformation",
        human_readable_id: 31,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "cd9a4938-91ea-4f88-a1e3-fe3192656725",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
        name: "COFFEE TABLE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 5,
        description_x:
          "The floor lamp was purchased for the apartment transformation",
        human_readable_id: 32,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "7ca35723-9c04-4f9b-8ad9-1d6b9563f19f",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
        name: "FLOOR LAMP",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
      target_id: "10ff1090-9b1e-4259-ae3e-a68ec9d8d889",
      data: {
        text_unit_ids:
          "be6b343b4dbe9c5cbce6a68305fc602a649c9b79d197714c5e4cd9504b597726278ec83ca88e17fe6e4fbc8889468c4fe86a944a77809ea3c7d0a7e25d7dad86",
        weight: 1,
        description_x:
          "The dining chairs were reupholstered and used in the apartment transformation",
        human_readable_id: 33,
        n_tokens: 208,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "356522da-b732-4fff-b771-40af8472cc13",
      source: {
        id: "521fb9b3-2b92-4980-93cd-817b62db7c14",
        name: "APARTMENT TRANSFORMATION",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "10ff1090-9b1e-4259-ae3e-a68ec9d8d889",
        name: "DINING CHAIRS",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "IKEA curtains were used in the studio apartment makeover",
        human_readable_id: 149,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "9f7f84c3-437b-4f68-a114-0afcd0f624b6",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "0d71d569-7880-49fe-a7fe-9bac9ffb2610",
      data: {
        text_unit_ids:
          "134531d8b295df766d684a1edcee008e8386f575e74ea90a5e6f53ba0733c943783b710a17836ebee4d6971039227fe65dac8df7de870afe95d74c550790fad0",
        weight: 1,
        description_x:
          "IKEA provides the closet system that is being assembled",
        human_readable_id: 161,
        n_tokens: 70,
        title: "josi on TikTok",
        description_y:
          "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
        url: "https://www.tiktokv.com/share/video/7461773312218107166/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7461773312218107166.mp4",
        video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
        time_saved: "2025-01-21 04:57:39",
      },
      id: "3c37bc2a-f742-41b7-84ff-b9658be4325e",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "0d71d569-7880-49fe-a7fe-9bac9ffb2610",
        name: "CLOSET ASSEMBLY",
        group: 1,
        data: {
          title: "josi on TikTok",
          description:
            "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
          url: "https://www.tiktokv.com/share/video/7461773312218107166/",
          video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
          time_saved: "2025-01-21 04:57:39",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "580d612f-f12c-4dd2-b50b-db3caba3552b",
      data: {
        text_unit_ids:
          "134531d8b295df766d684a1edcee008e8386f575e74ea90a5e6f53ba0733c943783b710a17836ebee4d6971039227fe65dac8df7de870afe95d74c550790fad0",
        weight: 1,
        description_x: "The individual is assembling a product from IKEA",
        human_readable_id: 162,
        n_tokens: 70,
        title: "josi on TikTok",
        description_y:
          "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
        url: "https://www.tiktokv.com/share/video/7461773312218107166/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7461773312218107166.mp4",
        video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
        time_saved: "2025-01-21 04:57:39",
      },
      id: "522cd1cb-b30c-4f5c-a776-66f7a7ad8794",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "580d612f-f12c-4dd2-b50b-db3caba3552b",
        name: "INDIVIDUAL",
        group: 1,
        data: {
          title: "josi on TikTok",
          description:
            "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
          url: "https://www.tiktokv.com/share/video/7461773312218107166/",
          video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
          time_saved: "2025-01-21 04:57:39",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
      data: {
        text_unit_ids:
          "2a6c470016d3024a16884c76dcfddbfda422dc02ef1ed818f1ba39bcab928c2e3eaa00444b87f5af64ed26fc3dd6528e94faf1b68ac0bd717b33dbdc305f0747",
        weight: 1,
        description_x:
          "IKEA manufactures and sells the PAX wardrobe system, which is part of their product line",
        human_readable_id: 180,
        n_tokens: 47,
        title: "B S Y ✩ on TikTok",
        description_y:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7220431692467604779.mp4",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
      id: "418c3062-d7ac-4359-ad77-2e3179c860b9",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
        name: "PAX WARDROBE SYSTEM",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
    },
    {
      source_id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
      target_id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
      data: {
        text_unit_ids:
          "2a6c470016d3024a16884c76dcfddbfda422dc02ef1ed818f1ba39bcab928c2e3eaa00444b87f5af64ed26fc3dd6528e94faf1b68ac0bd717b33dbdc305f0747",
        weight: 1,
        description_x:
          "The assembly demonstration is organized by IKEA to highlight their PAX wardrobe system",
        human_readable_id: 181,
        n_tokens: 47,
        title: "B S Y ✩ on TikTok",
        description_y:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7220431692467604779.mp4",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
      id: "a16c7cba-b086-44f4-861a-83bed4a9e39b",
      source: {
        id: "84d82fe3-5e9f-45d4-a6d3-850248c082b2",
        name: "IKEA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
        name: "ASSEMBLY DEMONSTRATION",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
      data: {
        text_unit_ids:
          "45360f7e54423c62dfa2a8404189af7bea9ddf29939ddee2cb38d350d85f558968d60f13a773ec824d07f100f082fa240ac31f2347e5b86022376e7153c28bb6",
        weight: 8,
        description_x: "The studio apartment is located in New York City",
        human_readable_id: 80,
        n_tokens: 150,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7439095695274970410.mp4",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
      id: "4de6973a-3b69-40f3-8406-8ac01e7c5450",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
        name: "NEW YORK CITY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      data: {
        text_unit_ids:
          "6f44efc1e5599054486b52d3a68f69f07b0a2b238b02e4ba5ebedb76b897ade033a084833ad84c4f521e3fc177a98276d970612946be1712ae1121898552fb4e",
        weight: 7,
        description_x:
          "The studio apartment is located in the West Loop neighborhood of Chicago",
        human_readable_id: 272,
        n_tokens: 81,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "3eb0fd90-aec1-40e3-89e3-13d25e0b4994",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "e6cecea6-ed85-46a9-b206-2d94f868ef78",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 5,
        description_x:
          "The studio apartment features Hayao Miyazaki sketches as part of its personalized decor",
        human_readable_id: 425,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "356dc014-6a3a-4201-a8db-6048c6585cf1",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "e6cecea6-ed85-46a9-b206-2d94f868ef78",
        name: "HAYAO MIYAZAKI",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "66c1682e-6aac-4e5e-9f2f-0e0914522912",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 4,
        description_x:
          "The studio apartment includes a vintage car poster as part of its decor, reflecting personal taste",
        human_readable_id: 426,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "ffddd16e-039d-4128-a407-954a024374b8",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "66c1682e-6aac-4e5e-9f2f-0e0914522912",
        name: "VINTAGE CAR POSTER",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "d02e66f8-5679-4be9-afbf-159468f31e8f",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 1,
        description_x:
          "The studio apartment features a basketball-turned-planter, showcasing creativity in decoration",
        human_readable_id: 427,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "a98199f2-cc7d-498b-8226-e3701cf47180",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "d02e66f8-5679-4be9-afbf-159468f31e8f",
        name: "BASKETBALL-TURNED-PLANTER",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "7f0d8a00-39f4-421e-bff4-df57f230054c",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 11,
        description_x:
          "The studio apartment features plants as an integral part of its decor, contributing to a personalized and enhanced atmosphere. These plants are used as part of the personal decorations, adding a touch of nature and individuality to the living space.",
        human_readable_id: 428,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "59b45a39-97f5-495f-a452-ced105631f01",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "7f0d8a00-39f4-421e-bff4-df57f230054c",
        name: "PLANTS",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "1f55aecf-83b8-4835-9afe-cec8999e4a24",
      data: {
        text_unit_ids:
          "8ec3039befc29936665c9e352ab2e3c84c6cf828522347f67ed56f015361a4bde060a30c3a2a6667fa70d7e3df114ebb2ab5f8222f0f966cc8000836634ccc93",
        weight: 1,
        description_x:
          "The studio apartment features skateboard art, reflecting the occupant's personal style",
        human_readable_id: 429,
        n_tokens: 103,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "2700d520-fbab-44ff-9680-3d76517c1edf",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "1f55aecf-83b8-4835-9afe-cec8999e4a24",
        name: "SKATEBOARD ART",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "736383b0-a597-43d1-b94a-b99a30aad2bc",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 7,
        description_x:
          "The skateboard wall art is part of the personal decorations in the studio apartment",
        human_readable_id: 444,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "2cdfb61d-765c-4703-9bb1-aa1e8fbbcdd8",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "736383b0-a597-43d1-b94a-b99a30aad2bc",
        name: "SKATEBOARD WALL ART",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "55ad87d3-a81b-4243-9f74-c4237a5dcbbf",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 1,
        description_x:
          "Designer furniture is used to enhance the aesthetic of the studio apartment",
        human_readable_id: 445,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "d61c5eb9-f314-4f8d-ab23-ff55f809c86c",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "55ad87d3-a81b-4243-9f74-c4237a5dcbbf",
        name: "DESIGNER FURNITURE",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
      target_id: "e417a76f-fa74-4725-81b2-369ed3108677",
      data: {
        text_unit_ids:
          "72bbb4aaaab2c9c5d714f3874b0fe19ad5662d6ab4ffcc4f07e4aafdb4652d3d3318de4b7dab049532f08d71b4ec4ef3542bb6f62f1d38581fccd57dd394e640",
        weight: 1,
        description_x:
          "The studio apartment evokes a personal connection and sense of ownership in its inhabitants",
        human_readable_id: 446,
        n_tokens: 110,
        title: "gaby ♊︎ on TikTok",
        description_y: "the girl cave lately🎀 #studiotour #apartmenttour",
        url: "https://www.tiktokv.com/share/video/7459882855058001198/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459882855058001198.mp4",
        video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
        time_saved: "2025-02-02 03:33:26",
      },
      id: "c261347a-2dcc-4e7a-8747-cf97623771dc",
      source: {
        id: "d92c8a08-87a7-48fd-a03d-b194ef63238c",
        name: "STUDIO APARTMENT",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "e417a76f-fa74-4725-81b2-369ed3108677",
        name: "PERSONAL CONNECTION",
        group: 1,
        data: {
          title: "gaby ♊︎ on TikTok",
          description: "the girl cave lately🎀 #studiotour #apartmenttour",
          url: "https://www.tiktokv.com/share/video/7459882855058001198/",
          video_id: "3569e86a-0079-40cc-9089-41858bde5c6f",
          time_saved: "2025-02-02 03:33:26",
        },
      },
    },
    {
      source_id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
      target_id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
      data: {
        text_unit_ids:
          "48103e90f4a74e7e5c7854cee9e8c9576b5f615e181e40ace15173d1f327b307371c24cdd55cc4564508c2189ce81b8096e77c17e0821c421907a12d836a8bfc",
        weight: 6,
        description_x:
          "The coffee table complements the sofa in the living room transformation",
        human_readable_id: 67,
        n_tokens: 111,
        title: "Brianna Via on TikTok",
        description_y:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7395342834523475231.mp4",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
      id: "f7ff41b2-c78f-4076-8fb6-7e6fa8bf8c7f",
      source: {
        id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
        name: "COFFEE TABLE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
        name: "LIVING ROOM TRANSFORMATION",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
    },
    {
      source_id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "The coffee table was purchased and used in the studio apartment makeover",
        human_readable_id: 154,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "d621d072-11c9-419e-b211-7ff2ad74907f",
      source: {
        id: "872adb5e-c3b7-4b34-8089-8a7d0ab9ecda",
        name: "COFFEE TABLE",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
      target_id: "8a883164-b2f1-4248-a153-794456519049",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "The floor lamp was acquired and used in the studio apartment makeover",
        human_readable_id: 155,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "ab3eb3c5-36b3-48fd-905b-3243fb5a63f2",
      source: {
        id: "acfc4d68-1785-40ac-a657-9e57bbebba3b",
        name: "FLOOR LAMP",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "9258dd90-0e89-4c8d-b1ac-8cda47b7b120",
      target_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      data: {
        text_unit_ids:
          "9c4dc82e08b1798d635d70f20821fe02e8ab364cd24cdb2c7b1b974db017c382fd63be5c20507f837e015efbc5d85ed31a6902f85a8dd2b42a599b5cb7876381",
        weight: 1,
        description_x:
          "PT61 Section 4 Question 11 was used as a study tool to improve LSAT performance",
        human_readable_id: 34,
        n_tokens: 119,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "42845ad2-6f56-4f5e-b2fc-a2763848aeef",
      source: {
        id: "9258dd90-0e89-4c8d-b1ac-8cda47b7b120",
        name: "PT61 SECTION 4 QUESTION 11",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "9258dd90-0e89-4c8d-b1ac-8cda47b7b120",
      target_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      data: {
        text_unit_ids:
          "9c4dc82e08b1798d635d70f20821fe02e8ab364cd24cdb2c7b1b974db017c382fd63be5c20507f837e015efbc5d85ed31a6902f85a8dd2b42a599b5cb7876381",
        weight: 7,
        description_x:
          "PT61 Section 4 Question 11 was used to practice and understand conditional logic",
        human_readable_id: 35,
        n_tokens: 119,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "c303dcff-261e-4cc7-a9b2-cba9772c403b",
      source: {
        id: "9258dd90-0e89-4c8d-b1ac-8cda47b7b120",
        name: "PT61 SECTION 4 QUESTION 11",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "67747bef-0dbd-4db7-8499-03cdcfccda8d",
      data: {
        text_unit_ids:
          "9c4dc82e08b1798d635d70f20821fe02e8ab364cd24cdb2c7b1b974db017c382fd63be5c20507f837e015efbc5d85ed31a6902f85a8dd2b42a599b5cb7876381",
        weight: 1,
        description_x:
          "The score improvement was specifically related to the LSAT",
        human_readable_id: 36,
        n_tokens: 119,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "0c13ddb9-6691-491f-9e39-2d472b315761",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "67747bef-0dbd-4db7-8499-03cdcfccda8d",
        name: "SCORE IMPROVEMENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
      data: {
        text_unit_ids:
          "13a329a709d64c19d6f08becd6794be7eb924c0a9a3dd05124710053364925d7c23660449f5ebd0c5d0ff9847c782f4e9e9928cdb2930c46bfc0d4383f4abdd2",
        weight: 8,
        description_x:
          "LSAT scores are a critical component of the law school application process",
        human_readable_id: 50,
        n_tokens: 203,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "f97d1eda-41f5-417d-a20c-b38e36b14da5",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
        name: "LAW SCHOOL APPLICATION PROCESS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "108284c7-c07b-4918-9f6b-f2e01adb6c04",
      data: {
        text_unit_ids:
          "3e8ab88f7a16413fca2e44432ee266ce4a62e31c32efc1bcca6c5a35ec26a56d3a1285d264843c73ab933b37f1a16ad41774c68509feaa6d385a199aec75b24f",
        weight: 1,
        description_x:
          "The LSAT 27-18-9 rule is a strategy specifically designed for the reading comprehension sections of the LSAT",
        human_readable_id: 187,
        n_tokens: 121,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "50405688-f5bc-448d-a265-364b5833e54f",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "108284c7-c07b-4918-9f6b-f2e01adb6c04",
        name: "LSAT 27-18-9 RULE",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      data: {
        text_unit_ids:
          "be76a1998ebb376e29c86476a8c22d864a888777e91043e7c2bd9ba5f5f8dbecd677372a5580d4bd8811a412ae4925e149503c45bee536c865cbf1354db09493",
        weight: 1,
        description_x:
          "The content creator has scored 170+ on the LSAT and uses the prediction technique to achieve high scores",
        human_readable_id: 243,
        n_tokens: 167,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "d333b934-49c0-4697-9615-eb78464a6b1d",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
      data: {
        text_unit_ids:
          "be76a1998ebb376e29c86476a8c22d864a888777e91043e7c2bd9ba5f5f8dbecd677372a5580d4bd8811a412ae4925e149503c45bee536c865cbf1354db09493",
        weight: 8,
        description_x:
          "The prediction technique is used to improve performance on the LSAT by anticipating answers",
        human_readable_id: 244,
        n_tokens: 167,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "16baa59c-6355-46b5-933c-58c2ed79ec17",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
        name: "PREDICTION TECHNIQUE",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "6b9a53d4-08eb-4958-8043-782a97ea8685",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 7,
        description_x:
          "The diagnostic test is used to prepare for the LSAT by mimicking real testing conditions",
        human_readable_id: 266,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "13a7dd68-4d1c-441e-b196-54dad915e0c2",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "6b9a53d4-08eb-4958-8043-782a97ea8685",
        name: "DIAGNOSTIC TEST",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 8,
        description_x:
          "Practice tests are used to improve skills and prepare for the LSAT",
        human_readable_id: 267,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "b20f8d7c-23e6-46ad-b23f-9b15f2a86803",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
        name: "PRACTICE TESTS",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
      data: {
        text_unit_ids:
          "d95c3c4ea523b5ebb3cc294662537b1423fbea7962ff817fa5f519f9528ce52f31959c127b9943c4c25fd7b23ca1aa6cc7e40b6947dadfca273424bdb1aa68e3",
        weight: 16,
        description_x:
          "LSAT Demon provides resources and practice for preparing for the LSAT",
        human_readable_id: 366,
        n_tokens: 270,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "1e5d9e27-5c02-4b2a-b3a5-2a7453cf9ac5",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
        name: "LSAT DEMON",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "5db23971-9150-4e2c-9238-0ef2f472ae25",
      data: {
        text_unit_ids:
          "d95c3c4ea523b5ebb3cc294662537b1423fbea7962ff817fa5f519f9528ce52f31959c127b9943c4c25fd7b23ca1aa6cc7e40b6947dadfca273424bdb1aa68e3",
        weight: 2,
        description_x: "Law Hub offers practice assessments for the LSAT",
        human_readable_id: 367,
        n_tokens: 270,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "1ba0a1b7-5bcd-4c49-89fa-c87a4b9602a3",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "5db23971-9150-4e2c-9238-0ef2f472ae25",
        name: "LAW HUB",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      data: {
        text_unit_ids:
          "56e28516d7dc35b88ef8eb023f57f849c30e13220479fa2023a9c5a590a068461c684207a0b4fa10c77a9d71b3f0f3a09790471190b553286d2aa2d68f980624",
        weight: 8,
        description_x:
          "7Sage provides study tools and methods specifically for preparing for the LSAT",
        human_readable_id: 404,
        n_tokens: 154,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "ac412570-72e1-4ccb-b5b5-823d3007184b",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
      data: {
        text_unit_ids:
          "56e28516d7dc35b88ef8eb023f57f849c30e13220479fa2023a9c5a590a068461c684207a0b4fa10c77a9d71b3f0f3a09790471190b553286d2aa2d68f980624",
        weight: 8,
        description_x:
          "Manhattan Prep offers resources such as practice exams and forums to help students prepare for the LSAT",
        human_readable_id: 406,
        n_tokens: 154,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "2e22b79f-9c2d-4135-8d76-723be66f73c2",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
        name: "MANHATTAN PREP",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "d076b170-b018-4169-bd71-f4d76d0e836c",
      data: {
        text_unit_ids:
          "56e28516d7dc35b88ef8eb023f57f849c30e13220479fa2023a9c5a590a068461c684207a0b4fa10c77a9d71b3f0f3a09790471190b553286d2aa2d68f980624",
        weight: 1,
        description_x:
          "PowerScore offers LSAT preparation books known as bibles, which are recommended for LSAT study",
        human_readable_id: 407,
        n_tokens: 154,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "508c1791-cbc9-49f2-9997-97fa5c7724ec",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "d076b170-b018-4169-bd71-f4d76d0e836c",
        name: "POWERSCORE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 1,
        description_x:
          "Law schools use LSAT scores as a key factor in admissions, especially for applicants with low GPAs",
        human_readable_id: 418,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "81a06f94-7ba6-4a07-9b18-b42b2c7bf88a",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "88931f4a-1767-42b2-85b6-bf89de48ab45",
      data: {
        text_unit_ids:
          "109e1e99734f9f4f0739e2f5910c9e6c83eeac26ee038bb0947cea664cb23d319c26c322ed2752c3e23bfa8afe05e9e7cff0b0ad14d5be7de3d30a92e8880c78",
        weight: 1,
        description_x:
          "170+ scorers use prediction techniques to achieve high scores on the LSAT",
        human_readable_id: 448,
        n_tokens: 143,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "4b7cefb9-9c4f-4a6f-bfb6-26dbb0a7b482",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "88931f4a-1767-42b2-85b6-bf89de48ab45",
        name: "170+ SCORER",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "317de687-12b7-4a49-92e0-b8215d5a8262",
      target_id: "990fcd5c-ac50-4898-9374-af3ede519997",
      data: {
        text_unit_ids:
          "109e1e99734f9f4f0739e2f5910c9e6c83eeac26ee038bb0947cea664cb23d319c26c322ed2752c3e23bfa8afe05e9e7cff0b0ad14d5be7de3d30a92e8880c78",
        weight: 7,
        description_x:
          "Prediction is emphasized as a critical skill for scoring high on the LSAT",
        human_readable_id: 449,
        n_tokens: 143,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "b2cc44dd-2dd3-4c0c-81c2-6607f395cfe5",
      source: {
        id: "317de687-12b7-4a49-92e0-b8215d5a8262",
        name: "LSAT",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "990fcd5c-ac50-4898-9374-af3ede519997",
        name: "PREDICTION",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      target_id: "67747bef-0dbd-4db7-8499-03cdcfccda8d",
      data: {
        text_unit_ids:
          "9c4dc82e08b1798d635d70f20821fe02e8ab364cd24cdb2c7b1b974db017c382fd63be5c20507f837e015efbc5d85ed31a6902f85a8dd2b42a599b5cb7876381",
        weight: 8,
        description_x:
          "Focusing on conditional logic was a significant factor in the student's score improvement",
        human_readable_id: 37,
        n_tokens: 119,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "e5e16a95-20fd-48c5-a8d9-a2452a559ee3",
      source: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "67747bef-0dbd-4db7-8499-03cdcfccda8d",
        name: "SCORE IMPROVEMENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      target_id: "0be68aec-af81-48b7-8f29-4989d53cf590",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 1,
        description_x:
          "Preptest 61, section 4, question 11, is used to review and master conditional logic",
        human_readable_id: 164,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "601a1b30-009d-4741-80b9-36b90a7d7cf7",
      source: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "0be68aec-af81-48b7-8f29-4989d53cf590",
        name: "PREPTEST 61",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      target_id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 8,
        description_x:
          "The student focused on mastering conditional logic to improve test scores",
        human_readable_id: 165,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "18f8dafc-6be0-4814-9658-d4d198440d3a",
      source: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
        name: "STUDENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
      target_id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 1,
        description_x:
          "Mastery of conditional logic led to significant test score improvement",
        human_readable_id: 166,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "7c13abb8-1e82-4dc9-afc6-0aa6fd5d7035",
      source: {
        id: "4e1aea00-d52b-4b33-bc6a-a8460aa55afa",
        name: "CONDITIONAL LOGIC",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
        name: "TEST SCORE IMPROVEMENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "bff1c9c7-2ba4-4cb3-a753-733cef2b65cc",
      target_id: "bf163a77-6eaf-4055-bc65-94c666b51973",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 5,
        description_x:
          "The recruiter screening is an early stage that leads to the verbal offer in the hiring process",
        human_readable_id: 38,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "414d0331-5f6b-4ce6-a985-2fdaa0c24937",
      source: {
        id: "bff1c9c7-2ba4-4cb3-a753-733cef2b65cc",
        name: "RECRUITER SCREENING",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "bf163a77-6eaf-4055-bc65-94c666b51973",
        name: "VERBAL OFFER",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "c54d5876-bf2f-49e6-bfce-debfdae23a54",
      data: {
        text_unit_ids:
          "d72939d6faac0799646155c9d4f76aed3aefd5547b44c6c48f16fbe54818d9ce63acec5daa8a150478887c23e7de500813a0869d53c99eb8bec5aade193e6a3c",
        weight: 7,
        description_x:
          "Law schools implement COVID safety precautions to ensure student safety",
        human_readable_id: 325,
        n_tokens: 248,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "1de68734-19d2-4e64-a88e-d696d11b4198",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "c54d5876-bf2f-49e6-bfce-debfdae23a54",
        name: "COVID SAFETY PRECAUTIONS",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "bf163a77-6eaf-4055-bc65-94c666b51973",
      target_id: "8a0dbafe-c622-42cc-9409-1f9b6e7b377c",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 1,
        description_x:
          "The verbal offer should include a six month promotion review as part of the contract",
        human_readable_id: 39,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "20c09e90-f635-4aff-8687-04e0b9afd45d",
      source: {
        id: "bf163a77-6eaf-4055-bc65-94c666b51973",
        name: "VERBAL OFFER",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "8a0dbafe-c622-42cc-9409-1f9b6e7b377c",
        name: "SIX MONTH PROMOTION REVIEW",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
      target_id: "5a933e1d-d70e-4a33-aeee-33749920651a",
      data: {
        text_unit_ids:
          "715add6ab86c4330107b2efb2716027a038529d8f14c3a075c86e5f90dba16273db99e54a1fc3456cc0aedadeb5201190d5b6153abe7d6ac7d9940e95b28f405",
        weight: 6,
        description_x:
          "Hiring budgets are managed by companies to control salary expenses",
        human_readable_id: 40,
        n_tokens: 253,
        title: "Dan From HR on TikTok",
        description_y:
          "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
        url: "https://www.tiktokv.com/share/video/7327309292078402859/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7327309292078402859.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:15:34",
      },
      id: "9b10431b-8bcf-479b-8e7e-886e14dea46d",
      source: {
        id: "da5ea0a0-1306-4b9a-8f96-4cb5008a6cd0",
        name: "COMPANIES",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
      target: {
        id: "5a933e1d-d70e-4a33-aeee-33749920651a",
        name: "HIRING BUDGETS",
        group: 1,
        data: {
          title: "Dan From HR on TikTok",
          description:
            "#Jobhunt #jobtips #career #careeradvice #resume #linkedin #linkedintips #resumetips #gethired #getpaid #salarynegotiation  Salary expectations salary transparency salary negotiation salarymen salary salary job interview salary expectatiom questions salary negotiation tips salary pay salary job interview salary expectations question salary negotiation offer compensation negotiation compensation expectations compensation review compensation package ",
          url: "https://www.tiktokv.com/share/video/7327309292078402859/",
          video_id: "0",
          time_saved: "2025-01-29 04:15:34",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 8,
        description_x:
          "The analysis compares the immigration policies and deportation rates of Obama and Trump. Obama's immigration policies were more lenient compared to Trump's, indicating a difference in approach between the two administrations. The comparison highlights the variations in policy implementation and enforcement under each leader.",
        human_readable_id: 43,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "aac511dd-a13e-4fd9-b576-fa6ea3aea59f",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
        name: "TRUMP",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "d1fb8152-a6df-4363-bd7c-a7d2aa4d13e6",
      data: {
        text_unit_ids:
          "19ecb522f1c9c3191c13545ce62668a7f1876d2f4903c7c93c2793b5211393736af06f4706b468675d3c2d4d5f91eabb0e2486cf9699891c13b09c4686f4fac4",
        weight: 6,
        description_x:
          "Obama's immigration policies are part of the critical analysis",
        human_readable_id: 44,
        n_tokens: 103,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "917228e0-d631-4783-bbd8-20ea90403bba",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "d1fb8152-a6df-4363-bd7c-a7d2aa4d13e6",
        name: "IMMIGRATION POLICIES",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "12134920-97aa-4217-af3a-f6366dd225a0",
      data: {
        text_unit_ids:
          "19ecb522f1c9c3191c13545ce62668a7f1876d2f4903c7c93c2793b5211393736af06f4706b468675d3c2d4d5f91eabb0e2486cf9699891c13b09c4686f4fac4",
        weight: 5,
        description_x:
          "Obama's administration dealt with immigration as a significant policy area",
        human_readable_id: 45,
        n_tokens: 103,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "f0875703-2811-4d34-9468-16719df7112d",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "12134920-97aa-4217-af3a-f6366dd225a0",
        name: "IMMIGRATION",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "3dfb8a5f-2b3f-4b3b-bf3d-bd76ea9feb9a",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 5,
        description_x:
          "Obama deported fewer illegal immigrants compared to George Bush",
        human_readable_id: 101,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "0abfb43d-e261-4c51-852a-9ba8284bea0c",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "3dfb8a5f-2b3f-4b3b-bf3d-bd76ea9feb9a",
        name: "GEORGE BUSH",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      target_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 3,
        description_x:
          "Resourcefulness is exemplified by platforms like juntosseguros.com that provide critical information on ICE raid locations",
        human_readable_id: 334,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "613cecfc-b3cb-41e6-abee-d24eccf39db7",
      source: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "57220f5f-6650-4761-9380-1564961e7a32",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 5,
        description_x:
          "Obama deported fewer illegal immigrants compared to Bill Clinton",
        human_readable_id: 102,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "e577ddf3-ddd7-4fab-b202-920975d9a93d",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "57220f5f-6650-4761-9380-1564961e7a32",
        name: "BILL CLINTON",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 6,
        description_x:
          "Obama deported fewer illegal immigrants compared to his predecessors",
        human_readable_id: 103,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "01639d6c-17fc-4c15-9db9-cf7fb9bc89dc",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
        name: "ILLEGAL IMMIGRANTS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "493dc633-c881-4f2d-b93a-74dd7a526c15",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 7,
        description_x:
          "Obama prioritized the deportation of criminal offenders",
        human_readable_id: 104,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "417957f5-fc83-43eb-b3b2-81b8b04ec15c",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "493dc633-c881-4f2d-b93a-74dd7a526c15",
        name: "CRIMINAL OFFENDERS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
      target_id: "491d310b-caef-4928-9a04-9eeee7e22235",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 8,
        description_x:
          "Obama implemented protections for younger illegal immigrants",
        human_readable_id: 105,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "e9fe1ffc-e82e-4ebd-b3a2-8dfc994dfbeb",
      source: {
        id: "05afa165-064f-46b2-99b5-3a8793f6ed9e",
        name: "OBAMA",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "491d310b-caef-4928-9a04-9eeee7e22235",
        name: "YOUNGER ILLEGAL IMMIGRANTS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
      target_id: "d1fb8152-a6df-4363-bd7c-a7d2aa4d13e6",
      data: {
        text_unit_ids:
          "19ecb522f1c9c3191c13545ce62668a7f1876d2f4903c7c93c2793b5211393736af06f4706b468675d3c2d4d5f91eabb0e2486cf9699891c13b09c4686f4fac4",
        weight: 1,
        description_x:
          "Trump's immigration policies are part of the critical analysis",
        human_readable_id: 46,
        n_tokens: 103,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "42bb8ff7-213b-4e4d-af5f-08fe6d9359e8",
      source: {
        id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
        name: "TRUMP",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "d1fb8152-a6df-4363-bd7c-a7d2aa4d13e6",
        name: "IMMIGRATION POLICIES",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
      target_id: "12134920-97aa-4217-af3a-f6366dd225a0",
      data: {
        text_unit_ids:
          "19ecb522f1c9c3191c13545ce62668a7f1876d2f4903c7c93c2793b5211393736af06f4706b468675d3c2d4d5f91eabb0e2486cf9699891c13b09c4686f4fac4",
        weight: 1,
        description_x:
          "Trump's administration dealt with immigration as a significant policy area",
        human_readable_id: 47,
        n_tokens: 103,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "6ad9065c-6009-4bbf-a1e0-dccaca3282f4",
      source: {
        id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
        name: "TRUMP",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "12134920-97aa-4217-af3a-f6366dd225a0",
        name: "IMMIGRATION",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "a2827a2b-a8b5-4694-87a3-8fe9ac273877",
      data: {
        text_unit_ids:
          "d72939d6faac0799646155c9d4f76aed3aefd5547b44c6c48f16fbe54818d9ce63acec5daa8a150478887c23e7de500813a0869d53c99eb8bec5aade193e6a3c",
        weight: 6,
        description_x:
          "Law schools may offer a public defender track as a focus area",
        human_readable_id: 326,
        n_tokens: 248,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "5a6ec16c-5ac1-4291-9bd8-5076f9539933",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "a2827a2b-a8b5-4694-87a3-8fe9ac273877",
        name: "PUBLIC DEFENDER TRACK",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
      target_id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
      data: {
        text_unit_ids:
          "47ae57d4740cfd8f88580a4da2d414296d3fb13a6197b428457a0ee3bf7ec66a557452c6a314638cb7b91cc5749532ad16a6f8573d60aa2cc1b61f95d492619d",
        weight: 1,
        description_x:
          "Trump had a stricter view on illegal immigrants, viewing them negatively",
        human_readable_id: 106,
        n_tokens: 152,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "5a61d425-0890-456f-aafb-d18185928059",
      source: {
        id: "6602fd62-9c53-497d-8f8e-2cb145449ac4",
        name: "TRUMP",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "ca7ab46d-7e3c-4399-be89-ad67aa6a8b7b",
        name: "ILLEGAL IMMIGRANTS",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "d1fb8152-a6df-4363-bd7c-a7d2aa4d13e6",
      target_id: "12134920-97aa-4217-af3a-f6366dd225a0",
      data: {
        text_unit_ids:
          "19ecb522f1c9c3191c13545ce62668a7f1876d2f4903c7c93c2793b5211393736af06f4706b468675d3c2d4d5f91eabb0e2486cf9699891c13b09c4686f4fac4",
        weight: 8,
        description_x:
          "Immigration is the broader context within which specific immigration policies are analyzed",
        human_readable_id: 48,
        n_tokens: 103,
        title: "Bofa Deez on TikTok",
        description_y:
          "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
        url: "https://www.tiktokv.com/share/video/7465129704861781290/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7465129704861781290.mp4",
        video_id: "0",
        time_saved: "2025-01-29 23:55:50",
      },
      id: "7b3a9619-29a5-449b-90dc-63b0e9efe8f1",
      source: {
        id: "d1fb8152-a6df-4363-bd7c-a7d2aa4d13e6",
        name: "IMMIGRATION POLICIES",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
      target: {
        id: "12134920-97aa-4217-af3a-f6366dd225a0",
        name: "IMMIGRATION",
        group: 1,
        data: {
          title: "Bofa Deez on TikTok",
          description:
            "I don’t remember seeing people not showing up for their jobs out of fear of deportation under the Obama presidency??  is it because the ones that didn’t commit crimes didn’t feel targeted but they do now? And don’t even come at me with the whole being an illegal immigrant is a crime in itself standpoint. The majority of this girl’s page is just taking things out of their respective context so that it makes sense in her world and she gets a bunch of positive feedback from her echo chamber, nothing more than that. ##fypシ##fypシ゚viral",
          url: "https://www.tiktokv.com/share/video/7465129704861781290/",
          video_id: "0",
          time_saved: "2025-01-29 23:55:50",
        },
      },
    },
    {
      source_id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
      target_id: "5bc03290-abdc-479e-85b7-1421c63bdefe",
      data: {
        text_unit_ids:
          "13a329a709d64c19d6f08becd6794be7eb924c0a9a3dd05124710053364925d7c23660449f5ebd0c5d0ff9847c782f4e9e9928cdb2930c46bfc0d4383f4abdd2",
        weight: 7,
        description_x:
          "A graded addendum can be part of the law school application process to explain low GPA",
        human_readable_id: 51,
        n_tokens: 203,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "9f3e4ac0-c4e1-4c23-ade1-29f564d88ca5",
      source: {
        id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
        name: "LAW SCHOOL APPLICATION PROCESS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
      target: {
        id: "5bc03290-abdc-479e-85b7-1421c63bdefe",
        name: "GRADED ADDENDUM",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
      target_id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
      data: {
        text_unit_ids:
          "13a329a709d64c19d6f08becd6794be7eb924c0a9a3dd05124710053364925d7c23660449f5ebd0c5d0ff9847c782f4e9e9928cdb2930c46bfc0d4383f4abdd2",
        weight: 1,
        description_x:
          "Letters of recommendation are an important part of the law school application process",
        human_readable_id: 52,
        n_tokens: 203,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "652b5f17-b933-4ef3-90ed-047040538a43",
      source: {
        id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
        name: "LAW SCHOOL APPLICATION PROCESS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
      target: {
        id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
        name: "LETTERS OF RECOMMENDATION",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
      target_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      data: {
        text_unit_ids:
          "13a329a709d64c19d6f08becd6794be7eb924c0a9a3dd05124710053364925d7c23660449f5ebd0c5d0ff9847c782f4e9e9928cdb2930c46bfc0d4383f4abdd2",
        weight: 9,
        description_x:
          "The law school application process is the procedure for applying to a law school",
        human_readable_id: 53,
        n_tokens: 203,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "558fe46f-400d-437d-b406-a566b530b0a6",
      source: {
        id: "e0469a18-0d52-44dc-8e26-fa687cdd22a0",
        name: "LAW SCHOOL APPLICATION PROCESS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
      target: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
      target_id: "68c1f5de-d525-4866-96d6-97af86730cf1",
      data: {
        text_unit_ids:
          "13a329a709d64c19d6f08becd6794be7eb924c0a9a3dd05124710053364925d7c23660449f5ebd0c5d0ff9847c782f4e9e9928cdb2930c46bfc0d4383f4abdd2",
        weight: 8,
        description_x:
          "Professors can write letters of recommendation for law school applicants",
        human_readable_id: 54,
        n_tokens: 203,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "fb159967-0833-4d4f-ab07-a1a137f4a460",
      source: {
        id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
        name: "LETTERS OF RECOMMENDATION",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
      target: {
        id: "68c1f5de-d525-4866-96d6-97af86730cf1",
        name: "PROFESSORS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
      target_id: "f6c43243-50ac-48c7-937e-07b7bd5c0441",
      data: {
        text_unit_ids:
          "13a329a709d64c19d6f08becd6794be7eb924c0a9a3dd05124710053364925d7c23660449f5ebd0c5d0ff9847c782f4e9e9928cdb2930c46bfc0d4383f4abdd2",
        weight: 1,
        description_x:
          "Employers can write letters of recommendation for law school applicants if professors are not available",
        human_readable_id: 55,
        n_tokens: 203,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "0a4e95f6-5337-4dea-a4b2-ce2893090fd9",
      source: {
        id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
        name: "LETTERS OF RECOMMENDATION",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
      target: {
        id: "f6c43243-50ac-48c7-937e-07b7bd5c0441",
        name: "EMPLOYER",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
      target_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 7,
        description_x:
          "Law schools value strong letters of recommendation to assess an applicant's potential",
        human_readable_id: 420,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "2a9b49be-054c-4d5c-9de6-71a980b43049",
      source: {
        id: "e2356c51-fdda-416c-9ebd-f9be0c1e2ec2",
        name: "LETTERS OF RECOMMENDATION",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
      target: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 1,
        description_x:
          "The essay connects the journey to law school with themes of white liberalism, providing thought-provoking commentary",
        human_readable_id: 107,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "10a87b8f-0f46-4d7f-a28e-304ef6cd9118",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
        name: "WHITE LIBERALISM",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 3,
        description_x:
          "The essay connects the journey to the specific law school application",
        human_readable_id: 108,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "289f8a14-7688-4b99-bae4-29d5b4fbdca9",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
        name: "ESSAY",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 7,
        description_x:
          "The content creator provides strategies specifically for prospective law school applicants",
        human_readable_id: 196,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "07690b0b-c17d-4b88-8830-93f2ece3be93",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 8,
        description_x:
          "Admissions officers are responsible for evaluating applications to law schools",
        human_readable_id: 201,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "93610c58-da5e-4336-a43d-5ce73396f142",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
        name: "ADMISSIONS OFFICERS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 1,
        description_x:
          "Interviews are part of the law school application process",
        human_readable_id: 202,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "e9ac742b-2310-424a-989b-30572d85692b",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
        name: "INTERVIEWS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "e9ce7003-de15-45ad-8e94-2a7b5e6f8c99",
      data: {
        text_unit_ids:
          "a30161d8fe31e3170b36271f8b4b8831473596321e03760d93fc9b74b81c522555b12fe8ad1da160db20ee7573ca5ce5b056c39f16870382d69de1f73e866bae",
        weight: 1,
        description_x:
          "The personal statement discusses the author's journey to law school and critiques the notion that Ivy League schools are the only places to pursue racial justice",
        human_readable_id: 250,
        n_tokens: 269,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "b7d2d9b2-ef6d-4c01-8907-99c1ca70b8c2",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "e9ce7003-de15-45ad-8e94-2a7b5e6f8c99",
        name: "IVY LEAGUE SCHOOLS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "ff3329fc-8218-4cec-8e7b-8a1d8f5afc88",
      data: {
        text_unit_ids:
          "a30161d8fe31e3170b36271f8b4b8831473596321e03760d93fc9b74b81c522555b12fe8ad1da160db20ee7573ca5ce5b056c39f16870382d69de1f73e866bae",
        weight: 8,
        description_x:
          "The personal statement is a key component of the law school application, explaining the applicant's motivations and contributions",
        human_readable_id: 252,
        n_tokens: 269,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "6e24b728-abc2-4767-937a-1fb20d44c5b6",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "ff3329fc-8218-4cec-8e7b-8a1d8f5afc88",
        name: "PERSONAL STATEMENT",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "c200c460-0241-4f82-8045-033bfdb5ca62",
      data: {
        text_unit_ids:
          "d72939d6faac0799646155c9d4f76aed3aefd5547b44c6c48f16fbe54818d9ce63acec5daa8a150478887c23e7de500813a0869d53c99eb8bec5aade193e6a3c",
        weight: 8,
        description_x: "The first year of law school is referred to as One L",
        human_readable_id: 324,
        n_tokens: 248,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "47f9d05c-b395-4618-82ee-0988e8d73197",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "c200c460-0241-4f82-8045-033bfdb5ca62",
        name: "ONE L",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "80c11db7-2d9d-41f4-bb88-b77f01410a28",
      data: {
        text_unit_ids:
          "d72939d6faac0799646155c9d4f76aed3aefd5547b44c6c48f16fbe54818d9ce63acec5daa8a150478887c23e7de500813a0869d53c99eb8bec5aade193e6a3c",
        weight: 6,
        description_x:
          "Law schools may offer a business law track as a focus area",
        human_readable_id: 327,
        n_tokens: 248,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "05cdc44b-cff2-4448-a155-3cc938c4efc1",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "80c11db7-2d9d-41f4-bb88-b77f01410a28",
        name: "BUSINESS LAW TRACK",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "a33e1cbb-96d3-410c-8d49-28bd61a8c3f3",
      data: {
        text_unit_ids:
          "d72939d6faac0799646155c9d4f76aed3aefd5547b44c6c48f16fbe54818d9ce63acec5daa8a150478887c23e7de500813a0869d53c99eb8bec5aade193e6a3c",
        weight: 6,
        description_x: "Law schools may offer a tax track as a focus area",
        human_readable_id: 328,
        n_tokens: 248,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "ea2fce54-bf0b-4829-aec1-77e149aec2b4",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "a33e1cbb-96d3-410c-8d49-28bd61a8c3f3",
        name: "TAX TRACK",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "47d424aa-88cf-4021-9d06-909b5ca39677",
      data: {
        text_unit_ids:
          "d72939d6faac0799646155c9d4f76aed3aefd5547b44c6c48f16fbe54818d9ce63acec5daa8a150478887c23e7de500813a0869d53c99eb8bec5aade193e6a3c",
        weight: 6,
        description_x:
          "Law schools may offer an entertainment law track as a focus area",
        human_readable_id: 329,
        n_tokens: 248,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "b6394ab1-f007-4946-bb46-bb06149afdc1",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "47d424aa-88cf-4021-9d06-909b5ca39677",
        name: "ENTERTAINMENT LAW TRACK",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "d10397ae-7d40-43e6-a6e2-937551359e3a",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 8,
        description_x:
          "Current law students provide insights into the academic and cultural environment of the law school",
        human_readable_id: 342,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "3f5ac81a-abd6-4e31-8cbc-4e5741b0beaa",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "d10397ae-7d40-43e6-a6e2-937551359e3a",
        name: "CURRENT LAW STUDENTS",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "a93e0095-438f-4454-bcba-cc6358809b6a",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 7,
        description_x:
          "The law school uses a grading curve to assess student performance",
        human_readable_id: 343,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "14cdaaf2-47fd-4cde-bdcb-e718283f6b9d",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "a93e0095-438f-4454-bcba-cc6358809b6a",
        name: "GRADING CURVE",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "786a93ce-2abc-4dec-94b6-f572392675e9",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 8,
        description_x:
          "The law school offers experiential learning opportunities to enhance practical skills",
        human_readable_id: 344,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "e589fa12-bc28-4836-824e-e20c92eda5a3",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "786a93ce-2abc-4dec-94b6-f572392675e9",
        name: "EXPERIENTIAL LEARNING OPPORTUNITIES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "d9073a6c-2788-49c9-baf6-1014d7fa4132",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 9,
        description_x:
          "The law school implements COVID-19 safety measures to protect students",
        human_readable_id: 345,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "3af659fb-ff4f-4438-a7e3-23c9969b0b9c",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "d9073a6c-2788-49c9-baf6-1014d7fa4132",
        name: "COVID-19 SAFETY MEASURES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "220222e1-f217-4217-8ba2-faee2c864608",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x:
          "The law school offers a focus area in public defender work",
        human_readable_id: 346,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "010f0b85-d2b6-4a07-a343-0e99c975f040",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "220222e1-f217-4217-8ba2-faee2c864608",
        name: "PUBLIC DEFENDER",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "9e7d8f3c-0e5b-454a-a729-c8d16a4fe2e6",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x: "The law school offers a focus area in criminal law",
        human_readable_id: 347,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "5311cf52-dbb6-402a-9ac5-91fdf4b78660",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "9e7d8f3c-0e5b-454a-a729-c8d16a4fe2e6",
        name: "CRIMINAL LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "f48012d4-dcfc-4f05-ba28-d003dd429c25",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x: "The law school offers a focus area in business law",
        human_readable_id: 348,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "b0c92d45-8946-4d9d-9488-80959d811560",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "f48012d4-dcfc-4f05-ba28-d003dd429c25",
        name: "BUSINESS LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "a899167f-13d7-4776-95ec-dc4305aab0ac",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x: "The law school offers a focus area in tax law",
        human_readable_id: 349,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "d7ab3254-6e3f-43e4-a7e1-71833434aea4",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "a899167f-13d7-4776-95ec-dc4305aab0ac",
        name: "TAX LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "103c65e8-5adc-410f-8189-89de75e4dc04",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 6,
        description_x:
          "The law school offers a focus area in entertainment law",
        human_readable_id: 350,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "3551be3a-b3ee-47d4-943d-aecc2c3358b1",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "103c65e8-5adc-410f-8189-89de75e4dc04",
        name: "ENTERTAINMENT LAW",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "cb911e07-64c6-4999-801c-8201e85de699",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 5,
        description_x:
          "The law school provides information on living costs for students",
        human_readable_id: 351,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "8291c030-3275-476f-80dc-6320d0825d0b",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "cb911e07-64c6-4999-801c-8201e85de699",
        name: "LIVING COSTS",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "02e0138c-4313-4ecb-b872-87ced892539b",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 7,
        description_x:
          "The law school offers free resources to support students",
        human_readable_id: 352,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "ab6eafeb-9f8b-47de-b46c-61711c54d403",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "02e0138c-4313-4ecb-b872-87ced892539b",
        name: "FREE RESOURCES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "780e01a7-144c-4b50-9169-21c0d905a602",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 1,
        description_x:
          "The law school provides networking opportunities for students",
        human_readable_id: 353,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "d7f9421d-66ca-4f1c-bf8b-8ea93154cf43",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "780e01a7-144c-4b50-9169-21c0d905a602",
        name: "NETWORKING OPPORTUNITIES",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "caf7a7b1-45b2-4778-80c1-f03eaf81ce0b",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 8,
        description_x:
          "The law school offers mental health assistance to support student well-being",
        human_readable_id: 354,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "024163f5-d826-4319-8c47-b2c702ba2232",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "caf7a7b1-45b2-4778-80c1-f03eaf81ce0b",
        name: "MENTAL HEALTH ASSISTANCE",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "ec7b3d33-35fe-4803-8e29-a2ad4a96cf1c",
      data: {
        text_unit_ids:
          "86a5bbc063995fa59ee779b6f736c6eef5be64294f3492977ab01c7e956616a075e02fabf4b1d7b6db7496487bd1eb10edd0fc3bb6dfdba03d2ba4e08c011446",
        weight: 1,
        description_x:
          "The law school provides accommodations to support diverse student needs",
        human_readable_id: 355,
        n_tokens: 181,
        title: "SOPH ⵣ 🇲🇦 on TikTok",
        description_y:
          "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
        url: "https://www.tiktokv.com/share/video/7191230591352720686/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7191230591352720686.mp4",
        video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
        time_saved: "2025-01-10 03:45:37",
      },
      id: "64adc824-fa76-48e2-8a8a-e3668e6f95a5",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "ec7b3d33-35fe-4803-8e29-a2ad4a96cf1c",
        name: "ACCOMMODATIONS",
        group: 1,
        data: {
          title: "SOPH ⵣ 🇲🇦 on TikTok",
          description:
            "#sophinlaw #lawtok #lawschool #lawstudent #antilawschoolsuffering #prelaw #lawschoolapplicant ",
          url: "https://www.tiktokv.com/share/video/7191230591352720686/",
          video_id: "aad1d682-ae06-4f17-bc5e-6fa16171ba1c",
          time_saved: "2025-01-10 03:45:37",
        },
      },
    },
    {
      source_id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
      target_id: "dbd71c04-37d7-494a-ac72-3524c0f582c5",
      data: {
        text_unit_ids:
          "a9b03fdab576b3371eb70cac1d63ccae51b93f7469bee979d3fe5f6b5713e376f0af82407b9ad583c37919c1420cabfba70eb98950d64c4461de1f6fe4f5b2d9",
        weight: 1,
        description_x:
          "Splitter candidates present a unique challenge to law schools due to their mixed academic profiles",
        human_readable_id: 421,
        n_tokens: 359,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
        url: "https://www.tiktokv.com/share/video/7321386971035634990/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7321386971035634990.mp4",
        video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
        time_saved: "2025-01-08 21:45:48",
      },
      id: "bdc79b9e-f798-40b8-b33e-4b662eac7bb1",
      source: {
        id: "5599f0f7-e0b3-473f-8894-f34f537e85f0",
        name: "LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "dbd71c04-37d7-494a-ac72-3524c0f582c5",
        name: "SPLITTER CANDIDATE",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "One of my mentees is in a similar position, but has gotten 3 acceptances so far and ZERO  rejections— its deff possible #lawtok #prelawadvice #blackprelaw #prelawtiktok #CapCut ",
          url: "https://www.tiktokv.com/share/video/7321386971035634990/",
          video_id: "784b6c87-e303-4072-a20e-b90d99bbd1fd",
          time_saved: "2025-01-08 21:45:48",
        },
      },
    },
    {
      source_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      target_id: "7990f597-ecda-4897-94de-d8a3a7db025c",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 5,
        description_x:
          "The VP suggests that negotiation might be easier at a startup",
        human_readable_id: 56,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "66be7ba6-a36a-4b00-a143-b843a969943e",
      source: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "7990f597-ecda-4897-94de-d8a3a7db025c",
        name: "STARTUP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
      target_id: "31136ae2-5b67-43b5-ac65-4729afeb18a9",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 1,
        description_x:
          "Music played in the living room, enhancing the atmosphere",
        human_readable_id: 79,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "0821b704-495a-4b3c-9a75-ce0b50367a30",
      source: {
        id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
        name: "LIVING ROOM",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "31136ae2-5b67-43b5-ac65-4729afeb18a9",
        name: "MUSIC",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      target_id: "82f87e77-7ff8-4ea1-a05c-d2f6e8731351",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 5,
        description_x:
          "The VP notes potential inflexibility in larger companies during salary negotiations",
        human_readable_id: 57,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "849789c8-77e1-4140-9873-0e777cbf2860",
      source: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "82f87e77-7ff8-4ea1-a05c-d2f6e8731351",
        name: "LARGER COMPANIES",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
      target_id: "95503808-3df9-424f-b779-be3152c78a28",
      data: {
        text_unit_ids:
          "dcba66738a580a74adda54ec9d42641295c43167ba438a041755d3b412fe953d6068c722139f102e1381d5b4468e36a9abc782e14bc5e4a6eea2fe96240583c7",
        weight: 1,
        description_x:
          "The VP mentions possible difficulties in negotiating for programs or internships",
        human_readable_id: 58,
        n_tokens: 170,
        title: "Natasha on TikTok",
        description_y:
          "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
        url: "https://www.tiktokv.com/share/video/7190880646292573483/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7190880646292573483.mp4",
        video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
        time_saved: "2025-01-29 04:24:22",
      },
      id: "6a31e410-d43e-4a1e-8b4f-7d52d5138b46",
      source: {
        id: "eb948d01-c23e-4cfe-ac6d-fea4ab4ac63c",
        name: "VP",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
      target: {
        id: "95503808-3df9-424f-b779-be3152c78a28",
        name: "PROGRAMS/INTERNSHIPS",
        group: 1,
        data: {
          title: "Natasha on TikTok",
          description:
            "Replying to @kittykat.dance How I negotiated my salary #salarynegotiation #careertiktok #careeradvice #careertok #salarytips  #PepsiApplePieChallenge ",
          url: "https://www.tiktokv.com/share/video/7190880646292573483/",
          video_id: "3bbdc445-156e-4b47-b941-522ada1570ba",
          time_saved: "2025-01-29 04:24:22",
        },
      },
    },
    {
      source_id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
      target_id: "6c418376-f724-487e-aebf-ff618a6b4813",
      data: {
        text_unit_ids:
          "33becafada3009530b8d3c1462b722503b41e47731f8b1ae7a5e153464f5f3a8bb66bb56787c2a36ff4eeae7bcca5c16ffcbe8209bb5e3fa99ece602d91a959b",
        weight: 1,
        description_x:
          "Mirrors are used in a New York City apartment to enhance the decor and create an impression of greater space",
        human_readable_id: 61,
        n_tokens: 86,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "a54e6157-0639-4730-b41c-5e4f5344c26c",
      source: {
        id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
        name: "NEW YORK CITY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "6c418376-f724-487e-aebf-ff618a6b4813",
        name: "MIRRORS",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
      target_id: "1ac38851-a468-4064-8860-e6c80848b922",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 8,
        description_x: "The compact apartment is located in New York City",
        human_readable_id: 89,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "eb4b7f60-7be9-4d3d-a30f-fab0aaa19d1c",
      source: {
        id: "cb0f3683-152f-4e2b-ac27-4a466956b298",
        name: "NEW YORK CITY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "6c418376-f724-487e-aebf-ff618a6b4813",
      target_id: "4de72e22-4f4f-4c5e-ae62-1716552455cd",
      data: {
        text_unit_ids:
          "33becafada3009530b8d3c1462b722503b41e47731f8b1ae7a5e153464f5f3a8bb66bb56787c2a36ff4eeae7bcca5c16ffcbe8209bb5e3fa99ece602d91a959b",
        weight: 7,
        description_x:
          "Interior designers use mirrors as a trick to enhance the perception of space in a room",
        human_readable_id: 62,
        n_tokens: 86,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "16808e0b-b2d9-4e77-9085-186392afc2a7",
      source: {
        id: "6c418376-f724-487e-aebf-ff618a6b4813",
        name: "MIRRORS",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "4de72e22-4f4f-4c5e-ae62-1716552455cd",
        name: "INTERIOR DESIGNERS",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "00968741-9cd0-4d1d-90d0-caf0a7fe229a",
      target_id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
      data: {
        text_unit_ids:
          "48103e90f4a74e7e5c7854cee9e8c9576b5f615e181e40ace15173d1f327b307371c24cdd55cc4564508c2189ce81b8096e77c17e0821c421907a12d836a8bfc",
        weight: 1,
        description_x:
          "The millennial grey floored area is the subject of the living room transformation",
        human_readable_id: 65,
        n_tokens: 111,
        title: "Brianna Via on TikTok",
        description_y:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7395342834523475231.mp4",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
      id: "4eabd0ba-23a5-4689-91b0-97a33242f9a6",
      source: {
        id: "00968741-9cd0-4d1d-90d0-caf0a7fe229a",
        name: "MILLENNIAL GREY FLOORED AREA",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
      target: {
        id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
        name: "LIVING ROOM TRANSFORMATION",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
    },
    {
      source_id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
      target_id: "c6e0aea1-0432-4f65-92d5-e62e3f7b202a",
      data: {
        text_unit_ids:
          "48103e90f4a74e7e5c7854cee9e8c9576b5f615e181e40ace15173d1f327b307371c24cdd55cc4564508c2189ce81b8096e77c17e0821c421907a12d836a8bfc",
        weight: 7,
        description_x:
          "The sofa is a key component in the living room transformation for seating and conversation",
        human_readable_id: 66,
        n_tokens: 111,
        title: "Brianna Via on TikTok",
        description_y:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7395342834523475231.mp4",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
      id: "0645f77c-ac43-48bf-80da-a2549fd06944",
      source: {
        id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
        name: "LIVING ROOM TRANSFORMATION",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
      target: {
        id: "c6e0aea1-0432-4f65-92d5-e62e3f7b202a",
        name: "SOFA",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
    },
    {
      source_id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
      target_id: "f5304d2a-6f21-4291-996d-55a825699313",
      data: {
        text_unit_ids:
          "48103e90f4a74e7e5c7854cee9e8c9576b5f615e181e40ace15173d1f327b307371c24cdd55cc4564508c2189ce81b8096e77c17e0821c421907a12d836a8bfc",
        weight: 6,
        description_x:
          "The chair provides additional seating in the living room transformation",
        human_readable_id: 68,
        n_tokens: 111,
        title: "Brianna Via on TikTok",
        description_y:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7395342834523475231.mp4",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
      id: "c3b4b65d-2611-4f4d-9b71-5744df8cfa80",
      source: {
        id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
        name: "LIVING ROOM TRANSFORMATION",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
      target: {
        id: "f5304d2a-6f21-4291-996d-55a825699313",
        name: "CHAIR",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
    },
    {
      source_id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
      target_id: "3cb827c6-4545-4657-9c7d-303f6e23d6c0",
      data: {
        text_unit_ids:
          "48103e90f4a74e7e5c7854cee9e8c9576b5f615e181e40ace15173d1f327b307371c24cdd55cc4564508c2189ce81b8096e77c17e0821c421907a12d836a8bfc",
        weight: 5,
        description_x:
          "The accent table adds style and functionality to the living room transformation",
        human_readable_id: 69,
        n_tokens: 111,
        title: "Brianna Via on TikTok",
        description_y:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7395342834523475231.mp4",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
      id: "fc2b54c9-59a0-495e-8168-41ba02c8bc91",
      source: {
        id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
        name: "LIVING ROOM TRANSFORMATION",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
      target: {
        id: "3cb827c6-4545-4657-9c7d-303f6e23d6c0",
        name: "ACCENT TABLE",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
    },
    {
      source_id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
      target_id: "3cefcb02-7e96-4999-8bd7-b1ef8d8538ee",
      data: {
        text_unit_ids:
          "48103e90f4a74e7e5c7854cee9e8c9576b5f615e181e40ace15173d1f327b307371c24cdd55cc4564508c2189ce81b8096e77c17e0821c421907a12d836a8bfc",
        weight: 1,
        description_x:
          "The fable sectional adds a comfy feel to the living room transformation",
        human_readable_id: 70,
        n_tokens: 111,
        title: "Brianna Via on TikTok",
        description_y:
          "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
        url: "https://www.tiktokv.com/share/video/7395342834523475231/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7395342834523475231.mp4",
        video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
        time_saved: "2025-01-28 00:00:43",
      },
      id: "377f2777-4c1f-4769-9141-e2b0a66f25e9",
      source: {
        id: "4c4e8646-746b-4d13-bfac-41916d3c8773",
        name: "LIVING ROOM TRANSFORMATION",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
      target: {
        id: "3cefcb02-7e96-4999-8bd7-b1ef8d8538ee",
        name: "FABLE SECTIONAL",
        group: 1,
        data: {
          title: "Brianna Via on TikTok",
          description:
            "Send this to your millenial grey friend 🫢 @Castlery US  #greyfloors #castlery #cozylivingroom #livingroominspo ",
          url: "https://www.tiktokv.com/share/video/7395342834523475231/",
          video_id: "a141bfc0-af30-4af2-8935-7e0bdf3ee0b6",
          time_saved: "2025-01-28 00:00:43",
        },
      },
    },
    {
      source_id: "34446084-c7d3-4ab8-87b5-cb23570f396e",
      target_id: "0036cab4-e395-4397-a82f-87872ecabecc",
      data: {
        text_unit_ids:
          "8282cc0eef09ee92374d48d6d14021ae6b41568e95c696dccc62838d48a28d645177867ad0eda38ba51b520fcca0144306708c5ba5f902f4e523640964a77f23",
        weight: 1,
        description_x:
          "Kofie the dog is moving as part of the new apartment decorating series",
        human_readable_id: 71,
        n_tokens: 96,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "c358b85e-e96f-47c2-a938-5f0733979a2b",
      source: {
        id: "34446084-c7d3-4ab8-87b5-cb23570f396e",
        name: "KOFIE",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "0036cab4-e395-4397-a82f-87872ecabecc",
        name: "NEW APARTMENT DECORATING SERIES",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "08840aac-cc9d-4f6b-ad07-2ba64e45f5da",
      target_id: "93a9c8bf-2a4d-427f-ab49-8c6219fbe763",
      data: {
        text_unit_ids:
          "8282cc0eef09ee92374d48d6d14021ae6b41568e95c696dccc62838d48a28d645177867ad0eda38ba51b520fcca0144306708c5ba5f902f4e523640964a77f23",
        weight: 8,
        description_x:
          "The steam mop is used to remove peel and stick wallpaper effectively",
        human_readable_id: 72,
        n_tokens: 96,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "a242ce0b-467e-4577-8fcf-d8d9d5c559e2",
      source: {
        id: "08840aac-cc9d-4f6b-ad07-2ba64e45f5da",
        name: "PEEL AND STICK WALLPAPER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "93a9c8bf-2a4d-427f-ab49-8c6219fbe763",
        name: "STEAM MOP",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "d308cd80-c521-4550-ae3a-4be51ed68a06",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 2,
        description_x:
          "Accutane is a medication recommended by dermatologists for the treatment of severe acne. Before prescribing Accutane, dermatologists assess a patient's suitability for the treatment through blood tests to ensure it is appropriate and safe for the individual.",
        human_readable_id: 73,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "4e343be6-ba92-40c1-a47a-c74719218d6d",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "d308cd80-c521-4550-ae3a-4be51ed68a06",
        name: "DERMATOLOGIST",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      data: {
        text_unit_ids:
          "159adca2a8272c095ac02b692d67e0f6908818f2fae21399469a0cf6bc6c515a7ee5b58c91b5fec24830504b204264e4923c40c39f65b299733063498942265c",
        weight: 8,
        description_x:
          "The content creator shares their personal experience and side effects encountered while using Accutane",
        human_readable_id: 74,
        n_tokens: 179,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "a8e98cde-9386-4101-bd51-4ed645d22ad0",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 7,
        description_x:
          "CeraVe Hydrating Facial Cleanser is recommended for those on Accutane due to its gentle properties",
        human_readable_id: 208,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "d927efc7-288d-4975-a72e-8409d177cd87",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
        name: "CERAVE HYDRATING FACIAL CLEANSER",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 8,
        description_x:
          "CeraVe Daily Moisturizing Lotion is recommended to combat the widespread dryness caused by Accutane",
        human_readable_id: 209,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "fddb3458-9568-4d75-86b6-f3bf13c8c084",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
        name: "CERAVE DAILY MOISTURIZING LOTION",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "EltaMD Tinted Moisturizing Sunscreen is recommended for those on Accutane to even out skin tone",
        human_readable_id: 210,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "804be07e-a1c9-423f-b3f0-69737af85989",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
        name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          "The Round Wood Coffee Table is available for purchase on Amazon",
        human_readable_id: 254,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "fb22cc1c-0e6a-4b0b-b8cb-0a2543bae7c0",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "fce6a301-1609-4299-a839-8ec20522bae0",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 7,
        description_x:
          "Aquaphor is recommended for its moisturizing properties for those experiencing dryness from Accutane",
        human_readable_id: 211,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "6f3da1cb-73b8-4854-ad7c-778a923cfcfc",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "fce6a301-1609-4299-a839-8ec20522bae0",
        name: "AQUAPHOR",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 5,
        description_x:
          "MISSHA M Perfect Cover BB Cream No. 27 is recommended as a makeup product for those on Accutane",
        human_readable_id: 212,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "45dbd302-838d-4956-82e3-902445bbc31f",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
        name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
      data: {
        text_unit_ids:
          "4944049f2a0fd5a926eebbb178272a20d82d9d25d271032ccc243fd051d473c9c04e95527fecc4840d1da85f3c92633e1bd058544bb6cc5538475c96bea045d9",
        weight: 7,
        description_x:
          "Cerave products are recommended for individuals on Accutane due to their gentle formulation",
        human_readable_id: 361,
        n_tokens: 150,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "2b4e78b4-6204-4a1d-be98-84fb8d569aeb",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
        name: "CERAVE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "6766ec61-da79-49c3-a5be-bc6e9dc20295",
      data: {
        text_unit_ids:
          "4944049f2a0fd5a926eebbb178272a20d82d9d25d271032ccc243fd051d473c9c04e95527fecc4840d1da85f3c92633e1bd058544bb6cc5538475c96bea045d9",
        weight: 6,
        description_x:
          "Ulta MD sunscreen is recommended for individuals on Accutane to protect sensitive skin",
        human_readable_id: 362,
        n_tokens: 150,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "a6b7d27d-f829-4745-aa77-2e169fbb33ff",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "6766ec61-da79-49c3-a5be-bc6e9dc20295",
        name: "ULTA MD",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "7c5c5d87-5b2b-44cf-a007-a2ee83c0f5c2",
      data: {
        text_unit_ids:
          "4944049f2a0fd5a926eebbb178272a20d82d9d25d271032ccc243fd051d473c9c04e95527fecc4840d1da85f3c92633e1bd058544bb6cc5538475c96bea045d9",
        weight: 5,
        description_x:
          "Misha BB Cream is recommended for individuals on Accutane for its buildable coverage",
        human_readable_id: 363,
        n_tokens: 150,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "64abe153-8cba-4242-a95b-3a0d7b4eaaa7",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "7c5c5d87-5b2b-44cf-a007-a2ee83c0f5c2",
        name: "MISHA",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "21b7bdad-34ec-46e5-a0c1-103f6e908b85",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 9,
        description_x: "Accutane is used as a treatment to get rid of acne",
        human_readable_id: 451,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "907c8579-3689-438d-b1e4-f4895e172e5a",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "21b7bdad-34ec-46e5-a0c1-103f6e908b85",
        name: "ACNE",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "fe16457b-f86e-4df5-8a0d-1e32ff014a7b",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 8,
        description_x: "Accutane can cause extreme dryness as a side effect",
        human_readable_id: 452,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "d91f9d0e-0029-4e33-83fa-078f1fc5647a",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "fe16457b-f86e-4df5-8a0d-1e32ff014a7b",
        name: "DRYNESS",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "54749543-c531-448d-b9c0-4b4579cfd230",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 8,
        description_x:
          "Accutane can cause major skin thinning as a side effect",
        human_readable_id: 453,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "91c66dad-188b-44fd-9cc4-a165da2a59f6",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "54749543-c531-448d-b9c0-4b4579cfd230",
        name: "SKIN THINNING",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "798cd697-c2da-4189-9128-ef783c2b2e78",
      target_id: "f8a354f5-7441-4466-9cd4-712364dbcc29",
      data: {
        text_unit_ids:
          "fa4bb884245c0930a8cd35a0d04a998b011d1c117b487acdbf77a7bc1f1119972789cf48718d961d8683801b57eca3207d351a3a7bfeee2d3b4ddadb6929a4ca",
        weight: 1,
        description_x:
          "Accutane can cause mental or cognitive side effects such as aggression and low mood",
        human_readable_id: 454,
        n_tokens: 206,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "d720316f-98c2-4fa8-9b96-c10d51e18e74",
      source: {
        id: "798cd697-c2da-4189-9128-ef783c2b2e78",
        name: "ACCUTANE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "f8a354f5-7441-4466-9cd4-712364dbcc29",
        name: "MENTAL OR COGNITIVE SIDE EFFECTS",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      target_id: "9f972bc8-a06a-4ad0-b24d-5d6b0712d278",
      data: {
        text_unit_ids:
          "159adca2a8272c095ac02b692d67e0f6908818f2fae21399469a0cf6bc6c515a7ee5b58c91b5fec24830504b204264e4923c40c39f65b299733063498942265c",
        weight: 1,
        description_x:
          "The content creator underwent many blood tests during their Accutane treatment",
        human_readable_id: 75,
        n_tokens: 179,
        title: "Nour Khalil on TikTok",
        description_y:
          "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
        url: "https://www.tiktokv.com/share/video/7456168087755246894/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456168087755246894.mp4",
        video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
        time_saved: "2025-01-22 04:32:20",
      },
      id: "0254197b-f58b-4bc1-8920-72f4aae701de",
      source: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "9f972bc8-a06a-4ad0-b24d-5d6b0712d278",
        name: "BLOOD TESTS",
        group: 1,
        data: {
          title: "Nour Khalil on TikTok",
          description:
            "Accutane Side Effects - Pros and Cons ✨ #accutane #accutanejourney #accutanesideeffect #accutaneprosandcons #accutanetips #accutaneresults #accutanecheckskincare #sideeffectsofacutane ",
          url: "https://www.tiktokv.com/share/video/7456168087755246894/",
          video_id: "cbbf4cc8-5e0c-44a6-b9ef-2529280a14f0",
          time_saved: "2025-01-22 04:32:20",
        },
      },
    },
    {
      source_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      target_id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 6,
        description_x:
          "The content creator advises applicants to remember the names of admissions officers",
        human_readable_id: 197,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "aec6e62b-97b1-49a4-9fe0-bd215eb7562d",
      source: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
        name: "ADMISSIONS OFFICERS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      target_id: "62eac6b3-f325-47fa-b1e0-bec2d2cffb7d",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 5,
        description_x:
          "The content creator suggests strategies for engaging with admissions officers at recruitment events",
        human_readable_id: 198,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "bd81049d-1af5-46a0-a8a3-4074b7c08d34",
      source: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "62eac6b3-f325-47fa-b1e0-bec2d2cffb7d",
        name: "RECRUITMENT EVENTS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      target_id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 6,
        description_x:
          "The content creator advises applicants to reference past interactions during interviews to demonstrate genuine interest",
        human_readable_id: 199,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "31bc9bc4-2a2c-44bd-b613-d21e956eef83",
      source: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
        name: "INTERVIEWS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      target_id: "58f901cd-c90e-47d2-a615-5ae88b6196fd",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 5,
        description_x:
          "The content creator advises applicants not to obsessively check application statuses post-submission",
        human_readable_id: 200,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "22049c96-b5ad-48a5-b759-8be246d48367",
      source: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "58f901cd-c90e-47d2-a615-5ae88b6196fd",
        name: "APPLICATION STATUSES",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
      target_id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
      data: {
        text_unit_ids:
          "be76a1998ebb376e29c86476a8c22d864a888777e91043e7c2bd9ba5f5f8dbecd677372a5580d4bd8811a412ae4925e149503c45bee536c865cbf1354db09493",
        weight: 1,
        description_x:
          "The content creator advocates for the prediction technique as a key strategy for scoring high on the LSAT",
        human_readable_id: 245,
        n_tokens: 167,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "c84295da-a859-470f-a69e-7691b307af6f",
      source: {
        id: "bf5adbd7-5c4c-40cc-b7ae-02e437c1c7da",
        name: "CONTENT CREATOR",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "542f9350-b26b-4715-8d3c-6d0ffc370631",
        name: "PREDICTION TECHNIQUE",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
    {
      source_id: "2458d043-52e7-4607-970e-552d4d680d08",
      target_id: "3b14e240-3010-434f-ad03-0621badabf67",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 1,
        description_x:
          "The tablet interface is used to control the smart home automation system, enabling changes in lighting and ambiance",
        human_readable_id: 76,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "6cb047af-3411-4e85-a0b4-b2edac621a38",
      source: {
        id: "2458d043-52e7-4607-970e-552d4d680d08",
        name: "SMART HOME AUTOMATION",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "3b14e240-3010-434f-ad03-0621badabf67",
        name: "TABLET INTERFACE",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "2458d043-52e7-4607-970e-552d4d680d08",
      target_id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 7,
        description_x:
          "The living room's ambiance was transformed by the smart home automation system",
        human_readable_id: 77,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "790fe3f0-6fe7-4ca9-9c2f-d5e46b58e710",
      source: {
        id: "2458d043-52e7-4607-970e-552d4d680d08",
        name: "SMART HOME AUTOMATION",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "5ef23c70-96fc-4585-bd1c-e69f6bf086c0",
        name: "LIVING ROOM",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "2458d043-52e7-4607-970e-552d4d680d08",
      target_id: "3602b39f-c3c2-417a-900b-0a882180d352",
      data: {
        text_unit_ids:
          "6371ff46fb15183daff6457d43e9ff457cdc3e3aa6c8117546cd70feebab8d99b5fe3ebddded1fcff50e9cc55137139fb9641f0012aa047f8f7eb038f32e1748",
        weight: 8,
        description_x:
          "The smart home automation system controlled the light sources in the living room",
        human_readable_id: 78,
        n_tokens: 86,
        title: "osyqo on TikTok",
        description_y:
          "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
        url: "https://www.tiktokv.com/share/video/7448703919313685782/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448703919313685782.mp4",
        video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
        time_saved: "2025-02-02 23:15:15",
      },
      id: "4f39513d-5480-42b8-be6f-1ca41e90e1fa",
      source: {
        id: "2458d043-52e7-4607-970e-552d4d680d08",
        name: "SMART HOME AUTOMATION",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
      target: {
        id: "3602b39f-c3c2-417a-900b-0a882180d352",
        name: "LIGHT SOURCES",
        group: 1,
        data: {
          title: "osyqo on TikTok",
          description:
            "at peace 😌  #roominspo #livingroom #ambientlighting #apartmentdecor ",
          url: "https://www.tiktokv.com/share/video/7448703919313685782/",
          video_id: "4887bbdd-2c75-4a9b-9f3f-751178d517d5",
          time_saved: "2025-02-02 23:15:15",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
      data: {
        text_unit_ids:
          "45360f7e54423c62dfa2a8404189af7bea9ddf29939ddee2cb38d350d85f558968d60f13a773ec824d07f100f082fa240ac31f2347e5b86022376e7153c28bb6",
        weight: 7,
        description_x: "The curtain divider components are sourced from Amazon",
        human_readable_id: 81,
        n_tokens: 150,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7439095695274970410.mp4",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
      id: "0ab30abc-ecb0-442c-a126-de9c4588ae71",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
        name: "CURTAIN DIVIDER",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 16,
        description_x:
          "The NYC studio apartment incorporates various Amazon products, including a shoe cabinet and a wine rack, highlighting the integration of Amazon's offerings into the living space.",
        human_readable_id: 157,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "63bcc4b2-8a5b-4903-8076-875d448eab3f",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "8f7aee4b-eb26-458b-a3d4-ca136cb81dc8",
      data: {
        text_unit_ids:
          "d77968a305e08b0f09f9673ef96519decadfe71397e7a6cbc9cdaf83850d5b2efb1ca72c57e9be9615286de0968f2946e4c4a8ee015431348b41d0841a759af1",
        weight: 1,
        description_x:
          "Amazon sells products from Honbay, including modular sectional sleeper sofas",
        human_readable_id: 284,
        n_tokens: 61,
        title: "Hello Kristen on TikTok",
        description_y:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7377867118848593194.mp4",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
      id: "e9fb3d1e-784e-4738-9502-900975fa0fda",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "8f7aee4b-eb26-458b-a3d4-ca136cb81dc8",
        name: "HONBAY",
        group: 1,
        data: {
          title: "Hello Kristen on TikTok",
          description:
            "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
          url: "https://www.tiktokv.com/share/video/7377867118848593194/",
          video_id: "0",
          time_saved: "2025-02-02 00:45:00",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "CeraVe Hydrating Facial Cleanser is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 213,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "4f959428-f537-4db6-9021-01c6ab7550b2",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
        name: "CERAVE HYDRATING FACIAL CLEANSER",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "CeraVe Daily Moisturizing Lotion is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 214,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "fb870711-840b-47b5-a7e6-a9e580750f83",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
        name: "CERAVE DAILY MOISTURIZING LOTION",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "EltaMD Tinted Moisturizing Sunscreen is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 215,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "640eea8c-9f75-4815-9584-9b599dde1cec",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
        name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "fce6a301-1609-4299-a839-8ec20522bae0",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 6,
        description_x:
          "Aquaphor is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 216,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "459b7e6b-8a9b-49ec-96cc-e22f87775016",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "fce6a301-1609-4299-a839-8ec20522bae0",
        name: "AQUAPHOR",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 1,
        description_x:
          "MISSHA M Perfect Cover BB Cream No. 27 is available on Amazon under the Accutane Essentials storefront",
        human_readable_id: 217,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "d8130537-df8e-43f1-a937-e8e55fa1b9cc",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
        name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "5a610178-5f79-4cb5-8041-867ab2a41874",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          'The Yusong 73" Tall Bookshelf is available for purchase on Amazon',
        human_readable_id: 255,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "79233908-e737-4551-8a81-777b0f3c94fd",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "5a610178-5f79-4cb5-8041-867ab2a41874",
        name: 'YUSONG 73" TALL BOOKSHELF',
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "017de81e-714d-401f-b1da-fa549eb5fe98",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          "The Sony PS-LX310BT Wireless Turntable is available for purchase on Amazon",
        human_readable_id: 256,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "ab630c73-4055-4706-a320-0e95f4776823",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "017de81e-714d-401f-b1da-fa549eb5fe98",
        name: "SONY PS-LX310BT WIRELESS TURNTABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c761f5a8-9d2a-45f7-b519-bca78cbb47af",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 7,
        description_x:
          "The Sonos Era 300 speaker is available for purchase on Amazon",
        human_readable_id: 257,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "c4e6043a-4230-48bc-92da-368dab981295",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c761f5a8-9d2a-45f7-b519-bca78cbb47af",
        name: "SONOS ERA 300",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c2be0e25-8b77-4a5d-b997-3e9771d2384e",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 1,
        description_x:
          "The Mueller Retro Toaster is available for purchase on Amazon",
        human_readable_id: 258,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "dad79422-45fa-4773-aa92-c34ba1012619",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c2be0e25-8b77-4a5d-b997-3e9771d2384e",
        name: "MUELLER RETRO TOASTER",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "cd015a21-18d3-4ebf-be5e-8539457834de",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 5,
        description_x:
          "The cityscape view is part of the content highlighting products available on Amazon",
        human_readable_id: 259,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "79fa26c8-1be5-41f9-b7c6-d563a77f3467",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "cd015a21-18d3-4ebf-be5e-8539457834de",
        name: "CITYSCAPE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "977de398-cef3-4b97-999a-1374a43b432d",
      data: {
        text_unit_ids:
          "8c1c23ae7f3854ef5c9cb6a70adcc97ab46d10e416ec6c5a330c70a65313879487e3984d7b0bd04c96f6478af1dcd338ee2bcf5698f66fd030080f919cfa1272",
        weight: 1,
        description_x:
          "Amazon sells the Val couch, which is a product available for purchase",
        human_readable_id: 339,
        n_tokens: 67,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "112d7d52-b909-4848-8704-0ba77adb5345",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "977de398-cef3-4b97-999a-1374a43b432d",
        name: "VAL COUCH",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
      data: {
        text_unit_ids:
          "8c1c23ae7f3854ef5c9cb6a70adcc97ab46d10e416ec6c5a330c70a65313879487e3984d7b0bd04c96f6478af1dcd338ee2bcf5698f66fd030080f919cfa1272",
        weight: 6,
        description_x:
          "Social media influences consumer purchases on platforms like Amazon",
        human_readable_id: 340,
        n_tokens: 67,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "d8a86078-88a5-472d-84ec-5b4c10769206",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
        name: "SOCIAL MEDIA",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c9e08d94-26a3-42d2-9129-4c76ed8099e2",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 7,
        description_x:
          "Amazon offers Modway products, including the walnut TV sideboard, as part of its home decor selection",
        human_readable_id: 430,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "2fae99d9-0c9a-481d-ae8c-94cf49e7dcc9",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c9e08d94-26a3-42d2-9129-4c76ed8099e2",
        name: "MODWAY",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "281f9b2d-3728-43be-ade8-6104877e2a11",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 8,
        description_x:
          "The walnut Modway TV sideboard is available for purchase on Amazon",
        human_readable_id: 431,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "5f0400d2-b7e7-4868-9ba2-62c11a7d033b",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "281f9b2d-3728-43be-ade8-6104877e2a11",
        name: "WALNUT MODWAY TV SIDEBOARD",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c729bafe-4805-4403-84eb-9a68f394daea",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 8,
        description_x:
          "The matte black floating shelves are part of Amazon's curated home decor selection",
        human_readable_id: 432,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "4add4025-60f9-4404-8486-d7548637e03d",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c729bafe-4805-4403-84eb-9a68f394daea",
        name: "MATTE BLACK FLOATING SHELVES",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "1f4c4326-d517-4440-9b1b-d70e2d6e2c92",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 8,
        description_x:
          "The retro flip clock is available on Amazon, contributing to home decor aesthetics",
        human_readable_id: 433,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "4aa662b8-258c-4374-b4b4-a4dc00bee5a8",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "1f4c4326-d517-4440-9b1b-d70e2d6e2c92",
        name: "RETRO FLIP CLOCK",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 1,
        description_x:
          "The claw side table is part of Amazon's home decor offerings, emphasizing organic forms",
        human_readable_id: 434,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "b69940ea-8abe-4f02-add2-5c00f4f31872",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
        name: "CLAW SIDE TABLE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "687e66c5-3e76-4abb-adc4-afdaa21f1853",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the walnut TV sideboard as part of its interior design products",
        human_readable_id: 436,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "26d4f4e2-13e1-4484-8684-f3f2883f759a",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "687e66c5-3e76-4abb-adc4-afdaa21f1853",
        name: "WALNUT TV SIDEBOARD",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "3537c340-2931-409f-aa3a-f20206e1536d",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the black matte floating shelves as part of its interior design products",
        human_readable_id: 437,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "cb0f1f94-f3a5-4062-8c19-6f24e37b1d21",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "3537c340-2931-409f-aa3a-f20206e1536d",
        name: "BLACK MATTE FLOATING SHELVES",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "6a6cefcf-373f-4da5-aadd-77315b563cae",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the white adjustable flip desk clock as part of its interior design products",
        human_readable_id: 438,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "ee4c8f76-2fce-4abb-89b4-257d1af31c10",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "6a6cefcf-373f-4da5-aadd-77315b563cae",
        name: "WHITE ADJUSTABLE FLIP DESK CLOCK",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
      target_id: "18149e0d-4f47-421c-8536-971989ee8f02",
      data: {
        text_unit_ids:
          "d32e3305fcc24c2db810009d492b7d46eeaf87c3ff46fc8062399fc9c357fa2f13271ad82094db124f52c2526fd1e3d252a6ec96b4af3a5dde27574928b5f67e",
        weight: 5,
        description_x:
          "Amazon offers the natural edge side table as part of its interior design products",
        human_readable_id: 439,
        n_tokens: 74,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "8e8b5fcb-78c3-4d1d-98c3-6529a58c58c1",
      source: {
        id: "f7ef8f18-f0e1-433c-900a-00183260bbe9",
        name: "AMAZON",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "18149e0d-4f47-421c-8536-971989ee8f02",
        name: "NATURAL EDGE SIDE TABLE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
      target_id: "99adf17f-0a5c-4107-8582-81877059cac1",
      data: {
        text_unit_ids:
          "45360f7e54423c62dfa2a8404189af7bea9ddf29939ddee2cb38d350d85f558968d60f13a773ec824d07f100f082fa240ac31f2347e5b86022376e7153c28bb6",
        weight: 1,
        description_x:
          "The assembly process is part of creating the curtain divider",
        human_readable_id: 82,
        n_tokens: 150,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
        url: "https://www.tiktokv.com/share/video/7439095695274970410/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7439095695274970410.mp4",
        video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
        time_saved: "2025-01-31 18:01:36",
      },
      id: "c5dc33f5-8767-4a8b-ab9c-4d1f865e2b59",
      source: {
        id: "5908b7e7-638e-4140-91a2-e4af6fad765d",
        name: "CURTAIN DIVIDER",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
      target: {
        id: "99adf17f-0a5c-4107-8582-81877059cac1",
        name: "ASSEMBLY PROCESS",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "Part 1 of my new series on tips for decorating a studio apartment! #studioapartment #homedesignideas #studioapartmentideas #apartmenttour #designideas #livingroominspo #neutraldecor #apartmentdecorideas #curtaindivider",
          url: "https://www.tiktokv.com/share/video/7439095695274970410/",
          video_id: "309b52ae-4da8-40b8-9a38-944d63876fce",
          time_saved: "2025-01-31 18:01:36",
        },
      },
    },
    {
      source_id: "7f71d838-d2b0-4fbc-855e-9482d579b62e",
      target_id: "03aa5b58-061b-4a63-a547-abc81f6c12d3",
      data: {
        text_unit_ids:
          "75da543480de3b678afdc9591e26af952e110cb5667377ce72e01a75f997b84f87526ce539863686cfaa3c5935256e3dbba1c5a7e2ffc9efe43c06159d6dbf08",
        weight: 1,
        description_x:
          "The salary negotiation script is used when an individual receives a job offer to negotiate better compensation",
        human_readable_id: 83,
        n_tokens: 158,
        title: "isimemena on TikTok",
        description_y:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/6948869747832311045.mp4",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
      id: "09399e56-243f-47dd-8057-1d2380898824",
      source: {
        id: "7f71d838-d2b0-4fbc-855e-9482d579b62e",
        name: "SALARY NEGOTIATION SCRIPT",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
      target: {
        id: "03aa5b58-061b-4a63-a547-abc81f6c12d3",
        name: "JOB OFFER",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
    },
    {
      source_id: "7f71d838-d2b0-4fbc-855e-9482d579b62e",
      target_id: "05cc950d-5867-459e-91d2-390eba6728e7",
      data: {
        text_unit_ids:
          "75da543480de3b678afdc9591e26af952e110cb5667377ce72e01a75f997b84f87526ce539863686cfaa3c5935256e3dbba1c5a7e2ffc9efe43c06159d6dbf08",
        weight: 7,
        description_x:
          "Individuals use the salary negotiation script to achieve their desired compensation",
        human_readable_id: 84,
        n_tokens: 158,
        title: "isimemena on TikTok",
        description_y:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/6948869747832311045.mp4",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
      id: "8bb848da-2db0-4bd8-bace-fbdfc1754594",
      source: {
        id: "7f71d838-d2b0-4fbc-855e-9482d579b62e",
        name: "SALARY NEGOTIATION SCRIPT",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
      target: {
        id: "05cc950d-5867-459e-91d2-390eba6728e7",
        name: "INDIVIDUALS",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
    },
    {
      source_id: "03aa5b58-061b-4a63-a547-abc81f6c12d3",
      target_id: "05cc950d-5867-459e-91d2-390eba6728e7",
      data: {
        text_unit_ids:
          "75da543480de3b678afdc9591e26af952e110cb5667377ce72e01a75f997b84f87526ce539863686cfaa3c5935256e3dbba1c5a7e2ffc9efe43c06159d6dbf08",
        weight: 1,
        description_x:
          "Individuals receive a job offer which they aim to negotiate using the script",
        human_readable_id: 85,
        n_tokens: 158,
        title: "isimemena on TikTok",
        description_y:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/6948869747832311045.mp4",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
      id: "b9bd1101-cdaa-40a8-97a4-41b8ade0f76f",
      source: {
        id: "03aa5b58-061b-4a63-a547-abc81f6c12d3",
        name: "JOB OFFER",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
      target: {
        id: "05cc950d-5867-459e-91d2-390eba6728e7",
        name: "INDIVIDUALS",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
    },
    {
      source_id: "03aa5b58-061b-4a63-a547-abc81f6c12d3",
      target_id: "d94068c4-12a5-4faf-95bd-f8323c197872",
      data: {
        text_unit_ids:
          "f155e68c1449bc5cbf638b3ffcc4799e16342a535622646de46e7a1d0064419a20016afd2f811e46f1860db367a3dbb69e11bf04779c40ff7af181038bfed7e7",
        weight: 7,
        description_x:
          "The recruiter communicates the job offer to the candidate",
        human_readable_id: 98,
        n_tokens: 371,
        title: "Don’t Go Broke Trying💰🎙 on TikTok",
        description_y:
          "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
        url: "https://www.tiktokv.com/share/video/7334735055845182726/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7334735055845182726.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:11:33",
      },
      id: "cc2e5b6e-bafb-47c9-8e5d-516c9f05070c",
      source: {
        id: "03aa5b58-061b-4a63-a547-abc81f6c12d3",
        name: "JOB OFFER",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
      target: {
        id: "d94068c4-12a5-4faf-95bd-f8323c197872",
        name: "RECRUITER",
        group: 1,
        data: {
          title: "Don’t Go Broke Trying💰🎙 on TikTok",
          description:
            "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
          url: "https://www.tiktokv.com/share/video/7334735055845182726/",
          video_id: "0",
          time_saved: "2025-01-29 04:11:33",
        },
      },
    },
    {
      source_id: "05cc950d-5867-459e-91d2-390eba6728e7",
      target_id: "cd83faa5-87ad-4ebe-9d81-1adfa1b14f01",
      data: {
        text_unit_ids:
          "75da543480de3b678afdc9591e26af952e110cb5667377ce72e01a75f997b84f87526ce539863686cfaa3c5935256e3dbba1c5a7e2ffc9efe43c06159d6dbf08",
        weight: 6,
        description_x:
          "Individuals express excitement about joining the team as part of the job offer process",
        human_readable_id: 86,
        n_tokens: 158,
        title: "isimemena on TikTok",
        description_y:
          "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
        url: "https://www.tiktokv.com/share/video/6948869747832311045/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/6948869747832311045.mp4",
        video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
        time_saved: "2025-01-29 04:12:28",
      },
      id: "f095516a-49d7-48c5-b34b-d4f27cd83bcb",
      source: {
        id: "05cc950d-5867-459e-91d2-390eba6728e7",
        name: "INDIVIDUALS",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
      target: {
        id: "cd83faa5-87ad-4ebe-9d81-1adfa1b14f01",
        name: "TEAM",
        group: 1,
        data: {
          title: "isimemena on TikTok",
          description:
            "Word for word salary script! Watch now #salary #negotiation #salarynegotiation #joboffer #career #careercoach #careeradvice #jobhunt",
          url: "https://www.tiktokv.com/share/video/6948869747832311045/",
          video_id: "3237be70-46f4-4bb9-884a-96df85a9cb4f",
          time_saved: "2025-01-29 04:12:28",
        },
      },
    },
    {
      source_id: "983a48e3-8d42-4744-9891-79974d58a47f",
      target_id: "92ae7250-8853-4077-a6fe-45abb99a1c93",
      data: {
        text_unit_ids:
          "4027dac7325273445ab9c4e01e8ba26a4297f3f2df1207beea839ca42effcf21929af12af12601f1aab923cae2582c60aa64c941d258bdf7f5d12cb4157f5c8e",
        weight: 8,
        description_x:
          "SimplifyLSAT.com offers comprehensive guides and strategies specifically designed to enhance performance on the LSAT Reading Comprehension section.",
        human_readable_id: 87,
        n_tokens: 166,
        title: "simplifylsat on TikTok",
        description_y:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7096272025118887210.mp4",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
      id: "91af241b-ff09-40aa-8b2b-8b1430802c09",
      source: {
        id: "983a48e3-8d42-4744-9891-79974d58a47f",
        name: "LSAT READING COMPREHENSION",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
      target: {
        id: "92ae7250-8853-4077-a6fe-45abb99a1c93",
        name: "SIMPLIFYLSAT.COM",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
    },
    {
      source_id: "983a48e3-8d42-4744-9891-79974d58a47f",
      target_id: "e7df515f-54b7-42ff-86f5-439203cef1af",
      data: {
        text_unit_ids:
          "4027dac7325273445ab9c4e01e8ba26a4297f3f2df1207beea839ca42effcf21929af12af12601f1aab923cae2582c60aa64c941d258bdf7f5d12cb4157f5c8e",
        weight: 1,
        description_x:
          "The concept of criminal sanctions is used as an example in the LSAT Reading Comprehension section to illustrate complex legal arguments",
        human_readable_id: 175,
        n_tokens: 166,
        title: "simplifylsat on TikTok",
        description_y:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7096272025118887210.mp4",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
      id: "9e814cd3-2999-431d-81ca-60f495c24a3f",
      source: {
        id: "983a48e3-8d42-4744-9891-79974d58a47f",
        name: "LSAT READING COMPREHENSION",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
      target: {
        id: "e7df515f-54b7-42ff-86f5-439203cef1af",
        name: "CRIMINAL SANCTIONS",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
    },
    {
      source_id: "edfeddcd-13cf-499d-80ed-3d251b049651",
      target_id: "a57879b8-8546-409c-9185-9fb53f6f8962",
      data: {
        text_unit_ids:
          "2798f20bc4f1d15d9faab41b4bc4f24eee0148bc2f1ba9cfcc37d7480a3a6fa202f4e68f9f61d5a9d259055e19977580b2fb56d34200009889dc4bdb60b5faf3",
        weight: 5,
        description_x:
          "Legal theorists discuss the legal implications of how corporations are charged",
        human_readable_id: 88,
        n_tokens: 124,
        title: "simplifylsat on TikTok",
        description_y:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7096272025118887210.mp4",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
      id: "856ab6b7-6053-4cb8-8f3d-9cac3d634e70",
      source: {
        id: "edfeddcd-13cf-499d-80ed-3d251b049651",
        name: "LEGAL THEORISTS",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
      target: {
        id: "a57879b8-8546-409c-9185-9fb53f6f8962",
        name: "CORPORATIONS",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
    },
    {
      source_id: "edfeddcd-13cf-499d-80ed-3d251b049651",
      target_id: "e7df515f-54b7-42ff-86f5-439203cef1af",
      data: {
        text_unit_ids:
          "4027dac7325273445ab9c4e01e8ba26a4297f3f2df1207beea839ca42effcf21929af12af12601f1aab923cae2582c60aa64c941d258bdf7f5d12cb4157f5c8e",
        weight: 6,
        description_x:
          "Legal theorists argue against the use of criminal sanctions on corporations, suggesting civil liability instead",
        human_readable_id: 176,
        n_tokens: 166,
        title: "simplifylsat on TikTok",
        description_y:
          "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
        url: "https://www.tiktokv.com/share/video/7096272025118887210/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7096272025118887210.mp4",
        video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
        time_saved: "2025-01-11 03:19:15",
      },
      id: "78922cce-8ef7-4bbb-9d6a-2746b761a820",
      source: {
        id: "edfeddcd-13cf-499d-80ed-3d251b049651",
        name: "LEGAL THEORISTS",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
      target: {
        id: "e7df515f-54b7-42ff-86f5-439203cef1af",
        name: "CRIMINAL SANCTIONS",
        group: 1,
        data: {
          title: "simplifylsat on TikTok",
          description:
            "Lsat RC is easy #LSAT #lsatprep #lsatstudytips #lsatstudying #lsat #SimplifyLSAT #lsattips",
          url: "https://www.tiktokv.com/share/video/7096272025118887210/",
          video_id: "34b6a77e-694a-4955-9bb6-e9bb82adc299",
          time_saved: "2025-01-11 03:19:15",
        },
      },
    },
    {
      source_id: "1ac38851-a468-4064-8860-e6c80848b922",
      target_id: "c30dc008-2120-403a-8746-7c04394e1d3a",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 1,
        description_x:
          "The round mirror is used as a design strategy in the compact apartment",
        human_readable_id: 90,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "8521c6ed-4d66-4e57-ae6c-5728ec1726df",
      source: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "c30dc008-2120-403a-8746-7c04394e1d3a",
        name: "6-FOOT ROUND MIRROR",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "1ac38851-a468-4064-8860-e6c80848b922",
      target_id: "41d75f90-1452-423d-b02d-be618dc0e695",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 9,
        description_x: "The person resides in the compact apartment",
        human_readable_id: 91,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "330470e3-cf3d-4709-960e-f77a99ce6619",
      source: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "41d75f90-1452-423d-b02d-be618dc0e695",
        name: "PERSON LIVING IN APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "1ac38851-a468-4064-8860-e6c80848b922",
      target_id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 1,
        description_x:
          "The design strategy is being implemented in the compact apartment",
        human_readable_id: 92,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "ecc183ac-f467-48df-bd4b-3f6a2809abc0",
      source: {
        id: "1ac38851-a468-4064-8860-e6c80848b922",
        name: "COMPACT 580-SQUARE-FOOT APARTMENT",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
        name: "DESIGN STRATEGY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "c30dc008-2120-403a-8746-7c04394e1d3a",
      target_id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
      data: {
        text_unit_ids:
          "ab4da2af9dc43782b33a9d3f171f40ccd78c0430687d18fe171dbabcf15b1c920c6205f7cb4970942ae2441252fdfaed499f6993a18e4c7a41c9dc90aef037a9",
        weight: 7,
        description_x:
          "The design strategy involves using a 6-foot round mirror to enhance the apartment",
        human_readable_id: 93,
        n_tokens: 77,
        title: "Ivangellys on TikTok",
        description_y:
          "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
        url: "https://www.tiktokv.com/share/video/7272749638199954734/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7272749638199954734.mp4",
        video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
        time_saved: "2025-02-01 23:52:49",
      },
      id: "b4fea856-17f2-40ff-b959-9f7a43a49a8d",
      source: {
        id: "c30dc008-2120-403a-8746-7c04394e1d3a",
        name: "6-FOOT ROUND MIRROR",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
      target: {
        id: "86a6a63e-3656-48f8-9d77-bd4331f2eeb1",
        name: "DESIGN STRATEGY",
        group: 1,
        data: {
          title: "Ivangellys on TikTok",
          description:
            "using mirrors is the trick to making small spaces look lighter, brighter and bigger👏🏼 #loft #loft #homedecorideas #studio #LifeOnTikTok #upcurated #diyproject #floormirror #studioapartment #interiordesign ",
          url: "https://www.tiktokv.com/share/video/7272749638199954734/",
          video_id: "de52fcfb-9df7-4506-bd38-76a70afa83c7",
          time_saved: "2025-02-01 23:52:49",
        },
      },
    },
    {
      source_id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      target_id: "99ff1050-5468-4e5d-9b6d-86ee81275c70",
      data: {
        text_unit_ids:
          "a1010357db4a27ad21121d976da803433e9595946bae8597c18ad49ea087d2ece4cc2a13a08a45f1893187a9dd05d16b325ff39b64a74cc46faeb79cc51d83f3",
        weight: 8,
        description_x:
          "Stanford Law holds admissions information sessions to guide applicants",
        human_readable_id: 94,
        n_tokens: 183,
        title: "LegallyAmrutha on TikTok",
        description_y:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7252552522504834350.mp4",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
      id: "9671eb44-0227-48b6-a968-36671c6fa4f1",
      source: {
        id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
        name: "STANFORD LAW",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
      target: {
        id: "99ff1050-5468-4e5d-9b6d-86ee81275c70",
        name: "ADMISSIONS INFORMATION SESSIONS",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
    },
    {
      source_id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      target_id: "127e28ea-af4e-49d0-b0fc-efe7d62fb1c1",
      data: {
        text_unit_ids:
          "a1010357db4a27ad21121d976da803433e9595946bae8597c18ad49ea087d2ece4cc2a13a08a45f1893187a9dd05d16b325ff39b64a74cc46faeb79cc51d83f3",
        weight: 6,
        description_x:
          "Stanford Law encourages applicants to complete the diversity statement to show commitment",
        human_readable_id: 95,
        n_tokens: 183,
        title: "LegallyAmrutha on TikTok",
        description_y:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7252552522504834350.mp4",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
      id: "2d0b9a33-c6db-41b4-9ee4-4ae7d3b5979e",
      source: {
        id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
        name: "STANFORD LAW",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
      target: {
        id: "127e28ea-af4e-49d0-b0fc-efe7d62fb1c1",
        name: "DIVERSITY STATEMENT",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
    },
    {
      source_id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      target_id: "1b5d2231-d442-4d27-8cb1-44d956ad7b10",
      data: {
        text_unit_ids:
          "a1010357db4a27ad21121d976da803433e9595946bae8597c18ad49ea087d2ece4cc2a13a08a45f1893187a9dd05d16b325ff39b64a74cc46faeb79cc51d83f3",
        weight: 1,
        description_x:
          'Stanford Law recommends including a "Why Stanford Law" statement to express interest',
        human_readable_id: 96,
        n_tokens: 183,
        title: "LegallyAmrutha on TikTok",
        description_y:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7252552522504834350.mp4",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
      id: "e55dea43-427c-4ab4-aa62-d5a7bf646213",
      source: {
        id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
        name: "STANFORD LAW",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
      target: {
        id: "1b5d2231-d442-4d27-8cb1-44d956ad7b10",
        name: "WHY STANFORD LAW STATEMENT",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
    },
    {
      source_id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      target_id: "482c575c-d2f4-4ef9-b8d0-9213a45c93b8",
      data: {
        text_unit_ids:
          "3a9bb5627a67865df5f959bd55f15511eb85c87d7ed3c76951c66f69d95d09196d698261337c71d21b7a309f3e1e46cf20311413f1df7ae5f427a9c78fc8d120",
        weight: 9,
        description_x:
          "The Dean of Law School Admissions is a key figure in the admissions process at Stanford Law, providing guidance and information to prospective students",
        human_readable_id: 111,
        n_tokens: 213,
        title: "LegallyAmrutha on TikTok",
        description_y:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7252552522504834350.mp4",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
      id: "dc3da95e-cfb1-4cbb-aaf6-5bce89ced914",
      source: {
        id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
        name: "STANFORD LAW",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
      target: {
        id: "482c575c-d2f4-4ef9-b8d0-9213a45c93b8",
        name: "DEAN OF LAW SCHOOL ADMISSIONS",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
    },
    {
      source_id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
      target_id: "e4835908-3458-4f39-a06f-2f238084e018",
      data: {
        text_unit_ids:
          "3a9bb5627a67865df5f959bd55f15511eb85c87d7ed3c76951c66f69d95d09196d698261337c71d21b7a309f3e1e46cf20311413f1df7ae5f427a9c78fc8d120",
        weight: 1,
        description_x:
          "The Stanford Law application is a critical part of the admissions process for Stanford Law",
        human_readable_id: 112,
        n_tokens: 213,
        title: "LegallyAmrutha on TikTok",
        description_y:
          "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
        url: "https://www.tiktokv.com/share/video/7252552522504834350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7252552522504834350.mp4",
        video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
        time_saved: "2025-01-08 18:20:28",
      },
      id: "e5889953-4298-42b1-96ee-177d07efed67",
      source: {
        id: "eac95548-3f4c-47eb-a906-b9e3b073ae74",
        name: "STANFORD LAW",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
      target: {
        id: "e4835908-3458-4f39-a06f-2f238084e018",
        name: "STANFORD LAW APPLICATION",
        group: 1,
        data: {
          title: "LegallyAmrutha on TikTok",
          description:
            "Replying to @Kachowbb  good luck!!! #lawschooladmissions #lawtok #prelaw #t14lawschool #stanford #lawschool ",
          url: "https://www.tiktokv.com/share/video/7252552522504834350/",
          video_id: "bab53f98-efd8-48b5-aa39-6236b5baa1ac",
          time_saved: "2025-01-08 18:20:28",
        },
      },
    },
    {
      source_id: "d94068c4-12a5-4faf-95bd-f8323c197872",
      target_id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
      data: {
        text_unit_ids:
          "f155e68c1449bc5cbf638b3ffcc4799e16342a535622646de46e7a1d0064419a20016afd2f811e46f1860db367a3dbb69e11bf04779c40ff7af181038bfed7e7",
        weight: 1,
        description_x:
          "The speaker advises on how to interact with the recruiter during salary negotiations",
        human_readable_id: 97,
        n_tokens: 371,
        title: "Don’t Go Broke Trying💰🎙 on TikTok",
        description_y:
          "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
        url: "https://www.tiktokv.com/share/video/7334735055845182726/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7334735055845182726.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:11:33",
      },
      id: "fd07fe36-b730-4502-b7da-5e9877f832cf",
      source: {
        id: "d94068c4-12a5-4faf-95bd-f8323c197872",
        name: "RECRUITER",
        group: 1,
        data: {
          title: "Don’t Go Broke Trying💰🎙 on TikTok",
          description:
            "SAVE THIS EXACT SCRIPT TO USE AT YOUR NEXT INTERVIEW! Remember, you always want you negotiate over the phone, not via email. Do you research about an appropriate salary range, hop on a call, share your expectations with the recruiter and let them work their magic.  Would you try this approach? Let me know below 👇🏾  Full episode everywhere you listen to poscasts ",
          url: "https://www.tiktokv.com/share/video/7334735055845182726/",
          video_id: "0",
          time_saved: "2025-01-29 04:11:33",
        },
      },
      target: {
        id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
        name: "SPEAKER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
      target_id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 5,
        description_x:
          "The speaker successfully removed the cloud wallpaper using a steam mop",
        human_readable_id: 241,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "1ef35cd8-e521-4d07-bb15-2ac55a186293",
      source: {
        id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
        name: "SPEAKER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
        name: "CLOUD WALLPAPER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
      target_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 4,
        description_x:
          "The speaker is moving to a new apartment and will start a decorating series",
        human_readable_id: 242,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "4482991a-448a-4284-a218-5fcc4787b045",
      source: {
        id: "639b5027-7c23-4248-87c7-43aba1c7c4b5",
        name: "SPEAKER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
      target_id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 1,
        description_x:
          "The essay ties its theme to white liberalism, raising questions about societal desires",
        human_readable_id: 109,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "e3f8f759-a866-43c5-bde7-e2415dceb153",
      source: {
        id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
        name: "WHITE LIBERALISM",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
        name: "ESSAY",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
      target_id: "ff3329fc-8218-4cec-8e7b-8a1d8f5afc88",
      data: {
        text_unit_ids:
          "a30161d8fe31e3170b36271f8b4b8831473596321e03760d93fc9b74b81c522555b12fe8ad1da160db20ee7573ca5ce5b056c39f16870382d69de1f73e866bae",
        weight: 5,
        description_x:
          "The personal statement connects the author's journey to law school with the concept of white liberalism and racial justice",
        human_readable_id: 253,
        n_tokens: 269,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "3fadc0c5-0203-48c3-b248-527cf687e61e",
      source: {
        id: "f24b5935-5d88-4c7d-9830-42e95fb26657",
        name: "WHITE LIBERALISM",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "ff3329fc-8218-4cec-8e7b-8a1d8f5afc88",
        name: "PERSONAL STATEMENT",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
      target_id: "e442f5ba-fa57-4708-8878-3e9167250e74",
      data: {
        text_unit_ids:
          "60d89a3c7f53d540e5fbe0233c8f4d1c97f54be149ef4f0c6df8f91dbdc00ae7604cdc12f417a81c24e5f2b55cb5387d72a7a302564853abcf9b6a87d9a8c489",
        weight: 4,
        description_x:
          "The essay tells the journey to law school with nuance and insight",
        human_readable_id: 110,
        n_tokens: 140,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "dcc573d3-6be2-4fee-9d71-22957a2aab5d",
      source: {
        id: "56910bd0-13b9-4c40-ab11-8c07e0cd2e9d",
        name: "ESSAY",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "e442f5ba-fa57-4708-8878-3e9167250e74",
        name: "JOURNEY TO LAW SCHOOL",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "5896a336-26f6-4867-8767-1dfcc553a00f",
      target_id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
      data: {
        text_unit_ids:
          "34a9b65e87a3c902797e352bf9c158348b86a976594e10b768a379fd4a8f6d460cdc01180bcd8407c500adf1cc69806c791bcea3230869029bb5bf392afbcfa1",
        weight: 1,
        description_x:
          "The person in the red dress is attending the celebration of their friend's graduation",
        human_readable_id: 113,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "40287804-ade0-4fef-bcd0-5abfbe668021",
      source: {
        id: "5896a336-26f6-4867-8767-1dfcc553a00f",
        name: "FRIEND",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "538bb007-77a7-4c7b-a8d9-0123f3ce5d17",
        name: "PERSON IN RED DRESS",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "5896a336-26f6-4867-8767-1dfcc553a00f",
      target_id: "350ec84f-a734-4478-b3cd-5101ccd02880",
      data: {
        text_unit_ids:
          "34a9b65e87a3c902797e352bf9c158348b86a976594e10b768a379fd4a8f6d460cdc01180bcd8407c500adf1cc69806c791bcea3230869029bb5bf392afbcfa1",
        weight: 7,
        description_x:
          "The graduation is the event being celebrated by the friend",
        human_readable_id: 114,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "f247d0ef-e827-43aa-bac3-bce81ce7242f",
      source: {
        id: "5896a336-26f6-4867-8767-1dfcc553a00f",
        name: "FRIEND",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "350ec84f-a734-4478-b3cd-5101ccd02880",
        name: "GRADUATION",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "5896a336-26f6-4867-8767-1dfcc553a00f",
      target_id: "2a83e7c9-0261-4e45-bf13-e2e4072e257d",
      data: {
        text_unit_ids:
          "9431a5bc5ac5f28369eb11a5222e6d7df004be287277e5e2624da77c8558c604875215e442c4d312c989ca22bc609058fc8313a848fe555552e7d04a1aa037ca",
        weight: 8,
        description_x:
          "The graduation event is being held in honor of the friend",
        human_readable_id: 280,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "0cadce0a-8083-4582-8b26-84a72c1e2501",
      source: {
        id: "5896a336-26f6-4867-8767-1dfcc553a00f",
        name: "FRIEND",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "2a83e7c9-0261-4e45-bf13-e2e4072e257d",
        name: "FRIEND'S GRADUATION",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "6266b334-719c-4b35-87aa-9893b287782c",
      target_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 1,
        description_x:
          "The transition from a high-rise apartment to an industrial loft highlights distinct aesthetic and lifestyle shifts",
        human_readable_id: 116,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "dd8a9cdb-2bbe-4e0e-8d57-426190bb4ce0",
      source: {
        id: "6266b334-719c-4b35-87aa-9893b287782c",
        name: "HIGH-RISE APARTMENT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      target_id: "0ef3b297-2bb8-4497-a295-d04d6386e5e6",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 4,
        description_x:
          "The industrial loft offers expansive cityscape views through its large windows",
        human_readable_id: 117,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "efeb899a-8a67-499a-a3da-408a32963bc9",
      source: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "0ef3b297-2bb8-4497-a295-d04d6386e5e6",
        name: "CITYSCAPE VIEWS",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      target_id: "e57740c7-d189-4eab-bdfa-ef22f764bb73",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 3,
        description_x:
          "The industrial loft features a kitchen island as a notable design element",
        human_readable_id: 118,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "396df671-15fe-4a28-9820-89813215813a",
      source: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "e57740c7-d189-4eab-bdfa-ef22f764bb73",
        name: "KITCHEN ISLAND",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
      target_id: "aba2f5e5-0f72-41af-b618-7f2c7ccd292a",
      data: {
        text_unit_ids:
          "74048a5a7737053d441b302e0081419c939ca94f02edbcf3e5cb0d6dd2bebdfc71893a3ed880a27ac358ea41d2bbccbd4e3da732d31546e3f7d3f9136c2220d1",
        weight: 1,
        description_x:
          "The industrial loft's design is complemented by views of urban skylines",
        human_readable_id: 119,
        n_tokens: 129,
        title: "Noelle Simpson on TikTok",
        description_y:
          "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
        url: "https://www.tiktokv.com/share/video/7460338235550747950/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7460338235550747950.mp4",
        video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
        time_saved: "2025-01-21 02:53:42",
      },
      id: "98cdaa87-6596-4aa4-8997-3c40a6800916",
      source: {
        id: "153ce53d-b82d-4c84-8a7c-ed13abfe4ab0",
        name: "INDUSTRIAL LOFT",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
      target: {
        id: "aba2f5e5-0f72-41af-b618-7f2c7ccd292a",
        name: "URBAN SKYLINES",
        group: 1,
        data: {
          title: "Noelle Simpson on TikTok",
          description:
            "Forever a loft girly #loftapartment #loftdesign #industrialloft #chicago #chicagoloft #chicagoapartment #chicagocondo ",
          url: "https://www.tiktokv.com/share/video/7460338235550747950/",
          video_id: "3ed7b1f3-0f2d-4c37-a2db-a0d4ad7b4cb2",
          time_saved: "2025-01-21 02:53:42",
        },
      },
    },
    {
      source_id: "33301f56-8ede-495a-bd0f-900492128245",
      target_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x:
          "Working Nomads is a job board that specializes in remote work opportunities",
        human_readable_id: 120,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "a26b4c61-04a3-452b-91a1-2037448a7a6c",
      source: {
        id: "33301f56-8ede-495a-bd0f-900492128245",
        name: "WORKING NOMADS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "33301f56-8ede-495a-bd0f-900492128245",
      target_id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "Both Working Nomads and Dice are platforms that list remote job opportunities",
        human_readable_id: 291,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "29dc1737-ea1b-47aa-b975-11e5c7e473b9",
      source: {
        id: "33301f56-8ede-495a-bd0f-900492128245",
        name: "WORKING NOMADS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
        name: "DICE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "33301f56-8ede-495a-bd0f-900492128245",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "Working Nomads is one of the platforms recommended for remote job searches",
        human_readable_id: 292,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "1a8ff3d9-4bc6-4a7a-9b07-7818cdfb7416",
      source: {
        id: "33301f56-8ede-495a-bd0f-900492128245",
        name: "WORKING NOMADS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 7,
        description_x: "Dice offers remote positions in the tech industry",
        human_readable_id: 121,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "cb776c6b-3e3b-4d17-b846-c8146d0b3e73",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
        name: "DICE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x: "We Are Distributed provides remote work opportunities",
        human_readable_id: 122,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "51eb7ce1-9bdf-4af8-947e-d3f872df5cb2",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
        name: "WE ARE DISTRIBUTED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "37c46975-3a37-4055-896f-63cdd07cb45b",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x: "Flex Jobs lists flexible and remote job opportunities",
        human_readable_id: 123,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "2eee9a57-3f20-4d33-8e00-5c70e3771b79",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "37c46975-3a37-4055-896f-63cdd07cb45b",
        name: "FLEX JOBS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x:
          "Remote Source is dedicated to remote work opportunities",
        human_readable_id: 124,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "7d556010-23e0-4426-a626-ddd9da45d1a5",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
        name: "REMOTE SOURCE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 8,
        description_x: "Remote.co focuses on remote job listings",
        human_readable_id: 125,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "6d4fe0c6-97e5-44d9-a515-d4ed5cbcd504",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
        name: "REMOTE.CO",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "89c21614-fb16-4273-a3e2-74a519e52f62",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 7,
        description_x:
          "4DayWeek.io promotes remote jobs with a four-day workweek",
        human_readable_id: 126,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "337da7e9-2309-4688-9188-81bc4301adae",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "89c21614-fb16-4273-a3e2-74a519e52f62",
        name: "4DAYWEEK.IO",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "f4829c80-5a7f-47a3-83c5-fb4d0b2d7ac4",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 7,
        description_x:
          "Purpose Jobs connects job seekers with remote opportunities",
        human_readable_id: 127,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "55ddd84d-8085-46f8-aa86-72f5e21bf963",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "f4829c80-5a7f-47a3-83c5-fb4d0b2d7ac4",
        name: "PURPOSE JOBS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 1,
        description_x:
          "Power to Fly connects women with remote job opportunities",
        human_readable_id: 128,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "1175478a-bc99-47b3-9672-9a8ac12034ec",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
        name: "POWER TO FLY",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 6,
        description_x:
          "LinkedIn offers job listings that include remote work opportunities",
        human_readable_id: 129,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "bb84970f-b0f9-4026-8ba6-193538bfa612",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
        name: "LINKEDIN",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
      target_id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
      data: {
        text_unit_ids:
          "bbf455d3afdf728ef14b459f66f769bd3e4a3602ed3db7d61e28f3b310b9b587f363b16587d978e094307ff8459533df675699204e58971a519dc331baa81318",
        weight: 1,
        description_x:
          "Indeed provides job listings that include remote positions",
        human_readable_id: 130,
        n_tokens: 108,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "de8ae4b6-8d72-4f90-b769-c8caa1d461ad",
      source: {
        id: "2900c0cd-e18f-4daf-af6b-cbc9a6cf3653",
        name: "REMOTE WORK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
        name: "INDEED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "Dice is one of the platforms recommended for remote job searches",
        human_readable_id: 293,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "b221ff59-f4d6-4cd2-ab50-702dfa89c92a",
      source: {
        id: "a2b76c6c-de83-4483-be3c-2d0570f3f92a",
        name: "DICE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
      target_id: "37c46975-3a37-4055-896f-63cdd07cb45b",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "We Are Distributed and Flex Jobs both focus on providing remote job opportunities",
        human_readable_id: 294,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "5b12c7f1-61a6-4e6a-b67b-040e61fc1199",
      source: {
        id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
        name: "WE ARE DISTRIBUTED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "37c46975-3a37-4055-896f-63cdd07cb45b",
        name: "FLEX JOBS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "We Are Distributed is one of the platforms recommended for remote job searches",
        human_readable_id: 295,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "6a3a6c0e-424a-4f09-9db6-11f38e6961d0",
      source: {
        id: "2fd1ab58-053d-4687-ba5d-e07b76bbd31f",
        name: "WE ARE DISTRIBUTED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "37c46975-3a37-4055-896f-63cdd07cb45b",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "Flex Jobs is one of the platforms recommended for remote job searches",
        human_readable_id: 296,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "6ed08c13-051f-4b1d-ba33-9c652f3b2919",
      source: {
        id: "37c46975-3a37-4055-896f-63cdd07cb45b",
        name: "FLEX JOBS",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
      target_id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "Remote Source and Remote.co are platforms offering resources and listings for remote work",
        human_readable_id: 297,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "342a3f34-2c42-4505-ba96-07d06b3e3ac9",
      source: {
        id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
        name: "REMOTE SOURCE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
        name: "REMOTE.CO",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "Remote Source is one of the platforms recommended for remote job searches",
        human_readable_id: 298,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "e1b2e68e-e641-477a-86e9-6be781a0eba4",
      source: {
        id: "213c1431-ad51-4ebc-9a8c-92ec066d1d5b",
        name: "REMOTE SOURCE",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "Remote.co is one of the platforms recommended for remote job searches",
        human_readable_id: 299,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "3cecc0e9-a6da-494b-88e2-68c8d1576d10",
      source: {
        id: "5f4820b4-5832-4e7e-82b8-0fa9aefcbfa5",
        name: "REMOTE.CO",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "6b278f60-4b27-4436-88e7-3eb681bb80f3",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 7,
        description_x:
          "The remote is used to activate the pivoting mechanism of the framed artwork",
        human_readable_id: 132,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "568f2143-04b3-403f-b6b8-b0c6a8d30c9b",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "6b278f60-4b27-4436-88e7-3eb681bb80f3",
        name: "REMOTE",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
      target_id: "b63d7b1f-3af8-4548-9ce0-1c18dcb57a82",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 1,
        description_x:
          "4 Day Week and Power to Fly are job boards that promote specific types of remote work opportunities",
        human_readable_id: 300,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "9645dffa-135e-4c34-baf9-3d8017fdc2d6",
      source: {
        id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
        name: "POWER TO FLY",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "b63d7b1f-3af8-4548-9ce0-1c18dcb57a82",
        name: "4 DAY WEEK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 1,
        description_x:
          "Power to Fly is one of the platforms recommended for remote job searches",
        human_readable_id: 302,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "7f8c4529-4c4e-4285-aeef-90a549fc4f67",
      source: {
        id: "70626dee-31ed-4cd8-9de0-86bf6ec3f5d1",
        name: "POWER TO FLY",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
      target_id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 5,
        description_x:
          "LinkedIn and Indeed are both popular platforms for job searches, but their effectiveness for remote job searches is being challenged",
        human_readable_id: 288,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "2be63050-a10a-44af-8df1-bb643363ddb7",
      source: {
        id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
        name: "LINKEDIN",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
        name: "INDEED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
      target_id: "709567d5-0151-4fa2-b491-4015dbb13f79",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 3,
        description_x:
          "The blog post challenges the effectiveness of LinkedIn for remote job searches",
        human_readable_id: 289,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "d674ed16-b663-4bdb-bddf-dd99905b5445",
      source: {
        id: "c1d87e80-7ab1-47d9-8898-e04e4346011b",
        name: "LINKEDIN",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "709567d5-0151-4fa2-b491-4015dbb13f79",
        name: "BLOG POST",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
      target_id: "709567d5-0151-4fa2-b491-4015dbb13f79",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 3,
        description_x:
          "The blog post challenges the effectiveness of Indeed for remote job searches",
        human_readable_id: 290,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "a5e9d0a3-89b3-4717-bd88-c9af7c68092d",
      source: {
        id: "59e6ef5a-9148-4ef4-b1a9-05839f91cb47",
        name: "INDEED",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "709567d5-0151-4fa2-b491-4015dbb13f79",
        name: "BLOG POST",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "27216569-5b76-460a-9de0-589c665c5e0c",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 1,
        description_x:
          "The framed artwork pivots to reveal the hidden screen, integrating technology into the living space",
        human_readable_id: 131,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "023a7764-5592-4511-9b33-9f5bf9e18ba3",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "27216569-5b76-460a-9de0-589c665c5e0c",
        name: "SCREEN",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 6,
        description_x:
          "The framed artwork is part of the room's design, integrating technology into the living space",
        human_readable_id: 133,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "17f24862-fcba-48e9-a5a8-d1d7be9f1481",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
        name: "ROOM",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
      target_id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
      data: {
        text_unit_ids:
          "e790fbe5b55965e5ce5d547c54720feac417a8b55e33fa13545df84950952260ae1a4c5fce544928e3e978aa2963e2843e53de087cf8e2ec5488ff93cfbc4a0d",
        weight: 16,
        description_x: "The framed artwork is used to conceal the television",
        human_readable_id: 377,
        n_tokens: 85,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "4ea02535-5111-4c65-b6b6-9f083e001367",
      source: {
        id: "3f0f2953-4f1b-49e9-8f05-906413b7b933",
        name: "FRAMED ARTWORK",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
        name: "TELEVISION CONCEALMENT",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "27216569-5b76-460a-9de0-589c665c5e0c",
      target_id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
      data: {
        text_unit_ids:
          "970acd7795a95cf83d5f382a7a73ed0081b7d1dc90c4033c698bc9dcb8eb5023749859a083c58ede2d64f2408c4486c2fe84778fd4d3ad614ddcd56b59a6f656",
        weight: 1,
        description_x:
          "The screen is revealed in the room as part of the integrated design",
        human_readable_id: 134,
        n_tokens: 80,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "d13ab2a6-2686-4397-bfdc-a25b5230383b",
      source: {
        id: "27216569-5b76-460a-9de0-589c665c5e0c",
        name: "SCREEN",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "136f09c3-4e02-40d3-b3c0-760aa734e1bb",
        name: "ROOM",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
      target_id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
      data: {
        text_unit_ids:
          "bbcd90222e5ad69e9cfb11aafe7df34f2a84e7d5319e629b2d27b33f7839169992009be2ed32e2590ccfc658d79fc0a0a3d96771da66e0bf545b73197e392282",
        weight: 8,
        description_x:
          "The strategies emphasize the importance of engaging with admissions officers to demonstrate interest and foster dialogue",
        human_readable_id: 135,
        n_tokens: 166,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "b7a5f3fe-4df2-41d1-bad9-1ce5aad37869",
      source: {
        id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
        name: "LAW SCHOOL APPLICATION STRATEGIES",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
      target: {
        id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
        name: "ADMISSIONS OFFICERS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
      target_id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
      data: {
        text_unit_ids:
          "bbcd90222e5ad69e9cfb11aafe7df34f2a84e7d5319e629b2d27b33f7839169992009be2ed32e2590ccfc658d79fc0a0a3d96771da66e0bf545b73197e392282",
        weight: 1,
        description_x:
          "The strategies highlight the importance of referencing prior conversations during interviews to show serious interest",
        human_readable_id: 137,
        n_tokens: 166,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "bf519137-e807-40c4-8811-57663679efe2",
      source: {
        id: "4f9b687c-2da6-4d62-bcc4-59153d1703cb",
        name: "LAW SCHOOL APPLICATION STRATEGIES",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
      target: {
        id: "ca5a01ce-1807-4976-ae9a-af5c50b944c0",
        name: "INTERVIEWS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
      target_id: "62eac6b3-f325-47fa-b1e0-bec2d2cffb7d",
      data: {
        text_unit_ids:
          "c2b6b7e78c481d5ea5bb2569073df5655d93abcd052162f4d116c866371b1f32c964fa66618ec770be0ca3020e260b1340257e4b3376b8266a14b4293dc2e844",
        weight: 1,
        description_x:
          "Admissions officers are present at recruitment events to meet prospective applicants",
        human_readable_id: 203,
        n_tokens: 136,
        title: "Charis | Law School Life on TikTok",
        description_y:
          "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
        url: "https://www.tiktokv.com/share/video/7125082002067295531/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7125082002067295531.mp4",
        video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
        time_saved: "2025-01-10 03:49:27",
      },
      id: "6d6022bd-12b4-46c7-b3e6-9a22a90ac198",
      source: {
        id: "26658e2b-eb33-4e2f-a079-ff03ffc32d66",
        name: "ADMISSIONS OFFICERS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
      target: {
        id: "62eac6b3-f325-47fa-b1e0-bec2d2cffb7d",
        name: "RECRUITMENT EVENTS",
        group: 1,
        data: {
          title: "Charis | Law School Life on TikTok",
          description:
            "Replying to @bennixx_  number 2 is what i wish someone had told me 😭😭 #prelaw #lawschooladmissions #applicationtips #lawschool",
          url: "https://www.tiktokv.com/share/video/7125082002067295531/",
          video_id: "cd1c3514-fecb-4136-b9d6-3b5edd79800f",
          time_saved: "2025-01-10 03:49:27",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "71d1aa93-c6d4-4a73-b361-69a958dae90f",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 5,
        description_x:
          "The text discusses how one can become a product manager straight out of college",
        human_readable_id: 138,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "7665b8ac-bf83-4650-a9bb-609fe2d15572",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "71d1aa93-c6d4-4a73-b361-69a958dae90f",
        name: "COLLEGE",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "5b6f5b01-c267-413e-9a8a-8d213cd9f877",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 6,
        description_x:
          "Product managers may gain experience by building and managing software products",
        human_readable_id: 139,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "d579ebfd-b303-43c4-a2bf-9e4ce7f70cf0",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "5b6f5b01-c267-413e-9a8a-8d213cd9f877",
        name: "SOFTWARE PRODUCT",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "14eabc50-306b-4b9c-8fb9-8427ac6c8160",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 1,
        description_x:
          "Product managers may gain experience by building and managing physical products",
        human_readable_id: 140,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "4be209ff-e796-4272-a2c1-c43f5b2f6bec",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "14eabc50-306b-4b9c-8fb9-8427ac6c8160",
        name: "PHYSICAL PRODUCT",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "7923e185-ff75-4cfa-9528-582a0cbb7ef6",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 7,
        description_x:
          "Product managers gain valuable skills and insights through product experience",
        human_readable_id: 141,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "d310268b-2043-4e2a-8b49-66c3e614d632",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "7923e185-ff75-4cfa-9528-582a0cbb7ef6",
        name: "PRODUCT EXPERIENCE",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "718ab578-5292-4d09-a2e8-a5cac4fa5141",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 8,
        description_x: "Product managers oversee the entire product life cycle",
        human_readable_id: 142,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "a07b78d9-fbe8-47c4-9815-c4a811b54ae6",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "718ab578-5292-4d09-a2e8-a5cac4fa5141",
        name: "PRODUCT LIFE CYCLE",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "2c62f196-6757-43c0-8b49-17d6c08a8be1",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 9,
        description_x:
          "Product managers communicate with customers to understand their needs and pain points",
        human_readable_id: 143,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "44e2fd1c-d8a2-49d8-a060-64dd0373e0d0",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "2c62f196-6757-43c0-8b49-17d6c08a8be1",
        name: "CUSTOMERS",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "b3eecaaa-62bc-4389-8ad4-c0ca3a49452e",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 9,
        description_x:
          "Product managers work with clients to solve their problems and address their needs",
        human_readable_id: 144,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "b13fce1b-af20-4aaa-801a-1fdcc7428b45",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "b3eecaaa-62bc-4389-8ad4-c0ca3a49452e",
        name: "CLIENTS",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "b5aeefb8-a661-4211-8e7d-abdd06b336d6",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 8,
        description_x:
          "Product managers communicate with engineers to ensure product development aligns with goals",
        human_readable_id: 145,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "d65c7283-2b70-457a-b978-f46dddb51583",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "b5aeefb8-a661-4211-8e7d-abdd06b336d6",
        name: "ENGINEERS",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "aa94bec5-0b90-43a6-bfe0-320757038d7e",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 7,
        description_x:
          "Product managers collaborate with the marketing team to promote and sell products",
        human_readable_id: 146,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "4f9e50e4-7b36-4d52-9979-4b3996c39bd7",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "aa94bec5-0b90-43a6-bfe0-320757038d7e",
        name: "MARKETING TEAM",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "df6efd4c-892a-4025-ace0-291e150849ac",
      target_id: "58256bfb-fa32-460a-bbbe-dbe0cefb1d13",
      data: {
        text_unit_ids:
          "cc5059e4e937ca4ffba9ba7467bae77e47159057702394b55389af05531fa04678ae4fa54e20ae3f699a71c95e07ab13b8b0182295004f01785bfc40fa825d56",
        weight: 1,
        description_x:
          "Product managers work with the finance team to manage the financial aspects of a product",
        human_readable_id: 147,
        n_tokens: 226,
        title: "Mar 💌 on TikTok",
        description_y:
          "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
        url: "https://www.tiktokv.com/share/video/7317191589821025567/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7317191589821025567.mp4",
        video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
        time_saved: "2025-01-22 02:48:28",
      },
      id: "5f3a9b06-486b-4e1d-bafb-d7e09a4c7ee9",
      source: {
        id: "df6efd4c-892a-4025-ace0-291e150849ac",
        name: "PRODUCT MANAGER",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
      target: {
        id: "58256bfb-fa32-460a-bbbe-dbe0cefb1d13",
        name: "FINANCE TEAM",
        group: 1,
        data: {
          title: "Mar 💌 on TikTok",
          description:
            "Replying to @Dr. Curious lmk if you have any questions 🥰 #manager #productmanager #softwaredeveloper #computerscience #bigtech #FAANGcareer ",
          url: "https://www.tiktokv.com/share/video/7317191589821025567/",
          video_id: "9631a6b7-c80a-4343-9022-b80e35f1c58d",
          time_saved: "2025-01-22 02:48:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "7fd92e05-fef3-4884-9186-4611d70f262b",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 7,
        description_x:
          "The mid-century dresser was the most expensive item purchased for the studio apartment makeover",
        human_readable_id: 150,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "6b3a81a6-c842-4e60-bac2-80d83a7a2f40",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "7fd92e05-fef3-4884-9186-4611d70f262b",
        name: "MID-CENTURY DRESSER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "6f90bc3c-f5e0-4e60-ab12-601659045b08",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 1,
        description_x:
          "The vintage 1990s sofa was purchased for the studio apartment makeover",
        human_readable_id: 151,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "6c42d3ab-030b-4161-baea-2bbf72bdb208",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "6f90bc3c-f5e0-4e60-ab12-601659045b08",
        name: "VINTAGE 1990S SOFA",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "e7783877-7a97-4397-8538-388e81631c6a",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 7,
        description_x:
          "The plywood headboard was crafted and integrated into the studio apartment makeover",
        human_readable_id: 152,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "0bc5c4ee-aad0-447e-a765-fb5efc4d7031",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "e7783877-7a97-4397-8538-388e81631c6a",
        name: "PLYWOOD HEADBOARD",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "ccbbe157-b4d2-4a4f-9338-24f4538f931b",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 6,
        description_x:
          "The bed frame was purchased and used in the studio apartment makeover",
        human_readable_id: 153,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "10f8732a-1ebf-4f75-bb77-da7624cf157b",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "ccbbe157-b4d2-4a4f-9338-24f4538f931b",
        name: "BED FRAME",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "8a883164-b2f1-4248-a153-794456519049",
      target_id: "9973cce6-df8b-496f-9f32-ab6cb6462fe7",
      data: {
        text_unit_ids:
          "96a61e85ea040112c2b947f31916ff252822f930a77f57a0d603d61de558e56c9c5a149a1215a50ff628197a254893c8661f81dca8efeba9d132f41459d58bba",
        weight: 1,
        description_x:
          "The accent chairs were re-upholstered and integrated into the studio apartment makeover",
        human_readable_id: 156,
        n_tokens: 179,
        title: "Lone Fox on TikTok",
        description_y:
          "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
        url: "https://www.tiktokv.com/share/video/7255399405438668075/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7255399405438668075.mp4",
        video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
        time_saved: "2025-02-02 15:19:28",
      },
      id: "7bb8506c-a7e0-4cec-8894-03313931ca3a",
      source: {
        id: "8a883164-b2f1-4248-a153-794456519049",
        name: "STUDIO APARTMENT MAKEOVER",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
      target: {
        id: "9973cce6-df8b-496f-9f32-ab6cb6462fe7",
        name: "ACCENT CHAIRS",
        group: 1,
        data: {
          title: "Lone Fox on TikTok",
          description:
            "The smallest apartment i’ve made over BUT I kinda wanna move in… 🤩 #studioapartment #studio #apartmentmakeover #roommakeover ",
          url: "https://www.tiktokv.com/share/video/7255399405438668075/",
          video_id: "dfaeb5dc-4f31-42f7-b3a9-c06a7d4c42f8",
          time_saved: "2025-02-02 15:19:28",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "4ae2e0eb-f69d-4590-8812-2ef9fb1f218e",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 16,
        description_x:
          "The NYC studio apartment is furnished with a couch from West Elm, showcasing the use of West Elm furniture in its interior design.",
        human_readable_id: 158,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "1580fde0-4b2f-495e-80ff-ccc6afd64444",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "4ae2e0eb-f69d-4590-8812-2ef9fb1f218e",
        name: "WEST ELM",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "f4cca64e-12ac-4028-97b9-18c4ce6b8ebc",
      data: {
        text_unit_ids:
          "ec574b47aa335924b2134278202dc0eb2dece8805eb0835f299bd707e0c65e6bf8223ccbd59f943984dda3bef79ce30b751c3f0fb38be372998ddba469d3a87e",
        weight: 10,
        description_x:
          "HomeGoods decor items are used in the NYC studio apartment",
        human_readable_id: 159,
        n_tokens: 218,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "0ecc4a6e-d8ad-4495-9ece-25a5472a8dfc",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "f4cca64e-12ac-4028-97b9-18c4ce6b8ebc",
        name: "HOMEGOODS",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "0203a028-101c-45c0-a4a6-64ed7eb594e6",
      data: {
        text_unit_ids:
          "ec574b47aa335924b2134278202dc0eb2dece8805eb0835f299bd707e0c65e6bf8223ccbd59f943984dda3bef79ce30b751c3f0fb38be372998ddba469d3a87e",
        weight: 2,
        description_x:
          "Elfa storage systems are used in the NYC studio apartment",
        human_readable_id: 160,
        n_tokens: 218,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "7029cf98-ca79-46db-818d-5725f7118f29",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "0203a028-101c-45c0-a4a6-64ed7eb594e6",
        name: "ELFA",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "4782a313-1999-43b3-a33e-9bb0b940f1e5",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 6,
        description_x: "The NYC studio apartment features rugs from TJ Maxx",
        human_readable_id: 413,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "20037ef3-a12e-46f0-afca-81c195f39c46",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "4782a313-1999-43b3-a33e-9bb0b940f1e5",
        name: "TJ MAXX",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "2ce31692-da20-453f-9fad-1eb340da78bd",
      target_id: "68fe43fd-29b6-4560-9d7c-5778abf843b3",
      data: {
        text_unit_ids:
          "ec4b6336bf2e4829ae6b5efbeae343596c3d6082d57ed8a2eefd456f84feb32d195173b473a666b1cd06b5c81e62b643564b201c707c4f78e8dbfca0747b4ce7",
        weight: 2,
        description_x:
          "The NYC studio apartment features a coffee table from Home Goods",
        human_readable_id: 414,
        n_tokens: 170,
        title: "annie on TikTok",
        description_y:
          "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
        url: "https://www.tiktokv.com/share/video/7386796774712806699/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7386796774712806699.mp4",
        video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
        time_saved: "2025-02-01 23:56:03",
      },
      id: "73b6feab-8382-4df6-9764-1da70f4f5ed8",
      source: {
        id: "2ce31692-da20-453f-9fad-1eb340da78bd",
        name: "NYC",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
      target: {
        id: "68fe43fd-29b6-4560-9d7c-5778abf843b3",
        name: "HOME GOODS",
        group: 1,
        data: {
          title: "annie on TikTok",
          description:
            "FULL STUDIO APARTMENT TOUR! With storage tips and where I bought most items #nyc #nycapartment #studioapartment #apartmenttour #studioliving #apartment #foryou #fyp ",
          url: "https://www.tiktokv.com/share/video/7386796774712806699/",
          video_id: "b0aabfa8-0bad-485a-a261-183cc40f0198",
          time_saved: "2025-02-01 23:56:03",
        },
      },
    },
    {
      source_id: "0d71d569-7880-49fe-a7fe-9bac9ffb2610",
      target_id: "580d612f-f12c-4dd2-b50b-db3caba3552b",
      data: {
        text_unit_ids:
          "134531d8b295df766d684a1edcee008e8386f575e74ea90a5e6f53ba0733c943783b710a17836ebee4d6971039227fe65dac8df7de870afe95d74c550790fad0",
        weight: 7,
        description_x:
          "The individual is actively involved in the assembly of the IKEA closet system",
        human_readable_id: 163,
        n_tokens: 70,
        title: "josi on TikTok",
        description_y:
          "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
        url: "https://www.tiktokv.com/share/video/7461773312218107166/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7461773312218107166.mp4",
        video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
        time_saved: "2025-01-21 04:57:39",
      },
      id: "7e7a7679-b7bc-46bf-abd9-2331e0bed526",
      source: {
        id: "0d71d569-7880-49fe-a7fe-9bac9ffb2610",
        name: "CLOSET ASSEMBLY",
        group: 1,
        data: {
          title: "josi on TikTok",
          description:
            "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
          url: "https://www.tiktokv.com/share/video/7461773312218107166/",
          video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
          time_saved: "2025-01-21 04:57:39",
        },
      },
      target: {
        id: "580d612f-f12c-4dd2-b50b-db3caba3552b",
        name: "INDIVIDUAL",
        group: 1,
        data: {
          title: "josi on TikTok",
          description:
            "that’s the way I like it 🔨🤌🏼 (grey- beige pax + tonstad doors) #ikeapax #ikeacloset #pax #ikeahack ",
          url: "https://www.tiktokv.com/share/video/7461773312218107166/",
          video_id: "46bb328a-8cf1-43c7-9459-7cf18a1cd4d1",
          time_saved: "2025-01-21 04:57:39",
        },
      },
    },
    {
      source_id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
      target_id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
      data: {
        text_unit_ids:
          "1a179331701a8cd0df59da802b48db647dbd4808b7717bae8946cdd7b16c626f84cf7ddaa13254da6d90ca87a45a095f83159b81eeb8204819c41922cf124f13",
        weight: 7,
        description_x:
          "The student achieved significant test score improvement",
        human_readable_id: 167,
        n_tokens: 84,
        title: "Lizna Lakhani on TikTok",
        description_y:
          "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
        url: "https://www.tiktokv.com/share/video/7308139870768188702/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7308139870768188702.mp4",
        video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
        time_saved: "2025-01-08 20:24:29",
      },
      id: "2d6f19cf-e28e-4e3c-9cf5-3329c5ce5c1a",
      source: {
        id: "b992cacd-0b42-4729-b71d-7ef36d8fe2a6",
        name: "STUDENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
      target: {
        id: "4ef86b50-30ad-4f6e-a26b-bb97cdcebdc9",
        name: "TEST SCORE IMPROVEMENT",
        group: 1,
        data: {
          title: "Lizna Lakhani on TikTok",
          description:
            "don’t stop believin’ (in yourself) 🕺🪩#prelaw #lsatprep #lsattips #lsatstudying #lsattutor #lsatstudytips #lsat ",
          url: "https://www.tiktokv.com/share/video/7308139870768188702/",
          video_id: "97fb614d-2455-4ba6-b979-c4b1c11a802e",
          time_saved: "2025-01-08 20:24:29",
        },
      },
    },
    {
      source_id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
      target_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      data: {
        text_unit_ids:
          "dbf12ab489b76f281cd7426d31cbbd004e234a8320c2ee309d9966ec126738b3bbc9238a1f8d33c374438051aca1af544ce292c5d7523d1d651068b720755dd2",
        weight: 8,
        description_x:
          "The apartment hunt is specifically focused on the West Loop neighborhood",
        human_readable_id: 169,
        n_tokens: 59,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "e35cb54e-7e6f-4970-993c-b052d77c31ca",
      source: {
        id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
        name: "APARTMENT HUNT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
      target_id: "5849e087-bd35-43a8-bad4-8f2eec0e5d78",
      data: {
        text_unit_ids:
          "dbf12ab489b76f281cd7426d31cbbd004e234a8320c2ee309d9966ec126738b3bbc9238a1f8d33c374438051aca1af544ce292c5d7523d1d651068b720755dd2",
        weight: 1,
        description_x:
          "The convertible space is the focus of the apartment hunt",
        human_readable_id: 170,
        n_tokens: 59,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "f7c6decb-e37c-4b07-98ec-ae7529925037",
      source: {
        id: "9f705c55-39ec-4e40-b7ce-e80ea6c4e9d1",
        name: "APARTMENT HUNT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "5849e087-bd35-43a8-bad4-8f2eec0e5d78",
        name: "CONVERTIBLE SPACE",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 1,
        description_x:
          "The event of apartment hunting is specifically focused on the West Loop neighborhood in Chicago",
        human_readable_id: 173,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "10b2bf86-3936-4ed2-8ca0-fc6df76865e7",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "134a1de7-1762-4f88-b3aa-10f285d655d7",
        name: "APARTMENT HUNTING",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
      data: {
        text_unit_ids:
          "c880d4f7171a4d95208ba026e6a2545f7cb6301431d0c0a956ae2be79756f53c9c7c6b30ee7cc2e677a3a0969454451a35436266d9361b94102c31326eab9805",
        weight: 8,
        description_x:
          "The convertible unit is located in the West Loop neighborhood of Chicago",
        human_readable_id: 174,
        n_tokens: 98,
        title: "Jessika | Chicago Living on TikTok",
        description_y:
          "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
        url: "https://www.tiktokv.com/share/video/7444708551710346542/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7444708551710346542.mp4",
        video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
        time_saved: "2025-02-01 03:41:29",
      },
      id: "3d89ebd0-9988-4619-9790-b2301b9b4429",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "fbaeebe6-d4b0-46d1-a595-d61dcb0ec362",
        name: "CONVERTIBLE UNIT",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "b370beda-376c-417e-85c9-14b410d1782e",
      data: {
        text_unit_ids:
          "6f44efc1e5599054486b52d3a68f69f07b0a2b238b02e4ba5ebedb76b897ade033a084833ad84c4f521e3fc177a98276d970612946be1712ae1121898552fb4e",
        weight: 1,
        description_x:
          "The 1 bed/1 bath apartment is located in the West Loop neighborhood of Chicago",
        human_readable_id: 273,
        n_tokens: 81,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "e0988b92-0e9d-415c-b593-1f355d8f1575",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "b370beda-376c-417e-85c9-14b410d1782e",
        name: "1 BED/1 BATH APARTMENT",
        group: 1,
        data: {
          title: "Smart City Locating Chicago on TikTok",
          description:
            "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
          url: "https://www.tiktokv.com/share/video/7456922942690184478/",
          video_id: "0",
          time_saved: "2025-01-14 20:26:54",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "cfe02158-3bda-40fc-831e-f8ff450c2404",
      data: {
        text_unit_ids:
          "669026b821037dd5cc37691b24b10da7013785ad0451a353c52cc1fa4c5ddac7c8e80bf04994d7d5d7ae51130673abb655e1cc98ab53bbd5726d2041c3b9cea8",
        weight: 9,
        description_x:
          "The West Loop studio is located in the West Loop neighborhood",
        human_readable_id: 276,
        n_tokens: 98,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "43a951eb-d7ee-450d-a914-0739aadd37cf",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "cfe02158-3bda-40fc-831e-f8ff450c2404",
        name: "WEST LOOP STUDIO",
        group: 1,
        data: {
          title: "Smart City Locating Chicago on TikTok",
          description:
            "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
          url: "https://www.tiktokv.com/share/video/7456922942690184478/",
          video_id: "0",
          time_saved: "2025-01-14 20:26:54",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "e6203578-bb2e-48e1-a9f0-0412a6b2ba74",
      data: {
        text_unit_ids:
          "669026b821037dd5cc37691b24b10da7013785ad0451a353c52cc1fa4c5ddac7c8e80bf04994d7d5d7ae51130673abb655e1cc98ab53bbd5726d2041c3b9cea8",
        weight: 9,
        description_x:
          "The one-bedroom unit is located in the West Loop neighborhood",
        human_readable_id: 277,
        n_tokens: 98,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "f3304c91-856e-45af-83f4-0dcacb24f758",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "e6203578-bb2e-48e1-a9f0-0412a6b2ba74",
        name: "ONE-BEDROOM UNIT",
        group: 1,
        data: {
          title: "Smart City Locating Chicago on TikTok",
          description:
            "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
          url: "https://www.tiktokv.com/share/video/7456922942690184478/",
          video_id: "0",
          time_saved: "2025-01-14 20:26:54",
        },
      },
    },
    {
      source_id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
      target_id: "322fe56a-2c42-481f-97c9-acb15abc79f1",
      data: {
        text_unit_ids:
          "669026b821037dd5cc37691b24b10da7013785ad0451a353c52cc1fa4c5ddac7c8e80bf04994d7d5d7ae51130673abb655e1cc98ab53bbd5726d2041c3b9cea8",
        weight: 1,
        description_x:
          "Train stations are within a five-minute walk from the West Loop apartments",
        human_readable_id: 278,
        n_tokens: 98,
        title: "Smart City Locating Chicago on TikTok",
        description_y:
          "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
        url: "https://www.tiktokv.com/share/video/7456922942690184478/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7456922942690184478.mp4",
        video_id: "0",
        time_saved: "2025-01-14 20:26:54",
      },
      id: "b46e3688-5332-4369-afc0-1e2d7ae24d49",
      source: {
        id: "a8041957-db2d-4e2a-b068-7f2e1551f8e4",
        name: "WEST LOOP",
        group: 1,
        data: {
          title: "Jessika | Chicago Living on TikTok",
          description:
            "Who wants 2 months + $1,500 of free rent? This building in West Loop is offering the best specials around 🤑 Fill out the form in my bio for more info! #chicago #apartment #apartmenttour #chicagotiktok #loft #realestate ",
          url: "https://www.tiktokv.com/share/video/7444708551710346542/",
          video_id: "0aa3d706-91c0-4790-974c-d9622b3db951",
          time_saved: "2025-02-01 03:41:29",
        },
      },
      target: {
        id: "322fe56a-2c42-481f-97c9-acb15abc79f1",
        name: "TRAIN STATIONS",
        group: 1,
        data: {
          title: "Smart City Locating Chicago on TikTok",
          description:
            "Nothing like ringin’ in the new year with 2 👏 MONTHS 👏 FREE 👏 in the West Loop! Being so close to the L AND a short walk away from a TON of West Loop faves? Truly chef’s kiss! 🧑‍🍳💋👇 These will get snatched up fast & we want to make sure you are one of them!! 📲 DM us or comment below to get more info from an agent! — #chicago #chicagorealestate #chicagolife #livinginchicago #smartcitylocating #chicagoil #apartmenttour #realtor #chicagorealestate #westloop #westloopapartments",
          url: "https://www.tiktokv.com/share/video/7456922942690184478/",
          video_id: "0",
          time_saved: "2025-01-14 20:26:54",
        },
      },
    },
    {
      source_id: "271058e0-f3cb-41f0-a760-0c940b50563d",
      target_id: "678fe9f5-60b3-418e-93fd-67c3857ceeb4",
      data: {
        text_unit_ids:
          "b8601f026b83def18535fdd08d25809fefcbb62adcf0ed4147ddb946d133a9a51575c7af61bd656bac776728fe2c9d596e86557830c003e914dce9e840d130ba",
        weight: 10,
        description_x:
          "The anti-slip couch cover is one of the products discussed in the Home Finds for 2024 event",
        human_readable_id: 177,
        n_tokens: 88,
        title: "ash on TikTok",
        description_y:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7328209604150693150.mp4",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
      id: "d0eeef00-ba7f-4936-b3aa-d76c562e794b",
      source: {
        id: "271058e0-f3cb-41f0-a760-0c940b50563d",
        name: "HOME FINDS FOR 2024",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
      target: {
        id: "678fe9f5-60b3-418e-93fd-67c3857ceeb4",
        name: "ANTI-SLIP COUCH COVER",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
    },
    {
      source_id: "271058e0-f3cb-41f0-a760-0c940b50563d",
      target_id: "05cee4ee-de11-46f3-84a5-592e1a0c3ac5",
      data: {
        text_unit_ids:
          "b8601f026b83def18535fdd08d25809fefcbb62adcf0ed4147ddb946d133a9a51575c7af61bd656bac776728fe2c9d596e86557830c003e914dce9e840d130ba",
        weight: 10,
        description_x:
          "The lift-top coffee table is one of the products discussed in the Home Finds for 2024 event",
        human_readable_id: 178,
        n_tokens: 88,
        title: "ash on TikTok",
        description_y:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7328209604150693150.mp4",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
      id: "3eb5ef4a-d7e7-4c03-9c5d-cde4d0e5f05c",
      source: {
        id: "271058e0-f3cb-41f0-a760-0c940b50563d",
        name: "HOME FINDS FOR 2024",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
      target: {
        id: "05cee4ee-de11-46f3-84a5-592e1a0c3ac5",
        name: "LIFT-TOP COFFEE TABLE",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
    },
    {
      source_id: "271058e0-f3cb-41f0-a760-0c940b50563d",
      target_id: "7bd93453-e506-4bf9-9b08-96d223292400",
      data: {
        text_unit_ids:
          "b8601f026b83def18535fdd08d25809fefcbb62adcf0ed4147ddb946d133a9a51575c7af61bd656bac776728fe2c9d596e86557830c003e914dce9e840d130ba",
        weight: 2,
        description_x:
          "The solid wood entryway table is one of the products discussed in the Home Finds for 2024 event",
        human_readable_id: 179,
        n_tokens: 88,
        title: "ash on TikTok",
        description_y:
          "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
        url: "https://www.tiktokv.com/share/video/7328209604150693150/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7328209604150693150.mp4",
        video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
        time_saved: "2025-02-02 00:50:10",
      },
      id: "3e414ea4-9da0-4dd8-8676-4554c62e8fe3",
      source: {
        id: "271058e0-f3cb-41f0-a760-0c940b50563d",
        name: "HOME FINDS FOR 2024",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
      target: {
        id: "7bd93453-e506-4bf9-9b08-96d223292400",
        name: "SOLID WOOD ENTRYWAY TABLE",
        group: 1,
        data: {
          title: "ash on TikTok",
          description:
            "No gatekeeping!! 🫶🏼 #amazonhomefinds2024 #homedecor #amazonhomedecor #amazonfinds #amazonmusthaves ",
          url: "https://www.tiktokv.com/share/video/7328209604150693150/",
          video_id: "a295a809-ba1c-4ff6-a256-70386dc32d4d",
          time_saved: "2025-02-02 00:50:10",
        },
      },
    },
    {
      source_id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
      target_id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
      data: {
        text_unit_ids:
          "2a6c470016d3024a16884c76dcfddbfda422dc02ef1ed818f1ba39bcab928c2e3eaa00444b87f5af64ed26fc3dd6528e94faf1b68ac0bd717b33dbdc305f0747",
        weight: 7,
        description_x:
          "The assembly demonstration is focused on showcasing the features and assembly process of the PAX wardrobe system",
        human_readable_id: 182,
        n_tokens: 47,
        title: "B S Y ✩ on TikTok",
        description_y:
          "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
        url: "https://www.tiktokv.com/share/video/7220431692467604779/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7220431692467604779.mp4",
        video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
        time_saved: "2025-02-01 23:48:32",
      },
      id: "76658bf3-349e-4329-b936-d9ebcd585b5d",
      source: {
        id: "95df456b-f4d1-46f7-9b2b-25f6a1fa463b",
        name: "PAX WARDROBE SYSTEM",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
      target: {
        id: "5726c85a-2204-4efa-bce7-7aaaeb40b72c",
        name: "ASSEMBLY DEMONSTRATION",
        group: 1,
        data: {
          title: "B S Y ✩ on TikTok",
          description:
            "Finally got my pax installed 🧸🤍 #closet #ikea #aesthetic #roomtour #pax  #ikeapaxwardrobe #ikeapaxsystem ",
          url: "https://www.tiktokv.com/share/video/7220431692467604779/",
          video_id: "d0f7ba70-2e0f-4cbd-a2db-52ab116e89c7",
          time_saved: "2025-02-01 23:48:32",
        },
      },
    },
    {
      source_id: "0e8979ad-8c13-4dab-a3ed-06070e50c435",
      target_id: "7d35b208-2393-4f38-91ae-1b3af1405037",
      data: {
        text_unit_ids:
          "6eda358571d1ef590e699dbd8919e9241fb51e585dac1e96067c512f24ff63e797daa2e779857b7fe667e4870317b47c0f8c7662b25b8c1e77f73ac0aaf4a84c",
        weight: 7,
        description_x:
          "Imani’s Phase is a specific pattern of rental-friendly wallpaper",
        human_readable_id: 183,
        n_tokens: 94,
        title: "Imani | DIY, Design & Life on TikTok",
        description_y:
          "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
        url: "https://www.tiktokv.com/share/video/7301508923985497387/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7301508923985497387.mp4",
        video_id: "0",
        time_saved: "2025-02-02 00:31:03",
      },
      id: "fb6f0e8f-d81d-4602-b69e-65175e40613b",
      source: {
        id: "0e8979ad-8c13-4dab-a3ed-06070e50c435",
        name: "IMANI’S PHASE",
        group: 1,
        data: {
          title: "Imani | DIY, Design & Life on TikTok",
          description:
            "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
          url: "https://www.tiktokv.com/share/video/7301508923985497387/",
          video_id: "0",
          time_saved: "2025-02-02 00:31:03",
        },
      },
      target: {
        id: "7d35b208-2393-4f38-91ae-1b3af1405037",
        name: "RENTAL-FRIENDLY WALLPAPER",
        group: 1,
        data: {
          title: "Imani | DIY, Design & Life on TikTok",
          description:
            "Replying to @CK - Gemini leaning into the textures even more, giving an abstracted, impressionistic print… this is Imani’s Fave!  Shop my #peelandstickwallpaper collection with @Otto Studio - 🔗 in bio!  #renterfriendly #homedecorideas #maximalismalltheway #moodyinteriors ",
          url: "https://www.tiktokv.com/share/video/7301508923985497387/",
          video_id: "0",
          time_saved: "2025-02-02 00:31:03",
        },
      },
    },
    {
      source_id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
      target_id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
      data: {
        text_unit_ids:
          "893d74a691487a3b83da5c180cc49157e7d18f60f41ad2cc3eb906f3ba693ccc42bd9551cbfa767f140f7a9f1b36549ca365af00eafa8dde98c34687a602a96e",
        weight: 1,
        description_x:
          "TikTok played a significant role in popularizing and validating the couch as a desirable product",
        human_readable_id: 184,
        n_tokens: 114,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "48bfeac4-3869-4e85-9969-0fe2ae3d6af3",
      source: {
        id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
        name: "TIKTOK",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
        name: "COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
      target_id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
      data: {
        text_unit_ids:
          "cdb31729e036bf275a87c2186a0df85e143a38726bef4a8a3e3b29de47e3d8aa743fb1dcc6dca077b1b8e0e897c0b359f22d4b14d00b06ab2bcf52b6587b0316",
        weight: 7,
        description_x:
          "TikTok endorsed the off-white couch, contributing to its popularity",
        human_readable_id: 364,
        n_tokens: 89,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "27d72f87-531b-4bca-9c9f-d9e636a16188",
      source: {
        id: "a3de5d9b-bb58-4d3b-a48d-25bc7e7e4cca",
        name: "TIKTOK",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
        name: "OFF-WHITE COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
      target_id: "ca1c2ef7-6d7c-4fc7-8de0-377bea639783",
      data: {
        text_unit_ids:
          "893d74a691487a3b83da5c180cc49157e7d18f60f41ad2cc3eb906f3ba693ccc42bd9551cbfa767f140f7a9f1b36549ca365af00eafa8dde98c34687a602a96e",
        weight: 1,
        description_x:
          "The dog is present during the assembly of the couch, indicating a shared living space",
        human_readable_id: 185,
        n_tokens: 114,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "3413d0e2-989a-412d-af4d-989e261b8365",
      source: {
        id: "e174f36b-7bae-43de-a9d5-1fc949c361db",
        name: "COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "ca1c2ef7-6d7c-4fc7-8de0-377bea639783",
        name: "DOG",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "108284c7-c07b-4918-9f6b-f2e01adb6c04",
      target_id: "4028376c-5840-4be4-a2a2-b49f9c868cb1",
      data: {
        text_unit_ids:
          "3e8ab88f7a16413fca2e44432ee266ce4a62e31c32efc1bcca6c5a35ec26a56d3a1285d264843c73ab933b37f1a16ad41774c68509feaa6d385a199aec75b24f",
        weight: 1,
        description_x:
          "The LSAT 27-18-9 rule is applied to manage time effectively during the reading comprehension timed sections",
        human_readable_id: 186,
        n_tokens: 121,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "f3a90ba4-7ea5-495a-aa3b-8d4b5cb3c370",
      source: {
        id: "108284c7-c07b-4918-9f6b-f2e01adb6c04",
        name: "LSAT 27-18-9 RULE",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
      target: {
        id: "4028376c-5840-4be4-a2a2-b49f9c868cb1",
        name: "READING COMPREHENSION TIMED SECTIONS",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      target_id: "651f18e4-3106-4a30-821e-44a36aaacc93",
      data: {
        text_unit_ids:
          "863a0f38efc84e4b70933148fd2a9f813b17b3c47bd3b2db72cdbc91debb3ea0066ca497e9a10b50a41b60a2778ae2d5423f1da5c16eefc755a34a7c80351740",
        weight: 5,
        description_x:
          "Both McBean Immigration Law and ILRC are highlighted as trusted resources for information on immigration law",
        human_readable_id: 188,
        n_tokens: 119,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "22d30a7f-9fe6-417c-a3cb-9bb6bc4a31c5",
      source: {
        id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
        name: "MCBEAN IMMIGRATION LAW",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "651f18e4-3106-4a30-821e-44a36aaacc93",
        name: "ILRC",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      target_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 8,
        description_x:
          "MCBEAN IMMIGRATION LAW and JUNTOSSEGUROS.COM are both recognized as valuable resources for immigration-related information. They are involved in providing resources and support for immigrants, offering guidance and assistance to those navigating the complexities of immigration processes.",
        human_readable_id: 189,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "79ad8d9b-bd2f-4dd7-b0a7-18197e9d3370",
      source: {
        id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
        name: "MCBEAN IMMIGRATION LAW",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      target_id: "c0e350e3-59a6-4aae-b968-467057a3945f",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 5,
        description_x:
          "Both organizations provide resources and support related to immigration law",
        human_readable_id: 330,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "840f9ada-4f10-4f33-ac36-c7627d4900be",
      source: {
        id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
        name: "MCBEAN IMMIGRATION LAW",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "c0e350e3-59a6-4aae-b968-467057a3945f",
        name: "IMMIGRANT LEGAL RESOURCE CENTER",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
      target_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 3,
        description_x:
          "Resourcefulness involves guiding others to trusted information, such as that provided by McBean Immigration Law",
        human_readable_id: 331,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "b77a2d3f-8017-4542-ab90-7167ccaddf70",
      source: {
        id: "b73287fa-5c90-456a-a7d9-6ba7b154285e",
        name: "MCBEAN IMMIGRATION LAW",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "651f18e4-3106-4a30-821e-44a36aaacc93",
      target_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      data: {
        text_unit_ids:
          "863a0f38efc84e4b70933148fd2a9f813b17b3c47bd3b2db72cdbc91debb3ea0066ca497e9a10b50a41b60a2778ae2d5423f1da5c16eefc755a34a7c80351740",
        weight: 1,
        description_x:
          "Both ILRC and Juntosseguros.com are mentioned as resources for immigration-related information",
        human_readable_id: 190,
        n_tokens: 119,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "d0e8a6b1-9d6d-43cd-b705-d935739c7795",
      source: {
        id: "651f18e4-3106-4a30-821e-44a36aaacc93",
        name: "ILRC",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      target_id: "0324cd44-8202-46e7-b804-7ff420ef368e",
      data: {
        text_unit_ids:
          "863a0f38efc84e4b70933148fd2a9f813b17b3c47bd3b2db72cdbc91debb3ea0066ca497e9a10b50a41b60a2778ae2d5423f1da5c16eefc755a34a7c80351740",
        weight: 1,
        description_x:
          "Juntosseguros.com provides information on the locations of ICE raids",
        human_readable_id: 191,
        n_tokens: 119,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "f400f56a-67a5-4cfd-9d6d-50c82746c9be",
      source: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "0324cd44-8202-46e7-b804-7ff420ef368e",
        name: "ICE",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
      target_id: "c0e350e3-59a6-4aae-b968-467057a3945f",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 1,
        description_x:
          "Both organizations focus on supporting immigrants through legal assistance and information",
        human_readable_id: 332,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "b0aaca4a-6e2b-45bc-b4da-f2eaca82a304",
      source: {
        id: "ef892566-175a-4cdb-8a0f-ddc93daee217",
        name: "JUNTOSSEGUROS.COM",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "c0e350e3-59a6-4aae-b968-467057a3945f",
        name: "IMMIGRANT LEGAL RESOURCE CENTER",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "0324cd44-8202-46e7-b804-7ff420ef368e",
      target_id: "cbfe8634-3213-4dd0-9edc-30992801517a",
      data: {
        text_unit_ids:
          "8275ae28ee88227ea9838bc94f4d32dcac048145fa6a92ec82bb016936320f6dd2f515656c33b7c7d9ebe91bc720697661fa45f6230139e64407ff17af0cc4f0",
        weight: 1,
        description_x:
          "The Fifth Amendment rights were invoked in response to ICE agents' inquiries",
        human_readable_id: 336,
        n_tokens: 100,
        title: "Gloria Cardenas on TikTok",
        description_y:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7443202459209583914.mp4",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
      id: "3ede00e7-727b-4fc0-bf24-ab04bbbabba1",
      source: {
        id: "0324cd44-8202-46e7-b804-7ff420ef368e",
        name: "ICE",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "cbfe8634-3213-4dd0-9edc-30992801517a",
        name: "FIFTH AMENDMENT",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
    },
    {
      source_id: "0324cd44-8202-46e7-b804-7ff420ef368e",
      target_id: "9751441f-025b-4fb6-a313-5b00a59d9bc3",
      data: {
        text_unit_ids:
          "8275ae28ee88227ea9838bc94f4d32dcac048145fa6a92ec82bb016936320f6dd2f515656c33b7c7d9ebe91bc720697661fa45f6230139e64407ff17af0cc4f0",
        weight: 4,
        description_x:
          "ICE's actions are subject to the rights and protections provided by the U.S. Constitution",
        human_readable_id: 337,
        n_tokens: 100,
        title: "Gloria Cardenas on TikTok",
        description_y:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7443202459209583914.mp4",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
      id: "952e92a4-7f62-45fa-924e-d9d26e235e38",
      source: {
        id: "0324cd44-8202-46e7-b804-7ff420ef368e",
        name: "ICE",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "9751441f-025b-4fb6-a313-5b00a59d9bc3",
        name: "U.S. CONSTITUTION",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
    },
    {
      source_id: "0324cd44-8202-46e7-b804-7ff420ef368e",
      target_id: "8b5c5e3f-6100-4787-956f-780f222ad01a",
      data: {
        text_unit_ids:
          "8275ae28ee88227ea9838bc94f4d32dcac048145fa6a92ec82bb016936320f6dd2f515656c33b7c7d9ebe91bc720697661fa45f6230139e64407ff17af0cc4f0",
        weight: 1,
        description_x:
          "ICE agents arrived at the residence looking for an individual",
        human_readable_id: 338,
        n_tokens: 100,
        title: "Gloria Cardenas on TikTok",
        description_y:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7443202459209583914.mp4",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
      id: "b03caf57-5766-48c0-bc30-f7859cafdd6e",
      source: {
        id: "0324cd44-8202-46e7-b804-7ff420ef368e",
        name: "ICE",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "8b5c5e3f-6100-4787-956f-780f222ad01a",
        name: "RESIDENCE",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
    },
    {
      source_id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
      target_id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 1,
        description_x:
          "The pastor's confirmation of a soul tie being broken is related to the message of Proverbs 26:11 about not returning to detrimental relationships",
        human_readable_id: 192,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "3c62b449-675c-4e40-affd-7481cc61604d",
      source: {
        id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
        name: "PROVERBS 26:11",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
        name: "PASTOR",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
      target_id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 1,
        description_x:
          "The concept of a soul tie is related to the message of Proverbs 26:11 about returning to detrimental relationships",
        human_readable_id: 193,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "7fa3fc05-108b-4753-a60c-db55044d14ee",
      source: {
        id: "e92f2830-95cf-4552-8cdd-420cfa1c9132",
        name: "PROVERBS 26:11",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
        name: "SOUL TIE",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
      target_id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 6,
        description_x:
          "The pastor confirmed the breaking of the soul tie after the three-day fast",
        human_readable_id: 194,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "ed988d54-c360-4d69-ada1-8f5210a72186",
      source: {
        id: "3ed8e0ff-2c56-49d8-97c8-152f182dd96c",
        name: "PASTOR",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
        name: "THREE-DAY FAST",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
      target_id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
      data: {
        text_unit_ids:
          "ec3374e02bf5d7746976695eec74cbeaebf09a7dd8c52043a4966ca9b0b2e59e93d721b3832adf2cb22255cf4268849f1aa9e005280e6b1be0b7121aaaf4b2c4",
        weight: 7,
        description_x:
          "The three-day fast was undertaken to break the soul tie",
        human_readable_id: 195,
        n_tokens: 179,
        title: "Vanessa Elaine on TikTok",
        description_y: "#christiantiktok #proverbs31woman #lifelessons ",
        url: "https://www.tiktokv.com/share/video/7464073695967907115/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464073695967907115.mp4",
        video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
        time_saved: "2025-01-27 06:19:44",
      },
      id: "6fa09273-1ab6-4673-a3d2-f8268a71d47a",
      source: {
        id: "93ec4222-043f-427f-a125-1ad5a9b6fe32",
        name: "SOUL TIE",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
      target: {
        id: "d845eada-4e12-42d6-8eaf-aacd6bc75d91",
        name: "THREE-DAY FAST",
        group: 1,
        data: {
          title: "Vanessa Elaine on TikTok",
          description: "#christiantiktok #proverbs31woman #lifelessons ",
          url: "https://www.tiktokv.com/share/video/7464073695967907115/",
          video_id: "f929b0d1-638e-4cd3-bc52-2eac2c8764bc",
          time_saved: "2025-01-27 06:19:44",
        },
      },
    },
    {
      source_id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
      target_id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
      data: {
        text_unit_ids:
          "9d3ab01f5678be5681ea9fb96c10e9e11dab35fafd96012d40c4efd6e92bbd6d5120419be2692c0b0b6244094a70c74017eaca482b1fcc0d30528000af6f0312",
        weight: 8,
        description_x:
          "The 27-18-9 rule is a strategy specifically designed for the reading comprehension section of the LSAT",
        human_readable_id: 205,
        n_tokens: 91,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "5671ec87-fcbb-4087-8620-88a56f2f6afe",
      source: {
        id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
        name: "LAW SCHOOL ADMISSION TEST",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
      target: {
        id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
        name: "27-18-9 RULE",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
      target_id: "7377c763-102f-4452-bbb8-f22487ebde3f",
      data: {
        text_unit_ids:
          "9d3ab01f5678be5681ea9fb96c10e9e11dab35fafd96012d40c4efd6e92bbd6d5120419be2692c0b0b6244094a70c74017eaca482b1fcc0d30528000af6f0312",
        weight: 9,
        description_x:
          "The reading comprehension section is a component of the LSAT",
        human_readable_id: 206,
        n_tokens: 91,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "dcfc283b-7952-4ea3-9e92-e99eb00c40f6",
      source: {
        id: "0fea591b-5595-4cd4-ac05-6d3034f6d1a6",
        name: "LAW SCHOOL ADMISSION TEST",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
      target: {
        id: "7377c763-102f-4452-bbb8-f22487ebde3f",
        name: "READING COMPREHENSION SECTION",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
      target_id: "7377c763-102f-4452-bbb8-f22487ebde3f",
      data: {
        text_unit_ids:
          "9d3ab01f5678be5681ea9fb96c10e9e11dab35fafd96012d40c4efd6e92bbd6d5120419be2692c0b0b6244094a70c74017eaca482b1fcc0d30528000af6f0312",
        weight: 1,
        description_x:
          "The 27-18-9 rule provides a time management strategy for the reading comprehension section",
        human_readable_id: 207,
        n_tokens: 91,
        title: "theburtonmethod on TikTok",
        description_y:
          "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
        url: "https://www.tiktokv.com/share/video/7435820692387499294/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7435820692387499294.mp4",
        video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
        time_saved: "2025-01-11 03:27:54",
      },
      id: "d355409d-f519-4130-96cd-d9ddc61e0970",
      source: {
        id: "f77f6bd6-7502-44f2-9882-709487e3a8d2",
        name: "27-18-9 RULE",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
      target: {
        id: "7377c763-102f-4452-bbb8-f22487ebde3f",
        name: "READING COMPREHENSION SECTION",
        group: 1,
        data: {
          title: "theburtonmethod on TikTok",
          description:
            "LSAT 27-18-9 rule #fyp #lsat #lawschool #prelaw #futurelawyer #studytok ",
          url: "https://www.tiktokv.com/share/video/7435820692387499294/",
          video_id: "7ca4f823-f59c-4b47-a1ad-b37c5ed274bf",
          time_saved: "2025-01-11 03:27:54",
        },
      },
    },
    {
      source_id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
      target_id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 9,
        description_x:
          "CeraVe Hydrating Facial Cleanser is a product offered by the CeraVe brand",
        human_readable_id: 218,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "cf5733f7-8718-4c34-bc7c-fd0ff3add608",
      source: {
        id: "8a78cec8-2ed0-419b-b8c2-0b2ec937d1b5",
        name: "CERAVE HYDRATING FACIAL CLEANSER",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
        name: "CERAVE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
      target_id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 9,
        description_x:
          "CeraVe Daily Moisturizing Lotion is a product offered by the CeraVe brand",
        human_readable_id: 219,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "43070cca-9e88-43f4-991d-cd199af4c48a",
      source: {
        id: "6573c168-7f4a-4fb0-b0ae-ba2be8dce559",
        name: "CERAVE DAILY MOISTURIZING LOTION",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "30e74974-3a9e-48f8-a9b7-3b5a77304aab",
        name: "CERAVE",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
      target_id: "979d0892-ba23-4572-a746-320e71e24750",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 9,
        description_x:
          "EltaMD Tinted Moisturizing Sunscreen is a product offered by the EltaMD brand",
        human_readable_id: 220,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "54350872-d9e6-4380-96c6-b32ef537a6b2",
      source: {
        id: "9784d35a-d60b-44cd-926d-1b14604bd13f",
        name: "ELTAMD TINTED MOISTURIZING SUNSCREEN",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "979d0892-ba23-4572-a746-320e71e24750",
        name: "ELTAMD",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
      target_id: "25626dfa-ad22-4270-af3a-2d3b6d54c141",
      data: {
        text_unit_ids:
          "7ab1ef4ccd2b5e185d7cedd725b3df71496626e17a4c724796133c25e1541c6d0f1868f734e9c6da905e178627ab7a83baee35cb52aa87de595ae9a97cc7e208",
        weight: 1,
        description_x:
          "MISSHA M Perfect Cover BB Cream No. 27 is a product offered by the MISSHA brand",
        human_readable_id: 221,
        n_tokens: 221,
        title: "AlexAcosta-RubioBright on TikTok",
        description_y:
          "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
        url: "https://www.tiktokv.com/share/video/7335243212774231339/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7335243212774231339.mp4",
        video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
        time_saved: "2025-01-08 05:34:50",
      },
      id: "99fb75b4-2a27-4e85-836a-cab0f95548d9",
      source: {
        id: "6bb84f2c-643c-4341-9aad-8db3390dfb5f",
        name: "MISSHA M PERFECT COVER BB CREAM NO. 27",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
      target: {
        id: "25626dfa-ad22-4270-af3a-2d3b6d54c141",
        name: "MISSHA",
        group: 1,
        data: {
          title: "AlexAcosta-RubioBright on TikTok",
          description:
            "I’m not sponsored by any of these, but I very much wish I was based off of how much I genuinely use these products! ##accutane##accutanemusthaves##accutaneproducts##skincare##dryskin##dryskincare",
          url: "https://www.tiktokv.com/share/video/7335243212774231339/",
          video_id: "f83549ab-e731-4586-a275-89fb3831d9f5",
          time_saved: "2025-01-08 05:34:50",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "886d1720-4657-46d8-921c-05724c65cd76",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 1,
        description_x:
          "Stanford University has a specific college application process that includes essay writing and activity prioritization",
        human_readable_id: 222,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "fd2c7868-0bcb-4735-a0dd-f624f57aeb04",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "886d1720-4657-46d8-921c-05724c65cd76",
        name: "COLLEGE APPLICATION PROCESS",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 8,
        description_x:
          "Stanford University's application process includes specific essay writing requirements",
        human_readable_id: 223,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "56f9a935-1c8d-418a-805f-ffde420ddaff",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 8,
        description_x:
          "Stanford University's application process involves prioritizing extracurricular activities",
        human_readable_id: 224,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "e24eed1d-67c1-4feb-bbb1-d5c0d17576b7",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
        name: "ACTIVITY PRIORITIZATION",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "6be95519-2b3d-4e42-bfe0-a2170ea073a5",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 8,
        description_x:
          'The "Biggest Challenge Society Faces" essay is part of the Stanford University application',
        human_readable_id: 391,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "9954b88e-4724-4937-9023-fcb04ac8ae88",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "6be95519-2b3d-4e42-bfe0-a2170ea073a5",
        name: '"BIGGEST CHALLENGE SOCIETY FACES" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "d4435904-02f6-4735-8742-2ad44ef292fc",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 8,
        description_x:
          'The "Historical Moment" essay is part of the Stanford University application',
        human_readable_id: 392,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "14dcc63f-e2a4-4862-bbc1-60c2b0439d3f",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "d4435904-02f6-4735-8742-2ad44ef292fc",
        name: '"HISTORICAL MOMENT" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "bd2c3241-e0c0-45d6-8081-95177785c0fd",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 8,
        description_x:
          'The "Last Two Summers" prompt is part of the Stanford University application',
        human_readable_id: 393,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "78fd3834-9419-4024-ac98-e4583ddfa1c3",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "bd2c3241-e0c0-45d6-8081-95177785c0fd",
        name: '"LAST TWO SUMMERS" PROMPT',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "8bdbc9dc-0390-4e37-840c-8012b3b7be3c",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 8,
        description_x:
          'The "Five Important Things" essay is part of the Stanford University application',
        human_readable_id: 394,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "83bc7c11-e84e-423b-b9ea-aec86e04f046",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "8bdbc9dc-0390-4e37-840c-8012b3b7be3c",
        name: '"FIVE IMPORTANT THINGS" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "e927ce12-1d0f-4c9a-918d-fa43ff85a3af",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 8,
        description_x:
          'The "Roommate" essay is part of the Stanford University application',
        human_readable_id: 395,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "17a3113d-817a-4236-885f-056b5e2afa9a",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "e927ce12-1d0f-4c9a-918d-fa43ff85a3af",
        name: '"ROOMMATE" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "1d33e06a-b745-4352-87d8-2dca42f48c9a",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 8,
        description_x:
          'The "An Idea That Excites You" essay is part of the Stanford University application',
        human_readable_id: 396,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "ebce07e9-78b8-45ac-9ba7-9dd86526e7de",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "1d33e06a-b745-4352-87d8-2dca42f48c9a",
        name: '"AN IDEA THAT EXCITES YOU" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "c2bc915d-4b1e-4520-8b24-48d0e84228d8",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 1,
        description_x:
          'The "Distinctive Contribution" essay is part of the Stanford University application',
        human_readable_id: 397,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "de3dd32d-2548-4faa-ac02-84bf9bb63b14",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "c2bc915d-4b1e-4520-8b24-48d0e84228d8",
        name: '"DISTINCTIVE CONTRIBUTION" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
      target_id: "b25974e8-2520-4533-81a9-f255e0c13866",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 9,
        description_x:
          "The application tips are specifically for applicants to Stanford University",
        human_readable_id: 398,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "e382f0e8-364a-4573-b9ab-442f58aab888",
      source: {
        id: "db071636-5c88-4f61-a67e-8c3cf6b85e95",
        name: "STANFORD UNIVERSITY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "b25974e8-2520-4533-81a9-f255e0c13866",
        name: "APPLICATION TIPS",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "13659935-4065-4988-8e18-8dab82c574f9",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          'The essay writing process includes addressing the "biggest challenge society faces" prompt',
        human_readable_id: 226,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "b3476cbc-231b-41b9-ad5b-e36c609eabb1",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "13659935-4065-4988-8e18-8dab82c574f9",
        name: "BIGGEST CHALLENGE SOCIETY FACES PROMPT",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "017de81e-714d-401f-b1da-fa549eb5fe98",
      target_id: "1c4f288b-3c69-4164-b618-b5da44755e25",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 1,
        description_x:
          "A potted plant is placed beside the Sony PS-LX310BT Wireless Turntable",
        human_readable_id: 265,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "4892bd0d-153b-4544-a389-d7b1fb10cb62",
      source: {
        id: "017de81e-714d-401f-b1da-fa549eb5fe98",
        name: "SONY PS-LX310BT WIRELESS TURNTABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "1c4f288b-3c69-4164-b618-b5da44755e25",
        name: "PLANT",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "93440a23-ab4a-414f-bb20-94c17e8329cf",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          'The essay writing process includes addressing the "historical moment" question',
        human_readable_id: 227,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "8add9d13-0a1b-4622-8138-47ba8ada36d6",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "93440a23-ab4a-414f-bb20-94c17e8329cf",
        name: "HISTORICAL MOMENT QUESTION",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "f1abda8b-8a72-463b-ab02-84bdb4d8be3b",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          'The essay writing process includes composing a "roommate essay" focusing on personal background',
        human_readable_id: 228,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "7a5f2d67-525d-4b9c-825c-c8558fa820ef",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "f1abda8b-8a72-463b-ab02-84bdb4d8be3b",
        name: "ROOMMATE ESSAY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
      target_id: "2decae6e-eb04-4fa1-9bfc-c061045a275a",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 7,
        description_x:
          "The essay writing process includes a final essay focusing on personal values and upbringing",
        human_readable_id: 229,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "66e7dd1c-c52d-4f99-ba4c-6df10765b944",
      source: {
        id: "8c35a993-d118-4f6f-8559-28a6de1b8ff5",
        name: "ESSAY WRITING",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "2decae6e-eb04-4fa1-9bfc-c061045a275a",
        name: "FINAL ESSAY",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
      target_id: "cf0c8784-59b6-4b49-8957-eac895827848",
      data: {
        text_unit_ids:
          "4fdffe23addad6fd3c9ded659caaec6a820eb253d1ae865b6bc28ad0c1afaec00fb52450fcc63b232a4591a023e6100b3dd763c3d518b263046586ca9fd6cb24",
        weight: 1,
        description_x:
          "Activity prioritization involves selecting key extracurricular activities to highlight",
        human_readable_id: 225,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "e0f3baf5-50d6-4789-b99e-ff05f38be2b7",
      source: {
        id: "11940b8a-9170-4882-a29f-abf5ed6297f9",
        name: "ACTIVITY PRIORITIZATION",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "cf0c8784-59b6-4b49-8957-eac895827848",
        name: "EXTRACURRICULAR ACTIVITIES",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "86854636-36a3-4713-a8bd-fcc970f65198",
      target_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      data: {
        text_unit_ids:
          "9004cccb8df0f556f15a518b11ff923d7d0052747f0fb24b952ceaef7c44f5e6c9d71dbf222a175d9896a19854f047f08a1fdc2c3243052a20a6d28e30f7445c",
        weight: 1,
        description_x:
          "OneClickWorker.com provides tools and resources to help event managers prepare for job interviews",
        human_readable_id: 230,
        n_tokens: 146,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "30d875d6-d1aa-4cf1-9eda-d69f3e0e296d",
      source: {
        id: "86854636-36a3-4713-a8bd-fcc970f65198",
        name: "ONECLICKWORKER.COM",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "86854636-36a3-4713-a8bd-fcc970f65198",
      target_id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 8,
        description_x:
          "OneClickWorker.com provides tools and resources to help candidates prepare for job interviews",
        human_readable_id: 415,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "e42c579d-b62b-43a7-b513-df4e94ac698e",
      source: {
        id: "86854636-36a3-4713-a8bd-fcc970f65198",
        name: "ONECLICKWORKER.COM",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
        name: "JOB INTERVIEW",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "34d1996e-bb34-4718-a709-a5e1b0190a7c",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 3,
        description_x: "The name Rina appears on a poster within the apartment",
        human_readable_id: 368,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "930a9a6a-6ae5-4593-b869-6a592511dc93",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "34d1996e-bb34-4718-a709-a5e1b0190a7c",
        name: "RINA",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      target_id: "c52eb868-b399-408b-b1db-be0c56473d56",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 15,
        description_x:
          "An event manager is responsible for the planning, management, and execution of corporate conferences. They handle all aspects of organizing these events, ensuring that every detail is meticulously planned and executed to meet the objectives of the corporate conference.",
        human_readable_id: 231,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "cab7dae0-7c12-4706-8f35-240aa109b736",
      source: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "c52eb868-b399-408b-b1db-be0c56473d56",
        name: "CORPORATE CONFERENCES",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      target_id: "22d9e728-de3f-426e-a059-3ac1a0f5515c",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 15,
        description_x:
          "An event manager is responsible for organizing, planning, and managing weddings. They play a crucial role in ensuring that all aspects of a wedding are coordinated smoothly, from the initial planning stages to the execution of the event. Event managers work closely with clients to understand their vision and requirements, and they oversee all logistical details to ensure a successful and memorable wedding experience.",
        human_readable_id: 232,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "3b58c6c3-9e8e-46fc-abf1-f3db84ed8e83",
      source: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "22d9e728-de3f-426e-a059-3ac1a0f5515c",
        name: "WEDDINGS",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      target_id: "f693cdbb-8cd0-430a-b1eb-dfa607cecf74",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 9,
        description_x:
          "An event manager is responsible for planning and managing community festivals. They play a crucial role in organizing these events, ensuring that all aspects are coordinated effectively to create a successful and enjoyable experience for the community.",
        human_readable_id: 233,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "e8d5e634-8c38-48dc-9afb-e693d9c9681b",
      source: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "f693cdbb-8cd0-430a-b1eb-dfa607cecf74",
        name: "COMMUNITY FESTIVALS",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "718d313e-4e89-42bf-ae98-a936669eb238",
      target_id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
      data: {
        text_unit_ids:
          "4f33e5ae14177208797e5f77eeb14e64f2d5518d52e600d4509fda65dee2adc8749126b2b9a20ba706764abff63f41d3e0c2cdd08269a2fc54a5097210abce55",
        weight: 8,
        description_x:
          "Event managers frequently participate in job interviews as a means to showcase their expertise and capabilities in the field of event planning and management. During the job interview process, an event manager's experience and skills are thoroughly assessed to determine their suitability for the role. This evaluation is crucial in ensuring that the event manager possesses the necessary qualifications and competencies to effectively manage and execute events.",
        human_readable_id: 234,
        n_tokens: 145,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "5fd35b28-31ce-452c-86da-b5ff3d20b8f5",
      source: {
        id: "718d313e-4e89-42bf-ae98-a936669eb238",
        name: "EVENT MANAGER",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
        name: "JOB INTERVIEW",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "f6cf40a0-4374-4061-a4f7-88f281a6a04f",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 5,
        description_x:
          "A projection of Calcifer from Howl's Moving Castle is displayed on the wall of the apartment",
        human_readable_id: 369,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "fcebb073-cd97-475a-9d69-aff3057e79f1",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "f6cf40a0-4374-4061-a4f7-88f281a6a04f",
        name: "HOWL'S MOVING CASTLE",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
      target_id: "8890a70d-3414-44ac-a1a1-ea2d6488588d",
      data: {
        text_unit_ids:
          "9004cccb8df0f556f15a518b11ff923d7d0052747f0fb24b952ceaef7c44f5e6c9d71dbf222a175d9896a19854f047f08a1fdc2c3243052a20a6d28e30f7445c",
        weight: 1,
        description_x:
          "AI helps candidates prepare for job interviews by providing unique answers and increasing confidence",
        human_readable_id: 235,
        n_tokens: 146,
        title: "One Click Worker on TikTok",
        description_y:
          "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
        url: "https://www.tiktokv.com/share/video/7384682133022903557/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7384682133022903557.mp4",
        video_id: "0",
        time_saved: "2025-01-22 02:49:49",
      },
      id: "7ba50cbd-c749-4564-8105-ac9b52b26f5f",
      source: {
        id: "aa328f9f-8751-44ac-ae40-7c02ca8f433d",
        name: "JOB INTERVIEW",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
      target: {
        id: "8890a70d-3414-44ac-a1a1-ea2d6488588d",
        name: "AI",
        group: 1,
        data: {
          title: "One Click Worker on TikTok",
          description:
            "Job interview questions and answers for event manager positions: Example 1. To see the 50 most important interview questions AND get FREE access to the most simple to use (and advanced) interview preparation tool on the planet, click the link in bio or go to https://oneclickworker.com/interview-questions. Prepare for an event manager job interview by getting ready to answer common event manager interview questions. Our specialized AI chatbot for event manager job interviews assists with all aspects of preparing for an event manager interview, including general job interview tips for event managers. The AI chatbot won’t just generate interview questions for event managers, you’ll get event manager job interview questions AND answers. Answer event manager interview questions with more confidence after this event manager interview preparation. The event manager interview tips and event manager interview strategies from the chatbot are customized for you - nothing generic. The chatbot is a tool for learning how to answer event manager interview questions and a event manager job interview guide that gives you tips for a event manager job interview and increases the chances of event manager interview success. OneClickWorker.com Cover letters: Generate custom cover letters based on a specific job description and your background - in less than 1 minute! Interview questions: Customized answers to every common interview question based on your specific target job and your resume. Career building: Customized advice about how to advance your career based on your specific circumstances and goals. #eventmanagerinterview #eventmanagerinterviewquestions #eventmanagerinterviewpreparation #eventmanagerjobinterview #eventmanagerinterviewtips #eventmanagerinterviewanswers #oneclickworker #interviewquestions",
          url: "https://www.tiktokv.com/share/video/7384682133022903557/",
          video_id: "0",
          time_saved: "2025-01-22 02:49:49",
        },
      },
    },
    {
      source_id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      target_id: "23204505-dfc4-4f08-8e1e-ba69bb6359c7",
      data: {
        text_unit_ids:
          "b8f49276c56c2f04e0e49475149a0e6f876568bbdb0417c3630ad18c6d732290a880f665833220a4c47ccbd692c961d4e64b9b2406cf495a2ead6d13d2c5a41d",
        weight: 2,
        description_x:
          "The Marshall-Motley Scholars Program is sponsored by the NAACP",
        human_readable_id: 236,
        n_tokens: 105,
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description_y:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7442712485888806190.mp4",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
      id: "a8424f2e-13d2-4f91-8502-1090f0211705",
      source: {
        id: "35d036f3-2823-4e6a-9fa1-326257353a03",
        name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
      target: {
        id: "23204505-dfc4-4f08-8e1e-ba69bb6359c7",
        name: "NAACP",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
    },
    {
      source_id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      target_id: "abda8c0d-6084-4820-925e-6b1301562a16",
      data: {
        text_unit_ids:
          "b8f49276c56c2f04e0e49475149a0e6f876568bbdb0417c3630ad18c6d732290a880f665833220a4c47ccbd692c961d4e64b9b2406cf495a2ead6d13d2c5a41d",
        weight: 16,
        description_x:
          "The MARSHALL-MOTLEY SCHOLARS PROGRAM is designed to cultivate civil rights lawyers who are committed to social justice work specifically in the Southern region. The program places a strong emphasis on developing legal professionals who are dedicated to advancing civil rights and addressing social justice issues in the South.",
        human_readable_id: 237,
        n_tokens: 105,
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description_y:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7442712485888806190.mp4",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
      id: "1aa8c3f9-f241-402b-a1b5-16af91e5cd23",
      source: {
        id: "35d036f3-2823-4e6a-9fa1-326257353a03",
        name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
      target: {
        id: "abda8c0d-6084-4820-925e-6b1301562a16",
        name: "SOUTH",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
    },
    {
      source_id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      target_id: "ac8fbb02-7a30-4280-8c28-2a3f77db9d65",
      data: {
        text_unit_ids:
          "141f5b67aaf29b82266423060b05a91e669bec8f1d495b54ae7868f4b547789fa616cd3da46485ef22c521f38f1213bbf18a7687f1f46fba8c05e6b9629bdf6f",
        weight: 1,
        description_x:
          "February 5th is the application deadline for the program",
        human_readable_id: 238,
        n_tokens: 109,
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description_y:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7442712485888806190.mp4",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
      id: "6050dcb7-e95c-46df-8b64-77aa7a1d4b4c",
      source: {
        id: "35d036f3-2823-4e6a-9fa1-326257353a03",
        name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
      target: {
        id: "ac8fbb02-7a30-4280-8c28-2a3f77db9d65",
        name: "FEBRUARY 5TH",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
    },
    {
      source_id: "35d036f3-2823-4e6a-9fa1-326257353a03",
      target_id: "1d9c5451-723e-446f-8f6c-bf5c389e00f9",
      data: {
        text_unit_ids:
          "b8f49276c56c2f04e0e49475149a0e6f876568bbdb0417c3630ad18c6d732290a880f665833220a4c47ccbd692c961d4e64b9b2406cf495a2ead6d13d2c5a41d",
        weight: 1,
        description_x:
          "The February 5th deadline is the application deadline for the program",
        human_readable_id: 447,
        n_tokens: 105,
        title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
        description_y:
          "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
        url: "https://www.tiktokv.com/share/video/7442712485888806190/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7442712485888806190.mp4",
        video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
        time_saved: "2025-01-10 17:05:31",
      },
      id: "3b2c3c80-8acc-40e3-b242-9fa10754ae37",
      source: {
        id: "35d036f3-2823-4e6a-9fa1-326257353a03",
        name: "MARSHALL-MOTLEY SCHOLARS PROGRAM",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
      target: {
        id: "1d9c5451-723e-446f-8f6c-bf5c389e00f9",
        name: "FEBRUARY 5TH DEADLINE",
        group: 1,
        data: {
          title: "Elijah | IR+Pre Law Advice 🌎 on TikTok",
          description:
            "👨🏾‍⚖️Marshall-Motley Scholars Program | Apply by February 5th, 2025 #prelaw #lawschool #careeradvice #government #lawyer #civilrights #election #NAACP #black #supremecourt ",
          url: "https://www.tiktokv.com/share/video/7442712485888806190/",
          video_id: "51bcec8a-ba96-49c9-9dc8-ee7e4b0ace48",
          time_saved: "2025-01-10 17:05:31",
        },
      },
    },
    {
      source_id: "8db10b44-d57c-48f9-9227-45981320a879",
      target_id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 1,
        description_x:
          "The removal of cloud wallpaper is part of the preparation for the apartment move",
        human_readable_id: 239,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "e37cec8a-fa46-4108-a2c2-f752b691221e",
      source: {
        id: "8db10b44-d57c-48f9-9227-45981320a879",
        name: "APARTMENT MOVE",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "60d4a95d-f20c-4e6e-a782-83965b5e0a6a",
        name: "CLOUD WALLPAPER",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "8db10b44-d57c-48f9-9227-45981320a879",
      target_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      data: {
        text_unit_ids:
          "208be1c286191d854924dd5b47a2fb3bf460650f04abe1d8fda6684fa0a8cb4b2d928fc0e5d660ddb34290a641eaa0731db1f109bc9efbf2208d8faa893b51f3",
        weight: 1,
        description_x: "The apartment move is related to the new location",
        human_readable_id: 240,
        n_tokens: 95,
        title: "jasmeannnn on TikTok",
        description_y:
          "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
        url: "https://www.tiktokv.com/share/video/7280586291387829546/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7280586291387829546.mp4",
        video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
        time_saved: "2025-02-02 00:25:02",
      },
      id: "c44af898-3923-49f7-85a0-8dfe6c75aa59",
      source: {
        id: "8db10b44-d57c-48f9-9227-45981320a879",
        name: "APARTMENT MOVE",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "7f7a0c09-7af7-4044-a7d9-a5862eb03a8c",
      data: {
        text_unit_ids:
          "d77968a305e08b0f09f9673ef96519decadfe71397e7a6cbc9cdaf83850d5b2efb1ca72c57e9be9615286de0968f2946e4c4a8ee015431348b41d0841a759af1",
        weight: 1,
        description_x:
          "The modular sectional sleeper sofa is designed to fit well in apartments due to its compact size",
        human_readable_id: 285,
        n_tokens: 61,
        title: "Hello Kristen on TikTok",
        description_y:
          "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
        url: "https://www.tiktokv.com/share/video/7377867118848593194/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7377867118848593194.mp4",
        video_id: "0",
        time_saved: "2025-02-02 00:45:00",
      },
      id: "96bc7817-e07b-42b3-9f01-e8e45f78270e",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "7f7a0c09-7af7-4044-a7d9-a5862eb03a8c",
        name: "MODULAR SECTIONAL SLEEPER SOFA",
        group: 1,
        data: {
          title: "Hello Kristen on TikTok",
          description:
            "Amazon Modular Sleeper Sectional Sofa ✨ This couch was so easy to put together and looks amazing in my small apartment. They have all kinds of sizes, colors, and modular options for any space. @HONBAY #amazon #amazonfinds #homedecor #couch #sofa #modularsofa #sectionalcouch ",
          url: "https://www.tiktokv.com/share/video/7377867118848593194/",
          video_id: "0",
          time_saved: "2025-02-02 00:45:00",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "219f5408-d1ce-44d6-81d5-1477a216219f",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 1,
        description_x: "Calcifer is projected onto the wall of the apartment",
        human_readable_id: 370,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "9c1b8100-a7a4-4fa4-a5a7-dd5e7e6d76d3",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "219f5408-d1ce-44d6-81d5-1477a216219f",
        name: "CALCIFER",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "8b8cfef4-fe46-415e-a069-56151b41663a",
      target_id: "05a46f1f-98a8-4006-b927-0c1fd58458ec",
      data: {
        text_unit_ids:
          "3479b81ef2abf1179e5b33146d07ba6e06542cb2af9e43d8502128c537c6618fc39bf731663df4fef0681cbfc0b71ba5f7dcc722fe6e9c2c2b71627f1b05cde0",
        weight: 7,
        description_x:
          "The apartment is designed with a 70s aesthetic vibe, incorporating elements typical of that era",
        human_readable_id: 371,
        n_tokens: 66,
        title: "cata haus on TikTok",
        description_y:
          "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
        url: "https://www.tiktokv.com/share/video/7450836822566915350/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7450836822566915350.mp4",
        video_id: "0",
        time_saved: "2025-02-02 04:27:30",
      },
      id: "f4a1a4a0-4018-474f-96a8-004fcceec485",
      source: {
        id: "8b8cfef4-fe46-415e-a069-56151b41663a",
        name: "APARTMENT",
        group: 1,
        data: {
          title: "jasmeannnn on TikTok",
          description:
            "My steam mop hack on how to properly remove peel and stick wallpaper!! Now lets get that security deposit backkk lol #homedecor #roommakeover #peelandstickwallpaper #renterfriendly ",
          url: "https://www.tiktokv.com/share/video/7280586291387829546/",
          video_id: "f9131382-2f5a-450a-a52e-2db0d6d48379",
          time_saved: "2025-02-02 00:25:02",
        },
      },
      target: {
        id: "05a46f1f-98a8-4006-b927-0c1fd58458ec",
        name: "70S AESTHETIC",
        group: 1,
        data: {
          title: "cata haus on TikTok",
          description:
            "One of the best moments this year was us moving to this city and being able to furnish this apartment together (◕ᴗ◕✿) 🧡 • • #interiordesign #interiorstyling #apartmentstyling #cornersofmyhome #apartmentdecor #slowliving  #apartmenttherapy #homevibes #somewhereiwouldliketolive #livingroominspiration #midcenturymodern #cozyhome #vintageinterior #interiorinspo #homedecorinspiration #cozyroom #slowhome ",
          url: "https://www.tiktokv.com/share/video/7450836822566915350/",
          video_id: "0",
          time_saved: "2025-02-02 04:27:30",
        },
      },
    },
    {
      source_id: "26096370-0225-4089-9198-582e07c0702f",
      target_id: "49259c9b-4e62-4a37-adb1-adfcfc789613",
      data: {
        text_unit_ids:
          "fdf62deffd4ce4a62643183c8df1d22ab45c598ae0b41e296dae0b8c6e3657b838c31f2ba03bb3f8205f386cc0863fa01509cf5105bba6c04f150662f03f1c0a",
        weight: 7,
        description_x:
          "The transformation involved decorating the apartment with a neutral color palette and furnishings",
        human_readable_id: 249,
        n_tokens: 162,
        title: "Sarah Elizabeth on TikTok",
        description_y:
          "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
        url: "https://www.tiktokv.com/share/video/7432918152297614634/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7432918152297614634.mp4",
        video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
        time_saved: "2025-02-02 03:36:08",
      },
      id: "d2408170-c786-47cc-8aa7-b4f8cfb9df42",
      source: {
        id: "26096370-0225-4089-9198-582e07c0702f",
        name: "TRANSFORMATION",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
          url: "https://www.tiktokv.com/share/video/7432918152297614634/",
          video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
          time_saved: "2025-02-02 03:36:08",
        },
      },
      target: {
        id: "49259c9b-4e62-4a37-adb1-adfcfc789613",
        name: "DECOR",
        group: 1,
        data: {
          title: "Sarah Elizabeth on TikTok",
          description:
            "6 months in my little studio 🏠 #apartmenttour #studioapartment #nyc #homedecor #newyork #homedesign",
          url: "https://www.tiktokv.com/share/video/7432918152297614634/",
          video_id: "9908b630-0ae0-4c94-b6f6-0e9fe52706fd",
          time_saved: "2025-02-02 03:36:08",
        },
      },
    },
    {
      source_id: "e9ce7003-de15-45ad-8e94-2a7b5e6f8c99",
      target_id: "ff3329fc-8218-4cec-8e7b-8a1d8f5afc88",
      data: {
        text_unit_ids:
          "a30161d8fe31e3170b36271f8b4b8831473596321e03760d93fc9b74b81c522555b12fe8ad1da160db20ee7573ca5ce5b056c39f16870382d69de1f73e866bae",
        weight: 1,
        description_x:
          "The personal statement critiques the idea that Ivy League schools are the only places to pursue meaningful work in racial justice",
        human_readable_id: 251,
        n_tokens: 269,
        title: "Hadiyah | LawyHer🪩 on TikTok",
        description_y:
          "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
        url: "https://www.tiktokv.com/share/video/7189763520165121322/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7189763520165121322.mp4",
        video_id: "0",
        time_saved: "2025-01-09 20:55:02",
      },
      id: "12ee8074-cb85-481d-af83-0059fead0a49",
      source: {
        id: "e9ce7003-de15-45ad-8e94-2a7b5e6f8c99",
        name: "IVY LEAGUE SCHOOLS",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
      target: {
        id: "ff3329fc-8218-4cec-8e7b-8a1d8f5afc88",
        name: "PERSONAL STATEMENT",
        group: 1,
        data: {
          title: "Hadiyah | LawyHer🪩 on TikTok",
          description:
            "This is just ONE example!! There are manyyyy ways to wirte an amazing PS! The key takeway that good writing matters and taking time with your personal statement (this person has been working for months on it) can help get you there! #prelawadvice #personalstatement #lawtok #yalelaw #blackgirlsdolaw ",
          url: "https://www.tiktokv.com/share/video/7189763520165121322/",
          video_id: "0",
          time_saved: "2025-01-09 20:55:02",
        },
      },
    },
    {
      source_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      target_id: "e557f283-b55a-4900-9b35-c32668ca039a",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          "Books are styled on the Round Wood Coffee Table as part of the decor",
        human_readable_id: 260,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "9c677724-e3b3-4448-b853-bde2d9799f95",
      source: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "e557f283-b55a-4900-9b35-c32668ca039a",
        name: "BOOKS",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      target_id: "0dcef8d7-abb7-48d2-af97-a04e4f696b11",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          "A gaming controller is part of the setup on the Round Wood Coffee Table",
        human_readable_id: 261,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "6b51e1de-d598-4a46-9c82-d1447fb0ab02",
      source: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "0dcef8d7-abb7-48d2-af97-a04e4f696b11",
        name: "GAMING CONTROLLER",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
      target_id: "606ec4ed-b7da-4cd8-b054-e4f2ea79aade",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          "A mug is part of the setup on the Round Wood Coffee Table",
        human_readable_id: 262,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "89451d7d-ceec-46a5-a4b7-9a04b1a95ce0",
      source: {
        id: "4c26cb4d-a86e-412e-a15f-a740dad3c9f6",
        name: "ROUND WOOD COFFEE TABLE",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "606ec4ed-b7da-4cd8-b054-e4f2ea79aade",
        name: "MUG",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "5a610178-5f79-4cb5-8041-867ab2a41874",
      target_id: "73fdea2f-ff0f-47a4-b245-c6ce6a756e02",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          'The toy rocket is a decorative item on the Yusong 73" Tall Bookshelf',
        human_readable_id: 263,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "397cecf0-d47c-46bd-a2f2-1e2989adc70e",
      source: {
        id: "5a610178-5f79-4cb5-8041-867ab2a41874",
        name: 'YUSONG 73" TALL BOOKSHELF',
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "73fdea2f-ff0f-47a4-b245-c6ce6a756e02",
        name: "TOY ROCKET",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "5a610178-5f79-4cb5-8041-867ab2a41874",
      target_id: "83ea678e-7e1e-4a31-bfb1-f8898bae71b6",
      data: {
        text_unit_ids:
          "1e46a4e9a4fe43e8bc7a6ea2d2414343f11a5085a162b5f622fc7f69c2c0efb737ea617de07c9f7d82ff2bb46bc47af3342c22d084d04166fb071198f4177f9e",
        weight: 6,
        description_x:
          'The model car is a decorative item on the Yusong 73" Tall Bookshelf',
        human_readable_id: 264,
        n_tokens: 189,
        title: "John Baptist on TikTok",
        description_y:
          "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
        url: "https://www.tiktokv.com/share/video/7434976612132637994/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7434976612132637994.mp4",
        video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
        time_saved: "2025-02-02 00:50:45",
      },
      id: "0f840872-b9e8-49f5-9ccd-9c6ab7e397e8",
      source: {
        id: "5a610178-5f79-4cb5-8041-867ab2a41874",
        name: 'YUSONG 73" TALL BOOKSHELF',
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
      target: {
        id: "83ea678e-7e1e-4a31-bfb1-f8898bae71b6",
        name: "MODEL CAR",
        group: 1,
        data: {
          title: "John Baptist on TikTok",
          description:
            "All home accessories are in my Amazon store. 🔗 in bio  #homedecor #apartmenttour #homeaccessories #apartment #decor",
          url: "https://www.tiktokv.com/share/video/7434976612132637994/",
          video_id: "c1e334b8-ce96-4ec8-9cca-8fb0de76fefa",
          time_saved: "2025-02-02 00:50:45",
        },
      },
    },
    {
      source_id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
      target_id: "5db23971-9150-4e2c-9238-0ef2f472ae25",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 8,
        description_x: "Law Hub provides practice tests for LSAT preparation",
        human_readable_id: 268,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "be363485-b9df-4c4f-bc64-0a6c3779837e",
      source: {
        id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
        name: "PRACTICE TESTS",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
      target: {
        id: "5db23971-9150-4e2c-9238-0ef2f472ae25",
        name: "LAW HUB",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
      target_id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 1,
        description_x:
          "LSAT Demon offers practice tests to help refine skills for the LSAT",
        human_readable_id: 270,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "28ec8d10-ce8e-4398-b7f2-9f47f083f593",
      source: {
        id: "52cc64fc-0197-45d6-a02b-3a503250dfbf",
        name: "PRACTICE TESTS",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
      target: {
        id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
        name: "LSAT DEMON",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
      target_id: "6b040336-3002-442f-a870-baaa2b401d00",
      data: {
        text_unit_ids:
          "5591eb0754abd3bc7b1a187623a8950ef946b7d15582ad889d13e119398b87297e191bb9bcc3f665d2e452f36fe80ef2b6b8efb3d690467a2dbfdeee637d11a1",
        weight: 9,
        description_x:
          "The LSAT Demon podcast is part of the LSAT Demon platform, offering additional resources for preparation",
        human_readable_id: 269,
        n_tokens: 269,
        title: "Bianca on TikTok",
        description_y:
          "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
        url: "https://www.tiktokv.com/share/video/7458435359253286187/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458435359253286187.mp4",
        video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
        time_saved: "2025-01-13 02:32:58",
      },
      id: "ba7a6934-0be2-41d2-9c61-8c975e2dc3a9",
      source: {
        id: "049e97c4-8f0e-43a1-8335-3a9bc683ae87",
        name: "LSAT DEMON",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
      target: {
        id: "6b040336-3002-442f-a870-baaa2b401d00",
        name: "LSAT DEMON PODCAST",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "The Tell All! 💪🏻👍🏻🎧 #lsat #lsattip #lsattips #lsathelp #lsatprep #lsathacks #lsatdemon #lsats #law #prelaw #lawyer #lawschool #lawstudent #college #satexam #barprep #study #motivation #grind",
          url: "https://www.tiktokv.com/share/video/7458435359253286187/",
          video_id: "aa186d99-d1d3-451c-bb7d-ff59316701b8",
          time_saved: "2025-01-13 02:32:58",
        },
      },
    },
    {
      source_id: "2a83e7c9-0261-4e45-bf13-e2e4072e257d",
      target_id: "82f873cd-2d1a-4361-bb9f-c8a52ee9c1b7",
      data: {
        text_unit_ids:
          "9431a5bc5ac5f28369eb11a5222e6d7df004be287277e5e2624da77c8558c604875215e442c4d312c989ca22bc609058fc8313a848fe555552e7d04a1aa037ca",
        weight: 1,
        description_x:
          "The outfit was chosen specifically for attending the friend's graduation celebration",
        human_readable_id: 279,
        n_tokens: 48,
        title: "lizbethponceh on TikTok",
        description_y: "lizbethponceh's short video with ♬ original sound",
        url: "https://www.tiktokv.com/share/video/7448843990188756267/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7448843990188756267.mp4",
        video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
        time_saved: "2025-01-25 21:37:04",
      },
      id: "50c8d10d-92d6-43e6-8986-a265cc04eb7f",
      source: {
        id: "2a83e7c9-0261-4e45-bf13-e2e4072e257d",
        name: "FRIEND'S GRADUATION",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
      target: {
        id: "82f873cd-2d1a-4361-bb9f-c8a52ee9c1b7",
        name: "OUTFIT",
        group: 1,
        data: {
          title: "lizbethponceh on TikTok",
          description: "lizbethponceh's short video with ♬ original sound",
          url: "https://www.tiktokv.com/share/video/7448843990188756267/",
          video_id: "c34a3f3e-358c-4a6f-93e1-5bdfe5b83e81",
          time_saved: "2025-01-25 21:37:04",
        },
      },
    },
    {
      source_id: "a1ad1629-2d4e-49c9-94d6-bc0f8adea7b1",
      target_id: "e0d50f6d-cd7e-42a3-bcc3-cc33c281149c",
      data: {
        text_unit_ids:
          "1e89b612037ca647a20ecb04d19aa7b327745855c8e84f91c0d1874139d128147163b121b50c4d246dfbe699748d137f9c24c608b6f1bd6ab2cd416746fe9a9a",
        weight: 18,
        description_x:
          "The ICE officer is an employee of Immigration and Customs Enforcement",
        human_readable_id: 282,
        n_tokens: 96,
        title: "Gloria Cardenas on TikTok",
        description_y:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7443202459209583914.mp4",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
      id: "b38644f5-5a31-46a3-adad-3063c9d6456b",
      source: {
        id: "a1ad1629-2d4e-49c9-94d6-bc0f8adea7b1",
        name: "IMMIGRATION AND CUSTOMS ENFORCEMENT",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
      target: {
        id: "e0d50f6d-cd7e-42a3-bcc3-cc33c281149c",
        name: "ICE OFFICER",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
    },
    {
      source_id: "05d4f10a-8dad-4833-a42f-1ccd3caadeb8",
      target_id: "cbfe8634-3213-4dd0-9edc-30992801517a",
      data: {
        text_unit_ids:
          "1e89b612037ca647a20ecb04d19aa7b327745855c8e84f91c0d1874139d128147163b121b50c4d246dfbe699748d137f9c24c608b6f1bd6ab2cd416746fe9a9a",
        weight: 2,
        description_x:
          "The resident invokes the Fifth Amendment rights to remain silent and refuse to answer questions",
        human_readable_id: 283,
        n_tokens: 96,
        title: "Gloria Cardenas on TikTok",
        description_y:
          "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
        url: "https://www.tiktokv.com/share/video/7443202459209583914/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7443202459209583914.mp4",
        video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
        time_saved: "2025-01-20 18:29:43",
      },
      id: "06ee523e-df74-42ab-af82-fc367231652b",
      source: {
        id: "05d4f10a-8dad-4833-a42f-1ccd3caadeb8",
        name: "RESIDENT",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
      target: {
        id: "cbfe8634-3213-4dd0-9edc-30992801517a",
        name: "FIFTH AMENDMENT",
        group: 1,
        data: {
          title: "Gloria Cardenas on TikTok",
          description:
            "When ICE comes to the house looking for someone else.  You have rights as an undocumented person #ice#iceofficer#immigration#undocumented#undocumentedimmigrants#fyp#trumpdeportation #deportacion #defense#massdeportation#knowyourrights ",
          url: "https://www.tiktokv.com/share/video/7443202459209583914/",
          video_id: "4396abdb-6ce3-47f1-8234-87e288aa7582",
          time_saved: "2025-01-20 18:29:43",
        },
      },
    },
    {
      source_id: "185a6658-697e-44ab-ae57-fc82107d9dc6",
      target_id: "68f446f0-c8d3-4428-8258-b81f7e37b83a",
      data: {
        text_unit_ids:
          "41b9ff7ffd13d8aace7c745050e52b5f1d8a8dbf11d04cc43ba1980ecd7a2d5cb5a7e46a0ba6e792f86fe9b39490cb7cc8c730f97a071012e69c97a4574942c2",
        weight: 2,
        description_x:
          "The law library serves as a setting for individuals working towards increasing Latino representation in the legal field",
        human_readable_id: 286,
        n_tokens: 89,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "fb5d6e51-a957-4ebd-9e60-f121c2ced849",
      source: {
        id: "185a6658-697e-44ab-ae57-fc82107d9dc6",
        name: "LATINO REPRESENTATION IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "68f446f0-c8d3-4428-8258-b81f7e37b83a",
        name: "LAW LIBRARY",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "185a6658-697e-44ab-ae57-fc82107d9dc6",
      target_id: "1ad75421-4bb9-4b19-971d-3bddf7c08bcb",
      data: {
        text_unit_ids:
          "41b9ff7ffd13d8aace7c745050e52b5f1d8a8dbf11d04cc43ba1980ecd7a2d5cb5a7e46a0ba6e792f86fe9b39490cb7cc8c730f97a071012e69c97a4574942c2",
        weight: 7,
        description_x:
          "Legal studies are a critical component in achieving greater Latino representation in the legal field",
        human_readable_id: 287,
        n_tokens: 89,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "06ae9b9d-02c5-4e16-b963-d6aa6e61ead5",
      source: {
        id: "185a6658-697e-44ab-ae57-fc82107d9dc6",
        name: "LATINO REPRESENTATION IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "1ad75421-4bb9-4b19-971d-3bddf7c08bcb",
        name: "LEGAL STUDIES",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
      target_id: "85078403-3e8e-4bcc-9fca-169b2cea33f8",
      data: {
        text_unit_ids:
          "cdb31729e036bf275a87c2186a0df85e143a38726bef4a8a3e3b29de47e3d8aa743fb1dcc6dca077b1b8e0e897c0b359f22d4b14d00b06ab2bcf52b6587b0316",
        weight: 1,
        description_x:
          "The small dog interacts with the off-white couch by watching its assembly and relaxing on it",
        human_readable_id: 365,
        n_tokens: 89,
        title: "CLARISSA | NYC on TikTok",
        description_y:
          "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
        url: "https://www.tiktokv.com/share/video/7300963768840883498/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7300963768840883498.mp4",
        video_id: "923b6018-91af-440f-806d-ecc42e069d14",
        time_saved: "2025-02-02 00:45:24",
      },
      id: "4c13f3c0-ba0c-4468-85f9-604ad1116b21",
      source: {
        id: "e203cbbc-eec2-4a0d-84a7-2f9da90fcac5",
        name: "OFF-WHITE COUCH",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
      target: {
        id: "85078403-3e8e-4bcc-9fca-169b2cea33f8",
        name: "SMALL DOG",
        group: 1,
        data: {
          title: "CLARISSA | NYC on TikTok",
          description:
            "zero regrets so far 🧸🤎 #amazoncouchfinds #amazoncouchassembly #smallapartment #smallapartmentcouch ",
          url: "https://www.tiktokv.com/share/video/7300963768840883498/",
          video_id: "923b6018-91af-440f-806d-ecc42e069d14",
          time_saved: "2025-02-02 00:45:24",
        },
      },
    },
    {
      source_id: "709567d5-0151-4fa2-b491-4015dbb13f79",
      target_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "The blog post provides resources and links for remote job searches",
        human_readable_id: 303,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "ce355e4c-271f-479f-a82b-77b83949a9fd",
      source: {
        id: "709567d5-0151-4fa2-b491-4015dbb13f79",
        name: "BLOG POST",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
      target_id: "b63d7b1f-3af8-4548-9ce0-1c18dcb57a82",
      data: {
        text_unit_ids:
          "f3c0c3cd2b7ae5fa0b4e2a1d7f705dd8496090d679fee01f89d11e8dc6d05583b1e1fd91b8ab148eccd27e42e728664bb1cd5cc3a18515a6c988d377ca0c0b44",
        weight: 4,
        description_x:
          "4 Day Week is one of the platforms recommended for remote job searches",
        human_readable_id: 301,
        n_tokens: 113,
        title: "adri remote jobs on TikTok",
        description_y:
          "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
        url: "https://www.tiktokv.com/share/video/7336667809004686597/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7336667809004686597.mp4",
        video_id: "0",
        time_saved: "2025-01-26 02:00:37",
      },
      id: "51ce9a03-8ff6-4e7e-844c-5639a4cd179f",
      source: {
        id: "1ccb620e-181f-4104-8aab-95c63520a5e8",
        name: "REMOTE JOB SEARCHES",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
      target: {
        id: "b63d7b1f-3af8-4548-9ce0-1c18dcb57a82",
        name: "4 DAY WEEK",
        group: 1,
        data: {
          title: "adri remote jobs on TikTok",
          description:
            "2024: Forging Ahead in the Remote Work Frontier with Remote Canada Jobs 2024. Strategically Navigate How to Find Fully Remote Jobs Canada, Discover Immediate Work with Remote Jobs Canada Available Immediately, and Bypass Degree Constraints with Remote Jobs in Canada No Degree. Specialize in Remote Customer Service Excellence with Remote Customer Service Jobs Canada, Engage with the Leading Edge of Canadian Remote Work, Make Your Mark with Remote Jobs in Canada Entry Level, and Lay the Foundations for a Prosperous Future in Fully Remote Jobs Canada. #wfhjobs2024 #remotejobscanada #remotejobs2024 #remotejobsearch2024 #bestremotejobs2024 #StayAtHomeMomCareers #NoExperienceWork #WorkFromHome #RemoteJobsCanada #StayAtHomeMomCareers #NoExperienceWork #TypingJobsHome #QuickHireCanada #RemoteWorkLife #JobSearchCanada #FindYourJob #RemoteOpportunities2023 #remotejobsincanada #jobsincanada2023 #jobsincanada #jobincanada, #jobcanada #jobprospectscanada #howtofindjobincanada #JobSearch2023 #VirtualJobs #RemoteCareers #VirtualAssistantJobs2023 #CanadaIsHiring #RemoteWork #VirtualAssistantJobs #VirtualAssistantCanada #MedicalVirtualAssistant #BusinessVirtualAssistant #VirtualAssistantJobsHiring2023 #CanadaVirtualAssistant #CanadianVirtualAssistants #VirtualAssistant #AdminVirtualAssistant #VirtualAssistantJourney #aspiringvirtualassistant #virtualassistanttraining #adminassistant #executiveassistant #wfhparttime ",
          url: "https://www.tiktokv.com/share/video/7336667809004686597/",
          video_id: "0",
          time_saved: "2025-01-26 02:00:37",
        },
      },
    },
    {
      source_id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
      target_id: "be8dfb3b-437a-4e86-8ddb-9597fa5f0366",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 1,
        description_x:
          "The community is engaged in troubleshooting or seeking assistance for issues related to the smartphone camera interface",
        human_readable_id: 304,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "f2511914-f2df-43af-9ff1-007c1dc12644",
      source: {
        id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
        name: "SMARTPHONE CAMERA INTERFACE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "be8dfb3b-437a-4e86-8ddb-9597fa5f0366",
        name: "COMMUNITY",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
      target_id: "1f91da00-43ea-4194-ad08-122990c55de4",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 7,
        description_x:
          "The visual aesthetic is a key component of the smartphone camera interface",
        human_readable_id: 305,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "d54e873e-649e-4c87-8e4e-38a3bb5780b5",
      source: {
        id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
        name: "SMARTPHONE CAMERA INTERFACE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "1f91da00-43ea-4194-ad08-122990c55de4",
        name: "VISUAL AESTHETIC",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
      target_id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 1,
        description_x:
          "The user experience is shaped by the features and design of the smartphone camera interface",
        human_readable_id: 306,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "11a5fe72-2645-4294-b6aa-1da64498b127",
      source: {
        id: "bdab5390-98d5-4dd5-b809-2a7f80a6c968",
        name: "SMARTPHONE CAMERA INTERFACE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
        name: "USER EXPERIENCE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "1f91da00-43ea-4194-ad08-122990c55de4",
      target_id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
      data: {
        text_unit_ids:
          "0741afde61843e171b2ffdaab8544cb433581d3034b6e7e68810677dc9f817f497a2d988b6350eeac24771160f679f5fd6917098b39634ebe852147af9e0256c",
        weight: 6,
        description_x:
          "The visual aesthetic contributes to the overall user experience of the smartphone camera interface",
        human_readable_id: 307,
        n_tokens: 75,
        title: "nitya on TikTok",
        description_y:
          "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
        url: "https://www.tiktokv.com/share/video/7451326366747725099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451326366747725099.mp4",
        video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
        time_saved: "2025-01-21 04:56:34",
      },
      id: "96c48cec-384c-4f0a-9856-af9131f7251f",
      source: {
        id: "1f91da00-43ea-4194-ad08-122990c55de4",
        name: "VISUAL AESTHETIC",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
      target: {
        id: "4bd5ca68-e683-42a1-892b-c6c9b214b23a",
        name: "USER EXPERIENCE",
        group: 1,
        data: {
          title: "nitya on TikTok",
          description:
            "like literallyyy what is this 😭😭 #fyp #foryou #iphone #iphone16promax #canera #iphone16promaxcamera #filming #lifestyle #viral #fypシ #help ",
          url: "https://www.tiktokv.com/share/video/7451326366747725099/",
          video_id: "81603636-ee4d-4c3b-ad74-22ad135e9427",
          time_saved: "2025-01-21 04:56:34",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 17,
        description_x: "RECLECTIC is located in the Gurnee Mills Mall.",
        human_readable_id: 308,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "d214ad83-8ad1-42b3-a03c-8abdc87520c4",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
        name: "GURNEE MILLS MALL",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "8c57d3a9-dc3e-4621-9fc8-497d04fb120d",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 14,
        description_x:
          "RECLECTIC is a retailer that offers merchandise and products sourced from URBAN OUTFITTERS.",
        human_readable_id: 309,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "01a6c2bd-2c63-4842-9816-b1f79af374dd",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "8c57d3a9-dc3e-4621-9fc8-497d04fb120d",
        name: "URBAN OUTFITTERS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "04885dc2-130d-4604-b59e-386791a95b8e",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 14,
        description_x:
          "RECLECTIC is a business that specializes in selling merchandise and products sourced from ANTHROPOLOGY.",
        human_readable_id: 310,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "9769ec5a-47c0-4214-9857-6d1ca3f75d29",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "04885dc2-130d-4604-b59e-386791a95b8e",
        name: "ANTHROPOLOGY",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "801a7302-e140-48c3-866c-c23493f719ff",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 14,
        description_x:
          "RECLECTIC is a business entity that specializes in selling merchandise and products from the brand FREE PEOPLE.",
        human_readable_id: 311,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "0ac51159-0cac-441d-b707-84d13dd92917",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "801a7302-e140-48c3-866c-c23493f719ff",
        name: "FREE PEOPLE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "c4af8628-1f05-47c6-97a5-f1f5f06be506",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 14,
        description_x:
          "RECLECTIC is a company that sells merchandise and products from NUULY.",
        human_readable_id: 312,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "782c2e3d-6275-424d-a779-c19d7d4be0b5",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "c4af8628-1f05-47c6-97a5-f1f5f06be506",
        name: "NUULY",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "efd5419d-2d3b-48cf-bbed-cbcbfbd66df3",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from North Face",
        human_readable_id: 313,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "ef9c53d2-b4c3-4529-a14d-5ab5f9b973b0",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "efd5419d-2d3b-48cf-bbed-cbcbfbd66df3",
        name: "NORTH FACE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "5346b129-4305-41bb-a3b4-e60d6e51073c",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 14,
        description_x:
          "RECLECTIC is a retailer that offers merchandise from GOOD AMERICAN, including their jeans, at a discounted price.",
        human_readable_id: 314,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "cb9b29e6-9502-4457-94db-7dbfd884be8b",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "5346b129-4305-41bb-a3b4-e60d6e51073c",
        name: "GOOD AMERICAN",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "ce5bd19b-cb2b-4476-9831-1534d15f217d",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 7,
        description_x: "Reclectic sells merchandise from Gola",
        human_readable_id: 315,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "4e7dde69-55e9-4ac5-aa5a-2621023aef53",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "ce5bd19b-cb2b-4476-9831-1534d15f217d",
        name: "GOLA",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "7a11781f-dffa-471b-92c6-89d705061766",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 1,
        description_x: "Reclectic sells merchandise from Vans",
        human_readable_id: 316,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "d964542d-fbfa-4282-bc26-2297ba82f23f",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "7a11781f-dffa-471b-92c6-89d705061766",
        name: "VANS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "d8998fa3-450e-4bef-882f-a61465398a04",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 8,
        description_x: "Reclectic has a store located in Gurnee, Illinois",
        human_readable_id: 320,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "ee6083f6-d3c8-4cc5-92bf-735a02f03edf",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "d8998fa3-450e-4bef-882f-a61465398a04",
        name: "GURNEE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
      target_id: "9e6b14f4-118d-4549-8218-3bf0eba87c9f",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 7,
        description_x: "Reclectic sells Golas at a discounted price",
        human_readable_id: 321,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "e220b216-f9dd-4541-9538-798edcdf45fe",
      source: {
        id: "426035d7-4d0d-4acc-a820-5f5d691b5f17",
        name: "RECLECTIC",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "9e6b14f4-118d-4549-8218-3bf0eba87c9f",
        name: "GOLAS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
      target_id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 9,
        description_x: "Gurnee Mills Mall is located in Illinois",
        human_readable_id: 317,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "70243b11-e410-4a19-9cde-101323b46bce",
      source: {
        id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
        name: "GURNEE MILLS MALL",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
        name: "ILLINOIS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
      target_id: "d8998fa3-450e-4bef-882f-a61465398a04",
      data: {
        text_unit_ids:
          "3413c698c0750fcddc5461e2f2f0de0a580fe9b4ddf0568de139d2329d392fd6d0558456c694f3c0e3e132c575c9431c33acd035ff6230d66666d62cfc1d24ab",
        weight: 9,
        description_x: "Gurnee Mills Mall is located in the village of Gurnee",
        human_readable_id: 318,
        n_tokens: 124,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "a7507d71-2aae-45f2-84bf-0836064cf15f",
      source: {
        id: "0fc350e8-df6a-4c28-b6c2-3e952dec884e",
        name: "GURNEE MILLS MALL",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "d8998fa3-450e-4bef-882f-a61465398a04",
        name: "GURNEE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "801a7302-e140-48c3-866c-c23493f719ff",
      target_id: "9e6b14f4-118d-4549-8218-3bf0eba87c9f",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 1,
        description_x: "Free People lists Golas at a retail price over $100",
        human_readable_id: 323,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "4545d4d0-15ff-4566-8b1c-bf71e802af4d",
      source: {
        id: "801a7302-e140-48c3-866c-c23493f719ff",
        name: "FREE PEOPLE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "9e6b14f4-118d-4549-8218-3bf0eba87c9f",
        name: "GOLAS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
      target_id: "d8998fa3-450e-4bef-882f-a61465398a04",
      data: {
        text_unit_ids:
          "a897a08df48412dfef5a4d65419c4a6515639a6df254eb3f91487323965b072258144d618f76f63535ddd467e3bc5388e816c5fcad81d50872023f93058a7650",
        weight: 1,
        description_x: "Gurnee is a location within Illinois",
        human_readable_id: 322,
        n_tokens: 120,
        title: "natintheburbs on TikTok",
        description_y:
          "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
        url: "https://www.tiktokv.com/share/video/7463223158280097066/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463223158280097066.mp4",
        video_id: "0",
        time_saved: "2025-01-26 15:14:10",
      },
      id: "8b555329-140a-4e88-92e2-c68e1abef6ba",
      source: {
        id: "27ae41c2-a2fa-4f59-837b-eb9262527037",
        name: "ILLINOIS",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
      target: {
        id: "d8998fa3-450e-4bef-882f-a61465398a04",
        name: "GURNEE",
        group: 1,
        data: {
          title: "natintheburbs on TikTok",
          description:
            "Checking out the all new Reclectic store 🛒 AHHHHH! The prices here are so dang good 👏🏼 $30 for Good American denim?! Say less. I was so overwhelmed by all the good brands…North Face, Vans, Gola, etc. This is the spot to come for good quality clothes at an affordable price!  Have you visited a Reclectic store before?! I’m totally curious on your thoughts ⬇️  #reclectic #newthrift #thriftwithme #thingstodoinchicago #winterfashion2024 #freepeople #urbanoutfitters #nuuly #anthrostyle #fyp @nuuly @Anthropologie @Free People @Urban Outfitters ",
          url: "https://www.tiktokv.com/share/video/7463223158280097066/",
          video_id: "0",
          time_saved: "2025-01-26 15:14:10",
        },
      },
    },
    {
      source_id: "c0e350e3-59a6-4aae-b968-467057a3945f",
      target_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 3,
        description_x:
          "Resourcefulness includes directing individuals to comprehensive legal resources like those offered by the Immigrant Legal Resource Center",
        human_readable_id: 333,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "7810a6d5-d9af-4813-8fd5-eed217b5ea97",
      source: {
        id: "c0e350e3-59a6-4aae-b968-467057a3945f",
        name: "IMMIGRANT LEGAL RESOURCE CENTER",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
      target_id: "2539d697-bd0a-435d-a468-7d64fdf83772",
      data: {
        text_unit_ids:
          "5bffb94f7ed6af00e94b33ecc553b35d97b071cec744c946ba013427b7be24f4414492d49a92ef623e520063deaa1692def52c1d3dafc99b67dc22f42b5588d3",
        weight: 1,
        description_x:
          "Resourcefulness involves tracking and disseminating information about ICE raid locations",
        human_readable_id: 335,
        n_tokens: 147,
        title: "Yolanda Diaz on TikTok",
        description_y: "pretty pls 🥲 ",
        url: "https://www.tiktokv.com/share/video/7463201352936672543/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7463201352936672543.mp4",
        video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
        time_saved: "2025-01-23 21:44:32",
      },
      id: "30377c96-60d7-4413-b620-3519a9ec1425",
      source: {
        id: "40b342e1-06b3-4a05-b21f-36aefc39d4bc",
        name: "RESOURCEFULNESS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
      target: {
        id: "2539d697-bd0a-435d-a468-7d64fdf83772",
        name: "ICE RAID LOCATIONS",
        group: 1,
        data: {
          title: "Yolanda Diaz on TikTok",
          description: "pretty pls 🥲 ",
          url: "https://www.tiktokv.com/share/video/7463201352936672543/",
          video_id: "b4f007e1-6737-4ddd-99ee-94fc5bd61836",
          time_saved: "2025-01-23 21:44:32",
        },
      },
    },
    {
      source_id: "977de398-cef3-4b97-999a-1374a43b432d",
      target_id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
      data: {
        text_unit_ids:
          "8c1c23ae7f3854ef5c9cb6a70adcc97ab46d10e416ec6c5a330c70a65313879487e3984d7b0bd04c96f6478af1dcd338ee2bcf5698f66fd030080f919cfa1272",
        weight: 1,
        description_x:
          "Social media influenced the purchase decision of the Val couch",
        human_readable_id: 341,
        n_tokens: 67,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "53778ec6-dac7-4d21-8623-cdd151d47770",
      source: {
        id: "977de398-cef3-4b97-999a-1374a43b432d",
        name: "VAL COUCH",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
      target: {
        id: "cec3d893-8f22-4c1d-a883-e019a5f7c879",
        name: "SOCIAL MEDIA",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
      target_id: "e3de905f-ef6d-4098-bf47-4c3dce1a2771",
      data: {
        text_unit_ids:
          "b4d9e487f4522995ab66535771fcc8f9f3d9a509728a6d03bbf9b00171415636c0e2158a041ff081395d905d1a5b009064b774a825876954f20786b1c2b5d4ee",
        weight: 7,
        description_x:
          "Walmart offers stylish and affordable home decor options that align with interior design trends",
        human_readable_id: 356,
        n_tokens: 141,
        title: "jules on TikTok",
        description_y:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458376179553176863.mp4",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
      id: "de5936c6-bf01-4f90-bcdf-87e4c220ced7",
      source: {
        id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
        name: "WALMART",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
      target: {
        id: "e3de905f-ef6d-4098-bf47-4c3dce1a2771",
        name: "INTERIOR DESIGN",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
    },
    {
      source_id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
      target_id: "16cd135d-6e67-4136-ad67-ad10ea8b9b6b",
      data: {
        text_unit_ids:
          "b4d9e487f4522995ab66535771fcc8f9f3d9a509728a6d03bbf9b00171415636c0e2158a041ff081395d905d1a5b009064b774a825876954f20786b1c2b5d4ee",
        weight: 6,
        description_x:
          "Walmart provides products that fit the coastal decor theme, such as seashell-patterned blankets and surfboards",
        human_readable_id: 357,
        n_tokens: 141,
        title: "jules on TikTok",
        description_y:
          "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
        url: "https://www.tiktokv.com/share/video/7458376179553176863/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7458376179553176863.mp4",
        video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
        time_saved: "2025-01-26 04:51:41",
      },
      id: "eb1fd946-a142-45a2-be11-0a4f32b53400",
      source: {
        id: "943bb9df-b8b7-4cfd-bb41-79b4677cd8a6",
        name: "WALMART",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
      target: {
        id: "16cd135d-6e67-4136-ad67-ad10ea8b9b6b",
        name: "COASTAL DECOR",
        group: 1,
        data: {
          title: "jules on TikTok",
          description:
            "my cutie sectional sofa!! i’m so excited to finally have a big couch 🫶🫶 10/10 comfort too #walmartfinds #walmart #couch #sectionalsofa ",
          url: "https://www.tiktokv.com/share/video/7458376179553176863/",
          video_id: "ca53d2b0-cf70-4fc2-961a-8d2c1ffc7dee",
          time_saved: "2025-01-26 04:51:41",
        },
      },
    },
    {
      source_id: "da6dc505-8684-417b-9f89-acb48177a1e8",
      target_id: "49d10a73-0f3f-4d59-9e52-fd4f971e150d",
      data: {
        text_unit_ids:
          "2bb77b6ebf007030293db7ef1ad401c71b61e717aea0d266c091f1f2e0149325ab90f033cd7a2d341e79aa26980aec174593e8aec4da02b9db0c7431e84b432f",
        weight: 7,
        description_x:
          "Hiring managers have a budget that determines the salary range they can offer",
        human_readable_id: 359,
        n_tokens: 167,
        title: "Greg L. - Career Coach 🤓 on TikTok",
        description_y:
          "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
        url: "https://www.tiktokv.com/share/video/7320405835459480837/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320405835459480837.mp4",
        video_id: "0",
        time_saved: "2025-01-29 04:07:43",
      },
      id: "a9342e9e-d10e-47b7-9ae5-a69c7a2de713",
      source: {
        id: "da6dc505-8684-417b-9f89-acb48177a1e8",
        name: "HIRING MANAGERS",
        group: 1,
        data: {
          title: "Greg L. - Career Coach 🤓 on TikTok",
          description:
            "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
          url: "https://www.tiktokv.com/share/video/7320405835459480837/",
          video_id: "0",
          time_saved: "2025-01-29 04:07:43",
        },
      },
      target: {
        id: "49d10a73-0f3f-4d59-9e52-fd4f971e150d",
        name: "BUDGET",
        group: 1,
        data: {
          title: "Greg L. - Career Coach 🤓 on TikTok",
          description:
            "As always with salary negotiation, if you’re desperate or they’ve expressed thag there is no flexibility, just accept the offer. In most professional settings, there will be some wiggle room. Also remember, a poor reaction to a polite and modest salary negotiation could be a serious 🚩red flag from the hiring manager and if that happens you may want to reconsider working there (unless you’re desperate). Always negotiate politely and with caution. Place the relationship with the employer as a top priority while asking if you choose to ask! #resume #jobsearch #career #learnontiktok #negotiatesalary ",
          url: "https://www.tiktokv.com/share/video/7320405835459480837/",
          video_id: "0",
          time_saved: "2025-01-29 04:07:43",
        },
      },
    },
    {
      source_id: "7eb1f52e-afa9-47de-8dac-a0eca9c79210",
      target_id: "0f3489fb-5354-48c0-8344-a8c901f44820",
      data: {
        text_unit_ids:
          "69337a78af5caf7ef12eebb1f40241f2770e9ae5571e1d92ec2e9b17ff8dd032cf64aaa04363bc07fef181468fce465f4fa38c8359179981008ab342c609c407",
        weight: 5,
        description_x:
          "The cooked meal is used as a backdrop to discuss and highlight tensions around gender roles",
        human_readable_id: 372,
        n_tokens: 112,
        title: "Varnished on TikTok",
        description_y:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459173031680806176.mp4",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
      id: "fe5991d1-e9ac-4a89-be69-9979f579d33c",
      source: {
        id: "7eb1f52e-afa9-47de-8dac-a0eca9c79210",
        name: "COOKED MEAL",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
      target: {
        id: "0f3489fb-5354-48c0-8344-a8c901f44820",
        name: "GENDER ROLES",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
    },
    {
      source_id: "0f3489fb-5354-48c0-8344-a8c901f44820",
      target_id: "6a6ac87a-cf50-4fcd-8aca-f9bcc7a78eb7",
      data: {
        text_unit_ids:
          "69337a78af5caf7ef12eebb1f40241f2770e9ae5571e1d92ec2e9b17ff8dd032cf64aaa04363bc07fef181468fce465f4fa38c8359179981008ab342c609c407",
        weight: 1,
        description_x:
          "Culinary skills are framed as a traditional expectation within gender roles",
        human_readable_id: 373,
        n_tokens: 112,
        title: "Varnished on TikTok",
        description_y:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459173031680806176.mp4",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
      id: "308d2067-aebb-4913-9484-b532fdb6ab61",
      source: {
        id: "0f3489fb-5354-48c0-8344-a8c901f44820",
        name: "GENDER ROLES",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
      target: {
        id: "6a6ac87a-cf50-4fcd-8aca-f9bcc7a78eb7",
        name: "CULINARY SKILLS",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
    },
    {
      source_id: "0f3489fb-5354-48c0-8344-a8c901f44820",
      target_id: "02958d03-fc20-4f61-8bdf-8d2a15b28b85",
      data: {
        text_unit_ids:
          "69337a78af5caf7ef12eebb1f40241f2770e9ae5571e1d92ec2e9b17ff8dd032cf64aaa04363bc07fef181468fce465f4fa38c8359179981008ab342c609c407",
        weight: 8,
        description_x:
          "The dialogue highlights a conflict between personal identity and traditional gender roles",
        human_readable_id: 374,
        n_tokens: 112,
        title: "Varnished on TikTok",
        description_y:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459173031680806176.mp4",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
      id: "c1e1c625-294b-4dfc-9fdd-a6d238d36f2f",
      source: {
        id: "0f3489fb-5354-48c0-8344-a8c901f44820",
        name: "GENDER ROLES",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
      target: {
        id: "02958d03-fc20-4f61-8bdf-8d2a15b28b85",
        name: "PERSONAL IDENTITY",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
    },
    {
      source_id: "6a6ac87a-cf50-4fcd-8aca-f9bcc7a78eb7",
      target_id: "06cc6e83-b86d-46cd-95dd-de160407c360",
      data: {
        text_unit_ids:
          "69337a78af5caf7ef12eebb1f40241f2770e9ae5571e1d92ec2e9b17ff8dd032cf64aaa04363bc07fef181468fce465f4fa38c8359179981008ab342c609c407",
        weight: 1,
        description_x:
          "Culinary skills are presented as a societal expectation tied to gender roles",
        human_readable_id: 375,
        n_tokens: 112,
        title: "Varnished on TikTok",
        description_y:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459173031680806176.mp4",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
      id: "0125605c-ece5-45c6-a968-5f4bcf21bafe",
      source: {
        id: "6a6ac87a-cf50-4fcd-8aca-f9bcc7a78eb7",
        name: "CULINARY SKILLS",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
      target: {
        id: "06cc6e83-b86d-46cd-95dd-de160407c360",
        name: "SOCIETAL EXPECTATIONS",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
    },
    {
      source_id: "02958d03-fc20-4f61-8bdf-8d2a15b28b85",
      target_id: "06cc6e83-b86d-46cd-95dd-de160407c360",
      data: {
        text_unit_ids:
          "69337a78af5caf7ef12eebb1f40241f2770e9ae5571e1d92ec2e9b17ff8dd032cf64aaa04363bc07fef181468fce465f4fa38c8359179981008ab342c609c407",
        weight: 8,
        description_x:
          "The dialogue illustrates the tension between personal identity and societal expectations",
        human_readable_id: 376,
        n_tokens: 112,
        title: "Varnished on TikTok",
        description_y:
          "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
        url: "https://www.tiktokv.com/share/video/7459173031680806176/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7459173031680806176.mp4",
        video_id: "919989f7-652a-47cc-8872-816561dd0563",
        time_saved: "2025-01-27 22:29:33",
      },
      id: "9ccef486-2627-4b21-b480-3ef9d15a9920",
      source: {
        id: "02958d03-fc20-4f61-8bdf-8d2a15b28b85",
        name: "PERSONAL IDENTITY",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
      target: {
        id: "06cc6e83-b86d-46cd-95dd-de160407c360",
        name: "SOCIETAL EXPECTATIONS",
        group: 1,
        data: {
          title: "Varnished on TikTok",
          description:
            "Este audio es graciosísimo 🤦‍♀️😂 #marrymechicken #chickenrecipe #easyrecipe #cooking #dinnerideas #dinnerwithme #foodtok ",
          url: "https://www.tiktokv.com/share/video/7459173031680806176/",
          video_id: "919989f7-652a-47cc-8872-816561dd0563",
          time_saved: "2025-01-27 22:29:33",
        },
      },
    },
    {
      source_id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
      target_id: "4627d490-5180-45d0-b4b2-a7a88f75f717",
      data: {
        text_unit_ids:
          "e790fbe5b55965e5ce5d547c54720feac417a8b55e33fa13545df84950952260ae1a4c5fce544928e3e978aa2963e2843e53de087cf8e2ec5488ff93cfbc4a0d",
        weight: 2,
        description_x:
          "The remote control is used to operate the concealed television",
        human_readable_id: 378,
        n_tokens: 85,
        title: "Mallory on TikTok",
        description_y:
          "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
        url: "https://www.tiktokv.com/share/video/7241308107446488363/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7241308107446488363.mp4",
        video_id: "0",
        time_saved: "2025-01-31 22:27:59",
      },
      id: "fd26f9a7-358d-44e3-aa21-30a0c999c0a2",
      source: {
        id: "340b939b-d6bf-4fa7-b058-80469a8e82ed",
        name: "TELEVISION CONCEALMENT",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
      target: {
        id: "4627d490-5180-45d0-b4b2-a7a88f75f717",
        name: "REMOTE CONTROL",
        group: 1,
        data: {
          title: "Mallory on TikTok",
          description:
            "my vision became reality 🥲 i wanted both shelving AND our little tv in the bedroom, and found a way to have both. when it’s not in use, the tv looks like art leaning on the top shelf, but we can pull it out, down and even tilt it for the perfect view when we want to get cozy and watch tv from bed. let me know if you’re interested in how we made this! shout out to my gal @Brigette Muller for inspiring me to try this tv mount 🫶 #homedecor #diyideas #interiordesign #frametv #frametvhack ",
          url: "https://www.tiktokv.com/share/video/7241308107446488363/",
          video_id: "0",
          time_saved: "2025-01-31 22:27:59",
        },
      },
    },
    {
      source_id: "ae3d1d8c-a08c-4296-81ba-4965be515ee2",
      target_id: "a467062a-7f7c-4d77-a876-28ae38bb34f0",
      data: {
        text_unit_ids:
          "389aa02c0cea4641dd3565f3a81bf3a08351129935443a014b64153bbccfe2bbe3e83d9253ebf8d2f0fdb142cccb9b4881ee05eaedec11e2c7f7099c01133126",
        weight: 8,
        description_x: "The couch was purchased from Amazon Valyou",
        human_readable_id: 379,
        n_tokens: 89,
        title: "Rolph Alcide on TikTok",
        description_y:
          "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
        url: "https://www.tiktokv.com/share/video/7344137472655248671/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7344137472655248671.mp4",
        video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
        time_saved: "2025-02-02 00:45:51",
      },
      id: "fa98449b-f0f8-4123-b40f-a4b46738a931",
      source: {
        id: "ae3d1d8c-a08c-4296-81ba-4965be515ee2",
        name: "AMAZON VALYOU",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
      target: {
        id: "a467062a-7f7c-4d77-a876-28ae38bb34f0",
        name: "COUCH PURCHASE",
        group: 1,
        data: {
          title: "Rolph Alcide on TikTok",
          description:
            "Finally updated the new apartment with the viral amazon sofa. ##apartmentdecor##homedecor##style",
          url: "https://www.tiktokv.com/share/video/7344137472655248671/",
          video_id: "a9950727-6ddc-4631-a640-df9c8d80d2c6",
          time_saved: "2025-02-02 00:45:51",
        },
      },
    },
    {
      source_id: "e73f818c-2063-4571-a76c-4002e7ca7881",
      target_id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 5,
        description_x:
          "Both N.W.A. and The Notorious B.I.G. are iconic figures in the hip-hop music scene",
        human_readable_id: 380,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "7bffe9ee-dc3b-4824-9630-a7fe1bd75219",
      source: {
        id: "e73f818c-2063-4571-a76c-4002e7ca7881",
        name: "N.W.A.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
        name: "THE NOTORIOUS B.I.G.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "e73f818c-2063-4571-a76c-4002e7ca7881",
      target_id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 5,
        description_x:
          "Both N.W.A. and 2Pac are influential in the hip-hop genre and have left a lasting legacy",
        human_readable_id: 381,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "e330e0f7-7814-42d8-b919-ef3ae15c8f51",
      source: {
        id: "e73f818c-2063-4571-a76c-4002e7ca7881",
        name: "N.W.A.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
        name: "2PAC",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "e73f818c-2063-4571-a76c-4002e7ca7881",
      target_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 4,
        description_x:
          "N.W.A.'s music album is part of the decor in the home interior scene",
        human_readable_id: 382,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "8fc5d8da-7882-44f8-8ad3-569d5875d1b0",
      source: {
        id: "e73f818c-2063-4571-a76c-4002e7ca7881",
        name: "N.W.A.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
      target_id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 1,
        description_x:
          "The Notorious B.I.G. and 2Pac were both prominent figures in hip-hop and had a well-known rivalry",
        human_readable_id: 383,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "ee8c5ff9-603b-47f9-ad9b-f5d403a7e239",
      source: {
        id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
        name: "THE NOTORIOUS B.I.G.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
        name: "2PAC",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
      target_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 4,
        description_x:
          "The Notorious B.I.G.'s music album is part of the decor in the home interior scene",
        human_readable_id: 384,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "7878d333-499c-4e95-9290-7c929d136127",
      source: {
        id: "65fcb788-9bd9-4a74-b0cc-0182c888e75f",
        name: "THE NOTORIOUS B.I.G.",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
      target_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 1,
        description_x:
          "2Pac's music album is part of the decor in the home interior scene",
        human_readable_id: 385,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "3a062c6f-e3c0-46dc-85e4-a6685428da80",
      source: {
        id: "2d912c46-cf82-4507-8ff5-c740c4cf9769",
        name: "2PAC",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
      target_id: "6386f9c5-25dd-420a-a7dd-cc7a15c7ce26",
      data: {
        text_unit_ids:
          "f8a2605bc65052b5f7c5dfd7365444c9d8e0ea15b08f466f44b8d0c468d417efad9c13f5728299b74d40faf120b7ceb718196c3f454bd5fce3a9d07f3cafac08",
        weight: 6,
        description_x:
          "The vinyl player setup is a feature within the home interior scene",
        human_readable_id: 386,
        n_tokens: 91,
        title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
        description_y:
          "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
        url: "https://www.tiktokv.com/share/video/7429050617252941099/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7429050617252941099.mp4",
        video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
        time_saved: "2025-02-02 00:00:45",
      },
      id: "9db55da2-f670-4b8a-9faa-e11c32c73aa0",
      source: {
        id: "b0cb20b3-9b10-4b9c-badd-5998836bdbef",
        name: "HOME INTERIOR",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
      target: {
        id: "6386f9c5-25dd-420a-a7dd-cc7a15c7ce26",
        name: "VINYL PLAYER SETUP",
        group: 1,
        data: {
          title: "𝐷𝑎𝑛𝑎 ⋆𐙚₊˚⊹ on TikTok",
          description:
            "so in love with it 🩵 - - #homebody #fall #feminineenergy #feminineurge #couch #cloudcouch #fyp #viral #home #apartment #luxuryapartment #hypebeast #hypebeastdecor #streetwear #vlog ",
          url: "https://www.tiktokv.com/share/video/7429050617252941099/",
          video_id: "5352276a-3ef3-4f39-b087-c700352ec16b",
          time_saved: "2025-02-02 00:00:45",
        },
      },
    },
    {
      source_id: "35f75903-025d-473a-a6b5-e87fa63a9ed8",
      target_id: "44302998-0a5d-4638-820d-ef3c5c8db75f",
      data: {
        text_unit_ids:
          "0af272eccec360e473476d195fb88b16c4e716ed2c105e49f4de5317d814af246673db15ea7d60754ed43ea7add74472690bc1d880e68e349768755011beb517",
        weight: 1,
        description_x:
          "Admissions offices are responsible for conducting the law school admissions process",
        human_readable_id: 389,
        n_tokens: 193,
        title: "Michigan Law on TikTok",
        description_y:
          "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
        url: "https://www.tiktokv.com/share/video/7423422773327924510/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7423422773327924510.mp4",
        video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
        time_saved: "2025-01-16 03:42:48",
      },
      id: "0ea82eb2-6857-4c13-b1ce-4fcf40f7ee6d",
      source: {
        id: "35f75903-025d-473a-a6b5-e87fa63a9ed8",
        name: "LAW SCHOOL ADMISSIONS",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
      target: {
        id: "44302998-0a5d-4638-820d-ef3c5c8db75f",
        name: "ADMISSIONS OFFICES",
        group: 1,
        data: {
          title: "Michigan Law on TikTok",
          description:
            "Lets discuss degree GPA vs. LSAC GPA.  #LawSchool #LawAdmissions #A2Z ",
          url: "https://www.tiktokv.com/share/video/7423422773327924510/",
          video_id: "3afe4e50-bf3d-441b-948f-9e0282534e87",
          time_saved: "2025-01-16 03:42:48",
        },
      },
    },
    {
      source_id: "bd2c3241-e0c0-45d6-8081-95177785c0fd",
      target_id: "ac316247-f633-4afb-bb0d-7c7176f16409",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 7,
        description_x:
          'The "Last Two Summers" prompt encourages applicants to highlight their extracurricular activities',
        human_readable_id: 399,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "33218813-36d8-48ee-8844-467041d9ad08",
      source: {
        id: "bd2c3241-e0c0-45d6-8081-95177785c0fd",
        name: '"LAST TWO SUMMERS" PROMPT',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "ac316247-f633-4afb-bb0d-7c7176f16409",
        name: "EXTRACURRICULARS",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "8bdbc9dc-0390-4e37-840c-8012b3b7be3c",
      target_id: "ac316247-f633-4afb-bb0d-7c7176f16409",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 1,
        description_x:
          'The "Five Important Things" essay can include non-listed personal elements like extracurriculars',
        human_readable_id: 400,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "fbdeee50-4e48-47d3-a129-f4ce9fe89576",
      source: {
        id: "8bdbc9dc-0390-4e37-840c-8012b3b7be3c",
        name: '"FIVE IMPORTANT THINGS" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "ac316247-f633-4afb-bb0d-7c7176f16409",
        name: "EXTRACURRICULARS",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "1d33e06a-b745-4352-87d8-2dca42f48c9a",
      target_id: "ac316247-f633-4afb-bb0d-7c7176f16409",
      data: {
        text_unit_ids:
          "944f7d7dca4d8f7329146310dc3f5961a739b552259891977bde5fdd52a269a2415ba1974a27be76eb88d366309c714f2735c050052ef2a4b278dfec335e3881",
        weight: 6,
        description_x:
          'The "An Idea That Excites You" essay can highlight an extracurricular activity',
        human_readable_id: 401,
        n_tokens: 244,
        title: "Brandon (@tineocollegeprep) on TikTok",
        description_y:
          "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
        url: "https://www.tiktokv.com/share/video/7320351348946504991/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7320351348946504991.mp4",
        video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
        time_saved: "2025-01-08 18:17:40",
      },
      id: "73dbaf82-5c25-48d1-b0a5-f97df26e3ecd",
      source: {
        id: "1d33e06a-b745-4352-87d8-2dca42f48c9a",
        name: '"AN IDEA THAT EXCITES YOU" ESSAY',
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
      target: {
        id: "ac316247-f633-4afb-bb0d-7c7176f16409",
        name: "EXTRACURRICULARS",
        group: 1,
        data: {
          title: "Brandon (@tineocollegeprep) on TikTok",
          description:
            "Stanford application review of things students try to do but dont really work . .  . . . . . #collegeadmissions #collegeapps #collegeapplications #stanford #stanforduniversity #stanfordadmissions ",
          url: "https://www.tiktokv.com/share/video/7320351348946504991/",
          video_id: "b48e61c4-f69e-463a-9a83-50f4b1bbe312",
          time_saved: "2025-01-08 18:17:40",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 10,
        description_x:
          "7SAGE and Manhattan Prep are both recommended resources for LSAT preparation. They provide a variety of resources designed to help individuals prepare effectively for the LSAT exam.",
        human_readable_id: 402,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "27d46b5e-b4c0-46f9-91e4-c1ba90691457",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
        name: "MANHATTAN PREP",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "d076b170-b018-4169-bd71-f4d76d0e836c",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 9,
        description_x:
          "7Sage and PowerScore are both recognized as valuable resources for LSAT study. 7Sage specializes in offering online content, while PowerScore is known for providing books. Both entities are dedicated to offering resources that aid in LSAT preparation.",
        human_readable_id: 403,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "94730175-70d4-4e89-8720-6ed1587cb7e0",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "d076b170-b018-4169-bd71-f4d76d0e836c",
        name: "POWERSCORE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "36bba867-0a9d-4970-ab8b-07f795fdfc35",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 8,
        description_x:
          '7Sage emphasizes the "Blind Review" method as a key strategy for LSAT preparation',
        human_readable_id: 416,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "a74ee296-47f9-40a4-8db8-ffd862d8b353",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "36bba867-0a9d-4970-ab8b-07f795fdfc35",
        name: "BLIND REVIEW",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
      target_id: "fa14bbb0-2e7c-4afd-8630-ec67a9affba9",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 1,
        description_x:
          "The 7Sage YouTube channel is part of 7Sage's resources for LSAT preparation",
        human_readable_id: 417,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "ce105876-0336-4eda-bdb6-15c3dcb0deca",
      source: {
        id: "45bd550a-8ea7-47ea-8195-d4d7e5c4b035",
        name: "7SAGE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "fa14bbb0-2e7c-4afd-8630-ec67a9affba9",
        name: "7SAGE YOUTUBE CHANNEL",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
      target_id: "d076b170-b018-4169-bd71-f4d76d0e836c",
      data: {
        text_unit_ids:
          "3a6d0951dae1452f6f8198115794ae9c3e04d496c791c357a50196f1fc0334e39c423f7e1ad080a051581d746ec39ee8671411b99058d3ecdae8dc7bd0d1f013",
        weight: 2,
        description_x:
          "Manhattan Prep and PowerScore are both reputable providers of resources for LSAT preparation. They are recommended for their comprehensive study materials, which cater to different learning preferences and needs. Both entities offer a variety of resources designed to help students effectively prepare for the LSAT exam.",
        human_readable_id: 405,
        n_tokens: 106,
        title: "haley on TikTok",
        description_y:
          "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
        url: "https://www.tiktokv.com/share/video/7050975486012411182/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7050975486012411182.mp4",
        video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
        time_saved: "2025-01-08 20:53:59",
      },
      id: "073d384d-f38a-4be6-b87a-70fc959f3151",
      source: {
        id: "6b8d9976-ff09-4b0d-9794-f582308d6332",
        name: "MANHATTAN PREP",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
      target: {
        id: "d076b170-b018-4169-bd71-f4d76d0e836c",
        name: "POWERSCORE",
        group: 1,
        data: {
          title: "haley on TikTok",
          description:
            "Reply to @megduth  comment any resources I missed!! #lsatprep #prelaw #lsattips #lawschool #lawyertok #lawstudent #prelawadvice #lawtiktok",
          url: "https://www.tiktokv.com/share/video/7050975486012411182/",
          video_id: "3a01e4dc-a94e-4070-862d-9a73f026cc6e",
          time_saved: "2025-01-08 20:53:59",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "db9f1e53-d624-4643-8304-5163dc453afc",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 1,
        description_x:
          "The initiative to increase Latino representation is closely tied to the importance of legal education",
        human_readable_id: 408,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "16ddf1af-96dd-4138-9c7e-a38f8f0bc3f6",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "db9f1e53-d624-4643-8304-5163dc453afc",
        name: "LEGAL EDUCATION",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "bc77912e-3bca-44b1-aeae-1cb41ba8fae0",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 5,
        description_x:
          "Law books are essential resources for the education and representation of Latinos in law",
        human_readable_id: 409,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "846613a5-ed35-4f96-8600-5c77b26080a1",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "bc77912e-3bca-44b1-aeae-1cb41ba8fae0",
        name: "LAW BOOKS",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "202ed9f8-ce25-4863-bc98-4bf26e15e7b7",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 4,
        description_x:
          "The desk represents the focused effort required to increase Latino representation in law",
        human_readable_id: 410,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "74a78a9d-45f5-4697-af8d-683aff1ebcf4",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "202ed9f8-ce25-4863-bc98-4bf26e15e7b7",
        name: "DESK",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "2fef7194-3635-4785-b55d-5b65d4ef1c1a",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 4,
        description_x:
          "The laptop is a tool that aids in the education and representation of Latinos in law",
        human_readable_id: 411,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "411ca9d2-7773-45b2-919f-777f56c754e8",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "2fef7194-3635-4785-b55d-5b65d4ef1c1a",
        name: "LAPTOP",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "58e455c6-5093-422b-973c-6fae45fffe7b",
      target_id: "eacd213b-0e28-49aa-a3ff-b6171e4be01e",
      data: {
        text_unit_ids:
          "8643f722d7a627b284c3672c4388d6e42cd0301c6c1dc9d7bdd563081a52ca8650382e107487f3f2fdcbad39fb29c78f13e84a3ff458d6819ef0945823cad729",
        weight: 1,
        description_x:
          "Headphones are used to enhance focus, supporting the goal of increasing Latino presence in law",
        human_readable_id: 412,
        n_tokens: 103,
        title: "edgarr.jv on TikTok",
        description_y:
          "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
        url: "https://www.tiktokv.com/share/video/7464626372967976238/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7464626372967976238.mp4",
        video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
        time_saved: "2025-01-28 03:00:13",
      },
      id: "b8875674-6a12-458c-8626-284905d74dff",
      source: {
        id: "58e455c6-5093-422b-973c-6fae45fffe7b",
        name: "LATINOS IN LAW",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
      target: {
        id: "eacd213b-0e28-49aa-a3ff-b6171e4be01e",
        name: "HEADPHONES",
        group: 1,
        data: {
          title: "edgarr.jv on TikTok",
          description:
            "We need more Latinos in politics and law. Definitely time to lock in because we are the future generation that will do good in our society.                        #cornell #immigrant #fypシ #mexican ",
          url: "https://www.tiktokv.com/share/video/7464626372967976238/",
          video_id: "2db81cd2-d699-48c6-b73c-4e6473a17972",
          time_saved: "2025-01-28 03:00:13",
        },
      },
    },
    {
      source_id: "6a508c1f-0408-4024-bf11-965a8c045800",
      target_id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
      data: {
        text_unit_ids:
          "e38103529afbe759a8da4b4880e4ad715e2310606b9eab98ab0d74a00ea0f26be83cad06fdc372bed6c54ec5dcbf68b6f54025e649eaee0788b59b9d2ecea3a2",
        weight: 9,
        description_x:
          '"The Frame" is a product designed and manufactured by Samsung',
        human_readable_id: 422,
        n_tokens: 78,
        title: "paige_mariah on TikTok",
        description_y:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451658876052688159.mp4",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
      id: "92db2e68-3a1e-43ce-b2de-cc6291292021",
      source: {
        id: "6a508c1f-0408-4024-bf11-965a8c045800",
        name: "SAMSUNG",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
      target: {
        id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
        name: "THE FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
    },
    {
      source_id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
      target_id: "ebc40e35-36c2-40da-a009-c99cfb31cfba",
      data: {
        text_unit_ids:
          "e38103529afbe759a8da4b4880e4ad715e2310606b9eab98ab0d74a00ea0f26be83cad06fdc372bed6c54ec5dcbf68b6f54025e649eaee0788b59b9d2ecea3a2",
        weight: 8,
        description_x:
          '"The Frame" TV can be customized with different frames to match personal aesthetic preferences',
        human_readable_id: 423,
        n_tokens: 78,
        title: "paige_mariah on TikTok",
        description_y:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451658876052688159.mp4",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
      id: "55635b00-29d4-4b27-85d2-186778dae507",
      source: {
        id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
        name: "THE FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
      target: {
        id: "ebc40e35-36c2-40da-a009-c99cfb31cfba",
        name: "FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
    },
    {
      source_id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
      target_id: "fbdfade2-a57d-4481-a5cf-410cb3a5995a",
      data: {
        text_unit_ids:
          "e38103529afbe759a8da4b4880e4ad715e2310606b9eab98ab0d74a00ea0f26be83cad06fdc372bed6c54ec5dcbf68b6f54025e649eaee0788b59b9d2ecea3a2",
        weight: 7,
        description_x:
          '"The Frame" TV is designed to serve as a centerpiece within a gallery wall',
        human_readable_id: 424,
        n_tokens: 78,
        title: "paige_mariah on TikTok",
        description_y:
          "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
        url: "https://www.tiktokv.com/share/video/7451658876052688159/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7451658876052688159.mp4",
        video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
        time_saved: "2025-01-31 22:29:30",
      },
      id: "218443b5-823f-4172-bd3b-9fb869042e33",
      source: {
        id: "58a57ff8-0066-4ec4-9a44-bc2534ed98f6",
        name: "THE FRAME",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
      target: {
        id: "fbdfade2-a57d-4481-a5cf-410cb3a5995a",
        name: "GALLERY WALL",
        group: 1,
        data: {
          title: "paige_mariah on TikTok",
          description:
            "Replying to @TELAN here are all the deetz on my Samsung Frame TV—I’m completely obsessed. It definitely elevates the vibe of my space!  #newapartment #firstapartment #samsungtv #theframe #samsungframetv #gallerywall #dopaminedecor #apartmenttour ",
          url: "https://www.tiktokv.com/share/video/7451658876052688159/",
          video_id: "a0902b02-8842-4231-9384-54fcd94c9bdd",
          time_saved: "2025-01-31 22:29:30",
        },
      },
    },
    {
      source_id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
      target_id: "eaa86736-e1d3-4f7c-89df-f3bb1212fe24",
      data: {
        text_unit_ids:
          "253b7b8734431f120a0a918656e2e9db00d1629e95181fde96f49431d5f196eed4f1845c4a25b5b72163eb45f462684b8f1c8374514e84408866143106cc390d",
        weight: 1,
        description_x:
          "The claw side table crafted from live-edge wood offers a natural contrast to the urban skyline backdrop",
        human_readable_id: 435,
        n_tokens: 104,
        title: "Ian Kim on TikTok",
        description_y:
          "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
        url: "https://www.tiktokv.com/share/video/7400141960498908447/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7400141960498908447.mp4",
        video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
        time_saved: "2025-02-02 00:47:29",
      },
      id: "1f7ad3b2-2510-454b-b10c-51747c051eb7",
      source: {
        id: "c43e35e9-133b-49bd-9c77-15bbe97c5997",
        name: "CLAW SIDE TABLE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
      target: {
        id: "eaa86736-e1d3-4f7c-89df-f3bb1212fe24",
        name: "URBAN SKYLINE",
        group: 1,
        data: {
          title: "Ian Kim on TikTok",
          description:
            "When furnishing your place, Amazon is your best friend. All links to these in my link🌳 in bio.  #amazonfinds #homedecor #interiordesign #apartmenttherapy #apartmentdecor #livingroomdecor  #midcenturymodern #apartmentinspo #livingroominspo",
          url: "https://www.tiktokv.com/share/video/7400141960498908447/",
          video_id: "8dd0cfbf-7202-411e-b829-782910bbade5",
          time_saved: "2025-02-02 00:47:29",
        },
      },
    },
    {
      source_id: "88931f4a-1767-42b2-85b6-bf89de48ab45",
      target_id: "990fcd5c-ac50-4898-9374-af3ede519997",
      data: {
        text_unit_ids:
          "109e1e99734f9f4f0739e2f5910c9e6c83eeac26ee038bb0947cea664cb23d319c26c322ed2752c3e23bfa8afe05e9e7cff0b0ad14d5be7de3d30a92e8880c78",
        weight: 1,
        description_x:
          "170+ scorers employ prediction techniques to improve their performance on the LSAT",
        human_readable_id: 450,
        n_tokens: 143,
        title: "Bianca on TikTok",
        description_y:
          "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
        url: "https://www.tiktokv.com/share/video/7449974855551733034/",
        db_url:
          "https://storage.googleapis.com/graphify-videos/7449974855551733034.mp4",
        video_id: "0a26f721-1dfa-4e37-a776-736636116511",
        time_saved: "2025-01-11 03:15:04",
      },
      id: "b36e9dec-045b-45d4-ae69-08df36d57294",
      source: {
        id: "88931f4a-1767-42b2-85b6-bf89de48ab45",
        name: "170+ SCORER",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
      target: {
        id: "990fcd5c-ac50-4898-9374-af3ede519997",
        name: "PREDICTION",
        group: 1,
        data: {
          title: "Bianca on TikTok",
          description:
            "A non-negotiable!✨🥰 #lsat #lsatstudying #lsats #lawschool #law #resume #180 #lsattips #lawyersoftiktok #lawyer #prelaw #lawstudent #lsathelp #lsatexam #lsattip #lsatprep #tutor #satexam #barprep #fyp #trending ",
          url: "https://www.tiktokv.com/share/video/7449974855551733034/",
          video_id: "0a26f721-1dfa-4e37-a776-736636116511",
          time_saved: "2025-01-11 03:15:04",
        },
      },
    },
  ],
};

export default mockData;
