// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyDuekSJBrjldPos0wFPWzZLULVMml7Od2E",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "graphify-9099c.firebaseapp.com",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "graphify-9099c",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "graphify-9099c.firebasestorage.app",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "1003948463659",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:1003948463659:web:a8f9daac8d724a1312fc6e"
};



const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;