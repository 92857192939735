import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TikTokIcon from "@mui/icons-material/MusicNote"; // Using MusicNote as TikTok icon
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FolderIcon from "@mui/icons-material/Folder";
import CloseIcon from "@mui/icons-material/Close";

const AuthButton = styled(Button)(({ theme }) => ({
  padding: "12px 24px",
  borderRadius: "8px",
  textTransform: "none",
  fontWeight: 600,
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
  },
}));

const UploadCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px",
  border: `1px solid rgba(135, 135, 135, 0.43)`,
  transition: "all 0.2s ease-in-out",
  backgroundColor: 'rgba(0, 0, 0, 0.39)',
  borderRadius: '12px',
}));

const UploadButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "12px",
  marginTop: "16px",
  borderRadius: "8px",
  textTransform: "none",
  fontWeight: 500,
  boxShadow: "none",
}));

const UploadInput = styled("input")({
  display: "none",
});

const platformInfo = {
  tiktok: {
    icon: <TikTokIcon sx={{ fontSize: 40 }} />,
    title: "TikTok Data",
    subtitle: 'Upload your TikTok "Activity" Data',
    formats: "ZIP",
    color: "#25F4EE",
  },
  google: {
    icon: <GoogleIcon sx={{ fontSize: 40 }} />,
    title: "Google Data",
    subtitle: "Upload your Google Takeout data",
    formats: "ZIP",
    color: "#4285F4",
  },
  instagram: {
    icon: <InstagramIcon sx={{ fontSize: 40 }} />,
    title: "Instagram Data",
    subtitle: "Upload your Instagram data export",
    formats: "ZIP",
    color: "#C13584",
  },
  twitter: {
    icon: <TwitterIcon sx={{ fontSize: 40 }} />,
    title: "Twitter Data",
    subtitle: "Upload your Twitter data archive",
    formats: "ZIP",
    color: "#1DA1F2",
  },
};

const DataUploadPage = ({
  loading,
  uploadData,
  setUploadData,
  handleSkipUpload,
  handleUploadComplete,
  activeStep,
  setActiveStep,
  uploadingLoad,
}) => {
  const [uploading, setUploading] = useState({
    tiktok: false,
    google: false,
    instagram: false,
    twitter: false,
  });


  const renderUploadCard = (platform) => {
    const { icon, title, subtitle, formats, color } = platformInfo[platform];
    const isUploaded =
      uploadData && uploadData[platform] && uploadData[platform].length > 0;
    const isUploading = uploading[platform];

    const handleFileUpload = async (platform, event) => {
      const file = event.target.files[0];
      if (!file) return;

      // Check if it's a ZIP file
      if (!file.name.endsWith(".zip")) {
        alert("Please upload a ZIP file");
        return;
      }

      // Set uploading state
      setUploading((prev) => ({ ...prev, [platform]: true }));

      try {
        // For large files, we'll use a streaming approach
        // Load the JSZip library (you need to include this in your project)
        const JSZip = await import("jszip");
        const zip = new JSZip.default();

        // Read the zip file
        const zipContent = await zip.loadAsync(file);

        const extractedFiles = [];

        for (const filename in zipContent.files) {
          const zipEntry = zipContent.files[filename];

          // Skip directories
          if (zipEntry.dir) continue;

          // Check if it's an activity file
          const isActivityFile = filename.includes("Activity");

          if (isActivityFile) {
            // Extract file info

            const content = await zipEntry.async("text");

            const fileBlob = new Blob([content], { type: "text/plain" });

            const fileObject = new File([fileBlob], filename.split('/').pop(), {
              type: "text/plain",
              lastModified: new Date().getTime()
            });

            console.log("fileObject", fileObject)

            extractedFiles.push({
              fileName: filename,
              path: filename,
              content: fileObject,
              size: zipEntry._data ? zipEntry._data.uncompressedSize : 0,
              date: new Date(zipEntry.date).toISOString(),
              status: "completed",
            });
          }
        }

        setUploadData((prev) => ({
          ...prev,
          [platform]: extractedFiles,
        }));

        // Additional handling - you could read the Activity.txt content if needed
        console.log(
          `Extracted ${extractedFiles.length} files from ${platform} archive`
        );
        if (extractedFiles.length === 0) {
          alert("No files found in the uploaded ZIP. Please ensure the ZIP contains the correct Activity files and structure.");
          return;
        }
      } catch (error) {
        console.error(`Error processing ${platform} data:`, error);
        alert(
          `There was an error processing your ${platform} data. Please try again.`
        );
      } finally {
        setUploading((prev) => ({ ...prev, [platform]: false }));
      }
    };

    return (
      <UploadCard>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "64px",
            height: "64px",
            borderRadius: "50%",
            backgroundColor: `${color}15`,
            color: color,
            mb: 2,
          }}
        >
          {icon}
        </Box>

        <Typography variant="h6" fontWeight="600" textAlign="center">
          {title}
        </Typography>

        <Typography
          variant="body2"
          color="rgba(255, 255, 255, 0.83)"
          textAlign="center"
          sx={{ mb: 1 }}
        >
          {subtitle}
        </Typography>

        <Typography variant="caption" color="rgba(255, 255, 255, 0.83)" textAlign="center">
          Supported formats: {formats}
        </Typography>

        {isUploaded ? (
          <>
            <Box sx={{ mt: 2, width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="subtitle2" fontWeight="600" mb={1}>
                  Uploaded Files ({uploadData[platform].length})
                </Typography>
                <CloseIcon
                  sx={{
                    mr: 1,
                    fontSize: 20,
                    cursor: "pointer",
                    "&:hover": { color: "error.dark" },
                  }}
                  onClick={() => {
                    setUploadData((prev) => ({ ...prev, [platform]: null }));
                    setUploading((prev) => ({ ...prev, [platform]: false }));
                  }}
                />
              </Box>
              <Box
                sx={{
                  maxHeight: "150px",
                  overflowY: "auto",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "8px",
                  p: 1,
                }}
              >
                {uploadData[platform].map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                      borderRadius: "4px",
                      mb: 0.5,
                      bgcolor: "success.light",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{ mr: 1, fontSize: 16, color: "success.main" }}
                    />
                    <Typography
                      variant="caption"
                      noWrap
                      sx={{ maxWidth: "100%" }}
                    >
                      {file.fileName}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            {/* 
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
                p: 1,
                borderRadius: "8px",
                backgroundColor: "success.light",
                color: "success.dark",
                "&:hover": {
                  borderColor: "success.dark",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
                },
              }}
            >
              <CheckCircleIcon sx={{ mr: 1, fontSize: 20 }} />
              <Typography variant="body2" fontWeight="500">
                Uploaded: {uploadData[platform].fileName}
              </Typography>
              <CloseIcon
                sx={{
                  mr: 1,
                  fontSize: 20,
                  cursor: "pointer",
                  "&:hover": { color: "error.dark" },
                }}
                onClick={() => {
                  setUploadData((prev) => ({ ...prev, [platform]: null }));
                  setUploading((prev) => ({ ...prev, [platform]: false }));
                }}
              />
            </Box> */}
          </>
        ) : (
          <label htmlFor={`upload-${platform}`}>
            <UploadInput
              sx={{
                color: 'rgba(255, 255, 255, 0.83)',
                borderColor: 'rgba(255, 255, 255, 0.39)',
                '&:hover': {
                  borderColor: 'rgba(255, 255, 255, 0.6)',
                }
              }}
              id={`upload-${platform}`}
              type="file"
              accept=".zip"
              onChange={(e) => handleFileUpload(platform, e)}
              disabled={isUploading}
            />
            <UploadButton
              sx={{
                color: 'rgba(255, 255, 255, 0.83)',
                borderColor: 'rgba(255, 255, 255, 0.39)',
                '&:hover': {
                  borderColor: 'rgba(255, 255, 255, 0.6)',
                }
              }}
              variant="outlined"
              component="span"
              startIcon={
                isUploading ? <CircularProgress size={20} /> : <FolderIcon sx={{ color: 'rgba(255, 255, 255, 0.83)' }} />
              }
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Choose Folder"}
            </UploadButton>
          </label>
        )}
      </UploadCard>
    );
  };

  const anyUploaded =
    uploadData &&
    Object.values(uploadData).some(
      (platform) => platform && platform.length > 0
    );
  const anyUploading = Object.values(uploading).some((status) => status);

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h5" fontWeight="700" mb={2}>
        We are almost done!
      </Typography>

      <Typography variant="body2" color="rgba(255, 255, 255, 0.83)" mb={3}>
        Upload your social media data to generate your personalized graph. This
        data is essential for the system to function, but it remains private and
        securely encrypted at all times.
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
          gap: 3,
          mb: 4,
        }}
      >
        <Box
          component="iframe"
          src={"https://www.tiktokv.com/share/video/7459471816411630878/".replace(
            "tiktokv.com/share/video",
            "tiktok.com/embed"
          )}
          sx={{
            width: "400px",
            height: "650px",
            border: 0,
            bgcolor: "transparent",
            overflow: "hidden",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Box sx={{ gap: 2 }}>
          {renderUploadCard("tiktok")}

          {/* {renderUploadCard('twitter')} */}
        </Box>

        {/* {renderUploadCard('google')}
        {renderUploadCard('instagram')}
        {renderUploadCard('twitter')} */}
      </Box>

      <Divider sx={{ my: 3 }} />

      <Typography variant="body2" color="rgba(255, 255, 255, 0.83)" mb={3}>
        Not sure how to download your data?
        <a href="https://support.tiktok.com/en/account-and-privacy/personalized-ads-and-data/requesting-your-data">
          {" "}
          Click here{" "}
        </a>{" "}
        to view instructions for downloading your data from TikTok. Be sure to
        include the "Activity" data in your download as a .txt file and upload
        the entire zip folder as is.
      </Typography>

      <Box sx={{ display: "flex", gap: 2 }}>
        <AuthButton
          variant="outlined"
          onClick={() => setActiveStep(activeStep - 1)}
          sx={{ flex: 1, color: 'white', borderColor: 'rgba(255, 255, 255, 0.39)', '&:hover': { borderColor: 'rgba(255, 255, 255, 0.6)', backgroundColor: 'rgba(255, 255, 255, 0.10)' } }}
        >
          Go Back
        </AuthButton>
        <AuthButton
          disabled={!anyUploaded || uploadingLoad}
          variant="contained"
          color="primary"
          onClick={handleUploadComplete}
          sx={{ flex: 1, backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)',
            '&:hover': {
              backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)',
            }
          }}
        >
          {uploadingLoad ? "Loading..." : "Complete"}
        </AuthButton>
      </Box>
    </Box>
  );
};

export default DataUploadPage;
