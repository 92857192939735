import axios from 'axios';
import { auth } from '../firebase';
import { BASE_URL } from './configs';


export async function fetchWithAuth(url, options = {}) {
  const token = await auth.currentUser?.getIdToken();

  if (!token) {
    throw new Error('Not authenticated');
  }

  // Create base headers with authorization
  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${token}`
  };

  // Special handling for FormData
  if (options.body instanceof FormData) {
    return fetch(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${token}`,
        ...options.headers
      }
    });
  }

  // Special handling for JSON data
  if (options.body && typeof options.body === 'object' && !(options.body instanceof Blob) && !(options.body instanceof ArrayBuffer)) {
    return fetch(url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(options.body)
    });
  }

  // Default case for other types of requests
  return fetch(url, {
    ...options,
    headers
  });
}


export async function userCheck() {
    const response = await fetchWithAuth(`${BASE_URL}/users/me`, {
        method: 'GET',
    })

    if (!response.ok) {
        throw new Error('Failed to fetch user profile');
    }
    const data = await response.json();
    return data;
}

export async function userUpdate(userData) {
    const response = await fetchWithAuth(`${BASE_URL}/users/me`, {
        method: 'PATCH',
        body: userData,
    })

    if (!response.ok) {
        throw new Error('Failed to update user profile');
    }

    return await response.json();
}


export async function uploadToGoogleCloud(fileData, platform, user_id) {
  // Get the file content if it's not already a blob
  const fileContent = fileData.content || new Blob(['placeholder content']);

  // Create form data for the upload
  const formData = new FormData();
  formData.append('file', fileContent, fileData.fileName);
  formData.append('platform', platform);

  // Call your cloud function or backend service
  const response = await fetchWithAuth(`${BASE_URL}/uploads/gcs-upload`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Failed to upload to Google Cloud Storage');
  }

  const data = await response.json();
  return data.url; // Return the public URL
};


export async function createSurvey(surveyData) {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/survey/create`, {
      method: 'POST',
      body: surveyData  // No need to manually stringify or set Content-Type
    });

    if (!response.ok) {
      throw new Error(`Server returned ${response.status}: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating survey:', error);
    throw error;
  }
};


export async function processFiles(platform, limit=100) {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/uploads/process-files?platform=${encodeURIComponent(platform)}&limit=${limit}`, {
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to process files');
    }

    return await response.json();
  } catch (error) {
    console.error('Error processing files:', error);
    throw error;
  }
}

export async function checkJobStatus(user_id) {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/jobs/check_job_status?user_id=${user_id}`, {
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to check job status');
    }

    return await response.json();
  } catch (error) {
    console.error('Error checking job status:', error);
    throw error;
  }
}

export async function getGraphData() {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/graphs/my`, {
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to get graph data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error getting graph data:', error);
    throw error;
  }
}

export async function createNode(node) {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/nodes`, {
      method: 'POST',
      body: node
    });

    if (!response.ok) {
      throw new Error('Failed to create node');
    }

    return await response.json();
    
  } catch (error) {
    console.error('Error creating node:', error);
    throw error;
  }
}

export async function getVideoUrl(video_id) {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/videos/${video_id}`, {
      method: 'GET',
    }); 

    if (!response.ok) {
      throw new Error('Failed to get video url');
    }

    return await response.json();
  } catch (error) {
    console.error('Error getting video url:', error);
    throw error;
  }
}