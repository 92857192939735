import React, { useState, useEffect, useMemo } from "react";
import GraphVisualizer from "./GraphVisualizer";
import TiktokPanel from "./TiktokPanel";
import { Typography, Box, fabClasses, Button } from "@mui/material";
import WelcomeModal from "./WelcomeModal";
import { AuthProvider } from "./contexts/AuthContext";
import AuthGuard from "./components/AuthGuard";
import { useAuth } from "./contexts/AuthContext";
import { LinearProgress } from "@mui/material";
import { checkJobStatus, processFiles, userCheck } from "./utils/api";
import ReactGA from "react-ga4";
import DemoGraphVisualizer from "./DemoGraphVisualizer";

ReactGA.initialize('G-E2GWYEL7RS');

const UserMenu = ({ handleTryItOut }) => {
  const { currentUser, logout, exploring, setExploring } = useAuth();
  if (!currentUser && !exploring) return null;

  if (exploring)
    return (
      <div style={{ justifyContent: 'right', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, marginRight: 10 }}>
        <Button onClick={() => { handleTryItOut(); setExploring(false); }} variant="contained" size="small" sx={{ backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)', color: 'white', boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)', '&:hover': { backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)', } }}>
          Sign in
        </Button>
      </div>
    );

  return (
    <div style={{ justifyContent: 'right', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
      <div style={{ color: "white", fontSize: "12px" }}>{currentUser.email}</div>
      <Button variant="contained" size="small" sx={{ backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)', color: 'white', boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)', '&:hover': { backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)', } }} onClick={logout}>Logout</Button>
    </div>
  );
};

const ProcessingWarning = ({ warning, setWarning }) => {
  const { currentUser, userOnboardingStatus } = useAuth();
  const [processing, setProcessing] = useState(false);

  const handleTryItOut = () => {
    console.log("try it out");
    alert("Graph data is just starting to be processed. Please wait... and try again.");

    window.location.reload();
  }

  useEffect(() => {
    if (currentUser && userOnboardingStatus.isComplete == true) {
      userCheck().then((response) => {
        console.log("user check", response)
        if (response.userOnboardingStatus?.isComplete) {
          checkJobStatus(response.id).then((response) => {
            console.log("job status", response)
            if (response.length > 0 && response[0].status === "completed") {
              setProcessing(false);
              setWarning(false);
            } else {
              console.log("processing", response[0])
              setProcessing(true);
              setWarning(true);
            }
          })
        }
      })
    }
  }, [currentUser, warning, userOnboardingStatus]);


  if (processing && warning && currentUser) return (
    <div style={{ position: 'fixed', zIndex: 1000, justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
      <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: '#ffffff', textAlign: 'center' }}>We are processing your graph data. We will email you when it is ready. (This could take anywhere from 10 minutes to a few hours)</Typography>
      <Typography sx={{ fontSize: 10, fontWeight: 'medium', color: '#ffffff', mt: 4 }}>You can't connect the dots looking forward; you can only connect them looking backwards. - Steve Jobs</Typography>
      <Button variant="contained" size="small" sx={{ mt: 2, backgroundImage: 'linear-gradient(135deg, #6E00B3 0%,rgb(143, 0, 179) 100%)', color: 'white', boxShadow: '0px 4px 20px rgba(110, 0, 179, 0.4)', '&:hover': { backgroundImage: 'linear-gradient(135deg, #7C00CC 0%, #C600CC 100%)', } }} onClick={handleTryItOut}>
        Refresh
      </Button>
    </div>
  );
};

const GraphVisualizers = ({ warning, setWarning, selectedNode, setSelectedNode, setShowVideo, highlightLinks, setHighlightLinks, highlightNodes, setHighlightNodes }) => {
  const { currentUser, exploring } = useAuth();

  const isAuthenticated = useMemo(() => {
    return currentUser && !exploring;
  }, [currentUser, exploring]);
  
  if (isAuthenticated) return (
    <div>
      <GraphVisualizer exploring={exploring} warning={warning} setWarning={setWarning} selectedNode={selectedNode} setSelectedNode={setSelectedNode} setShowVideo={setShowVideo} highlightLinks={highlightLinks} setHighlightLinks={setHighlightLinks} highlightNodes={highlightNodes} setHighlightNodes={setHighlightNodes} />
    </div>
  )
  else return (
    <div>
      <DemoGraphVisualizer exploring={exploring} warning={warning} setWarning={setWarning} selectedNode={selectedNode} setSelectedNode={setSelectedNode} setShowVideo={setShowVideo} highlightLinks={highlightLinks} setHighlightLinks={setHighlightLinks} highlightNodes={highlightNodes} setHighlightNodes={setHighlightNodes} />
    </div>
  )
}

function App() {
  const [selectedNode, setSelectedNode] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [warning, setWarning] = useState(false);


  const closePanel = () => {
    setShowVideo(false);
    setHighlightLinks(new Set());
    setHighlightNodes(new Set());
  };



  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleTryItOut = () => {
    console.log("try it out");

    // processFiles('tiktok').then(response => {
    //   console.log(response);
    //   if (response.status === "starting") {
    //   }
    //   if (response.status === "finishing") {
    //     alert("Graph data processed successfully");
    //   }
    //   if (response.status === "running") {
    //     alert("Graph data is being processed. Please wait... and try again.");
    //   }
    alert("Graph data is just starting to be processed. Please wait... and try again.");

    window.location.reload();

    // }).catch(error => {
    //   console.error('Error processing files:', error);
    // });

  }

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-E2GWYEL7RS";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-E2GWYEL7RS');
    `;
    document.head.appendChild(script2);
  }, []);



  function endExploreHandler() {
    setWarning(false);
    setSelectedNode(null);
    setShowVideo(false);
  }





  return (
    <AuthProvider>
      <AuthGuard>
        <div style={{ height: "100vh", background: "#000000" }}>
          {warning && <LinearProgress color="white" sx={{ width: '100%', height: '10px', backgroundColor: '#6E00B3' }} />}
          <Box
            sx={{
              width: '100%',
              zIndex: 1000,
              background: 'rgba(0, 0, 0, 0)', // Optional: semi-transparent background
              backdropFilter: 'blur(10px)',    // Optional: frosted glass effect
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: 2
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <Box>
                <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: '#ffffff' }}>
                  Graphify.me
                </Typography>
                <Typography sx={{ fontSize: 10, fontWeight: 'medium', color: '#ffffff' }}>
                  Created by @talbertherndon
                </Typography>
                <Typography sx={{ fontSize: 10, fontWeight: 'medium', color: '#ffffff' }}>
                  Selected Node: {selectedNode ? selectedNode.name : 'None'}
                </Typography>
              </Box>


            </Box>
            <UserMenu handleTryItOut={endExploreHandler} />
          </Box>

          <ProcessingWarning warning={warning} setWarning={setWarning} />
          <TiktokPanel open={showVideo} selectedNode={selectedNode} isExpanded={isExpanded} toggleExpand={toggleExpand} closePanel={closePanel} highlightNodes={highlightNodes} />
          <GraphVisualizers warning={warning} setWarning={setWarning} selectedNode={selectedNode} setSelectedNode={setSelectedNode} setShowVideo={setShowVideo} highlightLinks={highlightLinks} setHighlightLinks={setHighlightLinks} highlightNodes={highlightNodes} setHighlightNodes={setHighlightNodes} />
        </div>
      </AuthGuard>
    </AuthProvider>
  );
}

export default App;